export const rules = {
	role:{
		required: value => !!value || "Role is required!",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Role must be valid!"
	},			
	
	shift_name:{
		required: value => !!value || "Shift name is required!",
	},

	break_name:{
		required: value => !!value || "Break name is required!",
	},

	breaks:{
		required: value => !!value || "Break Type is required!",
	},	

	fee:{
		required: value => !!value || "Fee Type is required!",
	},


	attendance_name:{
		required: value => !!value || "Attendance name is required!",
	},

	log:{
		required: value => !!value || "Log Type is required!",
	},

	condition:{
		required: value => !!value || "Condition Type is required!",
	},

	timeframe:{
		required: value => !!value || "Timeframe Type is required!",
	},


	avatar:{
		type:value =>!value || value.type == "image/jpeg" || "Avatar must be a type of jpg/jpeg",
		size:value =>!value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
	},

	facebio:{
		type:value =>!value || value.type == "image/jpeg" || "Face biometrics must be a type of jpg/jpeg",
		size:value =>!value || value.size < 2000000 || 'Face biometrics size should be less than 2 MB!',
	},

	logo:{
		type:value =>!value || value.type == "image/jpeg" || value.type == "image/png" || "Logo must be a type of jpeg/png",
		size:value =>!value || value.size < 2000000 || 'Logo size should be less than 2 MB!',
	},

	first_name:{
		required: value => !!value || "First Name is required !",
		format:value => /^[a-zA-Z\s\.Ññ]*$/i.test(value) || "First Name must be valid !"
	},

	middle_name:{
		format:value => /^[a-zA-Z\s\.Ññ]*$/.test(value) || "Middle Name must be valid !"
	},

	last_name:{
		required: value => !!value || "Last Name is required !",
		format:value => /^[a-zA-Z\s\.Ññ]*$/.test(value) || "Last Name must be valid !"
	},

	suffix:{
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Suffix must be valid!"
	},

	employee_id:{
		required:value => !!value ||"Employee ID is required !",
	},

	email:{ 
		required:value => !!value || "E-mail is required !",
		format:value => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) || "E-mail must be valid !"
	},

	password:{ 
		required:value => !!value || "Password is required !",
		length:value => ( value && value.length >= 8 ) || "Password must be minimum of 8 characters",
	},

	confirm_password:{ 
		required:value => !!value || "Confirm Password is required !",
		length:value => ( value && value.length >= 8 )|| "Confirm Password must be minimum of 8 characters",
	},
	
	position:{
		required: value => !!value || "Position is required !",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Position must be valid !"
	},

	region:{
		required: value => !!value || "Region is required !",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Region must be valid !"
	},

	assigned_position:{
		required: value => !!value || "Assigned position is required !",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Assigned position must be valid !"
	},

	outlet:{
		required: value => !!value || "Outlet is required !",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Outlet must be valid !"
	},

	organization:{
		required:value => !!value ||"Organization is required !",
	},

	organization_name:{
		required:value => !!value ||"Organization Name is required !",
		format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Organization Name must be valid !"
	},

	organization_short_name:{
		required:value => !!value ||"Organization Short Name is required !",
		format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Organization Code must be valid !"
	},

	organization_code:{
		required:value => !!value ||"Organization Code is required !",
		format:value => /^[0-9]*$/.test(value) || "Organization Code must be valid !",
		length:value => ( value && value.length == 6 ) || "Organization Code must be 6 digits",
	},

	// ------------------------------- SITE MODULES ----------------------------------

		site_name:{
			required:value => !!value ||"Site Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Site Name must be valid !"
		},

		site_short_name:{
			required:value => !!value ||"Site Short Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Site Code must be valid !"
		},

		site_code:{
			required:value => !!value ||"Site Code is required !",
			format:value => /^[a-zA-Z0-9]*$/.test(value) || "Site Code must be valid !",
			length:value => ( value && value.length == 6 ) || "Site Code must be 6 digits",
		},

		site:{
			required:value => !!value ||"Site is required !",
		},

	// ------------------------------- ACCOUNT MODULES -------------------------------

		account_name:{
			required:value => !!value ||"Account Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Account Name must be valid !"
		},

		account_short_name:{
			required:value => !!value ||"Account Short Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Account Code must be valid !"
		},

		account_code:{
			required:value => !!value ||"Account Code is required !",
			format:value => /^[a-zA-Z0-9]*$/.test(value) || "Account Code must be valid !",
			length:value => ( value && value.length == 6 ) || "Account Code must be 6 digits",
		},

		account:{
			required:value => !!value ||"Account is required !",
		},

	// ------------------------------- ASSIGNMENT MODULES ----------------------------

		assignment_name:{
			required:value => !!value ||"Assignment Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Assignment Name must be valid !"
		},

		assignment_short_name:{
			required:value => !!value ||"Assignment Short Name is required !",
			format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Assignment Code must be valid !"
		},

		assignment_code:{
			required:value => !!value ||"Assignment Code is required !",
			format:value => /^[a-zA-Z0-9]*$/.test(value) || "Assignment Code must be valid !",
			length:value => ( value && value.length == 6 ) || "Assignment Code must be 6 digits",
		},

		assignment_description:{
			required:value => !!value ||"Assignment Description is required !",
			format:value => /^[a-zA-Z0-9\s\b]*$/.test(value) || "Assignment Description must be valid !",
		},

		assignment:{
			required:value => !!value ||"Assignment is required !",
		},

	// ------------------------------- EMPLOYEE ROLE MODULES -------------------------

		employee_role:{
			required:value => !!value ||"Employee Role is required !",
			format:value => /^[a-zA-Z\s\b]*$/.test(value) || "Employee Role must be valid !"
		},

		employee_role_code:{
			required:value => !!value ||"Employee Role Code is required !",
			format:value => /^[0-9]*$/.test(value) || "Employee Role Code must be valid !",
			length:value => ( value && value.length == 6 ) || "Employee Role Code must be 6 digits",
		},

	// ------------------------------- ASSIGNMENT COMPOSER ---------------------------

		title:{
			required:value => !!value || "Title is required",
		},

		button_text:{
			required:value => !!value || "Button Text is required",
		},

		paragraph:{
			required:value => !!value || "Paragraph is required",
		},


	person_name:{
		required: value => !!value || "Person Name is required !",
		format:value => /^[a-zA-Z\s]*$/.test(value) || "Person Name must be valid !"
	},

	date:{
		required: value => !!value || "Date is required !",
	},

	time:{
		required: value => !!value || "Time is required !",
	},

	url:{
		check:value => !value,
	},

	alphaNumericWithDashOnly(event){
		if (!/[a-zA-Z0-9-\s\b]/.test(event.key)) return event.preventDefault();
	},

	alphaNumericOnly(event){
		if (!/[a-zA-Z0-9\s\b]/.test(event.key)) return event.preventDefault();
	},

	integerOnly(event){
		if (!/[0-9\b]/.test(String.fromCharCode(event.which))) return event.preventDefault();
	},

	stringOnly(event){
   		if(!/[a-zA-Z\s\b\.Ññ]/.test(event.key)) return event.preventDefault();
	},

    isEmail(event){
		if(!/[a-zA-Z\.\s\b]/.test(String.fromCharCode(event.which))) return event.preventDefault(); 
	}
}