import store from "@/store"

const state = { 
	users:{ list:[] }
}

const getters = {

	/*
	* GET Users list
	* to set the list after fetching
	*/	
	getUserList:state=>{
		return state.users.list;
	}
}

const mutations = {

	/*
	* GET Users list
	* to get the list after fetching
	*/	
	GET_USER_LIST:(state,list)=>{
		state.users.list = list;
	},

	/*
	* ADD Users record
	* to set the list additional record after add/registraion
	*/	
	ADD_USER_RECORD:(state,data)=>{
		Object.assign(state.users.list, data);
	},

 	/*
	* UPDATE Users record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_USER_RECORD:(state,data)=>{
		let index = state.users.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.users.list[index],data)
	},

	/*
	* DELETE Users record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_USER_RECORD:(state,id) => {
		let index = state.users.list.findIndex(list => list.id == id)
		state.users.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch Users list
	*
	* @return string
	*/	
	getUserList:(context)=>{
		axios.get(`/users`)
		.then((response) => { context.commit('GET_USER_LIST',response.data);})
		.catch((errors)  => console.log(errors));
	},

	/*
	* Search site 
	*
	* @return array
	*/	
	setSearchUser(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/users/search", { params:{ keyword:keyword } })
			.then((response)=>{ context.commit('GET_USER_LIST',response.data); resolve(response); })
			.catch((errors)=>reject(errors))
		});
	},

	/*
	* Add/Register Users record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterUser:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/users`,data)
			.then((response)=>{ context.dispatch("getUserList");})
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Edit/Update Users record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateUser:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.put(`/users/${data.id}`,data)
			.then((response)=>{ context.dispatch("getUserList");})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>{ resolve(response) });
		});
	},
	
	/*
	* Delete/Remove Users record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteUser:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.delete(`/users/${data.id}`)
			.then((response)=>{ context.dispatch("getUserList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const user = {
	state,
	getters,
	mutations,
	actions
}