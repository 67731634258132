import { mapActions, mapGetters } from 'vuex';

export const auth = {
	
	methods:{

		...mapGetters(["authUserRole","authUserOrganization","authUserInfo"]),

		...mapActions(["profile"]),

		/*
		* Get authenticated profile
		*
		* @return void 
		*/
		getProfile()
		{
			this.profile();
		},
		
		/*
		* Get authenticated role
		*
		* @return array 
		*/
		getAuthRole()
		{
			return this.authUserRole;
		},
		
		/*
		* Get authenticated information
		*
		* @return array 
		*/
		getAuthInfo()
		{
			return this.authUserInfo;
		},

		/*
		* Get authenticated organization
		*
		* @return array 
		*/
		getAuthOrganization()
		{
			return this.authUserInfo;
		},

		AuthRoleAllowed(role,redirect = "/")
		{
			if(this.AuthRole != role)
			{
				this.$router.push(redirect);
			}
		}
	},

	computed:{

		AuthUserInfo:
		{	
			set(value)
			{
				return value;
			},

			get()
			{
				return this.$store.getters.authUserInfo;
			}

		},


		AuthInfo:
		{	
			set(value)
			{
				return value;
			},

			get()
			{
				return this.$store.getters.authUserInfo;
			}

		},

		AuthOrganization:
		{	
			set(value)
			{
				return value;
			},
			
			get()
			{
				return this.$store.getters.authUserOrganization;
			}
		},

		AuthRole:
		{	
			set(value)
			{
				return value;
			},
			
			get()
			{
				return this.$store.getters.authUserRole;
			}
		},
	},
} 