<template>
	<v-autocomplete
		dense
		outlined
		v-model="sites"
		item-value="id"
		item-text="name"
		append-icon="search"
		:items="values"
		:disabled="disabled"
		:multiple="multiple"
		:placeholder="placeholder"
		@change="onChangeValue($event)"
	>	
		<template v-slot:selection="{ attrs, item, selected, index }">
			<!-- v-if="data.selected.length < sites.length" -->
			<div v-if="index <= 4">
				<v-chip
					close 
					v-bind="attrs" 
					:input-value="selected"
					@click:close="remove(item.id)"
				>
					<v-avatar left>
						<v-img :src="item && item.organization ? item.organization.logo : default_logo"/>
					</v-avatar>
					{{ item.name }}
				</v-chip>
				<v-chip
					v-if="index >= 4"
					v-bind="attrs" 
					:input-value="selected"
					@click:close="remove(item.id)"
				>
					...
				</v-chip>
			</div>
			<!-- <div v-if="selected.length < sites.length">
				<v-chip v-if="index < 5"
						small
						@click.stop="">
				<span> {{selected[index]}} </span>
				</v-chip>
				<v-chip v-else-if="index === 5"
						small
						@click.stop="">
						<span> ... </span>
				</v-chip>
			</div> -->
		</template>

		<!-- <template v-slot:selection="data">
			<v-chip
				close 
				v-bind="data.attrs" 
				:input-value="data.selected"
				@click:close="remove(data.item.id)"
			>
				<v-avatar left>
					<v-img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo"/>
				</v-avatar>
				{{ data.item.name }}
			</v-chip>
		</template> -->
		<template v-slot:item="data">
			<template v-if="data.item">
				<v-list-item-content v-text="data.item.name"/>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"/>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";

export default {
	props:["placeholder","site","disabled","multiple"],
	mixins:[modal,states,auth],
    data () {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			isEmpty: false,
			sites:"",
			 txt: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Expedita consectetur suscipit vel! Aliquam, temporibus laborum delectus ea architecto obcaecati laudantium eos sed ducimus cum error repellat voluptatum ullam fugit, officia sit laboriosam vero similique. Voluptatibus provident magnam libero facilis eum in, labore pariatur optio nesciunt culpa, delectus tenetur, sit quod praesentium? Iusto, non ipsam quis aliquid veniam aliquam placeat minima cupiditate expedita enim alias saepe vero autem. Voluptatum doloribus distinctio illo adipisci expedita, sequi maxime aut ratione magnam ipsum velit vero officia odio deserunt pariatur minima! At, magni aliquam! Ipsum impedit non quos fugiat veniam dolorem ad itaque necessitatibus voluptas?'
		}
    },

	created()
	{	
		this.initilize();
	},

    methods:{

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/site/index 
 		*/ 
		initilize()
		{	
			if(this.site)
			{	
				// select organization with organization id 
				this.sites = this.site;
			}
 		},

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item) 
		{	
			if(typeof this.sites === "number")
			{
				this.sites = null;
			}

			else if (typeof this.sites === "object")
			{	
				var removeIndex = this.sites.indexOf(item);
				if(removeIndex >= 0) this.sites.splice(removeIndex, 1)
			}

			this.$emit("onChanged", this.sites);
		},

		/*
 		* Change value selected
 		*
 		* @return void
 		*/ 
		onChangeValue(event) 
		{	
			this.$emit("onChanged", event);
		},
    },

	computed:{

 		/*
 		* Array values 
 		*
 		* @return array getsiteList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getSiteDropDownList;
 		},
	},

    watch:{
    	
    	/*
 		* Loading/Progress when update values 
 		*
 		* @return void
 		*/ 
		isUpdating(val)
		{
			if (val) 
			{
				setTimeout(() => (this.isUpdating = false), 3000)
			}
		},

        /*
 		* Look for value site id as sync
 		*
 		* @return array getsiteList
 		*/ 
        sites(val)
        {
        	this.$emit('update:site', val)
        },

        /*
 		* Watch for value from 'site' props 
 		*
 		* @return void
 		*/ 
        site(val)
        {	
        	this.sites = val;
        }, 
    },
}
</script>

<style scoped>
	.wrapclass {
		max-width: 99%;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>