<template>
	<v-app  style="min-height: auto;">
		<!-- -------- NAVIGATION SIDE BAR LEFT -------- -->
		<v-navigation-drawer :width="220" :mini-variant-width="65" v-model="drawer.left" app :mini-variant="drawer_open.left" :expand-on-hover="false">
			<v-list>
				<v-list-item>
					<v-avatar>
						<img  class="organization_logo" :src="user.organization && user.organization.logo ?  user.organization.logo : default_logo"/>
					</v-avatar>
					<v-list-item-title> {{ `${ user.organization ? user.organization.short_name : 'GSSI' }` }} </v-list-item-title>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

			<!-- ---------- PROFILE MENU ----------  -->
			<v-profile-menu></v-profile-menu>

			<v-divider></v-divider>

			<!-- ---------- NAVIGATION SIDEBAR BUTTON ----------  -->
			<v-list dense nav responsive>
				<v-list-item-group v-model="navIndex" >
			        <!-- --------- ITEMS -------- -->
			        <v-list-item  v-for="(item,index) in navigation.items" :key="index" :to="item.path">
			        	<v-list-item-icon> 
				            <v-icon style="font-size:30px;" v-text="item.icon"/>
				        </v-list-item-icon>
			        	<v-list-item-content >
			            	<v-list-item-title v-text="item.title"/>
			          	</v-list-item-content> 
			        </v-list-item>
			        <!-- --------- SEPARATOR -------- -->
	 			    <template v-if="index == 3 || index == 8"> 
	 			    	<v-divider class="my-2"></v-divider> 
	 			    </template>
				</v-list-item-group>

				<!-- --------- SETTINGS  -------- -->
				<template v-if="AuthRole == 'Admin'">
			        <v-list-item
						@click="modal({ 
							name:'Settings', 
							title:'Settings', 
							view:'modal-settings' 
						})"
					>
			        	<v-list-item-icon>
				            <v-icon 
								style="font-size:25px;" 
								v-text="'mdi-settings'"
							/>
				        </v-list-item-icon>
			        	<v-list-item-content>
			            	<v-list-item-title class="white" v-text="'Settings'"></v-list-item-title>
			          	</v-list-item-content> 
			        </v-list-item>
				</template>
		    </v-list>

		    <!-- ---------- EXPAND NAVIGATION DRAWER ---------- -->
			<template v-slot:append>
				<div class="pa-2">
					<v-list>
						<v-list-item-group>
							<v-list-item @click.stop="openNavigationDrawerLeft()">
								<v-list-item-icon> </v-list-item-icon>
								<v-list-item-content> 
									<v-list-item-title> Expand </v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</div>
			</template>
		    
		    <!-- ----------  EXPAND NAVIGATION DRAWER ---------- -->
			<template v-slot:append>
				<v-list>
					<v-list-item>
						<v-avatar :tile="true">
							<img class="abizo_logo" :src="logo"/>
						</v-avatar>
						<v-list-item-title>
							Powered by Abizo
						</v-list-item-title>
					</v-list-item>
					<v-list-item>
						<v-avatar :tile="true"></v-avatar>
						<v-list-item-title>
							{{`v${version}-${environment}-${build}`}}
						</v-list-item-title>
					</v-list-item>
				</v-list>
				<v-divider></v-divider>
   				<v-btn
				   icon
				   class="float-right mx-3 mt-2 mb-2"
				   @click.stop="openNavigationDrawerLeft()"
				>
		        	<v-icon>
						{{ drawer_open.left ? 'mdi-chevron-right': 'mdi-chevron-left' }}
					</v-icon>
		        </v-btn>
			</template>

		</v-navigation-drawer>

		<!-- -------- NAVIGATION SIDE BAR RIGHT -------- -->
		<v-navigation-drawer
			app
			right
			:width="220"
			:mini-variant-width="65"
			:mini-variant="drawer_open.right"
			:expand-on-hover="false"
			v-model="drawer.right"
		>
			
			<!-- ---------- ADD [+] BUTTON AT RIGHT SIDE BAR ------------ -->
			<v-list>
				<v-list-item @click="show()">
					<v-avatar :tile="true">
						<v-icon style="font-size:45px; color:#F16211;">
							mdi-plus-box
						</v-icon>
					</v-avatar>
					<v-list-item-title> 
						ADD 
					</v-list-item-title>
				</v-list-item>
			</v-list>

			<!-- ------------------- MODAL FOR ADDING ------------------- -->
			<v-menu 
				v-model="showMenu" 
				:position-x="x" 
				:position-y="y" 
				absolute offset-y
			>
			    <v-list>
			    	<div v-for="(item, index) in modal_menu_items" :key="index">
				        <v-list-item @click="modal(item)">
				        	<v-icon style="font-size: 25px; color:#212529;">
								{{ item.icon }}
							</v-icon>
				        	<span class="mx-4" style="font-size:15px;">
								{{ item.label }}
							</span>
				        </v-list-item>
			        </div>
			    </v-list>
    		</v-menu>

			<v-divider></v-divider>

			<!-- ---------------- BELL FOR NOTIFICATION ----------------- -->
			<v-list>
				<v-list-item>
					<v-list-item-avatar>
						<v-avatar>
							<!-- <v-icon >mdi-bell</v-icon> -->
						</v-avatar>
					</v-list-item-avatar>
				</v-list-item>
			</v-list>

			<v-divider></v-divider>

		    <!-- --------------- EXPAND NAVIGATION DRAWER --------------- --> 
			<template v-slot:append>
				<div class="pa-2">
					<v-list>
						<v-list-item-group>
							<v-list-item @click.stop="openNavigationDrawerLeft()">
								<v-list-item-icon> </v-list-item-icon>
								<v-list-item-content> 
									<v-list-item-title> Expand </v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</div>
			</template>
		    
		    <!-- --------------- EXPAND NAVIGATION DRAWER --------------- -->
			<template v-slot:append>
				<v-divider></v-divider>
   				<v-btn 
				   icon 
				   class="float-left mx-3 mt-3 mb-3" 
				   @click.stop="openNavigationDrawerRight()"
				>
		        	<v-icon>
						{{ drawer_open.right ? "mdi-chevron-left": "mdi-chevron-right" }}
					</v-icon>
		        </v-btn>
			</template>
		</v-navigation-drawer>

		<!-- -------- TOP NAVBAR -------- -->
		<v-app-bar color="white" app class="elevation-1 mx-5 border-bottom p-4"> 
			
			<!-- ----------------- SITE WITH ORG NAME  ----------------- -->
			<template v-if="$route.name == 'sites' && $route.query.organization_id && $route.query.organization_name">
				<v-toolbar-title>
					<b>
						<a @click="redirect('/organizations')">
							Organizations
						</a>
					</b>
				</v-toolbar-title>
				<v-icon>mdi-chevron-right</v-icon>
				<span>
					<b>
						{{organization_name}}
					</b>
				</span>
			</template>

			<!-- ----------------- MONTHLY CALENDAR ----------------- -->
			<template v-else-if="$route.name == 'calendar_monthly'">
				<v-avatar size="35px">
					<img 
						alt="Photo" 
						:src="monthly_calendar.image ? monthly_calendar.image : default_avatar"
					/>
					<span 
						class="my-auto" 
						style="width: 100px; transform: scale(0.900);"
					>
						{{ monthly_calendar.name }}
					</span>
					<span 
						class="mx-2 my-auto" 
						style="transform: scale(0.900);"
					>
						{{ monthly_calendar.employee_id }}
					</span>
				</v-avatar>
			</template>

			<!-- ----------------- COMPOSE ----------------- -->
			<template v-else-if="$route.name == 'compose'">
				<v-toolbar-title>
					<b>
						<a @click="redirect('/assignments')">
							Assignment
						</a>
					</b>
				</v-toolbar-title>
				<v-icon>mdi-chevron-right</v-icon>
				<v-toolbar-title><b> Compose </b></v-toolbar-title>
			</template>

			<template v-else>
				<b>{{ page_title }}</b>
			</template>

			<template v-if="AuthRole == 'Admin'">
				<template v-if="calendar_table">
					<v-layout 
						class="d-flex flex-row flex-grow-1 flex-shrink-1 align-center justify-end" 
						style="min-width:300px; flex-basis:120px;"
					>
						<!-- ---------------- DATE PICKER  ------------ -->
						<div class="ml-3 mt-6 " v-if="to.date_range">
							<date-range :value.sync="dates"></date-range>
						</div>

						<!-- ---------------- MONTH PICKER  ------------ -->
						<div class="mt-6 " v-if="to.month_picker" style="width: 130px;">
							<month-picker :value.sync="dates"></month-picker>
						</div>

						<!-- ---------------- CALENDAR TYPE BUTTON ------------ -->
						<div class="my-auto" v-if="to.calendar_toggle" style="width: 125px;">
							<v-btn small outlined  dense @click="setCalendatToWeeklyOrMonthly()">{{calendar_type}}</v-btn>
						</div>

						<!-- ---------------- SEARCH BAR | FOR TOTAL TIMELOGS ------------ -->
						<div class="mt-6" v-if="to.search_fiter">
							<search-bar :placeholder="calendar_table ? 'Employee name or ID number' : 'Search'"></search-bar>
						</div>

						<!-- ---------------- FILTER | FOR TOTAL TIMELOGS ------------ -->
						<div class="mt-6" style="width: 150px;" v-if="to.account_filter">
							<v-account-filter :account.sync="account_filter_id"></v-account-filter>
						</div>

						<!-- ---------------- FILTER | FOR TOTAL TIMELOGS ------------ -->
						<div class="mt-6"  style="width:150px;"  v-if="to.site_filter">
							<v-site-filter :site.sync="site_filter_id"></v-site-filter>
						</div>

						<!-- ---------------- FILTER | FOR TOTAL TIMELOGS ------------ -->
						<div  class="mt-6"  style="width:150px; align-self:flex-end;" v-if="to.assignment_filter">
							<v-assignment-filter :assignment.sync="assignment_filter_id"></v-assignment-filter>
						</div>
					</v-layout>
				</template>
			</template>	

			<v-row v-if="!calendar_table" :justify="'end'">

				<template v-if="AuthRole == 'Admin'">
					<!-- ---------------- FILTER | FOR TOTAL TIMELOGS ------------ -->
					<v-col class="col-2 mt-6" v-if="to.filter">
						<v-site-filter :site.sync="site_filter_id"></v-site-filter>
					</v-col>

					<!-- ---------------- FILTER | FOR TOTAL TIMELOGS ------------ -->
					<v-col class="col-2 mt-6" v-if="to.filter">
						<v-account-filter :account.sync="account_filter_id"></v-account-filter>
					</v-col>
				</template>
				<v-col class="mt-6 col-auto">

					<!-- ---------------- FOR TIMELOGS ------------------- -->
					<v-menu v-if="to.timelogs_tables">
						<template v-slot:activator="{ on }" >
							<v-btn-toggle tile color="indigo" group>
								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn style="width:20px; height: 25px;" value="left" v-on="on" v-show="to.timelogs" fab small to="/timelogs/recent">
											<v-icon style="font-size:25px;">mdi-view-list</v-icon>
										</v-btn>
									</template>
									<span> Raw Time Logs </span>
								</v-tooltip>

								<v-tooltip bottom>
									<template v-slot:activator="{ on }">
										<v-btn style="width:20px; height: 25px;" value="center" v-on="on" v-show="to.timelogs" fab small to="/timelogs/timeline">
											<v-icon style="font-size:25px;">mdi-chart-gantt</v-icon>
										</v-btn>
									</template>
									<span> Summary View </span>
								</v-tooltip>

								<v-tooltip bottom v-if="AuthRole == 'Admin'">
									<template  v-slot:activator="{ on }">
										<v-btn style="width:20px; height: 25px;" value="center" v-on="on" v-show="to.timelogs" fab small to="/timelogs/daily/activity/report">
											<v-icon style="font-size:25px;">mdi-view-list</v-icon>
										</v-btn>
									</template>
									<span> Daily Activity Report 1 </span>
								</v-tooltip>
								
								<v-tooltip bottom v-if="AuthRole == 'Admin'">
									<template  v-slot:activator="{ on }">
										<v-btn style="width:20px; height: 25px;" value="center" v-on="on" v-show="to.timelogs" fab small to="/timelogs/daily/activity/report2">
											<v-icon style="font-size:25px;">mdi-view-list</v-icon>
										</v-btn>
									</template>
									<span> Daily Activity Report 2 </span>
								</v-tooltip>
								
								<v-tooltip bottom v-if="AuthRole == 'Admin'">
									<template  v-slot:activator="{ on }">
										<v-btn style="width:20px; height: 25px;" value="right" v-on="on" v-show="to.timelogs" fab small to="/timelogs/attendance">
											<v-icon style="font-size:25px;">mdi-format-list-bulleted-type</v-icon>
										</v-btn>
									</template>
									<span> Attendance Today </span>
								</v-tooltip>
								
							</v-btn-toggle>
						</template> 
					</v-menu>

				</v-col>

				<!-- ---------------- DATE PICKER  ------------ -->
				<v-col class="col-3 mt-6 " v-if="to.date_range">
					<date-range :value.sync="dates"></date-range>
				</v-col>

				<!-- ---------------- DATE PICKER  ------------ -->
				<v-col class="col-2 mt-6" v-if="to.date_picker">
					<date-picker :value.sync="dates"></date-picker>
				</v-col>

				<!-- ---------------- COMPOSE   ------------ -->
				<v-col class="col-2 my-auto" v-if="to.compose">
					<template v-if="AuthRole == 'Admin'">
						<v-btn small outlined dense dark color="#0057AD" @click="redirect('/assignments/compose')"> + Compose </v-btn>
					</template>
				</v-col>

				<!-- ---------------- SEARCH BAR | FOR TOTAL TIMELOGS ------------ -->
				<v-col class="col-2 mt-6" v-show="to.search_bar">
					<search-bar style="" :placeholder="calendar_table ? 'Employee name or ID number' : 'Search'" :value.sync="keyword"></search-bar>
				</v-col>
				
				<!-- ---------------- COLUMN FILTER | FOR TOTAL TIMELOGS ------------ -->
				<v-col class="col-2 mt-6" v-show="to.column_filter">
					<v-column-filter></v-column-filter>
				</v-col>
				
				<!-- ------------------ IMPORT MODAL --------------- -->
				<v-col :class="[ to.upload ? 'col-md-2 mt-6':'']" v-if="to.upload">
					<v-menu left bottom>
						<template v-slot:activator="{ on }">
							<v-text-field v-on="on" value="Import Data" readonly dense outlined class="import_modal" prepend-inner-icon="save_alt">
							</v-text-field>
						</template>
						<v-list>
							<template v-for="(item, index) in modal_import">
								<v-list-item @click="modal(item)">
									<span style="font-size:15px;" >{{ item.label }}</span>
								</v-list-item>
							</template>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>

			<!-- ------------------ FOR ALL TABLE --------------- -->
			<v-menu left bottom>
				<template v-slot:activator="{ on }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
						<v-btn v-show="to.download" v-on="on" icon @click="export_excel()" >
							<v-icon style="font-size:25px; color:black;">save_alt</v-icon>
						</v-btn>
						</template>
						<span> Download </span>
					</v-tooltip>
				</template>
		    </v-menu>

		    <!-- <QueryBuilderHeader v-if="$route.path == '/query-builder/template'"/> -->

		    <portal-target name="query-builder-header"></portal-target>
		</v-app-bar>

		<!-- -------- Content -------- -->
		<v-main> 	 
			<v-container fluid> 

				<!-- IINJECT VIEWS/PAGE -->
				<router-view></router-view>

				<!-- set progressbar -->
		        <!-- <vue-progress-bar></vue-progress-bar> -->

			</v-container>
		</v-main>
    	
		<!-- -------- MODAL -------- -->
 		<v-modal></v-modal>

 		<!-- --------- IDLE -------- -->
 		<v-idle v-if="isIdle":isIdle="isIdle"></v-idle>

    </v-app>
</template> 

<script>

	import QueryBuilderHeader from "@/modules/QueryBuilder/Builder/components/BuilderHeaders.vue";

	import Modal from "@/components/Form/Modal/Index";
	import Menu  from "@/components/Menu/Profile/Index";
	import Idle  from "@/components/Form/Modal/Idle/Index";

	import DateRange from "@/components/Fields/DateRange/Index";
	import DatePicker from "@/components/Fields/DatePicker/Index";
	import MonthPicker from "@/components/Fields/MonthPicker/Index";
	
	import SearchBar from "@/components/Fields/SearchBar/Index";
	import SiteFilter from "@/components/Fields/Filter/DropDown/Site/Index";
	import ColumnFilter from "@/components/Fields/Filter/DropDown/Column/Index";
	import AccountFilter from "@/components/Fields/Filter/DropDown/Account/Index";
	import AssignemntFilter from "@/components/Fields/Filter/DropDown/Assignment/Index";

	import Link from "@/components/Fields/Links/Index";

	import { modal } from "@/mixins/modal"; 
	import { auth } from "@/mixins/auth";
	import { mapActions, mapGetters, mapState } from "vuex";
	import { calendar_filter } from "@/mixins/filter/calendar";

	export default {
		mixins:[modal,auth,calendar_filter],
		name:"dashboard",
		components:{ 
			"v-idle":Idle, 
			"v-link":Link, 
			"v-modal":Modal, 
			"v-profile-menu":Menu, 
			"v-site-filter":SiteFilter,
			"v-column-filter":ColumnFilter,
			"v-account-filter":AccountFilter, 
			"v-assignment-filter":AssignemntFilter,
			"month-picker":MonthPicker,
			"date-picker":DatePicker, 
			"date-range":DateRange, 
			"search-bar":SearchBar, 
			QueryBuilderHeader
		},
		data()
		{
			return{
				defaults:[
					{ text: "#",align: "left",sortable: false,value: "id", id:"a"},
		            { text: "PHOTO",   value: "avatar", },
		            { text: "LOG TYPE", value: "type" }
		        ],
				drawer:{ right:true, left:true },
				drawer_open:{ left:false, right:true, },
				item:1,
				page_title:"",
				to:{ timelogs:true, dashboard:false },
	  			modals: false,
				navigation:{
					items: [ 
			          { title: "Dashboard",  icon: "dashboard", path:"/dashboard", can:["Super Admin","Admin"] },
			          { title: "Time Logs",  icon: "schedule",  path:"/timelogs/", can:["Super Admin","Admin"] },
			          { title: "Activities", icon: "mdi-current-ac",  path:"/activities", can:["Super Admin"] },
			          { title: "Calendar", icon: "mdi-calendar-multiselect",  path:"/calendar", can:["Admin"] },
			          { title: "Time Sheet", icon: "mdi-calendar-multiselect",  path:"/timesheet", can:["Admin"] },
			          { title: "Organizations", icon: "mdi-office-building",  path:"/organizations", can:["Super Admin"] },
			          { title: "Sites", icon: "mdi-map-marker",  path:"/sites", can:["Super Admin","Admin"] },
			          { title: "Accounts", icon: "mdi-picture-in-picture-bottom-right",  path:"/accounts", can:["Super Admin","Admin"] },
			          { title: "Assignment", icon: "mdi-clipboard-check-outline",  path:"/assignments", can:["Super Admin","Admin"] },
			          { title: "Admins", icon: "mdi-account-tie", path:"/admins", can:["Super Admin","Admin"]  },
			          { title: "Mobile Admins", icon:"mdi-account-tie-outline", path:"/mobileadmins", can:["Super Admin","Admin"] },
			          { title: "Employees", icon:"mdi-account-group", path:"/employees", can:["Super Admin","Admin"] },
			          { title: "Employee Role", icon:"mdi-account-cog", path:"/employee/roles", can:["Super Admin","Admin"] },
			          { title: "Query Builder", icon:"mdi-account-cog", path:"/query-builder/", can:["Admin"] },

			        ]
				}, 
				showMenu: false,
				x:0,
				y:0,
				modal_menu_items: [
					{ label:"+ Organization", title: "New Organization", mode:"add", view:"modal-organization",icon: "mdi-office-building",name:"organization", can:["Super Admin"] },
					{ label:"+ Site", title: "New Site", mode:"add", view:"modal-site",icon: "mdi-map-marker",name:"site", can:["Super Admin","Admin"] },
					{ label:"+ Account", title: "New Account", mode:"add", view:"modal-account",icon: "mdi-picture-in-picture-bottom-right",name:"account", can:["Super Admin","Admin"] },
					{ label:"+ Assignment", title: "New Assignment", mode:"add", view:"modal-assignment",icon: "mdi-clipboard-check-outline",name:"assignment", can:["Super Admin","Admin"] },
					{ label:"+ Admin",title: "New Admin", mode:"add", view:"modal-admin", icon: "mdi-account-tie", name:"admin", can:["Super Admin","Admin"] },
					{ label:"+ Mobile Admin", title: "New Mobile Admin", mode:"add", view:"modal-mobile-admin", icon:"mdi-account-tie-outline",name:"mobile_admin", can:["Super Admin","Admin"] },
					// { label:"+ Employee", title: "New Employee", mode:"add", view:"modal-employee",icon: "mdi-account-group", name:"employee", can:["Super Admin","Admin"] },
					{ label:"+ Employee Role", title: "New Employee Role", mode:"add", view:"modal-employee-role",icon:"mdi-account-cog", name:"employee role", can:["Super Admin","Admin"] },
					{ label:"+ Time Entry", title: "New Time Entry", mode:"add", view:"modal-timelog-time-entry",icon: "mdi-clock-outline",name:"time entry", can:["Admin"] },
				],
				modal_import:[ 
					{ label:"Employee Information", title:"1. Import Data to “Employees” ", mode:"import", view:"modal-import",icon:"baseline_image" },
				],
				modal_import_data:{},
				logo:require('@/assets/images/brand/abizo_dtr_logo.png'),
				default_logo:require('@/assets/images/logo/logo-placeholder.png'),
				gssi_logo:require('@/assets/images/logo/gssi_logo.png'),
				default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
				version:process.env.VUE_APP_VERSION,
				build:'0051',
				node_env:process.env.NODE_ENV,
				environment:"",
				MyAuthRole:"",

				account_filter_id:null,
				site_filter_id:null,
				assignment_filter_id:null,
				keyword:null,
				dates:null,

				monthly_calendar:{
					loading:true,
					name:null,
					image:null,
					employee_id:null
				},
				calendar_type:"Monthly",
				page:{
		            page:1,
		            itemsPerPage:10,
		            loading:false,
		        },
		        navindex:0,
			}
		},
		beforeMount()
		{
			this.getProfile();
		},
		created()
		{	
			this.setDefaultDate(); 
			this.setRouterData(this.$route); 
			this.$bus.$on("date-range",(date)=>{ this.dates = date });
			this.$bus.$on("keyword",(value)=>{ this.keyword = value });
            this.$bus.$on("table-per-page",(value)=>{ this.page.itemsPerPage = value });
			this.$bus.$on("monthly-calendar",(data)=>{
				if(data)
				{
					let { firstname, lastname, employee_id, image, loading } = data; 
					this.monthly_calendar.name = (firstname && lastname) ? `${firstname} ${lastname}` : null;
					this.monthly_calendar.employee_id = employee_id
					this.monthly_calendar.image = image && image.avatar ? image.avatar : require('@/assets/images/avatar/default-avatar.jpg')
					this.monthly_calendar.loading = false
				}
			});

			let filter = this.$store.getters.getFilter;

			if(Object.prototype.hasOwnProperty.call(filter,"site_id"))
			{
				this.site_filter_id = filter.site_id;
			}

			if(Object.prototype.hasOwnProperty.call(filter,"account_id"))
			{
				this.account_filter_id = filter.account_id;
			}
		},

		beforeDestroy()
		{
			this.$bus.$off("month-date");
			this.$bus.$off("date-range");
			this.$bus.$off("table-per-page");
			this.$bus.$off("clear-value")
		},

		updated()
		{
			this.setEnvironment();
		},	

		methods:{ 

			...mapActions("recent",['getRecent']),
			...mapActions("timeline",['getTimeline']),
			...mapActions("timelineV2",{'getTimelineV2':'getTimeline'}),
			...mapActions("attendance",['getAttendance']),
			...mapActions("daily_activity_report",['getDailyActivityReport']),
			...mapActions("calendar",["getCalendar","getEmployeeRecord"]),

			setCalendatToWeeklyOrMonthly()
			{
				if(this.$route.path == '/calendar/monthly'){	
					this.$router.push("/calendar");
					this.calendar_type = "Monthly";
				}
				else if(this.$route.path == '/calendar'){
					this.$router.push("/calendar/monthly");
					this.calendar_type = "Weekly";
				}
			},

			setFiltering(value = null)
			{
				switch(this.$route.name)
				{
					case "recent":	
						value = typeof value === "undefined" ? null : value;
						this.$bus.$emit("filter_recent",value);
					break;

					case "timeline":
						let timeline = Object.assign({ 
							page:1,
							itemsPerPage:this.page.itemsPerPage, 
							from:this.dates[0] ? this.dates[0] : this.dates.from,
							to:this.dates[1] ? this.dates[1] : this.dates.to,
							account_id:this.account_filter_id, 
							site_id:this.site_filter_id,
						});

						this.getTimeline(timeline);
					break;

					case "timeline_v2":
						let timelineV2 = Object.assign({ 
							page:1,
							itemsPerPage:this.page.itemsPerPage, 
							from:this.dates[0] ? this.dates[0] : this.dates.from,
							to:this.dates[1] ? this.dates[1] : this.dates.to,
							account_id:this.account_filter_id, 
							site_id:this.site_filter_id,
						});

						this.getTimelineV2(timelineV2);
					break;

					case "daily_activity_report":
						let daily_activity_report = Object.assign({ 
							page:1,
							itemsPerPage:this.page.itemsPerPage, 
							date:this.dates,
							account_id:this.account_filter_id, 
							site_id:this.site_filter_id,
						})	
						this.getDailyActivityReport(daily_activity_report);
					break;

					case "daily_activity_report2":
						let daily_activity_report2 = Object.assign({ 
							page:1,
							itemsPerPage:this.page.itemsPerPage, 
							date:this.dates,
							account_id:this.account_filter_id, 
							site_id:this.site_filter_id,
						})	
						this.getDailyActivityReport(daily_activity_report2);
					break;

					case "attendance":
						let attendance = Object.assign({ 
							page:1,
							itemsPerPage:this.page.itemsPerPage, 
							date:this.dates,
							account_id:this.account_filter_id, 
							site_id:this.site_filter_id,
						})	
						this.getAttendance(attendance);
					break;

					case "calendar":
						value = typeof value === "undefined" ? null : value;
						this.$bus.$emit("filter-weekly-calendar",value);
					break;

					case "calendar_monthly":
						value = typeof value === "undefined" ? null : value;
						this.$bus.$emit("filter_employee",value); // filters employee navigation names
					break;
				}
			},

			setEnvironment()
			{
				switch(this.node_env)
				{
					case "local":
						this.environment = "local"
					break;

					case "development":
						this.environment = "dev"
					break;

					case "production":
						this.environment = "prod"
					break;

					case "qa":
						this.environment = "qa"					
					break;
				} 
			},
  
			onBreadCrumbs()
			{
				this.$bus.$emit('on-profile-menu');
			},

			setDefaultDate()
			{
				this.date = [ moment().startOf('month').format('YYYY-MM-DD'), moment().endOf('month').format('YYYY-MM-DD')];
			},

			onMenuProfile()
			{
				this.$bus.$emit('on-profile-menu');
			},

			export_excel()
			{
				if(this.$route.name == "recent"){this.$bus.$emit('export-excel-timelogs'); }
				if(this.$route.path == "/timelogs/timeline"){this.$bus.$emit('export-excel-timeline'); }
				if(this.$route.path == "/timelogs/timeline-v2"){this.$bus.$emit('export-excel-timeline_v2'); }
				if(this.$route.path == "/timelogs/attendance"){this.$bus.$emit('export-excel-daily-timelogs-report'); }
				if(this.$route.path == "/organizations"){this.$bus.$emit('export-excel-organization'); }
				if(this.$route.path == "/calendar"){ this.$bus.$emit('export-excel-weekly-calendar'); }
				if(this.$route.path == "/calendar/monthly"){this.$bus.$emit('export-excel-monthly-calendar'); }
				if(this.$route.path == "/accounts"){this.$bus.$emit('export-excel-accounts'); }
				if(this.$route.path == "/employee/roles"){this.$bus.$emit('export-excel-employee-roles'); }
				if(this.$route.path == "/assignments"){this.$bus.$emit('export-excel-assignments'); }
				if(this.$route.name == "daily_activity_report"){this.$bus.$emit('export-excel-daily-activity-report'); }
				if(this.$route.name == "daily_activity_report2"){this.$bus.$emit('export-excel-daily-activity-report2'); }
				if(this.$route.path == "/sites"){this.$bus.$emit('export-excel-site'); }
				if(this.$route.path == "/admins"){this.$bus.$emit('export-excel-admin'); }
				if(this.$route.path == "/mobileadmins"){this.$bus.$emit('export-excel-mobile-admin'); }
				if(this.$route.path == "/employees"){this.$bus.$emit('export-excel-employee'); }
				if(this.$route.path == "/activities"){this.$bus.$emit('export-excel-activities'); }
			},

			modal(data)
			{ 
				if(data){ this.$store.dispatch("modal",{ name:data.name, title:data.title,  mode:data.mode, view:data.view }); }
			},	

			show()
			{
		        event.preventDefault();
		        this.showMenu = false
		        this.x = event.clientX
		        this.y = event.clientY
		        this.$nextTick(() => { this.showMenu = true });
		    },

			/*
			* This open the navigation drawer while checking it if the drawer is active or not
			*
			* @return void 
			*/
			openNavigationDrawerLeft()
			{
				if(!this.drawer.left)
				{
					this.drawer_open.left = false;
					this.drawer.left = true;
				}

				else
				{
					this.drawer_open.left = !this.drawer_open.left;
				}
			},

			/*
			* This open the navigation drawer while checking it if the drawer is active or not
			*
			* @return void 
			*/
			openNavigationDrawerRight()
			{
				if(!this.drawer.right)
				{
					this.drawer_open.right   = false;
					this.drawer.right = true;
				}

				else
				{
					this.drawer_open.right = !this.drawer_open.right ;
				}
			},

			/*
			* This open the dialog in an component
			*
			* @return void 
			*/
			openDIalog()
			{
 				this.$store.dispatch("getOpen");
 			},

 			/*
			* use to identify what routes is active then on/off features in top header
			*
			* @return void 
			*/
 			setRouterData(route)
 			{
 				this.page_title = route.meta.title;

				switch(route.name)
				{	
					case "dashboard":
						this.to = { timelogs:false, all:false }
					break;

					case "recent":
						this.to = { filter:true, search_bar:true, column_filter:false, timelogs_tables:true, date_picker:true, timelogs:true, download:true }
					break;

					case "timeline":
						this.to = { filter:true, search_bar:false, column_filter:true, timelogs_tables:true, date_range:true, timelogs:true, download:true }
					break;

					case "timeline_v2":
						this.to = { filter:true, search_bar:false, column_filter:true, timelogs_tables:true, date_range:true, timelogs:true, download:true }
					break;

					case "daily_activity_report":
						this.to = { filter:true, search_bar:false, column_filter:true, timelogs_tables:true, date_picker:true, timelogs:true, download:true }
					break;

					case "daily_activity_report2":
						this.to = { filter:true, search_bar:false, column_filter:true, timelogs_tables:true, date_picker:true, timelogs:true, download:true }
					break;

					case "attendance":
						this.to = { filter:true, search_bar:false, column_filter:true, timelogs_tables:true, date_picker:true, timelogs:true, download:true }
					break;

					case "activities":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "calendar":
						this.to = { search_fiter:true, site_filter:true,assignment_filter:true, account_filter:true, date_range:true, calendar_toggle:true, download:true }
					break;

					case "calendar_monthly":
						this.to = { search_fiter:true, site_filter:true,assignment_filter:true, account_filter:true, calendar_toggle:true, month_picker:true, download:true }
					break;

					case "organizations":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "sites":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "assignments":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false, compose:true }
					break;

					case "accounts":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "admins":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "mobile_admins":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					case "employees":
						this.to = { search_bar:true,  column_filter:true, upload:true, download:true, all:false }
					break;

					case "employee_roles":
						this.to = { search_bar:true,  column_filter:true, download:true, all:false }
					break;

					default:
						this.to = { date_picker:false , timelogs:false, upload:false, all:false }
					break;
				}
 			},

			redirect(route)
			{
				this.$router.push(route);
			},

			getOrganizationName()
			{	
				this.$nextTick(()=>{ this.organization_name = this.$route.params.organization_name });
			}
		},

		computed:{

			isIdle(){
      			return this.$store.state.idleVue.isIdle;
  			},

      		user(){	
      			return this.$store.getters.authUserInfo;
      		},

      		role(){		
      			return this.$store.getters.authUserRole;
      		},

      		timeline_version(){	
      			let user = this.$store.getters.authUserInfo;
      			const organization = Object.hasOwnProperty.call(user,"organization") ? user.organization : 0;
      			return organization ? organization.version : 0;
      		},

      		organization_name(){
      			return this.$route.query.organization_name;
      		},

      		calendar_table(){
      			return this.$route.name == 'calendar_monthly' || this.$route.name == 'calendar';
      		},

      		navIndex(){
      			return this.navigation.items.findIndex(data=>data.path == this.$route.path);
      		}
  		},

		watch: {

			"$route"(to, from)
			{	
				// to show / hide navigation features
				this.setRouterData(to);

				// to prevent using of filter values on different filter field into various routes 
				const timelogs = ["recent","timeline","daily_activity_report", "daily_activity_report2","attendance"];
				
				// when no route name is exist from an array
				if(timelogs.indexOf(to.name) == -1){
					this.$store.dispatch("clearFilter");
					this.account_filter_id = null;
					this.site_filter_id = null;
					this.assignment_filter_id = null;
					this.dates = null;
				}

				// to set default date when the route changes
				this.setDefaultDate();
			},

			modal_import_data(value)
			{
				// when the import modal data change
				this.modal(value)
			},

			async AuthRole(value)
			{	
				// for modal 
				this.navigation.items = this.navigation.items.filter((data)=>{ return data.can.indexOf(value ? value.toString() : null) > -1; });

				// for left side bar 
				this.modal_menu_items = this.modal_menu_items.filter((data)=>{ return data.can.indexOf(value ? value.toString() : null) > -1; });

				// Role
				this.MyAuthRole = value.toString();
			},

			account_filter_id(value)
			{	
				let values = typeof value === "undefined" ? null : value;
				this.setFilters({ account_id:value });
				this.setFiltering(value);
			},

			site_filter_id(value)
			{	
				let values = typeof value === "undefined" ? null : value;
				this.setFilters({ site_id:value });
				this.setFiltering(value);
			},

			assignment_filter_id(value)
			{	
				let values = typeof value === "undefined" ? null : value;
				this.setFilters({ assignment_id:value });
				this.setFiltering(value);
			},

			// search field 
			keyword(value)
			{
				if(this.$route.name == "calendar" || this.$route.name == "calendar_monthly")
				{	
					if(value){
						this.setFilters({ keyword:value });
					}
					else {
						this.setFilters({ keyword:null });
					}
				}

				if(this.$route.name != "recent")
				{	
					this.setFiltering(value);
				}
			},
	    },
	}

</script>

<style>
	.import_modal .v-input__prepend-inner .v-icon	{
		transform:rotate(180deg);
		color:black;
	}
</style>
 

<style scoped>
	
	.import_modal  .v-input__prepend-inner .v-icon	{
		transform:rotate(180deg);
		color:black;
	}
	.daterange_field .v-input__prepend-inner .v-icon{
		color: black;
		margin-right: 5px;
	}

	.border-bottom {
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
	}

	.bottom-drawer{
		margin-top: 240px;
	}

	.organization_logo{
		width:35px; height:35px;
	}

	.abizo_logo{
		width:30px; height:30px;
	}

	.v-list-item--active{
		background-color: #0057AD;
		visibility: visible;
		opacity: 1;
		color:#FFFFFF!important;
	}

	.icon-color{
		color: black;
	}

	.v-input__icon--prepend-inner{ 
    	color: purple;
	}

</style>
 