<template>
    <v-row justify="center">
		<v-dialog  persistent scrollable max-width="500px" v-model="dialog" @keydown.esc="onClose()" style="max-height:3000px;">
			<v-card>
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <span class="font-weight-bold">{{ title }}</span>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="onClose()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<v-divider></v-divider>
 
				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text>
					<v-container>
						<v-form v-model="valid" ref="form" @keyup.native.enter="submit()">
							<v-layout fluid no-gutters>
								<div class="d-flex align-start justify-start my-2" >
									<span style="color: black;"><b>{{ month_year_date_text }}</b></span>
								</div>
								<div>
									<v-btn icon @click="decrement" :disabled="mode == 'time'">
										<v-icon>mdi-chevron-left</v-icon>
									</v-btn>
								</div>
								<div>
									<v-btn icon @click="increment" :disabled="mode == 'time'">
										<v-icon>mdi-chevron-right</v-icon>
									</v-btn>
								</div>
								<v-spacer></v-spacer>
								<div class="d-flex align-end justify-end"  sm="8" md="6">
							        <v-text-field readonly dense outlined prepend-inner-icon="event" class="my-auto" :disabled="mode == 'time'" v-model="date_text"></v-text-field>
								</div>
							</v-layout>

							<v-layout fluid no-gutters >
								<v-date-picker no-title full-width class="elevation-0 date-picker-border" :disabled="mode == 'time'" v-model="date_picker_val">
								</v-date-picker>
							</v-layout>

							<div class=" d-flex align-start justify-start my-2" >
								<span style="color: black;"><b>Time</b></span>
							</div>

							<v-layout fluid no-gutters >
							 	<v-dialog ref="dialog" v-model="timepicker.show" :return-value.sync="timepicker.value" persistent width="290px">
							        <template v-slot:activator="{ on }">
										<v-text-field class=" row col-md-6" :disabled="mode == 'variable_date' "  v-model="timepicker.text" dense outlined readonly placeholder="Choose time" v-on="on" prepend-inner-icon="schedule" :rules="[rules.time.required]"/>
							        </template>
							        <v-time-picker v-if="timepicker.show" v-model="timepicker.value" full-width>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="timepicker.show = false">Cancel</v-btn>
										<v-btn text color="primary" @click="setTime(timepicker.value)">OK</v-btn>
							        </v-time-picker>
							    </v-dialog> 
							</v-layout>
						</v-form>
					</v-container>
				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">
					<v-btn dark outlined @click="onClose":color="'#0057AD'" class="mx-2 elevation-0 font-weight-bold custom-card-button">
						Cancel
					</v-btn>
					<v-spacer></v-spacer>
					<v-btn dark color="#0057AD" @click="onApply" class="mx-2 elevation-0 font-weight-bold custom-card-button"> 
						Apply 
					</v-btn>
	            </v-card-actions>
	            
	        </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { states } from "@/mixins/states";
import { modal }  from "@/mixins/modal";

import  Errors  from "@/validation/errors";

export default {
	props:["date","title"],
	mixins:[modal],
	data(){ 
		return {
			date_picker_val:new Date().toISOString().substr(0,10),
			month_year_date_text:null,
			date_text:null,
			time_picker_val:null,
			rules:rules,
        	valid:false,
			timepicker:{ 
				show:false,
				text:"",
				value:""
			},
		}	
	}, 

	created()
	{   
    	this.initilize();
    },

	methods:{

		/*
 		* This initilize value values needed
 		*
 		* @return void
 		*/ 
		initilize()
		{	
			this.setValues(0);
 		},

 		/*
 		* This set values with positive integer as increment
 		*
 		* @return void 
 		*/
 		increment(){ this.setValues(1); },
        
        /*
 		* This set values with positive integer as decrement
 		*
 		* @return void
 		*/            
		decrement(){ this.setValues(-1); }, 

		setValues(counter) 
		{	
			let date = moment(this.date_picker_val).add(counter,"months")

			this.month_year_date_text = date.format("MMMM YYYY");
 
			this.date_text = date.format("ddd MMM DD YYYY"); 

			this.date_picker_val = date.format("YYYY-MM-DD");
		},

		setTime(value)
		{
			this.$refs.dialog.save(value)
			this.time_picker_val = value;
		},

		onClose()
		{	 
			this.$bus.$emit(this.mode,null);
			this.close();
		},

		onApply()
		{	
        	if(this.mode == "datetime")
			{	
				if(this.$refs.form.validate()){
					this.$bus.$emit("datetime",`${this.date_picker_val} ${this.time_picker_val}`);
					this.close(); 
				}
			}
			
			else if(this.mode == "date")
			{	
				this.$bus.$emit("date", this.date_picker_val); 
				this.close();		
			}

			else if(this.mode == "time")
			{	
				if(this.$refs.form.validate()){ 
					this.$bus.$emit("time", this.time_picker_val); 
					this.close(); 
				}
			}
		
		},
	},

	watch:{ 
		date_picker_val(value,oldvalue){	
			this.date_text = moment(value).format("ddd MMM DD YYYY"); 
		},

		"timepicker.value"(newVal,oldvalue){
			this.$nextTick(()=>{ this.timepicker.text = moment(newVal,"H:mm:a").format("H:mm:a"); });
		}
	}
}

</script>

<style type="text/css" scoped> 
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}
	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}
  	.v-date-picker-header {
  		display: none !important;
	}

  	.date-picker-border{
  		border: 1px solid #dbdbdb;
  	}
</style>