import Vue from "vue";
import Vuex from "vuex";

import { auth } from "@/store/modules/auth/index";

import { timelog } from "@/store/modules/timelog/index";
import { recent } from "@/store/modules/timelog/recent/index";
import { timeline } from "@/store/modules/timelog/timeline/index";
import { timelineV2 } from "@/store/modules/timelog/timelineV2/index";
import { attendance } from "@/store/modules/timelog/attendance/index";
import { daily_activity_report } from "@/store/modules/daily_activity_report/index";

import { activity } from "@/store/modules/activity/index";
import { calendar } from "@/store/modules/calendar/index";
import { mobile_admin } from "@/store/modules/mobile_admin/index";
import { employee } from "@/store/modules/employee/index";
import { employee_role } from "@/store/modules/employee_role/index";

import { liveness_check } from "@/store/modules/liveness_check/index";
import { face_biometrics } from "@/store/modules/face_biometrics/index";
import { face_recognition } from "@/store/modules/face_recognition/index";

import { site } from "@/store/modules/site/index";
import { account } from "@/store/modules/account/index";
import { organization } from "@/store/modules/organization/index";
import { assignment } from "@/store/modules/assignment/index";
import { message } from "@/store/modules/message/index";


import { user } from "@/store/modules/user/index";
import { dialogs } from "@/store/modules/dialog/index";
import { column } from "@/store/modules/column/index";

import { attendances } from "@/store/modules/schedule/attendance/index";
import { breaks } from "@/store/modules/schedule/breaks/index";
import { schedule } from "@/store/modules/schedule/schedule/index";

import { filter } from "@/store/modules/filter/index";


Vue.use(Vuex);

const store_modules = {}; 

// Import all of the resource store files.
let stores =()=>{
	const  context = require.context("@/modules",true,/store.js$/i);
	return context.keys().map(context).map(m => m.default)
}
 
stores().forEach((resource) => {
	if(resource) store_modules[resource.name] = resource;
});

export default new Vuex.Store({

	modules:{ 
		auth, 
		recent, 
		filter, 
		timeline, 
		timelineV2, 
		assignment, 
		employee_role, 
		attendance, 
		daily_activity_report, 
		activity, 
		calendar, 
		message,  
		mobile_admin, 
		employee, 
		organization, 
		site, 
		account, 
		user, 
		face_recognition, 
		face_biometrics, 
		liveness_check, 
		dialogs, 
		attendances, 
		breaks, 
		schedule, 
		column,

		...store_modules,

	}
});
