<template>
	<!-- ---------------- DATE RANGE | FOR TOTAL TIMELOGS ------------ -->
	<v-menu ref="menu" v-model="menu" persistent transition="scale-transition" :close-on-content-click="false" offset-y max-width="290px" min-width="290px">
		<template v-slot:activator="{ on }">
			<v-text-field v-model="dateRangeText" v-on="on" small readonly dense outlined prepend-inner-icon="event">
			</v-text-field>
		</template>
		<v-date-picker  v-model="date" @change="event" range scrollable>
			<v-spacer></v-spacer>
			<v-btn text color="primary" @click="menu = false">Cancel</v-btn>
			<v-btn text color="primary" @click="getDateRange(date)">Ok</v-btn>
		</v-date-picker>
	</v-menu>
</template>

<script>
	
	export default { 
		props:["value"],
		data()
		{
			return {
				menu:false,
				cache:null,
				date:[],
			}
		},

		async created()
        {
        	  this.$bus.$on("set-date-range",(value)=>{
                    this.cache = this.date = value;
                });
        	  
            this.initialize();
        },

		beforeDestroy()
        {
            this.$bus.$off("set-date-range");
        },

		methods:{
			event()
			{
				if(moment(this.date[0]).format("YYYY-MM") != moment(this.date[1]).format("YYYY-MM"))
				{
					let date = this.date[1];
					this.date.length = 0;
					this.date = [date];
				}
			},

			/*
			* This initialize values
			*
			* @return void 
			*/
			initialize(value = this.type)
            {   
                this.cache = this.date = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD') ];

                if(this.value) this.cache = this.date = [this.value.from , this.value.to];

              
            },

			/*
			* This set the date headers in timelogs totatl
			*
			* @return void 
			*/
 			getDateRange(date)
 			{
 				this.menu = false;
 				this.$bus.$emit('get-date-range',{from:date[0],to:date[1]});
 				this.$bus.$emit(`${this.$route.name}-date-range`,{from:date[0],to:date[1]});
 			},
		},

		computed:{

			/*
			* This set the - dash line to separate two dates
			*
			* @return array 
			*/
			dateRangeText()
			{
       			return this.date.join(' - ')
      		},
		},

        watch:{

            date(value)
            {	
                this.$emit("update:value",value);
            },
        } 
	}

</script>

<style>
	.daterange_field .v-input__prepend-inner .v-icon{
		color: black;
		margin-right: 5px;
	}
</style>