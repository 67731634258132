import { state } from "@/modules/QueryBuilder/Builder/store/builder/builder.state";
import { getters } from "@/modules/QueryBuilder/Builder/store/builder/builder.getters";
import { mutations } from "@/modules/QueryBuilder/Builder/store/builder/builder.mutations";
import { actions } from "@/modules/QueryBuilder/Builder/store/builder/builder.actions";

export default {
	namespaced:true,
	name:"builder",
	state,
	getters,
	mutations,
	actions
}
