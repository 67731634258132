<template>
   <v-data-table 
      class="elevation-1 mx-2 pa-4"
      loading-text="Loading"
      :headers="headers" 
      :footer-props="{'items-per-page-options': [10, 50, 100]}"
      :items="values" 
      :loading="pagination.loading" 
      :options.sync="pagination" 
      :server-items-length="totalItems" 
      :expanded.sync="expanded"
   >
      <template v-slot:item.timein="{ item }">
         {{ format(item.timein,"time","h:mm a") }}
      </template>
      
      <template v-slot:item.timeout="{ item }">
         {{ format(item.timeout,"time","h:mm a") }}
      </template>

      <template v-slot:item.timein="{ item }">
         <div v-for="dtr in item.dtr" v-if="dtr.type == 'timein'">
            <a 
               href="javascript:void(0)" 
               @click="map({ employee:item, image:item.image}, dtr)"
            >
              {{ format(dtr.timestamp,"date","h:mm a") }}
            </a>
         </div>
      </template>

      <template v-slot:item.timeout="{ item }" class="timeout">
         <div v-for="dtr in item.dtr" v-if="dtr.type == 'timeout'"  >
            <a 
               href="javascript:void(0)"
               @click="map({ employee:item, image:item.image}, dtr)"
            >
               {{ format(dtr.timestamp,"date","h:mm a") }}
            </a>
         </div>
      </template>

      <!-- -------------- TIME ----------------- -->
      <template v-slot:item.ot_in="{ item }">
         <div v-if="item.overtime && item.overtime.start_time">
            {{ format_time(item.overtime.start_time,"h:mm a") }}
         </div>
      </template>
      
      <!-- -------------- TIME ----------------- -->
      <template v-slot:item.ot_out="{ item }">
         <div v-if="item.overtime && item.overtime.end_time">
            {{ format_time(item.overtime.end_time,"h:mm a") }}
         </div>
      </template>

      <template v-slot:item.overtime_status="{ item }">
         <div v-if="item.overtime && item.overtime.status || item.overtime && item.overtime.status == 0">
            <v-chip label class="v-chip-label" :color="item.overtime.status | chips_color" :text-color="item.overtime.status | status_color">
               <b>{{ item.overtime.status | status_text }}</b>
            </v-chip>
         </div>
      </template>

      <template v-slot:item.leave_status="{ item }">
         <div v-if="item.leave && item.leave.status || item.leave && item.leave.status == 0">
            <v-chip label class="v-chip-label" :color="item.leave.status | chips_color" :text-color="item.leave.status | status_color">
               <b>{{ item.leave.status | status_text }}</b>
            </v-chip>
         </div> 
      </template>

   </v-data-table>
</template>

<script>
     
import { status_text, status_color, chips_color } from "@/helper/status";

import { column_filter } from "@/mixins/filter/column";
import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { auth } from "@/mixins/auth";

import { mapActions, mapGetters } from "vuex";

export default {
   mixins:[column_filter,modal,excel,auth],
   data(){
      return {
         headers:[
            { text: "EMPLOYEE ID",   value: "employee_id", visible:true },
            { text: "FIRST NAME", value: "firstname", visible:true },
            { text: "LAST NAME", value: "lastname", visible:true },

            { text: "HOURS RENDERED", value: "hours_rendered", visible:true },
            { text: "TOTAL OVERTIME", value: "overtime.total", visible:true },

            { text: "TIME IN",  value: "timein", visible:true }, 
            { text: "TIME OUT", value: "timeout", visible:true }, 

            { text: "OT IN",  value: "ot_in", visible:true }, 
            { text: "OT OUT", value: "ot_out", visible:true }, 

            { text: "OT STATUS", value: "overtime_status", visible:true }, 

            { text: "LEAVE STATUS", value: "leave_status", visible:true }, 

            { text: "EMPLOYEE TYPE", value: "employee_type", visible:true }, 
         ],
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         date:moment().format("YYYY-MM-DD"),
         dates:[],
         expanded:false,
         initializing:false,
         pagination:{
            page:1,
            itemsPerPage:10,
            loading:true
         },
      } 
   },

   async created()
   { 
      this.initialize();
   },

   beforeDestroy()
   {
      this.$bus.$off("export-excel-daily-activity-report");
      this.$bus.$off("daily_activity_report-date-picker");
   },

   methods:{

      ...mapActions("daily_activity_report",["getDailyActivityReport"]),

      /*
      * This initilize value for table view
      */ 
      initialize()
      {  
         this.initializing = true;

         let filter = this.$store.getters.getFilter;

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            this.date = filter.date;
         }

         this.setDailyActivityReportValues(this.pagination, { date:this.date });
         
         // set value for date picker component
         this.$bus.$emit("set-date-picker",this.date);

         // set value null
         this.$bus.$emit("clear-value",null);

         // to export the records into an excel file
         this.$bus.$on("export-excel-daily-activity-report",()=>this.export_exel());

         // to set the date in range using Vue Event Bus
         this.$bus.$on("daily_activity_report-date-picker",(value) => { 
            this.filterPayload(value); // to retain the date selected 
            this.setDailyActivityReportValues(this.pagination, value);
         });
      },

      filterPayload(values)
      {  
         this.$store.dispatch("setFilter",values);
      },

      /*
      * This sets the values for attendance fetching 
      */
      setDailyActivityReportValues(page, date)
      {
         // set new date property 
         this.date = date.date;

         // loading for table 
         this.pagination.loading = true;

         Object.assign(this.pagination,page);

         // set date property to "page" value 
         let payload = Object.assign(page,date);

         payload = Object.assign(payload,this.getFiltersValues());

         // this set the header column by filtering 
         this.setTableFilter();
         
         // set fetch with dates 
         this.getDailyActivityReport(payload).then(response=>{ 
            this.initializing = false;
            this.pagination.loading = false;
         });
      },

      /*
      * This sets the values from filters site and account on header 
      */ 
      getFiltersValues()
      {
         let filter = this.$store.getters.getFilter;
         let value = {};

         if(Object.prototype.hasOwnProperty.call(filter,"site_id"))
         {
            Object.assign(value,{ site_id:filter.site_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"account_id"))
         {
            Object.assign(value,{ account_id:filter.account_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            Object.assign(value,{ date:filter.date });
         }

         return value;
      },

      /*
      * export date into an excel file 
      *
      * @return void
      */
      export_exel()
      {
         this.export_daily_activity_report(this.values)
      },

      /*
      * to view modal with map and timelog details
      */
      map(employee,dtr)
      {  
         let data = Object.assign(employee,dtr)

         this.$store.dispatch("modal",{ title:"Logs", data:data, mode:"view", view:"modal-timelog-map" });
      },

      /*
      * to set timestamp/date into format date with 
      */
      format(value,type,format)
      {  
         let text = " - ";
         if(value){

            switch(type)
            {
               case "time": text = moment(value).format(format); break;
               case "date": text = moment(value).format(format); break;
            }
         }

         return text;
      },


      /*
      * to set log type with design 
      *
      * @parameter value, the date
      *
      * @return string object
      */
      logtype(value)
      {
         switch(value)
         { 
            case "timein": return { color:"#E8F5E9" ,  text_color:"#5EAF59", format:"Time In"}; break;
            case "timeout": return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
            case "log": return { color:"#ffefc3" , text_color:"#ffbf4b", format:"Log"}; break;
            default: return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
         }
      },

       /*
      * to set timestamp/date into format date with 
      */
      format_time(value,format)
      {  
         let text = " - ";
         if(value) text = moment(value,format).format(format);
         return text;
      },
   },
   
   computed:{ 
      ...mapGetters("daily_activity_report",{values:"getDailyActivityReportList", totalItems:"totalItems"}),
   },

   watch:{
      "pagination.page"(oldV,oldN){
         this.setDailyActivityReportValues(this.pagination,{date:this.date});
      },

      "pagination.itemsPerPage"(oldV,oldN){
         this.setDailyActivityReportValues(this.pagination,{date:this.date});
      }
   },

   filters:{
      status_text, status_color, chips_color
   }
}
</script> 

<style type="text/css" scoped>

   .v-chip-label{
      width:90px;
      display:block;
      text-align:center;
      width:auto;
   }
   .timeout {
      display: inherit;
   }

</style>