<template>
    <v-menu ref="menu" v-model="menu" persistent transition="scale-transition" :close-on-content-click="false" offset-y max-width="290px" min-width="290px">
        <template v-slot:activator="{ on }" >
            <v-text-field v-model="date" v-on="on" readonly dense outlined class="daterange_field col-md" prepend-inner-icon="event" >
            </v-text-field>
        </template>
        <v-date-picker v-model="date" scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="cancel()">Cancel</v-btn>
            <v-btn text color="primary" @click="get(date)">OK</v-btn>
        </v-date-picker>
    </v-menu>
</template>

<script>
    export default {
        props:["value"],
        data()
        {
            return {
                date:[],
                menu: false,
                cache:""
            }
        },

        async created()
        {
            this.initialize();
        },  
        
        beforeDestroy()
        {
            this.$bus.$off("set-date-picker");
        },

        methods:{

            initialize(value = this.type)
            {   
                this.cache = null; 

                this.cache = this.date = moment().format("YYYY-MM-DD");

                this.$bus.$on("set-date-picker",(value)=>{
                    this.cache = this.date = value;
                });
            },

            /*
            * This get the date values 
            *
            * @return void 
            */
            get(date)
            {   
                // close the picker type
                this.menu = false;
                this.$bus.$emit('get-date-picker',{date});
                this.$bus.$emit(`${this.$route.name}-date-picker`,{date});
                this.cache = date;
            },

            /*
            * This set the date old value if "cancel" button clicked 
            *
            * @return void 
            */
            cancel()
            {   
                // Return the title back to it’s previous state
                this.date = this.cache;
                
                // close the date picker
                this.menu = false;
            },
        },

        watch:{

            date(value)
            {
                this.$emit("update:value",value);
            }
        } 
    }
</script>

<style>
   .datepicker_field .v-input__prepend-inner .v-icon{
      color: black;
      margin-right: 5px;
   }
</style>