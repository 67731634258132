<template>
	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading" :options.sync="paginations" :server-items-length="totalItems"  :item-per-page="10" class="elevation-1 mx-2 pa-4" >
		
    	<!-- -------------- LOGO ----------------- -->
		<template v-slot:item.logo="{ item }">
			<v-avatar size="35px">
				<img alt="organization logo" class="organization_logo" :src="item.organization && item.organization.logo ? item.organization.logo : default_logo"/>
			</v-avatar>
		</template>

		<!-- ------------ ORGANIZATION NAME ---------------- -->
		<template v-slot:item.organization_name="{ item }">
			{{ item.organization ? item.organization.name : null }}
		</template>

		<!-- -------------- EMPLOYEE COUNTS ---------------- -->
		<template v-slot:item.employee_count="{ item }">
			<a @click="redirect('/employees',{ organization_id:item.organization_id, assignment_id:item.id })">{{ item.employee_count ? item.employee_count : 0 }}</a>
		</template>

    	<!-- ------------------ ACTIONS -------------------- -->
		<template v-slot:item.action="{ item }" >
	        <v-menu bottom left transition="scale-transition">
            	<template v-slot:activator="{ on }">
			        <v-btn slot="activator" v-on="on" icon>
			            <v-icon>mdi-dots-horizontal</v-icon>
			        </v-btn>
		    	</template>
	            <v-list>
	            	<v-list-item  @click="edit_item(item)" >
				        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
				        <v-list-item-title> Edit </v-list-item-title>
					</v-list-item>
					<v-list-item  @click="delete_item(item)" >
						<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
						<v-list-item-title> Delete </v-list-item-title>
					</v-list-item>
	            </v-list>
	        </v-menu>
        </template>

	</v-data-table>
</template>

<script>
	
import { excel } from "@/mixins/docs/excel";
import { rules } from "@/validation/rules/index";
import { mapGetters, mapActions } from "vuex";

export default {
	mixins:[excel],
	name:"index",
	data()
	{ 
		return {
			headers:[
				{ text:"LOGO", value:"logo", visible:true, align:"start", sortable:false },
				{ text:"ORGANIZATION NAME", value:"organization_name", visible:true, align:"start", sortable:false },
				{ text:"SHORT NAME", value:"short_name", visible:true },
				{ text:"ASSIGNMENT NAME", value:"name", visible:true }, 
				{ text:"ASSIGNMENT ID", value:"id", visible:true },
				{ text:"ASSIGNMENT CODE", value:"code", visible:true },
				{ text:"EMPLOYEES", value:"employee_count", visible:true },
				{ text:"ACTIONS", value:"action", visible:true, align:"start", sortable:false }
			],	
			default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			rules:rules,
			page:{
				page:1,
				itemsPerPage:10,
				loading:false
			},
			initializing:false
		}
	}, 

	mounted()
	{
		this.$bus.$emit("set-column-filter-values",this.headers)
	},

	async created()
	{	
		this.initilize();

		let array = this.headers;

		this.$bus.$on("column-filter",(value)=>{
			if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
			else { this.headers.push(array[value.index]); }
		});
	},

	beforeDestroy()
    {
    	this.$bus.$off("export-excel-assignments");
    	this.$bus.$off(`assignments-search`);
    },

	methods:{
		...mapActions("assignment",["getList"]),

		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/site/index 
 		*/ 
		initilize()
		{	
			// this indicates as initial fetching
			this.initializing = true;

			// get values with or without query parameter 
        	this.fetch(this.page);

			// to export the records into an excel file 
        	this.$bus.$on("export-excel-assignments",()=> this.export_exel());
        	
        	// search for values from API 
        	this.$bus.$on(`assignments-search`,(value)=>this.search(value));
		},	

		/*
 		* This do search on API value for table view
 		*
 		* @return array /store/modules/site/index 
 		*/ 
		search(value)
		{	
			this.page.loading = true;

			// get some values as keyword 
			let payload = Object.assign(this.page,{ keyword:value });

			// set fetch with payloads 
			this.getList(payload).then(response=>{ 
				this.page.loading = false;
			});
		},

		/*
 		* This fatch values with or without query parameter
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		fetch(page)
		{	
			// loading for table 
			this.page.loading = true;

			// get some values from pagination 
			let payload = Object.assign(this.page,page);

			// set fetch with payloads
			this.getList(payload).then(response=>{ 
				this.initializing = false;
				this.page.loading = false;
			});
		},

		/*
		* export date into an excel file 
		*
		* @return void
		*/
		export_exel()
		{
			this.export_assignments(this.values)
		},

		/*
 		* This show the modal view for edit action
 		*
 		* @return void 
 		*/  
		edit_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Edit Assignment", data:data, mode:"edit", view:"modal-assignment" });
		},

		/*
 		* This show the modal view for delete action
 		*
 		* @return void 
 		*/  
		delete_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Delete Assignment", data:data, mode:"delete", view:"modal-assignment" });
		},

		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/  
		redirect(to,data)
		{	
			this.$router.push({ path:`${to}`, query:data });
		}
	},
	
    computed:{

    	...mapGetters("assignment",{"values":"getList", totalItems:"totalItems", pagination:"pagination"}),

		paginations:{
			get()
			{
				return this.pagination;
			},
			set(value)
			{  
				if(!this.initializing)
				{
					this.$bus.$emit("table-per-page",value.itemsPerPage);

					// get timelogs records
					this.fetch(value);
				}
			}
		}
    },
}
</script>
