<template>
	<v-select :items="item" dense outlined item-text="text" :disabled="disabled" v-model="values" placeholder="Select Default Schedule" item-value="value">
	</v-select>
</template>

<script>
	export default {
		props:["value","disabled"],
		data(){
			return {
				values:"",
				items:[
					{ text:"No Schedule", value:0 }
				],
			}
		},

		mounted()
		{
			this.initialize();
		},

		methods:{

	    	/*
	 		* This initialize value 
	 		*
	 		* @return array /store/modules/schedule/index 
	 		*/ 
			initialize()
			{	
				// get schedule records
				this.$store.dispatch("getScheduleList");
				
				this.$nextTick(()=>{

					this.$store.getters.getScheduleList.map((data)=>{
						this.items.push({ text: `${data.name}, ${ moment(data.from,"hh:mm:ss").format("hh:mm A") } - ${moment(data.to,"hh:mm:ss").format("hh:mm A")}`, value:data.id});
					});
				})

				if(this.value)
				{	
					// select organization with organization id 
					this.values = this.value;
				}
				
			}
		},

		computed:{

			item()
			{	
				let items = [
					{ text:"No Schedule", value:0 }
				];

				this.$store.getters.getScheduleList.map((data)=>{
					items.push({ text: `${data.name}, ${ moment(data.from,"hh:mm:ss").format("hh:mm A") } - ${moment(data.to,"hh:mm:ss").format("hh:mm A")}`, value:data.id});
				});

				return items;
			}
		},

	    watch: {

	    	/*
	 		* Watch for value schedule id as sync
	 		*
	 		* @return array getscheduleList
	 		*/ 
	        values(val)
	        {
	        	this.$emit('update:value', val)
	        },

	        /*
	 		* Watch for value from 'schedule' props 
	 		*
	 		* @return void
	 		*/ 
	        value(val)
	        {	
	        	this.values = val;
	        },
		}
	}
</script>