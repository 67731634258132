<template>
	<v-layout>
		<v-select :items="items" :disabled="disabled" @change="filter({ 'orderBy':$event })" item-text="title" item-value="value" placeholder="Sort" return-json dense/>
	</v-layout>
</template>

<script type="text/javascript">

	import { mapActions, mapGetters } from "vuex";

	export default {
		props:{
			disabled:{
				type:Boolean,
				required:true,
			},
		},
		data(){
			return {
				items:[
					{ title:"Last modified", value:"updated_at" },
					{ title:"Title", value:"title" },
					{ title:"All drafts", value:"draft" }
				]
			}
		},

		methods:{
			...mapActions("template",['getTemplates']),

			filter(data){
				this.getTemplates(data)
			}
		}
	}
</script>