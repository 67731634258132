<template>
	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading" :item-per-page="10" class="elevation-1 mx-2 pa-4">
    	
    	<!-- -------------- LOGO ----------------- -->
		<template v-slot:item.logo="{ item }">
			<v-avatar size="35px">
				<img alt="organization logo" class="organization_logo" :src="item.logo == null ? default_logo : item.logo "/>
			</v-avatar>
		</template>

		<!-- ------------ ORGANIZATION NAME ---------------- -->
		<template v-slot:item.name="{ item }">
			<a @click="redirectToSitesTable('/sites',item)">{{item.name}}</a>
		</template>

		<!-- ---------------- SITE COUNT ------------------- -->
		<template v-slot:item.site_count="{ item }">
			<a @click="redirect('/sites',{ organization_id:item.id })">{{ item.sites ? item.sites : 0 }}</a>
		</template>

		<!-- ------------- MOBILE ADMIN COUNTS  ------------ -->
		<template v-slot:item.mobile_admin_count="{ item }">
			<a @click="redirect('/mobileadmins',{ organization_id:item.id })">{{ item.mobile_admins ? item.mobile_admins : 0 }}</a>
		</template>

		<!-- -------------- EMPLOYEE COUNTS ---------------- -->
		<template v-slot:item.employee_count="{ item }">
			<a @click="redirect('/employees',{ organization_id:item.id })">{{ item.employees ? item.employees : 0 }}</a>
		</template>

    	<!-- ------------------ ACTIONS -------------------- -->
		<template v-slot:item.action="{ item }" >
	        <v-menu bottom left transition="scale-transition">
            	<template v-slot:activator="{ on }">
			        <v-btn slot="activator" v-on="on" icon>
			            <v-icon>mdi-dots-horizontal</v-icon>
			        </v-btn>
		    	</template>
	            <v-list>
	            	<v-list-item  @click="edit_item(item)" >
				        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
				        <v-list-item-title> Edit </v-list-item-title>
					</v-list-item>
					<v-list-item  @click="delete_item(item)" >
						<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
						<v-list-item-title> Delete </v-list-item-title>
					</v-list-item>
	            </v-list>
	        </v-menu>
        </template>

	</v-data-table>
</template>

<script>
	
import { excel } from "@/mixins/docs/excel";
import { rules } from "@/validation/rules/index";
import { mapGetters, mapActions } from "vuex";

export default {
	mixins:[excel],
	name:"index",
	data(){ 
		return {
			headers:[
				{ text:"LOGO", value:"logo", visible:true },
				{ text:"ORGANIZATION NAME", value:"name", visible:true },
				{ text:"SHORT NAME", value:"short_name", visible:true },
				{ text:"ORGANIZATION ID", value:"id", visible:true },
				{ text:"ORGANIZATION CODE", value:"code", visible:true },
				{ text:"SITES", value:"site_count", visible:true },
				{ text:"MOBILE ADMINS", value:"mobile_admin_count", visible:true },
				{ text:"EMPLOYEES", value:"employee_count", visible:true },
				{ text:"ACTIONS", value:"action", visible:true },
			],	
			default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			rules:rules,
			page:{
				page:1,
				itemsPerPage:10,
				loading:false
			},
			initializing:false
		}
	}, 

	mounted()
	{
		this.$bus.$emit("set-column-filter-values",this.headers)
	},

	async created()
	{	
		this.initilize();

		if(this.$store.getters.authUserRole == "Admin")
		{
			this.$router.push("/Dashboard");
		}

		let array = this.headers;

		this.$bus.$on("column-filter",(value)=>{
			if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
			else { this.headers.push(array[value.index]); }
		});
	},

	beforeDestroy()
    {
    	this.$bus.$off("export-excel-organization");
    	this.$bus.$off(`organizations-search`);
    },

	methods:{
		...mapActions(["setSearchOrganization","getOrganizations"]),
		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		initilize()
		{	
			// get organization list
			this.getOrganizations();
			
			// to export the records into an excel file 
        	this.$bus.$on("export-excel-organization",()=> this.export_exel());

        	// search for values from API 
        	this.$bus.$on(`organizations-search`,(value)=>this.search(value));
		},

		/*
 		* This do search on API value for table view
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		search(value)
		{	
			this.page.loading = true;

			this.setSearchOrganization(value).then(response=>{ 
				this.page.loading = false;
			})	
		},

		/*
		* export date into an excel file 
		*
		* @return void
		*/
		export_exel()
		{
			this.export_organization(this.values)
		},

		/*
 		* This show the modal view for edit action
 		*
 		* @return void 
 		*/  
		edit_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Edit Organization", data:data, mode:"edit", view:"modal-organization" });
		},

		/*
 		* This show the modal view for delete action
 		*
 		* @return void 
 		*/  
		delete_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Delete Organization", data:data, mode:"delete", view:"modal-organization" });
		},
		
		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/  
		toSite()
		{
			this.$router.push("/sites")
		},

		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/  
		redirectToSitesTable(to,data)
		{	
			this.$router.push({ path:`${to}`, query:{ organization_id:data.id, organization_name:data.name } })
		},

		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/  
		redirect(to,data)
		{	
			this.$router.push({ path:`${to}`, query:data });
		}
	},

	computed: {

 		/*
 		* Get Array values for table view
 		*
 		* @return array getOrganizationList\
 		*/ 
 		values()
 		{
 			return this.$store.getters.getOrganizationList;
 		},
	},
}
</script>
