<template>
	<main>	
  		<v-autocomplete  
  		v-model="values"
        :items="employees"
        :loading="isLoading"
        :disabled="disabled"
        :filter="filter"
        :rules="rules"
        item-text="firstname"
        item-value="id"
		placeholder="Search Person"
		return-object dense outlined>

  			<!-- chips -->
		    <template v-slot:selection="data">
                <v-chip v-bind="data.attrs" :input-value="data.selected" outlined>
					<v-avatar left>
						<v-img :src="data.item && data.item.image ? data.item.image.avatar : default_avatar"></v-img>
					</v-avatar>
					<template> {{ data.item.firstname }} {{ data.item.lastname }} </template>
                </v-chip>
            </template>
  			<!-- ../chips -->

  			<!-- dropdown list -->
		    <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                	<v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
					<v-list-item-avatar>
						<img :src="data.item && data.item.image ? data.item.image.avatar : default_avatar">
					</v-list-item-avatar>
					<v-list-item-content>
						<v-list-item-title>{{data.item.firstname}} {{data.item.lastname}}</v-list-item-title>
						<v-list-item-subtitle>{{data.item.organization.name}}</v-list-item-subtitle>
					</v-list-item-content>
                </template>
          	</template>
  			<!-- ../dropdown list -->

	    </v-autocomplete>
	</main>
</template>

<script type="text/javascript">

	// import { rules } from "@/components/Form/Modal/Timelog/TimeEntry/scripts/validation/rules";

	export default {
		props:{
			rules:{
				type:Array,
				required:true,
			},
			value:String,
			disabled:Boolean,
		},
		
		data(){
			return {
				default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
				isLoading:true,
				employee:null,
				values:null,
				employees:[],
			}
		},

		mounted(){
			this.getEmployees();
		},

		methods:{

			// filter "first name" and "last name"
			filter(item, queryText, itemText) {         
	            return item.firstname.toLowerCase().includes(queryText.toLowerCase()) || 
	            item.lastname.toLowerCase().includes(queryText.toLowerCase())
	        },

	        getEmployees(){
				axios.get("/employees").then(response=>{ 
					this.employees = response.data; 
					this.isLoading = false; 
				})
	        }
		},

		watch:{
			values(value){
				this.$emit('update:value',value);
			}
		}

	}
</script>