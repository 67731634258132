<template>
    <v-responsive justify="center">
    
		<!-- -------------------- CARD HEADER ------------------- -->
		<v-container>
			<v-row class="mt-3">
				<v-col cols="5" class="d-flex justify-left">
					<small>Face Biometrics</small>
				</v-col>
				<v-col cols="3">
					<small>Face Biometrics</small>
				</v-col>
				<v-col cols="3">
					<small>Liveness Check</small>
				</v-col>
			</v-row>

			<v-row v-for="(value,key) in form" :key="value" no-gutters>

				<!-- ---- Label ---- -->
				<v-col cols="6" class="d-flex justify-left">
					<label class="mt-5" style="color:black;">{{ label[key][value] }}</label>

				</v-col>

				<!-- Face biometrics -->
				<v-col cols="3">
					<v-skeleton-loader v-if="getFaceBiometricsList.id ? false : true" type="chip"></v-skeleton-loader>
					<v-switch v-else  v-model="getFaceBiometricsList[value]" @change="setFaceBiometrics({ id:getFaceBiometricsList.id, value:$event, type:value })"></v-switch>
				</v-col>	

				<!-- liveness check -->
				<v-col cols="3">
					<v-skeleton-loader v-if="getLivenessCheckList.id ? false : true" type="chip"></v-skeleton-loader>
					<v-switch v-else v-model="getLivenessCheckList[value]" @change="setLivenessCheck({ id:getLivenessCheckList.id, value:$event, type:value })">
					</v-switch>
				</v-col>
			</v-row>
			<v-row no-gutters>
				<v-col cols="6" class="d-flex justify-left">
					<label style="color:black;" class="mt-3">Provider</label>
				</v-col>

				<v-col cols="3" md="4" class="d-flex justify-left">
					<v-skeleton-loader width="320" v-if="getFaceRecognitionList.length && getLivenessCheckList.id ? false : true" type="button"></v-skeleton-loader>
					<v-select v-else v-model="getFaceRecognitionRecord.id" :items="getFaceRecognitionList" item-value="id" item-text="name" dense outlined @change="setFaceRecognitionProvider({ face_recognition_provider:$event })"></v-select>
				</v-col>
			</v-row>
		</v-container>
	</v-responsive>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import Errors from "@/validation/errors";

export default {

	mixins:[modal,states],
	data(){ 
		return {
			// to check if the field were all valid
			valid: false,

			// to validate the fields 
			rules:rules,

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: false,

			// to check if action is submitting
			isSubmit: false,

			// to check if mode is delete
			isDelete:false,

			form:["log","time_in","time_out"],

			label:[{"log":"DTR-Log"},{"time_in":"DTR-Time In"},{"time_out":"DTR-Time Out"}]
		}	
	}, 

    created()
    {
    	this.initilize();
    },

    updated()
    {
    	this.isDelete = this.mode == "delete"? true : false;
    },

	methods:{



		...mapActions(["setFRProviderByOrganization","getFaceRecognition","getFaceRecognitions","getFaceBiometrics","getLivenessCheck",
			"setUpdateFaceBiometrics","setUpdateLivenessCheck","setFRProviderByOrganization"]),

		/*
		* This initilize value values needed
		*
		* @return void
		*/ 
		initilize()
		{
			this.getFaceBiometrics();
    		this.getLivenessCheck();
    		this.getFaceRecognition();
    		this.getFaceRecognitions();
		},

		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		submit(type,data)
		{
			switch(type)
			{
				case "FACE_BIOMETRICS":
					this.isSubmit = true;
					this.$bus.$emit("onSubmit-modal",true);
					this.setUpdateFaceBiometrics(data)
					.then((response) => { this.isSubmit = false; this.$bus.$emit("onSubmit-modal",false); })
					.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
                  	.finally(() => {});
				break;

				case "LIVENESS_CHECK":
					this.isSubmit = true;
					this.$bus.$emit("onSubmit-modal",true);
					this.setUpdateLivenessCheck(data)
					.then((response) => { this.isSubmit = false; this.$bus.$emit("onSubmit-modal",false); })
					.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
                  	.finally(() => {});
				break;

				case "FACE_RECOGNITION_PROVIDER":
					this.isSubmit = true;
					this.$bus.$emit("onSubmit-modal",true);
					this.setFRProviderByOrganization(data)
					.then((response) => { this.isSubmit = false; this.$bus.$emit("onSubmit-modal",false); })
					.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
                  	.finally(() => {});
				break;
			} 
      	},

      	setFaceBiometrics(data)
      	{	
      		this.submit("FACE_BIOMETRICS",{ id:data.id, [data.type]:data.value });
      	},

      	setLivenessCheck(data)
      	{
      		this.submit("LIVENESS_CHECK",{ id:data.id, [data.type]:data.value });
      	},

      	setFaceRecognitionProvider(data)
      	{
      		this.submit("FACE_RECOGNITION_PROVIDER",data);
      	}
	},

	computed:{
		...mapGetters(["getFaceRecognitionRecord","getFaceRecognitionList","getFaceBiometricsList","getLivenessCheckList"]),
	},

	watch:{

		/*
 		* Resets the field form
 		*
 		* @return void
 		*/ 
 		dialog(value)
 		{
 			if(!value) { this.$refs.form.reset(); }
 		},

 		/*
 		* To set the field form if add or edit action
 		*
 		* @return void
 		*/ 
 		data(value)
 		{   
 			Object.assign(this.form,value);
 		},
	},
}

</script>

<style type="text/css" scoped>
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}
	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}

</style>

<style type="text/css">
	 	.v-skeleton-loader__button {
	    border-radius: 4px;
	    height: 36px;
	    width: unset !important;
	}
</style>