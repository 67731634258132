<template>
	<div>
		<!-- layout -->
		<v-container fluid>
			<v-expansion-panels  multiple v-model="panel">
				<v-expansion-panel>
					
					<!-- v-expansion-panel-header -->
					<v-expansion-panel-header>
						<template v-slot:default="{ open }">
						  <v-row no-gutters>
						    <v-col cols="4"><b>Default</b></v-col>
						  </v-row>
						</template>
					</v-expansion-panel-header>
					<!-- ../v-expansion-panel-header -->

					<!-- v-expansion-panel-content -->
					<v-expansion-panel-content>
						<v-form v-model="valid" ref="form">

							<!-- EMPLOYEE ROLE -->
							<v-employee-role 
							v-model="form.paragraph" 
							:employee_role.sync="form.employee_role_id" 
							:placeholder="'Recipient'" :disabled="isDelete||isSubmit"/>
							<!-- ../EMPLOYEE ROLE -->

							<v-text-field 
							v-model="form.title"
							:error-messages="error.get('title')" 
							:error.sync="error.has('title')" 
							@input="error.clear('title')" 
							:rules="[rules.title.required]" 
							placeholder="Title" maxLength="100" outlined dense/>
							
							<!-- PARAGRAPH -->
							<v-textarea 
							v-model="form.paragraph" 
							:error-messages="error.get('paragraph')" 
							:error.sync="error.has('paragraph')" 
							@input="error.clear('paragraph')" 
							@drop="drop($event)" @dragover="allowDrop($event)"
							:rules="[rules.paragraph.required]" 
							placeholder="Paragraph" outlined dense/>
							<!-- ../PARAGRAPH -->
							
							<!-- BUTTON TEXT -->
							<v-text-field  
							:error-messages="error.get('button_text')" 
							:error.sync="error.has('button_text')" 
							@input="error.clear('button_text')" 
							v-model="form.button_text" 
							:rules="[rules.button_text.required]" 
							placeholder="Button Text" outlined dense/>
							<!-- ../BUTTON TEXT -->
							
						</v-form>
					</v-expansion-panel-content>
					<!-- ../v-expansion-panel-content -->

					<!-- v-expansion-panel-content -->
					<v-expansion-panel-content>
						<v-row align="end" justify="end" no-gutters>

							<!-- v-btn -->
							<v-col cols="2">
								<v-btn outlined dense color="#0057AD" class="draft-button" @click="setDraft">Draft</v-btn>
							</v-col>
							<!-- ../v-btn -->

							<!-- v-btn-toggle -->
							<v-btn-toggle dense background-color="primary" dark>
								<v-btn :disabled="!form.employee_role_id" color="blue darken-4" @click="sendMessage"> Send </v-btn>
								<v-btn :disabled="!form.employee_role_id" color="blue darken-4" style="min-width:15px;" @click="datePicker({ title:'Schedule Send', mode:'datetime' })"> 
									<v-icon>schedule</v-icon>
								</v-btn>
							</v-btn-toggle>
							<!-- ../v-btn-toggle -->

						</v-row>	
					</v-expansion-panel-content>
					<!-- ../v-expansion-panel-content -->

				</v-expansion-panel>	
			</v-expansion-panels>
		</v-container>
		<!-- ../layout -->

		<!-- layout -->
		<v-layout class="my-3">
			
			<!-- v-row -->
			<v-row align="start" justify="start" no-gutters>
				
				<!-- v-col -->
				<v-col cols="1">
					<v-tooltip bottom>
						<template v-slot:activator="{ on }">
							<label class="d-block font-weight-bold label"> Items
								<span v-on="on" ><v-icon class="mb-1" color="black">mdi-alert-circle-outline</v-icon></span>
							</label>
						</template>
						<span>Items are variables that can only be dragged inside the paragraph block</span>
					</v-tooltip>
				</v-col>
				<!-- ../v-col -->
		
				<div class="mx-2" >
					<v-btn outlined dense color="#0057AD" draggable="true" :disabled="dragable.date" @dragstart="drag($event)" :value="'{{date}}'">	
						Date
					</v-btn>
				</div>
				
				<div class="mx-2">
					<v-btn outlined dense color="#0057AD" draggable="true" :disabled="dragable.time" @dragstart="drag($event)" :value="'{{time}}'">
						Time
					</v-btn>
				</div>

				<div class="mx-2">
					<v-btn outlined dense color="#0057AD" draggable="true" :disabled="dragable.assignment" @dragstart="drag($event)" :value="'{{assignment}}'">
						Assignment
					</v-btn>
				</div>

				<div class="mx-2">
					<v-btn outlined dense color="#0057AD" draggable="true" :disabled="dragable.member_name" @dragstart="drag($event)" :value="'{{memberName}}'">
						Member Name
					</v-btn>
				</div>

				<div class="mx-2">
					<v-btn outlined dense color="#0057AD" draggable="true" :disabled="dragable.employee_role" @dragstart="drag($event)" 
					:value="'{{employeeRole}}'">
						Employee Role
					</v-btn>
				</div>
			</v-row>
			<!-- ../v-row -->

		</v-layout>
		<!-- ../layout -->
	</div>	
</template>

<script>

	import ModalDatePicker from "@/components/Fields/Compose/Modal/DatePicker/Index.vue";
	import VEmployeeRole from "@/components/Fields/Autocomplete/EmployeeRole/Index";
	import  Errors  from "@/validation/errors";
	import { rules }  from "@/validation/rules/index";
	import { mapGetters, mapActions } from "vuex";

	export default {
		props:["show"],
		components:{
			"modal-date-picker":ModalDatePicker,
			"v-employee-role":VEmployeeRole,
		},	
		data(){
			return {
				panel:[0],
				rules:rules,
				error:new Errors(),
				valid:false,
				dragable:{
					date:false,
					time:true,
					assignment:true,
					member_name :false,
					employee_role :false,
					assignment:true,
				},
				employee_role_id:null,

			}
		},

		beforeDestroy()
		{
			this.$bus.$off("modal_datepicker");
			this.$bus.$off("modal_datepicker_cancel");
		},

		mounted()
		{
			this.modalDatePicker();
			this.onSetParagraph(this.form.paragraph);
		},

		methods:{ 

			...mapActions("message",["send","setForm","setClearForm"]),

			/* 
	 		* To create and set the message form status as new  
	 		*
	 		* @return void 
	 		*/ 
			sendMessage(){ this.setSend(0); },

			/*
	 		* To set the message form status as draft 
	 		*
	 		* @return void 
	 		*/  
			setDraft(){ this.setSend(2); },

			/*
	 		* To set message by status 
	 		*
	 		* @return void 
	 		*/ 
			setSend(status){
				if(this.$refs.form.validate()){
					this.send(Object.assign(this.form,{ status:status }))
					.then(response=>{
						this.setClearForm();
						this.$emit("update:show",false);
					})
					.catch(errors=>this.error.record(errors.response.data))
				}
			},

			/*
	 		* This show the modal view for edit action
	 		*
	 		* @return void 
	 		*/  
			datePicker(attribute){ this.$store.dispatch("modal",{ title:attribute.title, mode:attribute.mode, view:"modal-date-picker" }) },

			/*
			* To get the value from dragged element
			*
			* @return void
			*/
			drag(event){ event.dataTransfer.setData("text", event.target.value); },

			/*
			* To set the value from dragged element into an object
			*
			* @return void
			*/
		    drop(event){ this.form.paragraph = event.target.value += event.dataTransfer.getData("text"); },

			/*
			* To prevent some actions when dropping value
			*
			* @return void
			*/
		    allowDrop(event){ event.preventDefault(); },

		    /*
			* Set modal date picker
			*
			* @return void
			*/
		    modalDatePicker()
		    {
		    	this.$bus.$on("datetime",(date)=>{ this.$set(this.form,"broadcast_schedule",date); });
				this.$bus.$on("date",(date)=>{ this.$set(this.form,"date",date); if(!date) this.onDatePickerCancel(); });
				this.$bus.$on("time",(time)=>{ this.$set(this.form,"time",time); if(!time) this.onTimePickerCancel(); });
		    },

			/*
			* To reset remove the value date and string {{date}} on paragraph field
			*
			* @return void
			*/
		    onDatePickerCancel()
		    {
		    	if(this.paragraphContains(this.form.paragraph, "{{date}}")){
		    		this.$set(this.form,"paragraph",this.form.paragraph.replace("{{date}}","").trim());
		    	}
		    },

		    /*
			* To reset remove the value date and string {{time}} on paragraph field
			*
			* @return void
			*/
		    onTimePickerCancel()
		    {
		    	if(this.paragraphContains(this.form.paragraph, "{{time}}")){
		    		this.$set(this.form,"paragraph", this.form.paragraph.replace("{{time}}","").trim());
		    	}
		    },

		    /*
			* To look for string  from the paragraph field 
			*
			* @return boolean
			*/
		    paragraphContains(string = "", value)
		    {
		    	return string && string.indexOf(value) !== -1 ? true : false;
		    },

		    /*
			* To set the varialbles accessability based on the given conditions
			*
			* @return void
			*/
		    setVariablesAccessabilty()
		    {	
				// check if assignment exist, then disable the dragable assignment component 
				this.$set(this.dragable,"date",!this.paragraphContains(this.form.paragraph, "{{data}}"));

				// check if assignment exist, then disable the dragable assignment component 
				this.$set(this.dragable,"assignment",this.paragraphContains(this.form.paragraph, "{{assignment}}"));

				// check if time exist, then disable the dragable time component 
				this.$set(this.dragable,"time",this.paragraphContains(this.form.paragraph, "{{time}}"));
		    },

		    /*
			* To set values while the paragraph field modifying
			*
			* @return void
			*/
		    onSetParagraph(value)
		    {
		    	let _value = value;	

		    	if(this.paragraphContains(_value, "{{date}}"))
		    	{	
		    		// check if the variable date doesn't have value, then call picker for date 
					if(!this.form.date) this.datePicker({ title:"Pick a date", mode:"date" });

					// check if variable time doesn't have value, then call picker modal for time 
					if(this.paragraphContains(_value, "{{time}}") && !this.form.time) this.datePicker({ title:"Pick a time", mode:"time" });

					// check if variable time not exist, if not delete the value time in state 
					if(!this.paragraphContains(_value, "{{time}}")){
				    	this.$set(this.form,"paragraph", _value.replace("{{time}}","").trim());
				    	this.$set(this.form,"time",null);
					}

					// set the dragables accessability 
					this.setVariablesAccessabilty();
				}	

				else 
				{	
					this.$set(this.form,"date",null);

					Object.assign(this.dragable,{ date:false, time:true, assignment:true })

					if(this.paragraphContains(_value, "{{time}}")){
				    	this.$set(this.form,"paragraph", _value.replace("{{time}}","").trim());
				    	this.$set(this.form,"time",null);
					}

					if(this.paragraphContains(_value, "{{assignment}}")){
				    	this.$set(this.form,"paragraph", _value.replace("{{assignment}}","").trim());
					}
				}

				// check if time exist, then disable the dragable time component 
				this.$set(this.dragable,"member_name",this.paragraphContains(this.form.paragraph, "{{memberName}}"));

				// check if time exist, then disable the dragable time component 
				this.$set(this.dragable,"employee_role",this.paragraphContains(this.form.paragraph, "{{employeeRole}}"));
		    },

		    /*
			* To prevent some string re currence in text field
			*
			* @return void
			*/
		    preventOccurence(value)
		    {
		    	let words = ["{{date}}","{{time}}","{{assignment}}", "{{memberName}}", "{{employeeRole}}"];

		    	words.forEach((word,index)=>{
		    		const regex = new RegExp(word, "g");
					if ((value.match(regex) || []).length > 1) {
						const lastOccurrenceIndex = value.lastIndexOf(word);
						this.$nextTick(() => { this.form.paragraph = this.form.paragraph.substring(0, lastOccurrenceIndex); });
					}
		    	})
		    },
		},

		computed:{
			...mapGetters("message",{ "form":"getForm" }),
		},

		watch:{
			"form.paragraph"(value){

				

		    	this.onSetParagraph(value);
		    	this.preventOccurence(value);
   			},
		}
	}
</script>