<template>
    <v-row justify="center">
		<v-dialog v-model="dialog" persistent scrollable max-width="800px" style="max-height:3000px;" @keydown.esc="close()" > 
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <span class="font-weight-bold">{{ title }}</span>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<v-divider></v-divider>
 
				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text >
					<v-form v-model="valid" ref="form" @keyup.native.enter="submit(mode)">
					
		              	<!-- -------------------- ORGANIZATION -------------------- -->
	            		<v-row  style="margin-bottom: -30px;">
		              		<v-col >
		              			<label class="d-block text-left font-weight-bold label my-1"> Organization </label>
		              			<v-organization :organization.sync="form.organization_id" :disabled="AuthRole == 'Admin'||isDelete||isSubmit">
		              			</v-organization>
		              		</v-col>
			            </v-row>

		              	<v-row style="margin-bottom: -30px;">

		              		<!-- ------------------- SITE SHORT NAME -------------------- -->
		              		<v-col>
		              			<label class="d-block text-left font-weight-bold label my-1"> Short Name </label>
		              			<v-text-field outlined placeholder="Type the short name of your assignment" 
		              			dense clearable 
		              			:rules="[rules.assignment_short_name.required,rules.assignment_short_name.format]" 
		              			v-model="form.short_name"

		              			:error-messages="error.get('short_name')"
			             		:error.sync="error.has('short_name')"
			             		@input="error.clear('short_name')"

		              			@keydown="rules.alphaNumericOnly"
		              			:disabled="isSubmit||isDelete">
		              			</v-text-field>
		              		</v-col>
		              		
		              	</v-row>

		              	<!-- -------------------- LABEL NOTE -------------------- -->
		              	<v-row  style="margin-top: 30px;" class="row mx-2">
		              		<span class="d-block text-left bottom-note">Short Name and Logo will be used to personalize your Abizo web account.</span> 
		              	</v-row>

		              	<v-divider class="mx-2 mt-2"></v-divider>

		              	<!-- ------------------- SITE NAME -------------------- -->
		              	<v-row style="margin-bottom: -30px;">
		              		<v-col>
		              			<label class="d-block text-left font-weight-bold label my-1"> Assignment Name </label>
			             		<v-text-field outlined placeholder="Type the name of your assignment" 
			             		name="name"  
			             		dense clearable
			             		@keydown="rules.alphaNumericOnly" 
		              			:rules="[rules.assignment_name.required,rules.assignment_name.format]"

		              			:error-messages="error.get('name')"
			             		:error.sync="error.has('name')"
			             		@input="error.clear('name')"

		              			v-model="form.name"
		              			:disabled="isSubmit||isDelete">
		              			</v-text-field>
		              		</v-col>
		              	</v-row>

		            	<!-- ------------------- ASSIGNMENT CODE -------------------- -->
		              	<v-row style="margin-bottom: -30px;">
		              		<v-col>
		              			<label class="d-block text-left font-weight-bold label my-1" > Assignment Code </label>
			             		<v-text-field outlined placeholder="Type a 6-alpha numeric code" 
			             		dense clearable 
		              			:rules="[rules.assignment_code.required, rules.assignment_code.format, rules.assignment_code.length]"
		              			
		              			:error-messages="error.get('code')"
			             		:error="error.has('code')"
			             		@input="error.clear('code')"

		              			v-model="form.code" @keydown="rules.alphaNumericOnly" maxLength="6"

		              			:disabled="isSubmit||isDelete" 
			             		></v-text-field>
		              		</v-col>
		              	</v-row>

		              	<!-- ------------------- ASSIGNMENT DESCRIPTION -------------------- -->
		              	<v-row style="margin-bottom: -30px;">
		              		<v-col>
		              			<label class="d-block text-left font-weight-bold label my-1" > Assignment Description </label>
			             		<v-textarea outlined placeholder="Type here" dense clearable counter :counter="42"
		              			:rules="[rules.assignment_description.required, rules.assignment_description.format, rules.assignment_description.length]"
		              			:error-messages="error.get('description')"
			             		:error="error.has('description')"
			             		@input="error.clear('description')"
		              			v-model="form.description" maxLength="42"
		              			:disabled="isSubmit||isDelete" 
			             		></v-textarea>
		              		</v-col>
		              	</v-row> 

		        	</v-form>
				</v-card-text>

					<v-divider></v-divider>

	        	    <!-- -------------------- CARD ACTIONS ------------------- -->
		            <v-card-actions class="ma-4">

		               <!-- ------------- DELETE OR DISCARD ------------- -->
		               <v-btn dark  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
		               @click="mode == 'delete' ? submit(mode) : close()"
		               :color="mode == 'delete'?'red':'#0057AD'"
		               :loading="mode == 'delete'? isSubmit : false">
		               {{ mode == 'delete' ? 'Delete':'Discard'}} 
		               </v-btn>

		               <v-spacer></v-spacer>

		               <!-- ------------- CANCEL OR SUBMIT ------------- -->
		               <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
		               @click="mode == 'delete' ? close() : submit(mode)"
		               :loading="mode == 'delete'? false  : isSubmit">
		               {{ mode == 'delete' ? 'Cancel':'Submit' }}
		               </v-btn> 
		            </v-card-actions>

	        </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { states } from "@/mixins/states";
import { modal }  from "@/mixins/modal";
import { auth }   from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

import Errors from "@/validation/errors";
import VOrganizaion from "@/components/Fields/Autocomplete/Organization/Index";
export default {
	mixins:[modal,auth,states],
	components:{
		"v-organization":VOrganizaion,
	},
	data(){ 
		return {
			// to check if the field were all valid
			valid: false,

			// to validate the fields 
			rules:rules,

			// to set the data event Add or Edit
			form:{},

			// errors: new Errors()
			error:new Errors(),

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: false,

			// to check if action is submitting
			isSubmit: false,

			// to check if mode is delete
			isDelete:false,
		}	
	}, 

	created()
	{   
    	this.initilize();
    },

    updated()
    {
    	this.setActions();
    	this.setAuthOrganizationId();
    },

	methods:{
		...mapActions("assignment",["setRegister","setUpdate","setDelete"]),

		/*
 		* This initilize value values needed
 		*
 		* @return void
 		*/ 
		initilize()
		{
			this.setValues();
			this.setAuthOrganizationId();
		},

		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		submit(action = "add")
		{
			switch(action)
			{
				case "add": 
					if(this.$refs.form.validate())
			        {	
						this.isSubmit = true;
			     		this.setRegister(this.form)
			     		.then((response) => { this.isSubmit = false; this.redirect("/assignments"); })
						.then((response) => { this.close(); })
						.catch((errors)  => { 
							this.error.record(errors.response.data);
							this.isSubmit = false; 
						})
			        }
				break;

				case "edit":
					if(this.$refs.form.validate()) 
					{
						this.isSubmit = true;
						this.setUpdate(this.form)
						.then((response) => { this.isSubmit = false })
						.then((response) => { this.close(); })
						.catch((errors)  => { 
							this.error.record(errors.response.data);
							this.isSubmit = false; 
						});
					}
				break;

				case "delete": 
						this.isSubmit = true;
						this.setDelete(this.form)
						.then((response) => {this.isSubmit = false})
						.then((response) => {this.close()})
						.catch((errors)  => {});
				break;
			} 
      	},

      	/*
 		* This assign value values to the form object
 		*
 		* @return void
 		*/
      	setValues()
      	{	
      		Object.assign(this.form,this.data);
      	},

      	/*
 		* This assign organization id coming from organization admin
 		*
 		* @return void
 		*/
      	setAuthOrganizationId()
      	{
	    	if(this.AuthRole == "Admin")
	    	{
	        	this.form.organization_id = this.AuthOrganization.id;
	        }
      	},

      	/*
 		* This set actions based on modal action mode
 		*
 		* @return void
 		*/
      	setActions()
      	{
      		this.isDelete = this.mode == "delete" ? true : false;
			this.isEdit   = this.mode == "edit" ? true : false;
      	}
	},

	watch:{

		/*
 		* Resets the field form
 		*
 		* @return void
 		*/ 
 		dialog(value)
 		{
 			if(!value) { this.$refs.form.reset(); }
 		},
	},
}

</script>

<style type="text/css" scoped>
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}
	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}

</style>