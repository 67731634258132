import store from "@/store"

/**
 * We"ll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API;
// axios.defaults.credentials = true
axios.create({

  // `baseURL` will be prepended to `url` unless `url` is absolute.
  // It can be convenient to set `baseURL` for an instance of axios to pass relative URLs
  // to methods of that instance.
  timeout: 5000,

  // `withCredentials` indicates whether or not cross-site Access-Control requests
  // should be made using credentials
  // withCredentials: true, // default
  // credentials: true,
  crossDomain: true,
  headers: {'Accept': 'application/json','Content-Type':'application/json','Content-Type':'multipart/form-data','Access-Control-Request-Headers': 'x-requested-with' },
  contentType: false,
  processData: false,
  // `auth` indicates that HTTP Basic auth should be used, and supplies credentials.
  // This will set an `Authorization` header, overwriting any existing
  // `Authorization` custom headers you have set using `headers`.
  // Please note that only HTTP Basic auth is configurable through this parameter.
  // For Bearer tokens and such, use `Authorization` custom headers instead.
  // auth: {
  //   username: 'janedoe',
  //   password: 's00pers3cret'
  // },

  // `responseType` indicates the type of data that the server will respond with
  // options are: 'arraybuffer', 'document', 'json', 'text', 'stream'
  //   browser only: 'blob'
  responseType: 'json', // default

  // `responseEncoding` indicates encoding to use for decoding responses
  // Note: Ignored for `responseType` of 'stream' or client-side requests
  responseEncoding: 'utf8', // default

  // `xsrfCookieName` is the name of the cookie to use as a value for xsrf token
  xsrfCookieName: 'XSRF-TOKEN', // default

  // `xsrfHeaderName` is the name of the http header that carries the xsrf token value
  xsrfHeaderName: 'X-XSRF-TOKEN', // default

 

});

// // Add a request interceptor
axios.interceptors.request.use(function (config) {
    // assume your access token is stored in local storage 
    // (it should really be somewhere more secure but I digress for simplicity)
      let token = localStorage.getItem('abizo_user_token');
      if (token) {
         config.headers['Authorization'] = `Bearer ${token}`
      }
      return config;
   }, 
   function (error) {
    // Do something with request error
    return Promise.reject(error);
});

// Add a 401 response interceptor
window.axios.interceptors.response.use(function (response) {
    return response;
}, 
function (error) {
    if (401 === error.response.status) {
        store.dispatch("clear");
        return Promise.reject('Access Unauthorized');
    } 
    else {
        return Promise.reject(error);
  }
});