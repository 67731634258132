<template>
	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading" :item-per-page="10" class="elevation-1 mx-2 pa-4" >
    	<!-- -------------- LOGO ----------------- -->
		<template v-slot:item.logo="{ item }">
			<v-avatar size="35px">
				<img alt="organization logo" class="organization_logo" :src="item.organization && item.organization.logo ? item.organization.logo : default_logo"/>
			</v-avatar>
		</template>

		<!-- ------------ ORGANIZATION NAME ---------------- -->
		<template v-slot:item.org_name="{ item }">
			{{ item.organization ? item.organization.name : null }}
		</template>

		<!-- ------------- MOBILE ADMIN COUNTS  ------------ -->
		<template v-slot:item.mobile_admin_count="{ item }">
			<a @click="redirect('/mobileadmins',item.organization_id,item.id)">{{ item.mobile_admins ? item.mobile_admins : 0 }}</a>
		</template>

		<!-- -------------- EMPLOYEE COUNTS ---------------- -->
		<template v-slot:item.employee_count="{ item }">
			<a @click="redirect('/employees',item.organization_id,item.id)">{{ item.employees ? item.employees : 0 }}</a>
		</template>

    	<!-- ------------------ ACTIONS -------------------- -->
		<template v-slot:item.action="{ item }" >
	        <v-menu bottom left transition="scale-transition">
            	<template v-slot:activator="{ on }">
			        <v-btn slot="activator" v-on="on" icon>
			            <v-icon>mdi-dots-horizontal</v-icon>
			        </v-btn>
		    	</template>
	            <v-list>
	            	<v-list-item  @click="edit_item(item)" >
				        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
				        <v-list-item-title> Edit </v-list-item-title>
					</v-list-item>
					<v-list-item  @click="delete_item(item)" >
						<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
						<v-list-item-title> Delete </v-list-item-title>
					</v-list-item>
	            </v-list>
	        </v-menu>
        </template>

	</v-data-table>
</template>

<script>
	
import { excel } from "@/mixins/docs/excel";
import { rules } from "@/validation/rules/index";
import { mapGetters, mapActions } from "vuex";

export default {
	mixins:[excel],
	name:"index",
	data(){ 
		return {
			headers:[
				{ text:"LOGO", value:"logo", visible:true },
				{ text:"ORGANIZATION NAME", value:"org_name", visible:true },
				{ text:"ACCOUNT NAME", value:"name", visible:true },
				{ text:"ACCOUNT ID", value:"id", visible:true },
				{ text:"ACCOUNT CODE", value:"code", visible:true },
				{ text:"SHORT NAME", value:"short_name", visible:true },
				{ text:"ACTIONS", value:"action", visible:true },
			],	
			default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			rules:rules,
			options:{},
			page:{
				page:1,
				itemsPerPage:10,
				loading:false
			},
			initializing:false
		}
	}, 

	mounted()
    {
      this.$bus.$emit("set-column-filter-values",this.headers)
    },

	async created()
	{	
		this.initilize();

		let array = this.headers;

		this.$bus.$on("column-filter",(value)=>{
			if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
			else { this.headers.push(array[value.index]); }
		});
	},

	beforeDestroy()
    {
    	this.$bus.$off("export-excel-account");
    	this.$bus.$off(`accounts-search`);
    },

	methods:{
		...mapActions(["getAccounts","setSearchAccount"]),
		
		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		initilize()
		{	
			this.getAccounts(); 

			// to export the records into an excel file 
        	this.$bus.$on("export-excel-accounts",()=> this.export_exel());

        	// search for values from API 
        	this.$bus.$on(`accounts-search`,(value)=>this.search(value));
		},

		/*
 		* This do search on API value for table view
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		search(value)
		{	
			this.page.loading = true;

			this.setSearchAccount(value).then(response=>{ 
				this.page.loading = false;
			})	
		},

		/*
		* export date into an excel file 
		*
		* @return void
		*/
		export_exel()
		{
			this.export_accounts(this.values)
		},

		/*
 		* This show the modal view for edit action
 		*
 		* @return void 
 		*/  
		edit_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Edit Account", data:data, mode:"edit", view:"modal-account" });
		},

		/*
 		* This show the modal view for delete action
 		*
 		* @return void 
 		*/  
		delete_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Delete Account", data:data, mode:"delete", view:"modal-account" });
		},

		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/   
		redirect(to,organization_id,site_id)
		{	
			this.$router.push({ path:`${to}/${organization_id}/${site_id}` })
		}
	},

	computed: {

 		/*
 		* Get Array values for table view
 		*
 		* @return array getSiteList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getAccountList;
 		},
	},
}
</script>
