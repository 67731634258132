<template>
	<v-sheet class="col-md" height="600" interval-width="350" :short-weekdays="true">
		<v-calendar :value="date" color="primary" :class="[isLoading ? '':'v-calendar-height']">
			<template v-slot:day="{ date }" >
				<v-skeleton-loader 
					v-if="isLoading" 
					type="chip" 
					class="mx-6"
					
				/>
				<div v-else>
					<p class="font-weight-bold " style="color:#0057AD; margin-">
						<ul style="text-align: start;">
							<li v-if="holidays[date]">
								{{ holidays[date] }}
							</li>
							<li v-if="getDayType(values,date)">
								{{ getDayType(values,date) }}
							</li>
						</ul>
					</p>
					<div v-if="values && values.length" >
						<v-chip
							label
							style="margin-bottom:10px" 
							v-if="parseDateToYearMonth(date) == month"  
							:outlined="current_date > date ? false : true" 
							@click="current_date > date ? 
							'javascript:void(0)' : 
							setModalCalendarAssignment(values[0], date)"
						>
							{{ parseTextFromObject(values,date) }}
						</v-chip>
					</div>
				</div>
			</template>
		</v-calendar>

		
		<div class="d-flex justify-space-between my-5" >
			<div class="justify-start">
				<v-view-by :type.sync="view_by"></v-view-by>
			</div>
			<div class="justify-end">
				<v-employee-navigation :record.sync="employees"/>
			</div>
		</div>
	</v-sheet>
</template> 
<script>
	 
import { excel } from "@/mixins/docs/excel";
import { ArrayDates } from "@/helper/dates";
import { mapActions, mapGetters } from "vuex";
import { calendar_filter } from "@/mixins/filter/calendar";

import VSchedule from "@/components/Fields/Schedule/Index";
import VEmployeeNavigation from "@/components/Calendar/components/EmployeeNavigation/Index";
import ViewBy from "@/components/Calendar/components/ViewByFilter/Index";
import _ from "lodash";

export default {

	mixins:[excel,calendar_filter],
	name:"index",
	components:{
		"v-schedule":VSchedule,
		"v-employee-navigation":VEmployeeNavigation,
		"v-view-by":ViewBy,
	},

	data(){ 
		return {
			employees:"",
			view_by:null,
			timeout:null,
			values:null,
			month:null,
			from:null,
			to:null,
			date:null,
			initializing:true,
			isLoading:true,
			current_date:moment().format('YYYY-MM-DD'),
			dates:[],
			excel_header:[
				{ "header":"Employee ID", "width":25 },
				{ "header":"First Name", "width":25 },
				{ "header":"Last Name", "width":25 },
			],
			headers:[
				{ text:"EMPLOYEE", value:"employee", visible:true },
			],
		}
	}, 

	created(){

        this.getCalendarHolidays(moment().format('YYYY-MM-DD'));

		this.initialize();

		// to export the records into an excel file 
    	this.$bus.$on("export-excel-monthly-calendar",()=>this.export_exel());

		// to update calendar values
    	this.$bus.$on("weekly-calendar-submitted",()=>this.onNavigateEmployee(this.employees));
	},

	beforeDestroy(){
		this.$bus.$off("filter_view_by");
		this.$bus.$off("export-excel-monthly-calendar");
	},
	
	methods:{

		...mapActions("calendar",[
			"getMonthlyCalendar",
			"getIndividualRecord",
			"getCalendarHolidays"
		]),

		/*
 		* This initialize value for table view
 		*/ 
		initialize(){	
			this.onMonthPickerEvent();
			this.initialDates();
		},

		/*
 		* This initialize dates
 		*/ 
		initialDates(date){
        	this.getCalendarHolidays(date);
			
			this.from = date ? this.getStartDateOfMonthDate(date) : this.getStartDateOfMonthDate(moment());
			this.to = date ? this.getEndDateOfMonthDate(date) : this.getEndDateOfMonthDate(moment());
         	this.month = date ? this.parseDateToYearMonth(date) : this.parseDateToYearMonth(moment());

			// set initial date value for filter 
         	this.setFilters({ 
				to:this.to, 
				from:this.from 
			});

			// get the dates for fetching records from request response
			this.getDatesForHeader(this.from,this.to);
		},

		onMonthPickerEvent(){
			// to set the date in range using Vue Event Bus
         	this.$bus.$on("get-month-picker",(date) => { 
         		// initialize date as start of month for calendar view 
         		this.date = moment(date.date).startOf("month").format("YYYY-MM-DD");
         		this.initialDates(date.date);
				this.onNavigateEmployee(this.employees);
        	});
		},

		/*
 		* after entering some text in the input field
 		* the text will be shown after 1 second
 		*/
		onNavigateEmployee(values){

			// reset the headers array for excel to remove dates 
			this.headers.length = 1;

			// reset the headers array for excel to remove dates 
			this.excel_header.length = 3

			// date array for select field 
			this.dates.length = 0;

			this.setFilters(values);

			// get the dates for fetching records from request response
			this.getDatesForHeader(this.from,this.to);

			this.isLoading = true;
			let _values = values;
			let _self = this;
			clearTimeout(this.timeout);

			this.timeout = setTimeout(function(){
				_self.getMonthlyCalendar({ ..._values, ..._self.getFilters })
				.then(res=>{
					_self.isLoading = false; 
					_self.values = res;
					_self.initializing = false; 
				});
			},1000);
		},

		/*
		* export data into an excel file 
		*/
		export_exel(){ this.export_calendar_report(this.values,this.view_by); },

		/*
 		* to parse date into YYYY-MM format 
 		*/
		parseDateToYearMonth:date=>moment(date).format("YYYY-MM"),

		/*
 		* to parse datetime into h-a format 
 		*/
		parseDateTimeToHour:(date, format)=>moment(date,format).format(format),

		/*
 		* to parse  year month into YYYY-MM-DD format 
 		*/
		getStartDateOfMonthDate:(date)=>moment(date).startOf('month').format("YYYY-MM-DD"),

		/*
		* to parse year month into YYYY-MM-DD format 
 		*/
		getEndDateOfMonthDate:(date)=>moment(date).endOf('month').format("YYYY-MM-DD"),

		/*
 		* This set the form for changes of schedule
 		*/ 
		setModalCalendarAssignment(data,date){
			let form = Object.assign({date:date},data);
			this.$store.dispatch("modal",{ title:`${form.firstname} ${form.lastname}`, data:form, mode:"edit", view:"modal-calendar-assignment" });
		},

		/*
		* This append MM/DD dates in format as header into the table
		*/ 
        getDatesForHeader(from, to){

			from = moment(from);
			to = moment(to);

			this.dates.length = 0;

			// iterate, to get the dates in range 
			while (from <= to){

				// header date for table
				this.headers.push({
					"text":{
						date:moment(from).format('ddd'),
						day:moment(from).format('DD')
					},
					"value":moment(from).format('YYYY-MM-DD'),
					"visible":true
				});
				
				// formatted the date
                const formattedDate = moment(from).format('YYYY-MM-DD');

				// if holiday is exist on the date
				const header = this.holidays[formattedDate] ? `${formattedDate}-Holiday` : formattedDate;

				// excel header date for table
				this.excel_header.push({
					"header":header,
					"width":25,
				});

				// dates use for retrieving values from an object 
				this.dates.push(moment(from).format('YYYY-MM-DD'));

				// addition 1 day 
				from = moment(from).add(1, 'days');
			}
    	},

		/*
		* to parse object into a text value
		*/
		parseTextFromObject(value,date){
			// console.log(value)
			const index = 0;
 
			// if(value && value.length && value[index].calendar[date]){
			if(!_.isEmpty(value[index].calendar[date])){

				if(this.view_by == "ASSIGNMENT"){
				   return this.parseAssignmentData(value[index].calendar[date],"No assignment");
				}	

				else if(this.view_by == "SHIFT"){
					if(_.get(value[index].calendar[date],"schedule")){
						return this.parseObjectToText(_.get(value[index].calendar[date],"schedule"));
					} 
					return "No Shift";
				}
				return this.parseScheduleData(value[index].calendar[date]) 
			}

			return `No ${this.view_by.toLowerCase()}`;
		},

		/*
		* To parse the object into a text 
		*/
		parseAssignmentData(array,placeholder){
			if(array.filter(data=>data!=null).length > 0)
			{  
			    let names = array
			    .map(data=>data && data.name ? data.name : null )
			    .filter(data=>data!=null);
			    return (names.length > 1) ? `${ names[0] } + ${names.length - 1}` : names.toString();
			} 

			return placeholder;
		},

		
		/*
		* export data into an excel file 
		*/
		parseScheduleData(data){
			let { name, from, to } = data || {};
			return (from && to) ? `${name}, ${ this.parseDateTimeToHour(from, "h a") } - ${this.parseDateTimeToHour(to, "h a")}` : name;
		},
		 /*
		* to parse object data into a text 
		*/
		parseObjectToText(data){
			let { name, from, to } = data || {};
			const newFormat = (date) => moment(date,"h a").format("h a")
			return (from && to) ? 
			`${name}, ${newFormat(from)} - ${newFormat(to)}` : 
			name;
		},

		
		getDayType(item, date){
			const type = _.get(item[0].calendar[date],"day_type");
			if(type){
				if(type === 2){
					return "Rest Day";
				}
			} 
		},
	},

	computed:{
		...mapGetters("calendar",{ 
			"values":"getCalendarList", 
			"holidays":"getHolidays" 
		}),
	},

	watch:{
		employees(values){
			this.onNavigateEmployee(values);
			this.$bus.$emit("monthly-calendar",values); // for header name, employee id and avatar
		},
		
		view_by(value){  
        	if(!this.initializing) this.onNavigateEmployee(this.employees);
    	},
	}
}
</script>

<style scoped>
.v-calendar-height {
  display: table ;
}
</style>