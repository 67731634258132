<template>
	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading" :item-per-page="10" class="elevation-1 mx-2 pa-4">
		
		<!-- -------------- ORGANIZATION ----------------- -->
		<template v-slot:item.organization_logo="{ item }" >
		    <v-avatar size="35px">
				<img alt="Organization Logo" :src="item.organization.logo ? item.organization.logo : default_logo">
			</v-avatar>
			{{ item.organization ? item.organization.short_name : '' }}
		</template>

		<!-- --------------- PHOTO ----------------- -->
		<template v-slot:item.photo="{ item }">
			<v-avatar size="35px"> 
				<img alt="Photo" :src="item && item.image && item.image.avatar ?  item.image.avatar : default_avatar">
			</v-avatar>
		</template>

		<!-- ------------- FACE BIO ---------------- -->
		<template v-slot:item.facebio="{ item }">
			<v-avatar size="35px">
				<img alt="FaceBio" :src="item && item.image && item.image.facebio ?  item.image.facebio : default_facebio">
			</v-avatar>
		</template>

		<!-- ----------------- EMPLOYEE ROLE ---------------- -->
		<template v-slot:item.role="{ item }"> 
			<v-chip style="width:100px; display:block; text-align:center; width:auto;" label outlined>
				<span style="color: black;">
				{{item.employee_role && item.employee_role[0] ? item.employee_role[0].name : ' - '}}</span>
			</v-chip>
		</template>

		<!-- ------------ EMAIL ADDRESS ------------ -->
		<template v-slot:item.email="{ item }">
			<span style="color: black;">{{item.contact ? item.contact.email : "-"}}</span>
		</template>

		<!-- ------------ REGION ------------ -->
		<template v-slot:item.region="{ item }">
			<span style="color: black;">{{item.work_info && item.work_info.region ? item.work_info.region : "-"}}</span>
		</template>

		<!-- ------------ SITE ------------ -->
		<template v-slot:item.site="{ item }">
			<span style="color: black;"> {{item.site && item.site[0] ? item.site[0].name : "-"}}</span>
		</template> 

		<!-- ------------ ACCOUNT ------------ -->
		<template v-slot:item.account="{ item }">
			<span style="color: black;">{{item.account && item.account[0] ? item.account[0].name : "-"}}</span>
		</template>

		<!-- ------------ ASSIGNMENT ------------ -->
		<template v-slot:item.assignment="{ item }">
			<span style="color: black;">{{ parseArrayObjectToText(item.assignment,"name","-") }}</span>
		</template>

		<!-- -------------- ACTIONS ---------------- -->
		<template v-slot:item.action="{ item }" >
	        <v-menu bottom left transition="scale-transition">
            	<template v-slot:activator="{ on }">
			        <v-btn slot="activator" v-on="on" icon>
			            <v-icon>mdi-dots-horizontal</v-icon>
			        </v-btn>
		    	</template>
	            <v-list>
	            	<v-list-item  @click="edit_item(item)" >
				        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
				        <v-list-item-title> Edit </v-list-item-title>
					</v-list-item>
					<!-- <v-list-item  @click="delete_item(item)" >
						<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
						<v-list-item-title> Delete </v-list-item-title>
					</v-list-item> -->
	            </v-list>
	        </v-menu>
        </template>
        
	</v-data-table>
</template>
<script>

import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { rules } from "@/validation/rules/index";
import { auth } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
	mixins:[excel,auth],
	name:"index",
	data(){
		return {
			headers:[
				{ text:"ORGANIZATION", value:"organization_logo", visible:true},
				{ text:"PHOTO", value:"photo", visible:true },
				{ text:"USER ID", value:"id", visible:true },
				{ text:"EMPLOYEE ID", value:"employee_id", visible:true },
				{ text:"EMPLOYEE ROLE", value:"role", visible:true },
				{ text:"FIRST NAME", value:"firstname", visible:true },
				{ text:"MIDDLE NAME", value:"middlename", visible:true },
				{ text:"LAST NAME", value:"lastname", visible:true },
				{ text:"SUFFIX", value:"suffix", visible:true },
				{ text:"EMAIL ADDRESS", value:"email", visible:true },
				{ text:"REGION", value:"region", visible:true },
				{ text:"SITE", value:"site", visible:true },
				{ text:"ACCOUNT", value:"account", visible:true },
				{ text:"ASSIGNMENT", value:"assignment", visible:true },
				{ text:"ACTIONS", value:"action", visible:true}, 
			],
			default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
			default_facebio:require('@/assets/images/facebio/default-facebio.jpg'),
			form:{},
			page:{
				page:1,
				itemsPerPage:10,
				loading:false
			},
			initializing:false,
		}
	},

	mounted()
	{
		this.$bus.$emit("set-column-filter-values",this.headers)
	},

	async created()
	{		
		this.initialize();

		let array = this.headers;

		this.$bus.$on("column-filter",(value)=>{
			if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
			else { this.headers.push(array[value.index]); }
		});
	},

	beforeDestroy()
    {
    	this.$bus.$off("export-excel-employee");
    	this.$bus.$off(`employees-search`);
    },

	methods:{
		...mapActions(["setSearchEmployee","getEmployees"]),

		/*
 		* This initilize the value for table view
 		*
 		* @return array getEmployeeList
 		*/ 
		initialize()
		{	
			// get values with or without query parameter 
        	this.fetch();
			
			// to export the records into an excel file 
        	this.$bus.$on("export-excel-employee",()=> this.export_exel());

        	// search for values from API 
        	this.$bus.$on(`employees-search`,(value)=>this.search(value));
		},

		/*
 		* This fatch values with or without query parameter
 		*
 		* @return array /store/modules/organization/index 
 		*/	
		fetch()
		{	
			let query = this.$route.query; 
			this.getEmployees(query);
		},
		
		/*
 		* This do search on API value for table view
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		search(value)
		{	
			this.page.loading = true;

			this.setSearchEmployee(value).then(response=>{ 
				this.page.loading = false;
			})	
		},

		/*
		* export date into an excel file 
		*
		* @return void
		*/
		export_exel()
		{   
			this.export_employee(this.values)
		},
		
		organization(item)
		{
			if(item.work_info.role == 'Super Admin' && !item.organization && !item.organization.logo)
			{
				return this.default_facebio;
			}

			else
			{
				return item.organization.logo;
			}
		},

		/*
 		* This show the modal view for edit action
 		*
 		* @return void 
 		*/ 
		edit_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Edit Employee", data:data, mode:"edit", view:"modal-employee" });
		},

		/*
 		* This show the modal view for delete action
 		*
 		* @return void 
 		*/ 
		delete_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Delete Employee", data:data, mode:"delete", view:"modal-employee" });
		},

		/*
		* To parse the object into a text 
		*
		* @return string
		*/
		parseArrayObjectToText(array_object,property,placeholder)
		{     
			if(array_object && array_object.length > 0)
			{  
				return (array_object.length > 1) ?
				`${array_object.map(data=>data[property])[0].toString()} + ${array_object.length - 1}` :
				array_object.map(data=>data[property]).toString();
			} 
			return placeholder;
		},
	},

	computed:{		

		...mapGetters({"values":"getEmployeeList"}),
	}, 
}
</script>
