<template>
	<v-autocomplete v-model="organizations" :disabled="disabled" :items="values" item-text="name" item-value="id" placeholder="Search an organization" append-icon="search" outlined dense clearable>
		<template v-slot:selection="data">
			<v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item)" >
				<v-avatar left>
					<v-img :src="data.item.logo ? data.item.logo : default_logo"></v-img>
				</v-avatar>
				{{ data.item.name }}
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item.logo ? data.item.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
	props:["organization","disabled"],
	mixins:[modal,states,auth],
    data () 
    {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			organizations:"",
		}
    },

	mounted()
	{	
		this.initilize();
	},

    methods: {
    	...mapActions("assignment",{"getAssignmentDropdown":"getDropdown"}),
		...mapActions("employee_role",{"getEmployeeRolesDropdown":"getDropdown"}),

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		initilize()
		{	
			if(this.organization)
			{	
				// select organization with organization id 
				this.organizations = this.organization;

				// get sites per organization
				this.getSitesByOrganization(this.organization);

				// get accounts per organization
				this.getAccountsByOrganization(this.organization);

				// get assignment per organization
				this.getAssignmentDropdown(this.organization);

				// get employee role per organization
				this.getEmployeeRolesDropdown(this.organization);

				if(this.AuthOrganization)
				{
					// get organization list
					this.$store.dispatch("getOrganizationsById",{id:this.AuthOrganization.id});
				}
			}

			if(this.AuthRole == "Admin")
			{
				// get organization list
				this.$store.dispatch("getOrganizationsById",{id:this.AuthOrganization.id});
	      	}

	      	else 
	      	{
	      		// get organization list
				this.$store.dispatch("getOrganizations");
	      	}
		},

		/*
 		* get site per organization for site dropdown
 		*
 		* @return array /store/modules/organization/index 
 		*/
		getSitesByOrganization(organization_id)
		{
			// get sites per organization
			this.$store.dispatch("getSitesByOrganization",{ organization_id:organization_id });
		},

		/*
 		* get account per organization for account dropdown
 		*
 		* @return array /store/modules/organization/index 
 		*/
		getAccountsByOrganization(organization_id)
		{
			// get accounts per organization
			this.$store.dispatch("getAccountsPerOrganization",{ organization_id:organization_id });
		},

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item)
		{
			this.organizations = null;
		},
    },
	computed: {

 		/*
 		* Array values 
 		*
 		* @return array getOrganizationList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getOrganizationList;
 		},
	},

    watch: {

        /*
 		* Look for value organization id as sync
 		*
 		* @return array getOrganizationList
 		*/ 
        organizations(organization_id)
        {	
        	// set organization id when select organization on dropdown
        	this.$emit('update:organization', organization_id);

			// get sites per organization
			this.getSitesByOrganization(organization_id);

			// get accounts per organization
			this.getAccountsByOrganization(organization_id);

			// get assignment per organization
			this.getAssignmentDropdown(organization_id);

			// get employee role per organization
			this.getEmployeeRolesDropdown(organization_id);
		},

        /*
 		* Watch for value from 'organization' props 
 		*
 		* @return void
 		*/ 
        organization(val)
        {	
        	this.organizations = val; 
        },
		
    },

}
</script>