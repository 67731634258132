<template>

	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading"  :items-per-page="10" class="elevation-2 mx-2 pa-3">
		
		<template>{{ header }}</template>

		<!-- -------------- PHOTO ----------------- -->
		<template v-slot:item.avatar="{ item }">
			<v-avatar size="40px">
				<img alt="Avatar" :src="item && item.user && item.user.avatar ? item.user.avatar : default_avatar ">
			</v-avatar>
		</template>

		<!-- -------------- ROLE ----------------- --> 
		<template v-slot:item.role="{ item }">
			<v-chip style="width:100px; display:block; text-align: center;" label outlined>{{item.role ? item.role : ' - ' }}</v-chip>
		</template>

		<!-- -------------- LOGTYPE --------------- -->
		<template v-slot:item.log_type="{ item }">
         <v-chip label class="v-chip-label"
          :color="logtype(item.log_type).color" 
          :text-color="logtype(item.log_type).text_color"> {{ logtype(item.log_type).format }}</v-chip>
		</template>

		<!-- -------------- DATE ----------------- -->
		<template v-slot:item.date="{ item }">
			{{ format(item.timestamp,"date","DD MMM YYYY")  }}
		</template>

	   <!-- -------------- DATE ----------------- -->
		<template v-slot:item.time="{ item }">
			{{ format(item.timestamp,"date","h:mm a") }}
		</template>
   
   </v-data-table>
</template>

<script>

import { excel } from "@/mixins/docs/excel";
import { auth } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
   mixins:[excel,auth],
   data()
   {
      return {
         headers: [
            { text: "PHOTO",   value: "avatar", visible:true  },
            { text: "LOG TYPE", value: "log_type", visible:true  },
            { text: "LOG DATE", value: "date", visible:true  },
            { text: "LOG TIME", value: "time", visible:true  },
            { text: "ROLE", value: "role", visible:true  },
            { text: "FIRST NAME", value: "first_name", visible:true  },
            { text: "LAST NAME", value: "last_name", visible:true  },
            { text: "LOGIN IP", value: "login_ip", visible:true  },
         ],  
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         page:{
            page:1,
            itemsPerPage:10,
            loading:false
         },
         initializing:false
         
      }
   },

   mounted()
   {
      this.$bus.$emit("set-column-filter-values",this.headers);
   },

   async created()
   {  
      this.initialize();

      if(this.$store.getters.authUserRole == "Admin")
      {
         this.$router.push("/dashboard")
      }

      let array = this.headers;

      this.$bus.$on("column-filter",(value)=>{
         if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
         else { this.headers.push(array[value.index]); }
      });
   },
   
   beforeDestroy()
   {
      this.$bus.$off("export-excel-activities");
      this.$bus.$off(`activities-search`);
   },

   methods:{

      ...mapActions(["getActivityLogs","setSearchActivity"]),

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/Timelogs/Totals/index 
      */ 
      initialize()
      {
         this.getActivityLogs();

         // to export the records into an excel file 
         this.$bus.$on("export-excel-activities",()=>this.export_exel());

         // search for values from API 
         this.$bus.$on(`activities-search`,(value)=>this.search(value));
      },

      /*
      * This do search on API value for table view
      *
      * @return array /store/modules/account/index 
      */ 
      search(value)
      {  
         this.page.loading = true;

         this.setSearchActivity(value).then(response=>{ 
            this.page.loading = false;
         }) 
      },

      /*
      * export data into an excel file 
      *
      * @return void
      */
      export_exel()
      {
         this.export_activities(this.values)
      },

      /*
      * to set timestamp/date into format date with 
      *
      * @parameter value, the date
      * @parameter type, date only | time
      * @parameter format 
      *
      * @return string date
      */
      format(value,type,format)
      {
         switch(type)
         {
            case "time": return moment(value).format(format);  break;
            case "date": return moment(value).format(format); break;
         }
      },

      /*
      * to set log type with design 
      *
      * @parameter value, the date
      *
      * @return string object
      */
      logtype(value)
      {
         switch(value)
         { 
            case "login": return { color:"#E8F5E9" ,  text_color:"#5EAF59", format:"Log In"}; break;
            case "logout": return { color:"#E0E0E0" , text_color:"#757575", format:"Log Out" }; break;
         }
      },
   },

   computed:{

      /*
      * Get value from store using getter
      *
      * @return array getTimeLogsList
      */ 
      values(){
         // return 
         return this.$store.getters.getActivityList;;
      },
   },
}
</script>

<style type="text/css">
   .v-chip-label{
      width:90px;
      display:block;
      text-align:center;
   }

</style>