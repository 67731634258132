import store from "@/store"

const state = { 
	face_biometrics:{ list:[], record:[] }
}

const getters = {

	/*
	* GET face biometrics list
	* to set the list after fetching
	*/	
	getFaceBiometricsList:state=>{
		return state.face_biometrics.list;
	},

	/*
	* GET face_biometrics record
	* to set the record after fetching
	*/	
	getFaceBiometricRecord:state=>{
		return state.face_biometrics.record;
	}
}

const mutations = {

	/*
	* GET face biometrics record
	* to get the record after fetching
	*/	
	GET_FACE_BIOMETRICS_RECORD:(state,record)=>{
		state.face_biometrics.record = record;
	},

	/*
	* GET face biometrics list
	* to get the list after fetching
	*/	
	GET_FACE_BIOMETRICS_LIST:(state,list)=>{
		state.face_biometrics.list = list;
	},

	/*
	* ADD face biometrics record
	* to set the list additional record after add/registraion
	*/	
	ADD_FACE_BIOMETRICS_RECORD:(state,data)=>{
		Object.assign(state.face_biometrics.list, data);
	},

 	/*
	* UPDATE face biometrics record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_FACE_BIOMETRICS_RECORD:(state,data)=>{
		let index = state.face_biometrics.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.face_biometrics.list[index],data)
	},

	/*
	* DELETE face biometrics record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_FACE_BIOMETRICS_RECORD:(state,id) => {
		let index = state.face_biometrics.list.findIndex(list => list.id == id)
		state.face_biometrics.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch face biometrics list
	*
	* @return string
	*/	
	getFaceBiometrics(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/face-biometrics")
			.then((response)=>{ context.commit('GET_FACE_BIOMETRICS_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*	
	* Add/Register face biometrics record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterFaceBiometrics(context,data)
	{
		return new Promise((resolve,reject)=>{ 
			axios.post("/face-biometrics",data)
			.then((response)=>{ resolve(response);})
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>context.dispatch("getFaceBiometrics"));
		})
	},
	
	/*
	* Edit/Update face biometrics record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateFaceBiometrics(context,data)
	{	
		return new Promise((resolve,reject) => { 
			axios.put(`/face-biometrics/${data.id}`,data)
			.then((response)=>{ context.dispatch("getFaceBiometrics"); resolve(response);})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove face biometrics record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteFaceBiometrics(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/face-biometrics/${data.id}`)
			.then((response)=>{ context.dispatch("getFaceBiometrics");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const face_biometrics = {
	state,
	getters,
	mutations,
	actions
}