<template>
	<div>
		<div class="d-flex justify-space-between">
			<v-btn icon @click="onPrevious()" :disabled="index == 0">
				<v-icon>mdi-chevron-left</v-icon>
			</v-btn>
			<span class="mt-1 mx-6">{{ employeeName }}</span>
			<v-btn icon @click="onNext()" :disabled="disableButton">
				<v-icon>mdi-chevron-right</v-icon>
			</v-btn>
		</div>
	</div>
</template>
<script>
	import { mapActions, mapGetters } from "vuex";
	import { calendar_filter } from "@/mixins/filter/calendar";

	export default {
		mixins:[calendar_filter],
		props:{
			record:Object,
		},

		data(){
			return {
				index:0,
				index_count:0,
				values:[],
			}
		},

		created(){
			this.employee_initialization();

			this.$bus.$on("filter_employee",()=>{
				this.clearRouteParameter();
				this.employee_initialization();
			})
		},

		beforeDestroy(){
	    	this.$bus.$off("filter_employee");
		},

		methods:{
			...mapActions("calendar",["getEmployeeRecord","getOneEmployeeRecord"]),
			
			/*
			* Navigate employee record based on index backward
			* 
			* @return void 
			*/
			onPrevious(){
				this.index--;
				if(this.index <= -1){
					this.index = 0;
				}
				this.employees_record(this.index);
			},

			/*
			* Navigate employee record based on index forward
			*/
			onNext(){	 
				this.index++;
				if(!this.index == this.index_count){	
					let index_count = this.index_count - 1;
					this.index = index_count;
				}
				this.employees_record(this.index);
			},

			/*
			* Initialize employee record fetching
			*/
			employee_initialization(){

				if(this.$route.query.employee_id && this.$route.query.organization_id){
					this.getOneEmployeeRecord(this.$route.query).then(response=>{ 
						this.values = response;
						this.index = 0;
						this.$emit("update:record",response[0]); 
					});
				}
				else {
					this.getEmployeeRecord(this.getFilters).then(response=>{ 
						this.values = response;
						this.index = 0;
						this.$emit("update:record",response[0]); 
					});
				}
			},

			/*
			* To clear the route parameter when get filter 
			*/
			clearRouteParameter(){
				if(this.$route.query.employee_id && this.$route.query.organization_id){
					this.$router.replace({'query': null});
				}
			},

			/*
			* Throw value about employee record fetched
			*/
			employees_record(index){ 
				this.$emit("update:record",this.values[index]); 
			},
		},

		computed:{
			employeeName(){
				let values = this.values[this.index];
				if(values && Object.hasOwnProperty.call(values,"firstname") && Object.hasOwnProperty.call(values,"lastname")){
					return `${this.values[this.index].firstname} ${this.values[this.index].lastname}`
				}
			},

			disableButton(){ 
				return this.index == this.index_count - 1 || !this.index_count
			}
		},
		watch:{
			values(data){ 
				this.index_count = data.length; 
			},
		}
	}
</script>