<template>
	<v-icon>mdi-play</v-icon>
</template>

<script>
export default {
	props:["type","image","route_link"],
	data(){
		return {
			route_links:"",
			images:"",
			types:""
		}
	},

	created()
	{
		this.initialize();
	},

	updated()
	{

	},

	methods:{
		initialize()
		{
			this.route_links = this.route_link;

			this.images = this.images;

			this.types = this.type;
		},

		setValues()
		{

		},

		actions()
		{
			this.$bus.$emit("onDelete",{ 

			})
		}
	},
}
</script>