<template>
	<v-menu ref="menu" v-model="datepicker.show" :disabled="disable"  persistent transition="scale-transition" max-width="230px" min-width="290px">
		<template v-slot:activator="{ on }">
			<v-text-field style="height:25px; transform:scale(0.9); " v-model="datepicker.text" v-on="on" :disabled="disable" :placeholder="placeholder" readonly dense outlined/>
		</template>
		<v-date-picker v-model="date" no-title scrollable/>
	</v-menu>
</template>

<script>
	export default {
		props:{
			value:String,
			placeholder:{
				type:String,
				default:"Choose date",
			},
			disable:{
				type:Boolean,
				default:false,
			}
		},

		data(){
			return {
				datepicker:{ show:false, text:"", value:"" },
			}
		},

 		computed:{
 			date:{
 				get(){
 					this.$set(this.datepicker,'text',moment(this.value).format('DD MMM YYYY')); 
 					if(!this.value)this.$set(this.datepicker,'text',null);
 					return this.value ? this.value : null;
 				},
 				set(value){
					this.$emit("update:value",value);
 				}
 			}
 		},
	}

</script>