<template>
    <v-row justify="center">
		<v-dialog v-model="dialog" persistent scrollable max-width="800px"  @keydown.esc="close()">
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
					<div class="d-flex flex-column justify-start align-start">
						<span class="font-weight-bold">{{ title }}</span>
						<span class="subtitle-2">{{ this.form.date | formatDate }}</span>
					</div>
		            <v-spacer></v-spacer>
		            <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

		        <v-divider></v-divider>

				<!-- -------------------- SKELETON LOADER ------------------- -->
				<v-skeleton-loader v-if="isLoading" height="100%" type="article,article"></v-skeleton-loader>

				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text v-if="!isLoading">

					<!-- ------------ SHIFT ------------ -->
					<v-expansion-panels  class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header>
								<template v-slot:default="{ open }">
								  <v-row no-gutters>
								    <v-col cols="4">Shift</v-col>
								    <v-col cols="8" class="text--secondary">
								       <span v-if="!open" key="1"> {{ schedules.filter(data=> data.value == form.schedule_id).map(data=>data.text).toString() }} </span>
								    </v-col>
								  </v-row>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-list-item-group v-model="schedule_index">
									<v-list-item v-for="(item, i) in schedules" 
										:key="`item-${i}`" 
										@change="onChangesValue(item.value,'SCHEDULE')" :value="item.id" 
										active-class="blue--text text--accent-4">
										<v-list-item-content @click="setScheduleId(item.value)" >
											<v-list-item-title v-html="item.text"></v-list-item-title>
										</v-list-item-content>
									</v-list-item>
								</v-list-item-group>
							</v-expansion-panel-content>
						</v-expansion-panel>		
					</v-expansion-panels>		

					<!-- ------------ ACCOUNT NAME ------------ -->
					<v-expansion-panels class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header>	
								<template v-slot:default="{ open }">
								  <v-row no-gutters>
								    <v-col cols="4">Account</v-col>
								    <v-col cols="8" class="text--secondary">
								        <span v-if="!open" key="1"> {{ accounts.filter(data=> data.id == form.account_id).map(data=>data.name).toString() }}
								        </span>
								    </v-col>
								  </v-row>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-account 
								@onChanged="onChangesValue($event,'ACCOUNT')" 
								:account.sync="form.account_id">
								</v-account>
							</v-expansion-panel-content>
						</v-expansion-panel>		
					</v-expansion-panels>		

					<!-- ------------ SITE NAME ------------ -->
					<v-expansion-panels class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header>
								<template v-slot:default="{ open }">
								  <v-row no-gutters>
								    <v-col cols="4">Site</v-col>
								     <v-col cols="8" class="text--secondary">
								        <span v-if="!open" key="1"> 
								        	{{ 
									        	sites.filter(data=> data.id == form.site_id)
									        	.map(data=>data.name).toString() 
								        	}} 
								        </span>
								    </v-col>
								  </v-row>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-site @onChanged="onChangesValue($event,'SITE')" :site.sync="form.site_id" ></v-site>
							</v-expansion-panel-content>
						</v-expansion-panel>		
					</v-expansion-panels>		

					<!-- ------------ ASSIGNMENT NAME ------------ -->
					<v-expansion-panels class="mt-5">
						<v-expansion-panel>
							<v-expansion-panel-header>
								<template v-slot:default="{ open }">
								  <v-row no-gutters>
								    <v-col cols="4">Assignment</v-col>
								     <v-col cols="8" class="text--secondary">
								        <span v-if="!open" key="1"> 
								        	{{ parseAssignmentValues(form.assignment_id) }} 
								        </span>
								    </v-col>
								  </v-row>
								</template>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<v-assignment 
									@onChanged="onChangesValue($event,'ASSIGNMENT')"
									:assignment.sync="form.assignment_id"
									:multiple="true" 
								/>
								<v-radio-group 
									v-if="radio_assignment_status" 
									v-model="form.assignment_status"
									selected>
									<v-radio label="No, Set as default" value="0" @change="onSetDefault()"/>
									<v-radio label="Yes" value="1" @change="onSetManual()"/>
								</v-radio-group>
							</v-expansion-panel-content>
						</v-expansion-panel>		
					</v-expansion-panels>		

					<v-switch
						v-model="dayTypeSwitch"
						:label="'Assign as Rest Day'"
						@click="handleCallDayTypeDailog()"
					/>

				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">
				   
				   <!-- -------------------- SKELETON LOADER ------------------- -->
	               <v-skeleton-loader width="150" v-if="isLoading" type="button"></v-skeleton-loader>

	               <!-- ------------- DELETE OR DISCARD ------------- -->
	               <v-btn v-if="!isLoading" dark class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
	               @click="mode == 'delete' ? submit(mode) : cancel()"
	               :color="mode == 'delete'?'red':'#0057AD'"
	               :loading="mode == 'delete'? isSubmit : false ">
	               {{ mode == 'delete' ? 'Delete':'Cancel'}} 
	               </v-btn>

	               <v-spacer></v-spacer>

	               <!-- ------------- CANCEL OR SUBMIT ------------- -->
	               <v-btn v-if="!isLoading" dark color="#0057AD" :disabled="!isChanged" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
	               @click="mode == 'delete' ? close() : submit(mode)"
	               :loading="mode == 'delete'? false  : isSubmit">
	               {{ mode == 'delete' ? 'Cancel':'Save Changes' }}
	               </v-btn> 

	            </v-card-actions>

	        </v-card>
	    </v-dialog>
		
	    <v-dialog v-model="dialog_question" persistent scrollable max-width="800px" @keydown.esc="onCancelChanges()">
			<v-card>
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="onCancelChanges()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text class="card-text">
					<div class="modal-text">{{ message }}</div>
				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">
	            	
	               	<v-btn dark :class="modes == 'question' ? 'custom-card-button-wide' : modes == 'submition' ? 'custom-card-button' : ''" 
						outlined @click="onSetDefault()" :color="'#0057AD'">
						{{ modes == "question" ? "No, set as default" : modes == "submition" ? "Cancel" : "" }} 
					</v-btn>

	               <v-spacer></v-spacer>

	               <!-- ------------- CANCEL OR SUBMIT ------------- -->
	               <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
	               		@click="modes == 'question' ? onSetManual() : modes == 'submition' ? onSubmit() : ''">
	               		{{      modes == "question" ? "Yes" : modes == "submition" ? "Yes" : "" }}
	               </v-btn> 

	            </v-card-actions>

	        </v-card>
	    </v-dialog>

		<v-dialog v-model="dayTypeDialog.state" persistent scrollable max-width="800px" @keydown.esc="onCancelChanges()">
			<v-card>
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <v-btn  class="ml-auto" icon @click="handleCancelAssign()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text class="card-text">
					<div class="modal-text">{{ dayTypeDialog.message }}</div>
				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4 ml-auto">
	            	
	               	<v-btn 
					   	dark 
					   	outlined
					    :color="'#0057AD'"
					   	class="custom-card-button" 
						@click="handleCancelAssign()"
					>
						Cancel 
					</v-btn>
					<div class="mx-2"></div>
	               	<!-- ------------- CANCEL OR SUBMIT ------------- -->
	               	<v-btn 
						dark 
						color="#0057AD" 
						class="mx-2 elevation-0 font-weight-bold custom-card-button" 
						@click="handleAssignRestDay()"
					>
						Assign
	               </v-btn> 

	            </v-card-actions>

	        </v-card>
	    </v-dialog>

	</v-row>
</template>
<script>

import { auth }  from "@/mixins/auth";
import { modal } from "@/mixins/modal";

import VAccount from "@/components/Fields/Autocomplete/Accounts/Index";
import VSite from "@/components/Fields/Autocomplete/Site/Index";
import VSchedule from "@/components/Fields/Schedule/Index";
import VAssignment from "@/components/Fields/Autocomplete/Assignment/Index";
import { mapActions, mapGetters } from "vuex";
import _ from 'lodash';

export default {
	mixins:[modal,auth],
	components:{
		"v-site":VSite,
		"v-schedule":VSchedule,
		"v-account":VAccount,
		"v-assignment":VAssignment,
	},
	data(){ 
		return {
			
			// to check if the field were all valid
			valid: false,

			// to set the data event Add or Edit
			form:{
				rest_day_status:1
			},

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: true,

			// to check if action is submitting
			isSubmit: false,

			isChanged:false,

			// to check if mode is delete
			date: null,
	        items:[],
		    cache:{},
		    index:0,
		    schedule_index:0,

		    // for question dialog  
		    dialog_question:false,

		    // for question dialog  
		    radio_assignment_status:false,

		    message:null,
		    modes:null,
		    type:null,
			
			dayTypeDialog:{
				state:false,
				message:"Are you sure do you want to assign this day as rest day ?",
			},
			dayTypeSwitch:false,
			dayTypeStatus:1,
		}
	}, 
	
	beforeMount()
	{
		this.initialize();
	},

	methods:{
		...mapActions("calendar",[ 
			"getSitesByOrganization", 
			"getAccountsPerOrganization", 
			"getIndividualRecord",
			"setUpdateRegisterCalendar",
			"setDayType"
		]),

		handleCallDayTypeDailog(){
			this.dayTypeDialog.state = true;
			
			if(this.dayTypeSwitch){
				this.dayTypeDialog.message = "Are you sure do you want to assign this day as rest day ?";
			} else {
				this.dayTypeDialog.message = "Are you sure do you want to unassign this day as rest day ?";
			}
		},

		handleAssignRestDay(){
			let day_type = this.dayTypeSwitch ? 2 : 1; // check boolean value
			this.form = { ...this.form, day_type } // assign value

			this.setDayType(this.form)
			.then((res)=>{
				this.dayTypeDialog.state = false;
				this.$bus.$emit("weekly-calendar-submitted");
				this.$bus.$emit("monthly-calendar-submitted");
			})
		},

		handleCancelAssign(){
			this.dayTypeSwitch = this.form.day_type;
			this.dayTypeDialog.state = false;  // close dialog
		},

		/*
 		* This initialize value values needed
 		*
 		* @return void
 		*/ 
		initialize()
		{	
			// get the schedule list 
			this.$store.dispatch("getScheduleList");

			// check if data object had values contain
			if(this.data)
			{	
				const { date } = this.data;

				this.getIndividualRecord({ from:date, to:date, ...this.data })
				.then(res=>{
				 
		            let values = {};
					let schedule_cal = Object.assign({},...Object.values(res[0].schedule_calendar));
					let account_cal = Object.assign({},...Object.values(res[0].account_calendar));
					let site_cal = Object.assign({},...Object.values(res[0].site_calendar));
					let assignment_cal = Object.assign({},Object.values(res[0].assignment_calendar));
					let _assignment = assignment_cal[0];

					this.form = Object.assign({},{ 
						employee_id:res[0].id,
						schedule_id:schedule_cal.id, 
						account_id:account_cal.id,
						site_id:site_cal.id, 
						assignment_id:_assignment.map(data=>data ? data.id : 0).filter(data=>data!=0),
						day_type:_.get(res[0],"schedule.day_type"),
					});

					// day type initialization
					if(_.get(res[0],"schedule.day_type") === 1){
						this.dayTypeSwitch = false;
					} else if(_.get(res[0],"schedule.day_type") === 2){
						this.dayTypeSwitch = true;
					}

					// set date for submittion 
		            this.$set(this.form,"date",this.data.date);

				    // reset the selected schedule 
					this.setHighlightSchedule(this.form.schedule_id);

		            // set data for caching data from form 
		      		this.onSetCachingData(false);

		      		// to disable skeleton loader 
		            this.isLoading = false; 
		        });
			}
		},

		setScheduleId(value)
		{
			this.form.schedule_id = value;
		},
		
		/*
 		* This will change the value based on the type of field get selected
 		*
 		* @return void 
 		*/
		onChangesValue(value,type)
		{
	    	this.modes = "question";
			
			// message for modal when the value had change on the the field selected 
			this.message = `Would you like to set ${type.toLowerCase()} `

			// selection per type of field 
			switch(type)
			{
				case "SCHEDULE":
					// to open the modal dialog 
					this.dialog_question = true;
				
					this.form.schedule_id = value;
					this.form.schedule_status = 0;

					// set the selected schedule 
					this.setHighlightSchedule(value);
				break;

				case "ACCOUNT":
					// to open the modal dialog 
					this.dialog_question = true;

					this.form.account_id = value;
					this.form.account_status = 0;
				break;

				case "SITE":
					// to open the modal dialog 
					this.dialog_question = true;

					this.form.site_id = value;
					this.form.site_status = 0;
				break;

				case "ASSIGNMENT":
					// to show the radio status option
					this.radio_assignment_status = true;

					this.form.assignment_id = value;
					this.form.assignment_status = 0;
				break;
			}

			this.type = type;
		}, 

		/*
 		* This sets highlight as the schedule selected  
 		*
 		* @return void 
 		*/
		setHighlightSchedule(value)
		{
			this.schedule_index = this.schedules.findIndex(data=> data.value == value);
		},

		/*
 		* This sets the form values from old values
 		*
 		* @return void 
 		*/
		onCancelChanges()
		{
			// to close the modal question
			this.dialog_question = false;

			this.dayTypeDialog.state = true;

			const { site_id , account_id , schedule_id , assignment_id } = this.cache || 0;
			Object.assign(this.form,{ site_id , account_id, schedule_id, assignment_id});

			// reset the selected schedule 
			this.setHighlightSchedule(this.cache.schedule_id);
		},

		/*
 		* This sets the values from new values
 		*
 		* @return void 
 		*/
		onSetCachingData(isChanged = true)
		{	
			const { site_id , account_id , schedule_id , assignment_id } = this.form || 0;

			Object.assign(this.cache,{ site_id , account_id, schedule_id, assignment_id});

			this.isChanged = isChanged;
		},

		/*
 		* This sets the values 
 		* This set as default 
 		*
 		* @return void 
 		*/
		onSetDefault()
		{
			this.onSetCachingData();
			
			this.setTypeStatus(0);
			
			// to close the modal question
			this.dialog_question = false;
		},

		/*
 		* This sets the values from new values
 		* This set as manual 
 		*
 		* @return void 
 		*/
		onSetManual()
		{
			this.onSetCachingData();
			
			this.setTypeStatus(1);

			// to close the modal question
			this.dialog_question = false;
		},

		setTypeStatus(status)
		{
			let type = this.type;

			let _status = status;

			// selection per type of field 
			switch(type)
			{
				case "SCHEDULE": this.form.schedule_status = _status; break;
				case "ACCOUNT": this.form.account_status = _status; break;
				case "SITE": this.form.site_status = _status; break;
				case "ASSIGNMENT": this.form.assignment_status = _status; break;
			}
		},
	
 		/*
 		* This submit value for update or register on calendar 
 		*
 		* @return void
 		*/ 
		submit(){
			if(this.isChanged){
				this.dialog_question = true;
	    		this.modes = "submition";
	    		this.message = "Are you sure do you want to apply changes ?";
			}
      	},

      	/*
 		* This submit value for update or register on calendar 
 		*
 		* @return void
 		*/ 
		onSubmit(){	

	    	this.isSubmit = true;
	    	this.dialog_question = false;

	    	this.$set(this.form,"date",this.data.date);

			this.setUpdateRegisterCalendar(this.form)
			.then(response=>{ 
				this.$bus.$emit("weekly-calendar-submitted"); 
				this.$bus.$emit("monthly-calendar-submitted"); 
				this.isSubmit = false;
				this.close();
			});
      	},

      	/*
 		* This set event actions for cancel action from modal
 		*
 		* @return void
 		*/ 
		cancel(){ this.close(); },

      	/*
 		* This parse the value name from an array object  
 		*
 		* @return string 
 		*/  
		parseAssignmentValues(value){
			if(value){
				let object = this.assignments.filter(data=> data.id == value[0]).map(data=>data.name).filter(data=>data!=null).toString();
				return value.filter(data=>data!=0).length > 1 ? `${object} +${value.filter(data=>data!=0).length - 1}`: object;
			}
		}
	},

	computed:{
		...mapGetters("assignment",{"assignments":"getDropdown"}),

		/*
		* Array values for schedule select dropdown
		*
		* @return array getOrganizationList
		*/
		schedules()
		{
			let items = [
				{ text:"No Schedule", value:0 }
			];
			
			this.$store.getters.getScheduleList.map((data)=>{
				items.push({ 
					text:`${data.name}, ${ moment(data.from,"hh:mm:ss").format("hh:mm A") } - ${moment(data.to,"hh:mm:ss").format("hh:mm A")}`, value:data.id});
			});

			return items;
		},

		accounts(){
			return this.$store.getters.getAccountDropDownList;
		},

		sites(){
			return this.$store.getters.getSiteDropDownList;
		},
	},

	watch:{

		data(cache, values)
		{
			this.form = values;
		},

		dayTypeSwitch(bool,old){
			
		},
	},

	filters:{
		formatDate: (date) => {
			return date ? moment(date).format("dddd MMMM DD, YYYY") : ""
		}
	}
}

</script>

<style type="text/css" scoped>
	.card-text {
		height: 150px;
	}
	.modal-text {
		font-size:25px;  
		margin:40px; 
	}
    .custom-card-button{
      height:40px;
      width:150px;
    }

    .custom-card-button-wide{
      height:40px;
      width:230px;
    }

    .label {
      color:black;
      font-size: 16px;
    }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
    }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }
</style>