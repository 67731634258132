export const mutations = {
	
	/*
	* to submit the form as update into an API
	*/	
	SET_LOADING(state,boolean){
		state.loading = boolean;
	},
	
	SET_QUERY_BUILDER_LIST(state,payload){
       	state.table.list = payload.data;
	},
	
	/*
	* to add response for every template created
	*/
	ADD_RECORD(state,payload){
       	state.table.list.push(payload.data);
	},
	
	/*
	* to update the template using the response 
	*/
	UPDATE_RECORD(state,payload){
		let index = state.table.list.indexOf(payload.data.id)
		if(index > -1) Object.assign(state.table.list[index],payload.data);
	},
	
	/*
	* to remove a record from the table using the id 
	*/
	DELETE_RECORD:(state,payload) => {
		let index = state.table.list.findIndex(list => list.id == payload.id)
		state.table.list.splice(index, 1)
		state.table.total -= 1;  
	},
}