<template>
   <!-- -------------- MODAL: MAP ------------------- -->
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px" @keydown.esc="cancel()">
            <v-card :loading="file.isUploading||file.isCanceling||file.isImporting">

               <!-- -------------------- CARD HEADER ------------------- -->
               <v-card-title>
                  <span class="font-weight-bold">{{ file.isUploaded ? "2. Start Import" : title }}</span>
                  <v-spacer></v-spacer>
                  <span  class="caption mr-3">Press</span>
                  <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
                  <v-btn icon @click="cancel()"> <v-icon>close</v-icon> </v-btn>
               </v-card-title>

               <v-divider></v-divider>

               <!-- -------------------- CARD CONTENTS ------------------- -->
               <v-card-text style="min-height:300px;">

                  <!-- ------------ FILE TO BE UPLOAD  ------------ -->
                  <div class="dropzone" id="dropzone" ref="dropzone" @dragenter="onDragEnter" @dragleave="onDragLeave" @dragover.prevent @drop="onDrop">
                  </div>

                     <!-- ----------- FILE INPUT FOR UPLOAD -----------  -->
                     <input type="file" ref="file" accept=".xlsx" @change="selectFile" class="input-file">

                     <!-- ------- WHEN FILE IS STAGED/UPLOADED ------- -->
                     <template v-if="file.isSet">
                        <div class="dropped_icon"> <v-icon> mdi-file-excel </v-icon> </div> 
                        <div class="label">{{ uploaded_file_metadata }}</div>
                     </template>

                     <!-- ------- WHEN FILE IS UPLOADED TO SERVER ------- -->
                     <div class="upload_message" style="font-size:25px;" v-else-if="file.isUploaded||file.isImported||upload.hasError">
                        {{ upload.message }}
                     </div>

                     <!-- ---- WHEN FILE IS NOT YET STAGED/UPLOAD ---- -->
                     <template v-else class="call-to-action">

                        <!-- ----- WHEN FILE IS DRAGGED INTO MODAL ----- -->
                        <template v-if="isDragging" class="call-to-action">
                           <div class="dropzone_icon_ondragged"> <v-icon>save_alt</v-icon> </div>
                           <p class="label">Drop it here</p>
                        </template>

                        <!-- ----- WHEN NO FILE DRAGGED INTO MODAL ----- -->
                        <template v-else class="call-to-action">
                           <div class="dropzone_icon"> <v-icon>save_alt</v-icon> </div>
                           <p class="label"> <a target="_blank"  @click="$refs.file.click()">Upload a Excel file</a>, or drag here </p>
                        </template>

                     </template>
               </v-card-text>

               <v-divider></v-divider>
               
               <!-- -------------------- CARD ACTIONS ------------------- -->
              
                  <!-- ------- WHEN FILE IS UPLOADED TO SERVER ------- -->
                  <template v-if="file.isSet || file.isUploaded || file.isImported">

                     <v-card-actions class="ma-4">

                        <!-- ----------- CANCELATION OF PROCESS ------------ -->
                        <v-btn color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined v-if="action_cancel" 
                        :disabled="file.isUploading||file.isCanceling||file.isImporting" @click="cancel()">
                        {{ label_action_cancel }} 
                        </v-btn>

                        <!-- ---------------- LOADING PROGESS -------------- -->
                        <v-spacer></v-spacer>
                           <span v-if="file.isSet">{{`${upload.progress}% uploaded...`}}</span>
                        <v-spacer></v-spacer>

                        <!-- ---------------- PROCEED PROGESS -------------- -->
                        <v-btn color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
                        :disabled="file.isUploading || file.isCanceling || file.isImporting" @click="proceed()">
                        <span style="color:#FFFF;">{{ label_action_proceed }}</span>
                        </v-btn>

                     </v-card-actions>

                  </template>

                  <div v-else>
                     <v-card-actions class="ma-4">
                        <a href="#" class="mx-auto btn_download_template" @click="template()">Download an Excel template</a>
                     </v-card-actions>
                  </div>

         </v-card>
      <v-divider></v-divider>
      </v-dialog>
   </v-row>
</template>

<script>

import { excel } from "@/mixins/docs/excel";
import { rules }  from "@/validation/rules/index";
import { states } from "@/mixins/states";
import { modal }  from "@/mixins/modal";

export default {
   mixins:[modal,states,excel],
   data()
   { 
      return {
         upload:{
            file:[],
            progress:0,
            message:"",
            hasError:false,
         },

         file:{
            name:"",
            isSet:false,

            isUploading:false,
            isUploaded:false,
           
            isImported:false,
            isCanceling:false,
         },
         hasError:false,
         isDragging:false,
         dragCount:0,

         isImporting:false,
         headers :[
            { "header":"Organization Id", "width":25 },
            { "header":"Employee Id", "width":25 },
            { "header":"First Name", "width":25 },
            { "header":"Last Name", "width":25 },
            { "header":"Site", "width":25 },
         ]
      }
   }, 
   
   methods:{

      /*
      * On dragging Enter event  
      *
      * return void
      */
      onDragEnter(e){

         e.preventDefault();

         this.dragCount ++;
         this.isDragging = true;

         return false;
      },

      /*
      * On dragging Leave event  
      *
      * return void
      */
      onDragLeave(e){

         e.preventDefault();

         this.dragCount --;
         this.isDragging = false;

         return false;
      },

      /*
      * On drop event
      *
      * return void
      */
      onDrop(e){
         e.stopPropagation();
         e.preventDefault();

         const files =  e.dataTransfer.files[0]
       
         if(files.name.split(".").pop() == "xlsx")
         {    
            this.upload.file = files;
            this.file = { isSet:true };
            document.getElementsByClassName("dropzone")[0].remove();
         }
         else
         {
            this.isDragging = false; 
         }
      },
      /*
      * To export a sample of template for employee records
      *
      * return void
      */
      template()
      {
         this.excel_template(this.headers,"Employee import");
      },

      /*
      * Selecting to be upload into the server 
      *
      * return void
      */
      selectFile(event)
      {
         const file = event.target.files[0];

         // check if the uploaded file was containt of extension of "xlsx"
         if(file.name.split(".").pop() == "xlsx")
         {    
             this.upload.file = file;
             this.file = { isSet:true };
             document.getElementsByClassName("dropzone")[0].remove();
         }
      },

      /*
      * Uploading file into the server 
      * File store temporarily in local storage 
      * While importing process is ongoing
      *
      * return void
      */
      uploadFile()
      {
         let self  = this;
         this.file.isUploading= true;

         const data = new FormData();
         data.append("file",this.upload.file);

         var config = {
            onUploadProgress (progressEvent) {
               self.upload.progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            }
         }

         // upload file into the server 
         axios.post("/employees/upload", data, config)
         .then((response)=>{ 
            if(response.data){  
               this.upload.message = response.data.report;
               this.file = { isUploaded:true, name:response.data.file_name, isImporting:false, isCanceling:false }; 
            }
         })
         .catch((errors)=>{});
      },

      /*
      * Cancelling upload, by deleting the file stored temporarily
      *
      * return void
      */
      cancelUpload()
      {  
         this.file.isCanceling = true;
         axios.post("/employees/discard",{ file_name:this.file.name }).then((response)=>{ this.close(); }).catch((errors)=>{});
      },
 
      /*
      * Importing file into the server 
      *
      * return void
      */
      import()
      {
         this.file.isImporting = true;
         axios.post("/employees/import", {file_name:this.file.name})
         .then((response)=>{ if(response.data){ this.upload = { message:response.data.report }; this.file = { isSet:false, isImported:true } } })
         .catch((errors)=>{});
      },

      /*
      * Importing file into the server 
      *
      * return void
      */
      cancel()
      {
         return this.file.isSet ? this.close() : this.file.isUploaded ? this.cancelUpload() : this.close();
      },

      /*
      * To proceed file for uploading, import and done
      * 
      * return void
      */
      proceed()
      {
         return this.file.isSet ? this.uploadFile() : this.file.isUploaded ? this.import() : this.file.isImported ? this.done(): "";
      },

      /*
      * When done process
      * 
      * return void
      */
      done()
      {
         this.$store.dispatch("getEmployeeList");
         this.close();
      }
   },
   
   computed:{
      
      /*
      * Display size of file 
      * 
      * return void
      */
      uploaded_file_metadata()
      {
         return  this.upload.file ? `${ this.upload.file.name} (${Math.round((this.upload.file.size/1024))}) kb` : ''
      },

      /*
      * This set the buttons actions text   
      * 
      * return void
      */
      label_action_proceed()
      {
         return this.file.isUploaded ? "Start Import" : this.file.isImported ? "Done" : "Next";
      },

      /*
      * This set the buttons actions text   
      * 
      * return void
      */
      label_action_cancel()
      {
         return this.file.isUploaded ? "Dicard" : "Cancel";
      },

      /*
      * This set boolean if file is on stage,upload or imported 
      * 
      * return void
      */
      action_proceed()
      {
         return this.file.isSet ? true : this.file.isUploaded ? true : this.file.isImported ? true : false;
      },  

      /*
      * This set boolean if file is importing or imported 
      * 
      * return void
      */
      action_cancel()
      {
         return this.file.isImporting ? false : this.file.isImported ? false : true;
      }, 
   },

   watch:{
      file(value){
         if(value){
            this.isUploaded = true;
         }
      }
   }
}

</script>

<style type="text/css" scoped>
   
   .dropped_icon .v-icon {
      position: absolute;
      font-size: 60px;
      right: 300px;
      left: 300px;
      top: 150px;
   }

   .dropzone_icon_ondragged .v-icon {
      position: absolute;
      transform: rotate(-360deg);
      font-size: 60px;
      right: 300px;
      left: 300px;
      top: 150px;
      color:#1976d2;
   }

</style>

<style type="text/css" scoped>

   .upload_message{
      position: absolute;
      text-align: center;
      color: black;
      font-size: 25px;
      top: 200px;
      left: 50px;
      right: 50px;
   }
   .custom-card-button {
      height:40px;
      width:150px;
   }
   
   .dropped_files_label{
      position: absolute;
      font-size: 60px;
      right: 250px;
      left: 250px;
      top: 220px;
   }
   .dropzone{
    padding: 100px;
    width: 100%;
    min-height: 250px;
   }
   .dropzone_icon .v-icon{
      transform: rotate(-180deg);
      position: absolute;
      color:#5d5d5d8a;
      font-size: 60px;
      right: 300px;
      left: 300px;
      top: 150px;
   }

   .btn_download_template {
      text-decoration: none; 
      display:inline-block;
      font-size:12px;
   }

   .input-file {
      opacity: 0;
   }
   
   .label{
      position: absolute;
      font-size: 20px;
      right: 200px;
      left: 200px;
      top: 220px;
   }
   
</style>