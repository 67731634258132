<template>
	<v-card class="my-3" >
		<v-card-text>
			<!-- output name label -->
			<div class="my-2 d-flex justify-start">
				<h4>Output Name</h4>
			</div>
			<!--../output name label -->

			<!-- output name -->
			<div>
				<v-text-field style="height:25px; transform:scale(0.9); "  v-model="output_name" autocomplete="off" placeholder="Output name" outlined dense/>
			</div>
			<!-- ../output name  -->

			<div class="mt-5 d-flex justify-start">
				<h4>Range</h4>
			</div>

			<!-- from -->
			<div class="my-3 d-flex justify-space-around">
				<div class="d-flex flex-start">
					<span class="mx-3 flex-start">From</span>
					<DatePicker :placeholder="'YYYY-MM-DD'" :disable="thisDay" :value.sync="from"/>
				</div>
				<v-checkbox v-model="thisDay" class="my-auto"  label="This day"/>
			</div>
			<!-- ../from -->
			
			<!-- to -->
			<div class="mb-5 d-flex justify-space-around ">
				<span class="mx-3">To</span>
				<DatePicker :placeholder="'YYYY-MM-DD'" :disable="thisDay" :value.sync="to"/>
			</div>
			<!-- ../to -->	

		</v-card-text>
	</v-card>
</template> 

<script>

	import { mapGetters, mapActions, mapMutations } from "vuex";
	import DatePicker from "@/modules/QueryBuilder/Builder/components/BuilderDatePicker.vue";
	
	export default {
		components:{ DatePicker },
		methods:{

			...mapActions("builder", ["setDefaultColumn"]),

			/*
			*  this make "FROM" and "TO" dates the same
			*  disable "TO" datepicker when checkbox clicked
			*/
			setAsThisDayOnly(event){	
				if(event){
					if(!this.from || this.from){
						this.to = this.from = moment().format("YYYY-MM-DD");
					}
					this.isDisable = true;
				}
				else { this.isDisable = false;  }
			}
		},

		computed:{
			...mapGetters("builder",{ "form":"getForm" }),

			output_name:{
				get(){ return this.form.column_attributes.output_name ; },
				set(value){ this.setDefaultColumn({ output_name:value }); }
			},
			
			from:{
				get(){ return this.form.column_attributes.from; },
				set(value){ 
					if(value > this.to) this.setDefaultColumn({ from:this.to, to:value });
					this.setDefaultColumn({ from:value }); 
				}
			},

			to:{
				get(){ return this.form.column_attributes.to; },
				set(value){ 
					if(value < this.from) this.setDefaultColumn({ from:value, to:this.from });
					this.setDefaultColumn({ to:value }); 
				}
			},

			thisDay:{
				get(){ 
					return this.form.column_attributes.thisDay; 
				},
				set(value){ 
					this.setAsThisDayOnly(value);
					this.setDefaultColumn({ thisDay:value }); 
				}
			},
		},
	}
</script>