<template>
  	<v-text-field class="col-sm" @input="onSearch($event)" :disabled="isSearch" placeholder="Search" autocomplete="off" append-icon="search" 
  	clearable outlined dense/>
</template>

<script>
 	import { mapActions, mapGetters } from "vuex";
	export default {
		data(){
			return {
				query:"",
				outputValue: '',
          		timeout: null,
          		isSearch:false,
			}
		},

		methods:{
			...mapActions("template", ["getTemplates"]),

			onSearch(event)
			{
				clearTimeout(this.timeout); // clear timeout variable
				var self = this;
				this.timeout = setTimeout(function () {
					self.isSearch = true;
					self.getTemplates({ keyword:event }).then(()=>{ self.isSearch = false });
				},1000);
			}
		}
	}
</script>

