import Vue from "vue";
import App from "@/App.vue";

import "./registerServiceWorker";
import "@/plugin/axios";
import "@/plugin/google/map";
import "@/plugin/vue-progress-bar";
import "@/plugin/idle-vue";

window.$ = require('jquery');

import moment from 'moment';
window.moment =  moment;

// FILE SAVER 
window.FileSaver = require('file-saver');

// EXCELJS 
window.Excel = require('exceljs');

// VUE ROUTER
import router from "@/router";

// VUE STORE
import store from "@/store";

// VUE VUETIFY
import vuetify from "@/plugin/vuetify/index";

// VUE PORTAL 
import PortalVue from 'portal-vue';
Vue.use(PortalVue);

// ON PRODUCTION ENVIRONMENT 
if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'qa'){
	  Vue.config.productionTip = true;
    Vue.config.devtools = false;
    Vue.config.debug = false;
    Vue.config.silent = true; 
}

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <div>.';

Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  }
} 

// EVENT BUS
Vue.prototype.$bus = new Vue();

// MODAL EVENT BUS 
Vue.prototype.$modal = new Vue();

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App); }
}).$mount("#app")

