<template>
	<v-autocomplete v-model="accounts" :disabled="disabled" @change="onChangeValue($event)" :items="values" outlined  dense item-text="name" item-value="id" :placeholder="placeholder" append-icon="search">
		<template v-slot:selection="data">
			<v-chip v-bind="data.attrs" :input-value="data.selected"  @click="data.select" close @click:close="remove(data.item.id)">
				<v-avatar left>
					<v-img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo"></v-img>
				</v-avatar>
				{{ data.item.name }}
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";

export default {
	props:["placeholder","account","disabled"],
	mixins:[modal,states,auth],
    data () {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			isEmpty: false,
			accounts:"",
		}
    },

	created()
	{	
		this.initilize();
	},

	methods:{

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		initilize()
		{	
			if(this.account)
			{	
				// select organization with organization id 
				this.accounts = this.account;
			}
 		},

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item) 
		{
			if(typeof this.accounts === "number")
			{
				this.accounts = null;
			}

			else if (typeof this.accounts === "object")
			{	
				var index = this.accounts.indexOf(item);
				if(index >= 0) this.accounts.splice(index, 1)
			}

			this.$emit("onChanged",this.accounts);
		},

		/*
 		* Change value selected
 		*
 		* @return void
 		*/ 
		onChangeValue(event) 
		{	
			this.$emit("onChanged",event);
		},
    },

	computed:{

 		/*
 		* Array values 
 		*
 		* @return array getsiteList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getAccountDropDownList;
 		},
	},

    watch:{
    	
    	/*
 		* Loading/Progress when update values 
 		*
 		* @return void
 		*/ 
		isUpdating(val)
		{
			if (val) 
			{
				setTimeout(() => (this.isUpdating = false), 3000)
			}
		},

        /*
 		* Look for value account id as sync
 		*
 		* @return array getsiteList
 		*/ 
        accounts(val)
        {
        	this.$emit('update:account', val)
        },

        /*
 		* Watch for value from 'account' props 
 		*
 		* @return void
 		*/ 
        account(val)
        {	
        	this.accounts = val;
        }, 
    },
}
</script>