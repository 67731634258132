<template>
	<v-card>
		<v-card-text>
			<v-data-table 
			:items="items" 
			:options.sync="pagination"  
			:server-items-length="totalItems" 
			:items-per-page="100"
			:footer-props="{ 'items-per-page-options':[50,100,500,1000] }">
				<template v-slot:header="{ props }">
					<thead class="v-data-table-header">
						<tr>
							<th 
								class="text-center"
								v-for="header in headers"
								:colspan="headerVisible(header.subheader) ? headerVisible(header.subheader) : 1"
								:key="header"
							>	
								<template v-if="headerVisible(header.subheader)">
									<div>{{ header.text }}</div>
								</template>
							</th> 
						</tr>
						<tr>
							<th 
								class="text-center child-header" 
								v-show="header.visible"
								v-for="header in subheader" 
								:key="header"
							>
								<div>{{ header.text }}</div>
							</th>
						</tr>
					</thead> 
				</template>
	    		<template v-slot:body="{ itemsLength }" >
	    			<tbody>
		    			<tr 
							v-for="value in values" 
							:key="value"
						>
		    				<!-- Dates -->
		    				<template v-for="header in headers">	
								<template v-if="headerVisible(header.subheader)">
									<!-- Subheader -->
									<td v-for="subheader in setSubhedears(header.subheader)" :key="subheader">
										<template v-if="visibleColumn(header.subheader).indexOf(subheader)">
											{{ 
												value[header.value]? 
												getNameWithColumnMultiAttr(subheader, value[header.value][subheader], header.value) : 
												null 
											}}
										</template>
									</td>
									<!-- Subheader -->
								</template>
							</template>
		    			</tr> 
	    			</tbody> 
			    </template>
				</tbody>
			</v-data-table>
		</v-card-text>
	</v-card>
</template>
<script>
	
	import { mapActions, mapGetters } from "vuex";
	import { ArrayDates } from "@/helper/dates";
	import _ from "lodash";

	export default {
		props:{
			loading:{
				type:Boolean
			}
		},

		data(){
			return{
				arraydates: new ArrayDates(),
				multi_attr:['sites','accounts','employee-roles','assignments','schedules'],
				multiple:null,
				pagination:{
					page:1,
					itemsPerPage:100,
					loading:true
				},
				items:[{}]
			}
		},

		created(){
			// this.getCalendarHolidays(_.get(this.form,"column_attributes.from"));
		},

		methods:{

			...mapActions("builder", [
				"getGenerateReportPaginate",
				"getGenerateReport",
				"setTableHeader",
				"addColumn",
				"getCalendarHolidays"
			]),

			setSubHedear(header){
				let res = [];
				header.filter((val)=>val.subheader).forEach((val)=>{ res = res.concat(val.subheader); });
				return res;
			},

			setSubhedears(header){
				let res = [];
				header.filter((val)=>val.value).forEach((val)=>{ res = res.concat(val.type); });
				return res;
			},

			visibleColumn(header){
				let res = [];
				header.filter((val)=>val.visible == false).forEach((val)=>{ res = res.concat(val.type); });
				return res;
			},

			headerVisible(columns){
				return columns.filter(data=>data.visible==true).length;
			},

			getNameWithColumnMultiAttr(type, value, date=null){
				// This is for column with multiple values 
				// For Account, Assignment, Employee Role, Schedules and Site 
				if(this.multi_attr[this.multi_attr.indexOf(type)]){

					let values = this.$store.getters[`${type}/getRecords`];
					let filtered_val = values.filter(data=>data.id == value);

					if(type == 'assignments'){
						let names = [];
						value.forEach((value1)=>{
							let filtered_val = values.filter(data=>data.id ==value1 );
							let { name } = filtered_val[0];
							names.push(name);
						});
						return names.toString();
					}

					if(values.length > 0){
						if(filtered_val.length > 0){
							let { name } = filtered_val[0];
							return name;
						}
					} 
				} else if(type === "day_type"){
					if(this.holidays[date]){
						return `${value}, Holiday`;
					} else {
						return value;
					}
				} else {
					return value;
				}
			},

			onGeneratePreview(){
				let form = this.form;

				if(form.hasOwnProperty("column_attributes")){
					if(form.column_attributes.hasOwnProperty("columns")){
						let columns = form.column_attributes.columns.filter(data=>data.type !=null);
						return  { 
							columns:columns, 
							from:this.form.column_attributes.from, 
							to:this.form.column_attributes.to 
						};
					}
				}
			},
		},

		computed:{
			...mapGetters("builder",{ 
				"values":"getValues",
				"form_cache":"getFormCache", 
				"form":"getForm", 
				"dates":"getDateRange", 
				"headers":"getHeaders",
				"totalItems":"totalItems",
				"pagination":"pagination",
				"page":"page",
				"holidays":"getHolidays"
			}),

			subheader(){
				return this.setSubHedear(this.headers);
			},
		},

		watch:{
			"pagination.page"(oldV,oldN){
				this.getGenerateReportPaginate(Object.assign({...this.onGeneratePreview()},{ ...this.pagination  }))
			},

			"pagination.itemsPerPage"(oldV,oldN){
				this.getGenerateReportPaginate(Object.assign({
					...this.onGeneratePreview()
					},{ 
					...this.pagination 
					},{ itemsPerPage:oldV 
				}))
			}
		}
	} 
</script>	