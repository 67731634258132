<template>
	<v-card class="mx-2">
		<v-card-header>
			<v-container fluid>
				<v-row class="my-auto">
					<!-- title -->
					<div class="mx-5 my-auto">
						<b class="d-flex align-start justify-start">Content</b>
					</div>

					<!-- toggle -->
					<div class="d-flex align-start justify-start my-auto">
						<v-btn-toggle dense background-color="primary" dark no-gutters>
							<v-btn text @click="views = true"> Create </v-btn>
							<v-btn text @click="views = false"> Recent </v-btn>
						</v-btn-toggle>
					</div>
					<!--../toggle -->

					<v-spacer></v-spacer>

					<template v-if="views">
						<v-spacer></v-spacer>
							<div class="d-flex align-start justify-start my-auto"><b> Preview </b></div>
						<v-spacer></v-spacer>

						<!-- full screen -->
						<div>
							<v-btn icon @click="expand = !expand">
								<v-icon style="color:#000;">mdi-fullscreen</v-icon>
							</v-btn>
						</div>
						<!--../full screen -->

					</template>
				</v-row>
			</v-container>
			<v-divider></v-divider>
		</v-card-header>

		<v-card-text>
			<recent-view v-if="!views"></recent-view>
			<v-layout class="row" v-if="views">
				<div class="col-8" v-show="!expand"> 
					<form-view :show.sync="views"></form-view>
				</div>
				<v-spacer v-if="expand"></v-spacer>	
				<div :class="expand ? 'col-12':'col-4'"> 
					<mobile-view></mobile-view>
				</div>	
			</v-layout>
		</v-card-text>

	</v-card>
</template>

<script>

	import MobileView from "@/components/Fields/Compose/MobileView/Index.vue";
	import FormView from "@/components/Fields/Compose/FormView/Index.vue";
	import RecentView from "@/components/Fields/Compose/Recent/Index.vue";

	export default {
		components:{
			"mobile-view":MobileView,
			"form-view":FormView,
			"recent-view":RecentView,
		},

		data(){
			return {
				views:false,
				expand:false,
			}
		},

		mounted(){
			if(this.$store.getters.authUserRole != "Admin") this.$router.push("/Dashboard");
		},

		created(){	
			this.$bus.$on("edit",(data)=>this.views = data);
		},

		methods:{
			reload(){
				this.$bus.$emit("reload",true);
			}
		}
	}
</script>

<style>
	.custom-card-button{
      height:40px;
      width:150px;
    }

    .draft-button{
      height:40px;
      width:100px;
    }

    .text-white{
      color: white;
    }
</style>