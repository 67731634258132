const state = {
	values:{},
	type:null,
	dialog:false
}

const getters = {
	getValues(state){
		return state.values;
	},

	getType(state){
		return state.type;
	},

	getDialog(state){
		return state.dialog;
	}
}

const mutations = {
	SET_MODAL(state,payload){
		state.values = payload.values;
		state.type = payload.type;
		state.dialog = true;
	},

	SET_DIALOG_STATE(state,boolean){
		state.dialog = boolean;
	}
}

const actions = {
	setModal(context,data={}){
		context.commit("SET_MODAL",data);
	},

	setClose(context){
		context.commit("SET_DIALOG_STATE",false);
	},

	setOpen(context){
		context.commit("SET_DIALOG_STATE",true);
	}
}

export default {
	namespaced:true,
	name:"query_builder_modal",
	state,
	getters,
	mutations,
	actions
}


