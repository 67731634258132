import Vue from 'vue'

// VUE PROGRESS BAR  http://hilongjw.github.io/
import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'blue',
  height: '4px'
});
