
export class ArrayDates {

	/*
	* This sets dates in range
	* this arrange the dates 
	*
	* @param Object dates  
	* @return Object using Promise 
	*/
    setDates(dates){
		return new Promise((resolve,reject)=>{

			// if the date was revesely inputed 
			if(dates.from > dates.to) resolve(this.reversedDates(dates));

			// if ever the date range only one get selected date
			else if(dates.from && !dates.to) resolve(this.oneDateOnly(dates));

			// just return dates in normal 
			resolve(Object.assign({},dates));
		})
	}

	/*
	* This append MM/DD dates in format as header into the table
	*/ 
	getDateRangeInArray(from, to) {
		from = moment(from); to = moment(to); let array = [];
		return new Promise((resolve,reject)=>{
			while (from <= to)  // iterate, to get the dates in range 
			{ 
				array.push(moment(from).format("YYYY-MM-DD")); // push the dates in to an array
				from = moment(from).add(1, 'days'); // addition 1 day 
			}
			resolve(array);
		})
	}

	/*
	* This returns reversed dates base on the given object dates 
	*/ 
    reversedDates(dates){
    	return Object.assign({},{ from:dates.to, to:dates.from });
    }

    /*
	* This returns date ranges using "from" date  
	*/ 
    oneDateOnly(dates){
    	return Object.assign({},{ from:dates.from, to:dates.from });
    }
}