const state = { 
	
	daily_activity_report:{
		list:[],
		pagination:{ page:0, per_page:0 },
		total:0,
	},
}

const getters = {
	
	/*
	* This sets a value of state daily activity reportlist
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	getDailyActivityReportList:state => {
		return state.daily_activity_report.list;
	},

	// pagination of table 
	getPagePerItem:state=>{
		return state.daily_activity_report.pagination.per_page;
	},

	totalItems:state=>{
		return state.daily_activity_report.total;
	},

	pagination:state=>{
		return state.daily_activity_report.pagination.page;
	}
}

const mutations = {

	/*
	* This sets a value of state daily activity reportlist
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_DAILY_ACTIVITY_REPORT_LIST:(state,payload) => {
		state.daily_activity_report.list = payload.data;
	    state.daily_activity_report.pagination.current_page = payload.current_page;
	    state.daily_activity_report.pagination.per_page = payload.per_page;
	    state.daily_activity_report.total = payload.total;
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getDailyActivityReport:(context,data = {}) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/daily-activity-record",{ 
				params: {
					site: data.site_id,
					account: data.account_id,
					date:data.date,
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : context.state.daily_activity_report.total,
				}
			})
			.then((response) => {  context.commit('SET_DAILY_ACTIVITY_REPORT_LIST',response.data); resolve(); })
			.catch((errors) => console.log(errors) );
		});
	},
}

export const daily_activity_report = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}