import { state } from "@/modules/QueryBuilder/Template/store/template.state";
import { getters } from "@/modules/QueryBuilder/Template/store/template.getters";
import { mutations } from "@/modules/QueryBuilder/Template/store/template.mutations";
import { actions } from "@/modules/QueryBuilder/Template/store/template.actions";

export default {
	namespaced:true,
	name:"template",
	state,
	getters,
	mutations,
	actions
}
