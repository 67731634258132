<template>
	<v-select 
		v-model="view_by"
		label="View By"
		item-text="name"
		item-value="value"
		style="width:150px"
		:items="filter"
		:placeholder="'view by'"
		@change="setViewByFilter"
		dense
	/>
</template>

<script>
	import { calendar_filter } from "@/mixins/filter/calendar";
	export default {
		mixins:[calendar_filter],
		props:{
			type:Object,
		},
	    data() {
	    	return {
		    	view_by:"SHIFT",
		    	filter:[
					{ name:"Shift", value:"SHIFT"},
					{ name:"Account", value:"ACCOUNT"},
					{ name:"Site", value:"SITE"},
					{ name:"Assignment", value:"ASSIGNMENT"},
				],
			}
	    },

	    beforeMount(){
	    	this.getViewByFilter();
	    },

		methods:{

	 		/*
	 		* To set view filter on table 
	 		*
	 		* @return void 
	 		*/ 
			setViewByFilter(value){	
				localStorage.removeItem("view_by");

				if(!localStorage.hasOwnProperty("view_by")){
					localStorage.setItem("view_by",value);
				}

				this.view_by = value;
				this.setFilters({ view_by:value });
				this.$emit("update:type",value);
			},

			/*
	 		* To get view filter on table 
	 		*
	 		* @return void 
	 		*/ 
			getViewByFilter(){	
				if(localStorage.hasOwnProperty("view_by")){
					this.setViewByFilter(localStorage.getItem("view_by"));
				}
				this.setViewByFilter(this.view_by);
			},
	    },
	}
</script>