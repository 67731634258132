<template>
	<v-row justify="center">
		<v-dialog v-model="dialog"  persistent max-width="400" @keydown.esc="setClose()"> 
			<template>
				<v-card :loading="isSubmit">
					<v-card-header>
						<v-card-title>
						   <span class="font-weight-bold" style="text-transform:uppercase;">{{ type }}</span>
			               <v-spacer></v-spacer>
			               <span  class="caption mr-3">Press</span>
			               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
			               <v-btn icon @click="setClose()"> <v-icon>close</v-icon> </v-btn>
			           </v-card-title>
					</v-card-header>
					<v-card-text>
						<template v-if="type == 'edit'">
							<v-form v-model="valid" ref="form" id="v-form-submit">
								<label class="d-flex justify-content-left"> Title </label>
								<v-text-field autocomplete="off" v-model="values.title" outlined dense/>
							</v-form>
						</template>
           				<template v-if="type == 'delete'">
           					<span>Do you want to delete <strong>{{ values.title }}</strong></span>
           				</template>
					</v-card-text>
					<v-card-actions>
		                <v-btn dark  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
		                  @click="type == 'delete' ? submit('delete') : setClose()"
		                  :color="type == 'delete' ? 'red':'#0057AD'"
		                  :loading="type == 'delete' ? isSubmit : false ">
		                  {{ type == 'delete' ? 'Delete':'Discard'}} 
		                </v-btn>

		                <v-spacer></v-spacer>

		                <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button"
		                  @click="type == 'delete' ? setClose() : submit('edit')"
		                  :loading="type == 'delete'? false  : isSubmit">
		                  {{ type == 'delete' ? 'Cancel':'Submit' }}
		                </v-btn> 
					</v-card-actions>
				</v-card>
			</template>
		</v-dialog>
	</v-row>
</template>

<script>

 	import { mapActions, mapGetters } from "vuex";
	import Errors from "@/validation/errors";

	export default {
		props:{
			title:{
				type:String,
				required:true,
			},
			message:{
				type:String,
				required:true,
			},   
			response:Boolean,
		},

		data(){
			return {
         		valid: false,
        		error:new Errors(),
				isSubmit:false
			}
		},

		methods:{
			...mapActions("template", ["setUpdateTemplate","setDeleteTemplate"]),
			...mapActions("query_builder_modal", ["setModal","setSubmit","setOpen","setClose"]),
			
			submit(action)
			{	
				switch(action)
				{
					case "edit": 
						if(this.$refs.form.validate()){
							this.isSubmit = true;
							this.setUpdateTemplate(this.values)
							.then((response) => { this.isSubmit = false; this.setClose(); })
						}
					break;

					case "delete": 
						this.isSubmit = true;
						this.setDeleteTemplate(this.values)
						.then((response) => {this.isSubmit = false})
						.then((response) => {this.setClose()})
					break;
				} 
			},
		},

		computed:{
			...mapGetters('template', { 'values':'getQueryBuilderList' }),
			...mapGetters('query_builder_modal', { "values":"getValues", "dialog":"getDialog", 'type':'getType' }),

			isEdit(){
				return this.type == "edit";
			},

			isDelete(){
				return this.type == "delete";
			}
		}
	}
</script>

<style type="text/css" scoped>
	body {
		overflow: hidden;
	}
</style>