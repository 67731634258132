<template>
	<v-data-table :headers="headers" :items="values" :footer-props="{ 'items-per-page-options': [10, 50, 100] }" :loading="page.loading" loading-text="Loading" :options.sync="paginations" :server-items-length="totalItems" class="elevation-1 mx-2 pa-4">

      <!-- -------------- TIME ----------------- -->
      <template v-slot:item.time="{ item }">
         {{ format(item.timestamp,"time","h:mm a") }}
      </template>
      
      <!-- -------------- TIME ----------------- -->
      <template v-slot:item.time="{ item }">
         {{ format(item.timestamp,"time","h:mm a") }}
      </template>

       <!-- -------------- TIME ----------------- -->
      <template v-slot:item.time="{ item }">
         {{ format(item.timestamp,"time","h:mm a") }}
      </template>
      
	</v-data-table>
</template>

<script>
	 
import { column_filter } from "@/mixins/filter/column";
import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { auth } from "@/mixins/auth";

import { mapState, mapGetters, mapActions } from "vuex";

export default {
   mixins:[column_filter,excel,auth,modal],
   data(){
      return {
         headers:[
            { text: "EMPLOYEE ID",   value: "employee_id", visible:true },
            { text: "FIRST NAME", value: "firstname", visible:true },
            { text: "LAST NAME", value: "lastname", visible:true },
            { text: "DAYS", value: "number_of_days", visible:true },  
            { text: "HOURS RENDERED", value: "hours_rendered", visible:true },
         ],
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         date:
         { 
            from:moment().startOf('month').format('YYYY-MM-DD'), 
            to:moment().add(5 ,'days').format('YYYY-MM-DD') 
         },
         dates:[],
         header: [
            { "header":"First Name", "width":10 },
            { "header":"Last Name", "width":10 },
            { "header":"Days",      "width":10 },
            { "header":"Durations", "width":10 },
         ],
         page:{
            page:1,
            itemsPerPage:10,
            loading:false
         },
         initializing:false,
      } 
   },
   
   created()
   {
      this.initialize();
   },

   beforeDestroy()
   {
      this.$bus.$off("export-excel-timeline_v2");
      this.$bus.$off(`timeline_v2-date-range`);
   },

   methods:{

      ...mapActions('timelineV2',["getTimeline"]),

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/Timelogs/Totals/index 
      */ 
      initialize()
      {  
         this.initializing = true;

         // set value null
         this.$bus.$emit("clear-value",null);
 
         // this set the header for header date " MM/DD "
         this.setTimeLineValues(this.page, this.date);

         // set value for date picker component
         this.$bus.$emit("set-date-range",[this.date.from, this.date.to]);
        
         // to export the records into an excel file
         this.$bus.$on("export-excel-timeline_v2",()=>this.export_exel());
 
         // to set the date in range using Vue Event Bus
         this.$bus.$on("timeline_v2-date-range",(date) => { 

            let date_values = { from:date.from, to:date.to };
            
            if(date.from > date.to) date_values = { from:date.to, to:date.from };

            if(date.from && !date.to) date_values = { from:date.from, to:date.from };
            
            this.setTimeLineValues(this.page,date_values);
         });
      },

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/Timelogs/Totals/index 
      */ 
      setTimeLineValues(page, dates)
      {  
         this.setResetValuesOnArrays();

         // loading for table 
         this.page.loading = true;
         
         Object.assign(this.page,page);

         // set new date property 
         this.date = Object.assign({},dates);

         let payload = Object.assign(Object.assign(page, dates),this.getFiltersValues());
         
         // this set the header for header date " MM/DD "
         this.getDatesForHeader(dates.from,dates.to);
 
         // this set the header column by filtering 
         this.setTableFilter();

         // set fetch with dates 
         this.getTimeline(payload).then(response=>{ 
            this.initializing = false;
            this.page.loading = false;
         });
      }, 

      /*
      * To reset the values of array 
      *
      * @return void 
      */
      setResetValuesOnArrays()
      {
         // reset the headers array for excel to remove dates 
         this.header.length = 4
         
         // reset the headers array for table
         this.headers.length = 5;

         // reset the dates array for excel 
         this.dates.length = 0;
      },

      /*
      * This sets the values from filters site and account on header 
      *
      * @return void 
      */ 
      getFiltersValues()
      {
         let filter = this.$store.getters.getFilter;
         let value = {};

         if(Object.prototype.hasOwnProperty.call(filter,"site_id"))
         {
            Object.assign(value,{ site_id:filter.site_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"account_id"))
         {
            Object.assign(value,{ account_id:filter.account_id });
         }

         return value;
      },

      /*
      * export date into an excel file 
      *
      * @return void
      */
      export_exel()
      {
         this.export_timeline_report(this.values,"Timeline V2")
      },

      /*
      * This append MM/DD dates in format as header into the table
      *
      * @return void
      */ 
      getDatesForHeader(from, to) 
      {
         from = moment(from); to = moment(to);

         // iterate, to get the dates in range 
         while (from <= to)
         {  
            // header date for table
            this.headers.push({"text":moment(from).format('MM/DD'), "value": "timeline."+moment(from).format('YYYY-MM-DD'), visible:true });

            // header dates for excel 
            this.header.push({ "header": moment(from).format('YYYY-MM-DD'), "width":10 })
            
            // dates to push down to get the value for excel export 
            this.dates.push(moment(from).format('YYYY-MM-DD'));
            
            from = moment(from).add(1, 'days');
         }
      },
   },
   
   computed:{ 

      ...mapGetters("timelineV2",{ values:"getTimelineList", totalItems:"totalItems", pagination:"pagination"}),

      paginations:{
         get()
         {
            return this.pagination;
         },
         set(value)
         {
            if(!this.initializing)
            {  
               this.setTimeLineValues(value,this.date);
            }
         }
      }
   }
}
</script> 