<template>
<v-row justify="center">
   <v-dialog v-model="dialog" persistent scrollable max-width="850px" @keydown.esc="close()">
        <v-card :loading="isSubmit">
            <v-card-title>
               <span class="font-weight-bold">{{ title }}</span>
               <v-spacer></v-spacer>
               <span  class="caption mr-3">Press</span>
               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
               <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text style="height:600px;">
               <v-form  v-model="valid" ref="form"  id="v-form-submit" @keyup.native.enter="submit(mode)">
                  <v-container>
                     <!-- ---------------- ORGANIZATION ----------------- -->
                     <v-row no-gutters>
                        <v-col>
                           <v-row class="ma-2">
                              <label class="d-block text-left font-weight-bold label my-1">
                                 Organization
                              </label>
                           </v-row>
                           <v-row class="ma-2">
                              <v-organization 
                                 :organization.sync="form.organization_id"
                                 :disabled="AuthRole == 'Admin'||isDelete||isSubmit"
                              />
                           </v-row>
                        </v-col>
                     </v-row>
                 
                     <v-row no-gutters class="my-auto">
                        <!-- -------------------- ROLES -------------------- -->
                        <v-col class="mx-3">
                           <label class="d-block text-left font-weight-bold label"> Role </label>
                           <v-select :items="user_roles" placeholder="Role" dense outlined v-model="form.role" 
                           :rules="[rules.role.required]" @change="selectedRole" :disabled="AuthRole == 'Admin'||isDelete||isSubmit">
                           </v-select>
                        </v-col>

                        <!-- -------------------- PHOTO ------------------- -->
                        <v-col class="mx-3">
                           <label class="d-block text-left font-weight-bold label"> Photo </label>
                           <v-file-input accept="image/jpeg" placeholder="Upload an avatar" prepend-icon="" dense outlined @change="image_upload($event,'avatar')" 
                              :rules="[rules.avatar.type]":disabled="isSubmit||isDelete" v-model="avatar"
                              :error-messages="error.get('avatar')"
                              :error="error.has('avatar')"
                              @input="error.clear('avatar')">
                           </v-file-input>
                        </v-col>
                     </v-row>

                  <!-- -------------------- LABEL NOTE ------------------ -->
                  <v-row class="row mx-3 my-auto">
                     <span class="d-block text-left bottom-note"> Admin accounts have full access to Abizo DTR platform. </span> 
                  </v-row>

                  <v-divider class="mx-3 mt-3"></v-divider>

                  <v-row class="my-auto">
                     <!-- -------------------- FIRST NAME --------------- -->
                     <v-col class="mx-3">
                        <label class="d-block text-left font-weight-bold label my-1"> First Name </label>
                        <v-text-field outlined placeholder="Type Here" @keydown="rules.stringOnly" dense clearable 
                           :rules="[rules.first_name.required,rules.first_name.format]" v-model="form.first_name" 
                           :disabled="isSubmit||isDelete"
                           :error-messages="error.get('first_name')"
                           :error.sync="error.has('first_name')"
                           @input="error.clear('first_name')"> 
                        </v-text-field>
                     </v-col>

                     <!-- -------------------- LAST NAME ---------------- -->
                     <v-col class="mr-3">
                        <label class="d-block text-left font-weight-bold label my-1"> Last Name </label>
                        <v-text-field outlined placeholder="Type Here" @keydown="rules.stringOnly" dense clearable 
                           :rules="[rules.last_name.required,rules.last_name.format]"
                           v-model="form.last_name" 
                           :disabled="isSubmit||isDelete"
                           :error-messages="error.get('last_name')"
                           :error.sync="error.has('last_name')"
                           @input="error.clear('last_name')">
                        </v-text-field>
                     </v-col>
                  </v-row>

                  <v-row style="margin-top: -20px;">
                     <!-- ----------------- MIDDLE NAME ----------------- -->
                     <v-col class="mx-3">
                        <label class="d-block text-left font-weight-bold label my-1"> Middle Name (optional) </label>
                        <v-text-field outlined placeholder="Type Here" @keydown="rules.stringOnly" dense clearable :rules="[rules.middle_name.format]" v-model="form.middle_name":disabled="isSubmit||isDelete"
                           :error-messages="error.get('middle_name')"
                           :error.sync="error.has('middle_name')"
                           @input="error.clear('middle_name')">
                        </v-text-field>
                     </v-col>

                     <!-- -------------------- SUFFIX -------------------- -->
                     <v-col class="mr-3">
                        <label class="d-block text-left font-weight-bold label my-1"> Suffix (optional) </label>
                        <v-text-field outlined placeholder="Type Here" @keydown="rules.stringOnly" dense clearable
                           :rules="[rules.suffix.format]" v-model="form.suffix":disabled="isSubmit||isDelete"
                           :error-messages="error.get('suffix')"
                           :error.sync="error.has('suffix')"
                           @input="error.clear('suffix')">
                        </v-text-field>
                     </v-col>
                  </v-row> 

                  <v-divider class="mx-3"></v-divider>
                  
                  <!-- ----------------- EMAIL ADDRESS ---------------- -->
                  <v-row class="my-auto">
                     <v-col class="mx-3">
                        <label class="d-block text-left font-weight-bold label my-1"> Email Address </label>
                        <v-text-field outlined placeholder="Type Here" dense clearable
                           :rules="[rules.email.required,rules.email.format]" v-model="form.email":disabled="isSubmit||isDelete"
                           :error-messages="error.get('email')" 
                           :error.sync="error.has('email')"
                           @input="error.clear('email')">
                        </v-text-field>
                     </v-col>
                  </v-row>

                  <v-divider class="mx-3"></v-divider>
                  
                  <!-- ------------------- ADMIN URL ------------------ -->
                  <v-row class="my-auto">
                     <v-col class="mx-3">
                        <label class="d-block text-left font-weight-bold label my-1"> 
                          Admin URL  
                        </label>
                        <v-text-field outlined placeholder="Type Here" dense clearable
                           :rules="[rules.url.format]" v-model="form.url":disabled="isSubmit||isDelete"
                           :error-messages="error.get('url')" 
                           :error.sync="error.has('url')"
                           @input="error.clear('url')">
                        </v-text-field>
                     </v-col>
                  </v-row>

                  <v-divider class="mx-3"></v-divider>

                  <div v-if="mode == 'edit' && AuthUserInfo.default == 1 || mode == 'edit' && AuthUserInfo.default == 0">
                     <v-checkbox v-model="isChangePassword" label="change password" class="mx-3"></v-checkbox>
                  </div>

                  <v-row 
                     class="my-auto"
                     v-if="mode =='edit' && isChangePassword || mode =='add' && !isChangePassword"
                  >
                     <!-- ------------------- PASSWORD ------------------- -->
                     <v-col class="mx-3">
                        <label class="d-block text-left font-weight-bold label my-1"> Password </label>
                        <v-text-field class="centered-input password" :type="password ? 'text' : 'password'" outlined dense clearable 
                        :append-icon="password ? 'mdi-eye-off': 'mdi-eye'" placeholder="Type Here" autocomplete="new-password"
                        @click:append ="password = !password"
                        v-model="form.password" 
                     
                        :disabled="isSubmit||isDelete" 
                        v-show="mode =='add'||mode =='edit' && isChangePassword"
                        :rules="mode =='edit' && isChangePassword ? [rules.password.required,rules.password.length] :
                        mode =='add' && !isChangePassword ? [rules.password.required,rules.password.length] : [] "
                        
                        :error-messages="error.get('password')"
                        :error.sync="error.has('password')"
                        @input="error.clear('password')"> 
                        </v-text-field>
                     </v-col>

                     <!-- --------------- CONFIRM PASSWORD --------------- -->
                     <v-col class="mr-3">
                        <label class="d-block text-left font-weight-bold label my-1">
                           Confirm Password
                        </label>
                        <v-text-field
                           dense
                           outlined
                           clearable
                           placeholder="Type Here"
                           class="centered-input password"
                           autocomplete="new-confirm-password"
                           v-model="form.confirmpassword"
                           v-show="mode =='add'||mode =='edit' && isChangePassword"
                           @click:append ="confirm_password = !confirm_password"
                           @input="error.clear('confirmpassword')" 
                           :type="confirm_password ? 'text' : 'password'"
                           :append-icon="confirm_password ? 'mdi-eye-off': 'mdi-eye'" 
                           :disabled="isSubmit||isDelete" 
                           :rules="mode =='edit' && isChangePassword ? [rules.password.required,rules.password.length,password_match_rule] :
                           mode =='add' && !isChangePassword ? [rules.password.required,rules.password.length,password_match_rule] : [] "
                           :error-messages="error.get('confirmpassword')"
                           :error.sync="error.has('confirmpassword')"
                           >
                        </v-text-field>
                     </v-col>
                  </v-row>
                  </v-container>
               </v-form>
            </v-card-text>

            <v-divider></v-divider>
            
            <v-card-actions class="ma-4">

               <!-- ------------- DELETE OR DISCARD ------------- -->
               <v-btn dark  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
                  @click="mode == 'delete' ? submit(mode) : close()"
                  :color="mode == 'delete'?'red':'#0057AD'"
                  :loading="mode == 'delete'? isSubmit : false ">
                  {{ mode == 'delete' ? 'Delete':'Discard'}} 
               </v-btn>

               <v-spacer></v-spacer>

               <!-- ------------- CANCEL OR SUBMIT ------------- -->
               <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button"
                  @click="mode == 'delete' ? close() : submit(mode)"
                  :loading="mode == 'delete'? false  : isSubmit">
                  {{ mode == 'delete' ? 'Cancel':'Submit' }}
               </v-btn> 
            </v-card-actions>
        </v-card>
   </v-dialog>
</v-row>
</template>

<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";
import   Errors   from "@/validation/errors";
import VOrganizaion from "@/components/Fields/Autocomplete/Organization/Index";

export default {
	mixins:[modal,states,auth],
	name:"index",
   components:{
      "v-organization":VOrganizaion,
   },
	data(){ 
		return {

         // to check if the field were all valid
         valid: false,

         // to show password 
         password:false,

         // to show confirm password 
         confirm_password:false,

         // to validate the fields 
         rules:rules,

         // to set the data event Add or Edit
         form:{},
         
         // errors: new Errors()
         error:new Errors(),

         user_roles:["Super Admin","Admin"],

         // ------------- STATES ------------------ //

         // to check if loading or not 
         isLoading: false,

         // to check if action is submitting
         isSubmit: false,

         // to check if mode is delete 
         isDelete:false,

         // to check if mode is edit
         isEdit:false,

         // to check if change password if on action
         isChangePassword:false,

         // to reset the value from form.avatar
         avatar:null,

         // roles
         roles:"",
		}
	}, 

   created()
   {   
      this.initilize();
   },

   updated()
   {
      this.setActions();
      // this.setAuthOrganizationId();
   },

	methods:{
     
      /*
      * This initilize value values needed
      *
      * @return void
      */ 
      initilize()
      {
         this.setValues(); 
      },

      /*
      * To get image into base64 string 
      *
      * @return void
      */ 
      image_upload(event,type)
      {
         try {
            if(typeof null != event)
            {
               // new instance of FileReader class 
               const reader = new FileReader();
               
               if(type == "avatar")
               {
                  // get the base64 from result from File Reader
                  reader.onloadend = ((image) =>{ this.form.avatar =  reader.result; });
               }
              
               // read the data as url from upload payload file
               reader.readAsDataURL(event);
            }
         }

         catch(e)
         {
            if (!(e instanceof TypeError)) 
            {
               // The exception is not a TypeError, so throw it again.
               throw e;
             }
         }
      },

      /*
      * To submit values for Add,Edit,Delte
      *
      * @return void
      */ 
      submit(action = "add")
      {
         switch(action)
         {
             case "add": 
               if(this.$refs.form.validate())
               {   
                 this.isSubmit = true;

                 this.$store.dispatch("setRegisterUser",this.form)
                 .then((response) => {this.isSubmit = false; this.redirect("/admins"); })
                 .then((response) => {this.close()})
                 .catch((errors)  => { 
                   this.error.record(errors.response.data.message);
                   this.isSubmit = false; 
                 })
                 .finally(() => {});
               }
             break;

             case "edit": 
               if(this.$refs.form.validate())
               {
                 this.isSubmit = true;

                 this.$store.dispatch("setUpdateUser",this.form)
                 .then((response) => {this.isSubmit = false})
                 .then((response) => {this.close()})
                 .catch((errors)  => { 
                   this.error.record(errors.response.data.message);
                   this.isSubmit = false; 
                 })
                 .finally(() => {});
               }
             break;

             case "delete": 
                  this.isSubmit = true;
                  this.$store.dispatch("setDeleteUser",this.form)
                 .then((response) => {this.isSubmit = false})
                 .then((response) => {this.close()})
                 .catch((errors)  =>{})
                 .finally(() => {});
             break;
         } 
      },

      /*
      * This assign value values to the form object
      *
      * @return void
      */
      setValues()
      {  
         Object.assign(this.form,this.data);

         this.form.organization_id = this.data && this.data.organization_id ? this.data.organization_id : null;
         this.form.role = this.data && this.data.roles ? this.data.roles[0].name : null;

         if(this.AuthRole.toString() == "Admin")
         {
            this.form.role = this.AuthRole.toString();
            this.form.organization_id = this.AuthOrganization ? this.AuthOrganization.id : [];
         }
      },

      selectedRole(type)
      {  
         this.roles = type;
      },

      /*
      * This assign organization id coming from organization admin
      *
      * @return void
      */
      setAuthOrganizationId()
      {
         if(this.AuthRole.toString() == "Admin")
         {  
            this.form.organization_id = this.AuthOrganization.id;
            this.form.role = this.AuthRole.toString();
         }
      },

      /*
      * This set actions based on modal action mode
      *
      * @return void
      */
      setActions()
      {
         this.isDelete = this.mode == "delete" ? true : false;
         this.isEdit   = this.mode == "edit"   ? true : false;
      }
	},

  computed:{

      /*
      * Password rule, to check if password and confirm password are both the same 
      *
      * @return organization list
      */  
      password_match_rule()
      {
         return () => (this.form.password == this.form.confirmpassword) || 'Password and Confirm Password must match'
      },

      /*
      * To check if Change password is on edit mode only
      *
      * @return organization list
      */  
      changepassword(value)
      {
         this.isEdit = value ? true : false;
      }
   },

	watch:{

      /*
      * Resets the form.avatar field form
      *
      * @return void
      */
      avatar(value)
      {
         this.form.avatar = value;
      },

      /*
      * Resets the field form
      *
      * @return void
      */
      isChangePassword(value)
      {
         this.form.changepassword = value ? true : false;
      },
      
      /*
      * Resets the field form
      *
      * @return void
      */ 
      dialog(value)
      {
         if(!value) { this.$refs.form.reset(); } 
      },
	}
}
</script>

<style type="text/css" scoped>

   .custom-card-button{
      height:40px;
      width:150px;
   }

   .label {
      color:black;
      font-size: 16px;
   }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
   }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }

</style>