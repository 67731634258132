<template>

<div class="text-center">
   <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="100" offset-x>
      <template v-slot:activator="{ on }">
         <v-list-item @click="menu=true" class="nav-link">
            <!-- ------------- USER AVATAR ------------- -->
            <v-list-item-avatar>
               <v-avatar size="35px">
                  <img 
                     :src="user.avatar == null ? default_avatar : user.avatar"
                     alt="Avatar"
                  />
               </v-avatar>
            </v-list-item-avatar>

            <!-- ------------- USER NAME ------------- -->
            <v-list-item-title>
               {{`${user.first_name || ''}  ${user.last_name || ''}`}}
            </v-list-item-title>
         </v-list-item>
      </template>

      <v-card>
         <v-list>
            <v-list-item>
               
               <!-- ------------- USER AVATAR ------------- -->
               <v-list-item-avatar>
                  <img :src="user.avatar == null ? default_avatar : user.avatar " alt="Avatar"/>
               </v-list-item-avatar>

               <!-- ------------- USER AVATAR ------------- -->
               <v-list-item-content>
                  <v-list-item-title> {{ `${user.first_name}  ${user.last_name}` }} </v-list-item-title>
                  <v-list-item-subtitle>{{ `${role }` }}</v-list-item-subtitle>
               </v-list-item-content>

            </v-list-item>
         </v-list>

         <v-divider></v-divider>

         <v-list>
            <v-list-item @click="logout"> 
               <v-list-item-icon>
                  <v-icon>mdi-logout</v-icon>
               </v-list-item-icon>
               <v-list-item-title> Logout </v-list-item-title>
            </v-list-item>
         </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {

   data: () => ({
      fav: true,
      menu: false,
      message: false,
      hints: true,
      default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
   }),

   methods:{
      /*
      * This initilize the value for table view
      *
      * @return array getEmployeeList
      */ 
      logout()
      {
         this.$store.dispatch("logout");
      },
   },

   computed:{
      user()
      {
         return this.$store.getters.authUserInfo;
      },

      role(){
         return this.$store.getters.authUserRole;
      }
   }
}
</script>