<template>
	<v-autocomplete outlined dense clearable item-text="name" item-value="id" append-icon="search" v-model="employee_roles" :disabled="disabled" :items="values" :placeholder="placeholder" @focus="onFocus">
		<template v-slot:selection="data">
			<v-chip v-bind="data.attrs" :input-value="data.selected" close @click="data.select" @click:close="remove(data.item.id)" >
				<v-avatar left>
					<v-img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo"></v-img>
				</v-avatar>
				<span>
					{{ data.item.name }}
				</span>
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>
<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
	props:{
		placeholder:{
			type:String,
			default:null
		},
		employee_role:{
			type:Number,
			default:null
		},
		disabled:{
			type:Boolean,
			default:false
		},
	},
	mixins:[modal,states,auth],
    data () {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			isEmpty: false,
			employee_roles:"",
		}
    },

	async created()
	{
		this.$nextTick(()=>{ this.initilize(); });
	},

	beforeDestroy(){
	},

	methods:{

		...mapActions("employee_role",{"getEmployeeRolesDropdown":"getDropdown"}),

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/employee_role/index 
 		*/ 
		initilize()
		{	
			if(this.AuthOrganization)
			{
				// get employee role per organization
				this.$nextTick(()=>{ this.getEmployeeRolesDropdown(this.AuthOrganization.id); });
			}

			if(this.employee_role)
			{	
				// select organization with organization id 
				this.employee_roles = this.employee_role;
			}
 		},

 		/*
 		* When dropdown on focus set initialize
 		*
 		* @return void
 		*/ 
 		onFocus()
		{
			this.initilize();
		},

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item) 
		{
			if(typeof this.employee_roles === "number")
			{
				this.employee_roles = null;
			}

			else if (typeof this.employee_roles === "object")
			{	
				let index = this.employee_roles.indexOf(item);
				if(index >= 0) this.employee_roles.splice(index, 1)
			}
		},
    },

	computed:{
		...mapGetters("employee_role",{"values":"getDropdown"}),
	},

    watch:{

        /*
 		* Look for value employee_role id as sync
 		*
 		* @return array getsiteList
 		*/ 
        employee_roles(val)
        {
        	this.$emit('update:employee_role', val)
        },

        /*
 		* Watch for value from 'employee_role' props 
 		*
 		* @return void
 		*/ 
        employee_role(val)
        {	
        	this.employee_roles = val;
        }, 
    },
}
</script>