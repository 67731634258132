import store from "@/store"

const state = { 
	liveness_check:{ list:[], record:[] }
}

const getters = {

	/*
	* GET liveness Check list
	* to set the list after fetching
	*/	
	getLivenessCheckList:state=>{
		return state.liveness_check.list;
	},

	/*
	* GET liveness Check record
	* to set the record after fetching
	*/	
	getLivenessCheckRecord:state=>{
		return state.liveness_check.record;
	}
}

const mutations = {

	/*
	* GET liveness Check record
	* to get the record after fetching
	*/	
	GET_LIVENESS_CHECK_RECORD:(state,record)=>{
		state.liveness_check.record = record;
	},

	/*
	* GET liveness Check list
	* to get the list after fetching
	*/	
	GET_LIVENESS_CHECK_LIST:(state,list)=>{
		state.liveness_check.list = list;
	},

	/*
	* ADD liveness Check record
	* to set the list additional record after add/registraion
	*/	
	ADD_LIVENESS_CHECK_RECORD:(state,data)=>{
		Object.assign(state.liveness_check.list, data);
	},

 	/*
	* UPDATE liveness Check record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_LIVENESS_CHECK_RECORD:(state,data)=>{
		let index = state.liveness_check.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.liveness_check.list[index],data)
	},

	/*
	* DELETE liveness Check record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_LIVENESS_CHECK_RECORD:(state,id) => {
		let index = state.liveness_check.list.findIndex(list => list.id == id)
		state.liveness_check.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch liveness Check list
	*
	* @return string
	*/	
	getLivenessCheck(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/liveness-check")
			.then((response)=>{ context.commit('GET_LIVENESS_CHECK_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*	
	* Add/Register liveness Check record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterLivenessCheck(context,data)
	{
		return new Promise((resolve,reject)=>{ 
			axios.post("/liveness-check",data)
			.then((response)=>{ resolve(response);})
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>context.dispatch("getLivenessCheckList"));
		})
	},
	
	/*
	* Edit/Update liveness Check record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateLivenessCheck(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/liveness-check/${data.id}`,data)
			.then((response)=>{ context.dispatch("getLivenessCheckList"); resolve(response);})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove liveness Check record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteLivenessCheck(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/liveness-check/${data.id}`)
			.then((response)=>{ context.dispatch("getLivenessCheckList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const liveness_check = {
	state,
	getters,
	mutations,
	actions
}