<template>
    <v-row justify="center">
		<v-dialog v-model="dialog" persistent scrollable max-width="900px" style="max-height:3000px;" @keydown.esc="close()" > 
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <span class="font-weight-bold">{{ title }}</span>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

		        <v-card-text>
		        	
		        	<v-tabs v-model="tab">
						<v-tab>Schedule</v-tab>
						<template v-if="mode != 'quick_add'">
							<v-tab>Mobile App</v-tab>
						</template>
					</v-tabs>

					<v-tabs-items v-model="tab">
						<v-tab-item v-for="item in items" :key="item.tab">
							<component :is="item.content"></component>
						</v-tab-item>
					</v-tabs-items>

		        </v-card-text>

		        <v-divider></v-divider>

		        <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions  class="ma-4" >

					<!-- ------------- DELETE OR DISCARD ------------- -->
	        		<v-skeleton-loader v-if="!onLoading_cancel_btn ? false : true" width="150" type="button"></v-skeleton-loader>
					<v-btn v-else  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
					@click="close()" :color="'#0057AD'":disabled="isSubmit ? true : false"> Discard </v-btn>

					<v-spacer></v-spacer>

					<!-- ------------- CANCEL OR SUBMIT ------------- -->
	        		<v-skeleton-loader v-if="!onLoading_submit_btn ? false : true" width="150" type="button"></v-skeleton-loader>
					<v-btn v-if="!onLoading_submit_btn && tab != 1" color="#0057AD" class="mx-2 elevation-0 white--text font-weight-bold custom-card-button"  @click="submit()" :color="mode == 'delete'?'red':'#0057AD'"
					:disabled="isSubmit":loading ="isSubmit ? true : false"> Submit </v-btn>

	            </v-card-actions>
		    </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";

import { mapActions } from "vuex";
import { mapGetters } from "vuex";

import modal_setting1 from "@/components/Form/Modal/Settings/Schedules/Index.vue";
import modal_setting2 from "@/components/Form/Modal/Settings/MobileApp/Index.vue";

export default {

	mixins:[modal,states],
	components:{
		"modal-schedule":modal_setting1,
		"modal-mobile-app":modal_setting2,
	},
	data(){ 
		return {
			onLoading_cancel_btn: true,
			onLoading_submit_btn: true,

			isSubmit: false,

			form:["log","time_in","time_out"],

			items: [
	          { tab: 'One', content: 'modal-schedule' },
	          { tab: 'Two', content: 'modal-mobile-app' },
	        ],
	        
	        tab: null,
		}	
	}, 
	mounted(){
		this.$bus.$on("onSubmit-modal",(value)=>{
			this.isSubmit = value;
		});

		this.$bus.$on("onLoading-modal",(value)=>{
			this.onLoading_cancel_btn = value;
			this.onLoading_submit_btn = value;
		});
	},

    methods:{
    	submit()
    	{	
    		this.$bus.$emit("submit-modal");
    	}
    }
}

</script>

<style type="text/css" scoped>
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}
	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}

</style>