import store from "@/store"

const state = { 
	breaks:{ list:[] }
}

const getters = {

	/*
	* GET break list
	* to set the list after fetching
	*/	
	getBreakList:state=>{
		return state.breaks.list;
	}
}

const mutations = {

	/*
	* GET break list
	* to get the list after fetching
	*/	
	GET_BREAK_LIST:(state,list)=>{
		state.breaks.list = list;
	},

	/*
	* ADD break record
	* to set the list additional record after add/registraion
	*/	
	ADD_BREAK_RECORD:(state,data)=>{
		Object.assign(state.breaks.list, data);
	},

 	/*
	* UPDATE break record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_BREAK_RECORD:(state,data)=>{
		let index = state.breaks.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.breaks.list[index],data)
	},

	/*
	* DELETE break record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_BREAK_RECORD:(state,id) => {
		let index = state.breaks.list.findIndex(list => list.id == id)
		state.breaks.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch break list
	*
	* @return string
	*/	
	getBreakList:(context,auth = {})=>{
		axios.get(`/breaks`)
		.then((response) => { context.commit('GET_BREAK_LIST',response.data); })
		.catch((errors)  => console.log(errors));
	},

	/*
	* Add/Register break record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateOrRegisterBreak:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/breaks`,data)
			.then((response)=>{ })
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Add/Register break record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterBreak:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/breaks`,data)
			.then((response)=>{ })
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Edit/Update break record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateBreak:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.put(`/breaks/${data.id}`,data)
			.then((response)=>{ context.dispatch("getBreakList");})
			.catch((errors)=>{ reject(errors) }) 
			.finally((response)=>{ resolve(response) });
		});
	},
	
	/*
	* Delete/Remove break record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteBreak:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.delete(`/breaks/${data.id}`)
			.then((response)=>{ })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const breaks = {
	state,
	getters,
	mutations,
	actions
}