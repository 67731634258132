/*
Options
idle-vue accepts the following options when loaded; all of them are facultative, except store or eventEmitter; they cannot be both omitted:

eventEmitter: The Vue instance through which the idle-vue plugin is to send events. The plugin will send idleVue_onIdle and idleVue_onActive events to this instance; all Vue objects created after the plugin is loaded will run their onIdle and onActive hooks when idleVue_onIdle and idleVue_onActive events are sent.

store: The Vuex instance which stores the state of the app (idle or active); this store has a state idleVue.isIdle and a mutation idleVue/IDLE_CHANGED(isIdle).

idleTime: The time (in ms) without input before the program is considered idle. For instance, with idleTime: 40000, the module will emit idle events after 40 seconds of inactivity. Default value: 60000 (one minute).

events: Events that "break" idleness. Default value: ['mousemove', 'keydown', 'mousedown', 'touchstart']

keepTracking: Whether you want to track more than once. Default value: true.

startAtIdle: Start in idle state. Default value: true.

moduleName: The name of the vuex module (if store is defined), and the prefix of the emitted events (if eventEmitter is defined). Default value: idleVue.

PACKAGE LINK : 
https://www.npmjs.com/package/idle-vue

*/

// VUE 
import Vue from "vue";

// VUE STORE
import store from "@/store";

import IdleVue from 'idle-vue';

const eventsHub = new Vue();

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 15 * 60000, // 15 mins
  startAtIdle: false
});