export const actions = {
	
	/*
	* to get all templates as list
	*/	
	getTemplates(context,data={}){
		return new Promise((resolve,reject)=>{
			context.commit("SET_LOADING",true); 
			axios.get("/query-builder",{
				params:{
					keyword:data.keyword,
					orderBy:data.orderBy,
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : 10,
				}
			})
			.then((response)=>{ 
				context.commit("SET_QUERY_BUILDER_LIST",response); 
				context.commit("SET_LOADING",false); 
				resolve(response);
			 })
			.catch((errors)=>{
				context.commit("SET_LOADING",false); 
				reject(errors);
			})
		});
	},
	
	/*
	* to submit the form as update into an API
	*/
	setUpdateTemplate(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.put(`/query-builder/${data.id}`,data)
			.then((response)=>{ 
				context.commit("UPDATE_RECORD",response.data); 
				resolve(response.data); 
			}) 
			.catch((errors)=>reject(errors))
		})
	},
	
	/*
	* to submit the form as delete into an API by id
	*/
	setDeleteTemplate:(context,data={})=>{
		return new Promise((resolve, reject)=>{
			axios.delete(`/query-builder/${data.id}`)
			.then((response)=>{ context.commit("DELETE_RECORD",data); resolve(response); })
			.catch((errors)=>{ reject(errors); })
		});
	},
}