<template>
	<main v-if="!loadings" style="display: flex; align-items:center; justify-content:flex-end;">
		<template v-if="visible.header.type == 'edit'">
			<v-btn style="margin-right:20px;" @click="onEditTemplate()" text color="primary">Edit</v-btn>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-btn v-bind="attrs" v-on="on" color="primary" dark> Export As </v-btn>
				</template>
				<v-list>
					<v-list-item v-for="(item, index) in items" @click="exportExcel()" :key="index">
						{{ item.title }}
					</v-list-item>
				</v-list>
			</v-menu>
		</template>

		<template v-if="visible.header.type == 'add' && !loadings">
			<v-checkbox v-model="keepTemplate" style="margin-top:20px; margin-right:30px;" label="Keep Template"/>
			<v-btn @click="onDone()" color="primary" 
			v-if="hastitle && hasDateRange">Done</v-btn>
			<v-btn class="ml-3" :loading="loading" @click="saveDraft()" color="primary">Save as Draft</v-btn>
		</template>
	</main>
</template>
<script type="text/javascript">
	
	import { mapActions, mapGetters } from "vuex";
	import _ from "lodash";

	export default {
		data(){
			return {
				status:1,
				items: [
					{ title: 'Excel File' },
				],
				loading:false,
				isDone:false,
				multi_attr:['sites','accounts','employee-roles','assignments','schedules'],
				pagination:{
		            page:1,
		            itemsPerPage:10,
		            loading:false,
		        },
			}
		},

		methods:{

			...mapActions("query_builder_modal",[
				"setOpen"
			]),
			
			...mapActions("builder",[
				"editTemplate",
				"setColumnAttributes",
				"setCreateTemplate",
				"setUpdateTemplate",
				"getCalendarHolidays"
			]),

			/*
			* saving when leaving the page 
			*/
			saveDraft(){

				this.loading = true;
				this.isDone = true;
				
				// if draft is not save yet
				let payload = { 
					column_attributes:JSON.stringify({
						...this.form.column_attributes
					}), 
					title:"Untitled",
					status:2
				}

				// if draft saved already
				if(this.form.id){
					payload = { 
						column_attributes:JSON.stringify({
							...this.form.column_attributes
						}), 
						status:2
					}
				}
				
				this.setCreateTemplate({ ...this.form, ...payload})
				.then((response)=>{
					this.loading = false;
					this.$router.push("/query-builder");
				});	
			},

			setSubHedear(header){
				let res = [];
				header.filter((val)=>val.subheader).forEach((val)=>{ 
					res = res.concat(val.subheader); 
				});
				return res;
			},

			setSubhedears(header){
				let res = [];
				header.filter((val)=>val.value).forEach((val)=>{ 
					res = res.concat(val.value); 
				});
				return res;
			},

			exportExcel(){

				const workSheetColumns = this.setSubHedear(this.headers);
			    const workbook = new Excel.Workbook();
			    const sheet = workbook.addWorksheet("My Sheet");

				let workSheetName = "Query Builder Report";
				
				// set bold rows as headers

				if(this.form.column_attributes.hasOwnProperty("output_name")){
					workSheetName = this.form.column_attributes.output_name;
				}

				/*
				* Add parent header from row 1 
				*/
				let columns = [];

				this.headers.forEach(data=>{
					if(data.subheader){
						data.subheader.forEach(value=>{
							columns.push({ 
								header:data.value, 
								key: data.value,
								width:25
							});
						});
					}
				});

				sheet.columns = columns;
				
				// set subheaders under the first header as dates
				sheet.addRow(workSheetColumns.map(data=>data.text));

				// add row of values within an array
				let data = this.excelDataRows();

				// add values per row
				data.forEach(values=>{ 
					sheet.addRow(values); 
				});

				// mapping subheaders
				let subheaders = this.headers.map(data=> 
					data.subheader.map(data2=>data.value)
				);

				let cellKeys = []; 
				let cellLetters = []; 

				subheaders.forEach(data=>{
					cellKeys.push(
						data.map(
							data2=>sheet.getColumn(data2).header
						)
					);
				});

				let cells = [];

				// cells extracted 
				cellKeys.forEach((data1,key)=>{
					let cell = cells[key] = [];

					// from excel columns
					sheet.columns.forEach(column=>{
						if(data1.indexOf(column.key) > -1) cell.push(column.letter);
					});

					cellLetters.push(cell);
				});


				// merging each cells with same key
				cellLetters.forEach(data=>{
					// data produce array ex. ['2020-01-01','2020-01-01'] and so on
			    	sheet.mergeCells(`${data[0]}1:${data[data.length - 1]}1`)
				});

				// make all row 1 as center 
				// set column 1 and column 3 as blank
				sheet.columns.forEach(data=>{

					// centering the row 1 values
					sheet.getCell(`${data.letter}1`).alignment = { 
						vertical:'middle', 
						horizontal:'center' 
					}

					// if there's column-1 or column-3 is found then set header as blank
					if(data.key == 'column-1' || data.key == 'column-3'){
						data.header = "";
					}
				});

				sheet.getRow(1).font = { bold:true }
				sheet.getRow(2).font = { bold:true }

				// process the data and save the file as download
				workbook.xlsx.writeBuffer().then(data => {
					const blob = new Blob([data], {
						type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' 
					}); 
					FileSaver.saveAs(blob, `${workSheetName}.xlsx`);
				});
			},

			excelDataRows(){

				let data = [];
				let obj = {};
				let keys = [];

				let workSheetName = "Query Builder Report";

				if(this.form.column_attributes.hasOwnProperty("output_name")){
					workSheetName = this.form.column_attributes.output_name;
				}

				const fileName = `${this.form.title}.xlsx`;

				this.values.forEach((value, key)=>{
						
					// use the keys of an object
					let keys = Object.keys(value);
					let object = {};

					// using date keys 
					// grouped by column-1 
					keys
					.filter(data=>data == "column-1")
					.forEach((key2,key)=>{ 
						for(let key in value[key2]){
							Object.assign(object,{
								[`${key2}-${key}`]:value[key2][key]
							});
						}
					});

					// using date keys 
					// center column
					// grouped by column-2
					keys
					.filter(data=>data != "column-1" && data != "column-3")
					.forEach(date=>{ 
						for(let key in value[date]){
							Object.assign(object,{ 
								[`column-2-${date}-${key}`]:this.getColumnName(key,value[date][key],date)
							});
						}
					});

					// using date keys 
					// grouped by column-3
					keys
					.filter(data=>data == "column-3")
					.forEach((key2,k)=>{ 
						for(let key in value[key2]){
							Object.assign(object,{
								[`${key2}-${key}`]:this.getColumnName(key,value[key2][key])
							});
						}
					});

					data.push(object);
				});

				return data.map(data=>Object.values(data));
			},

			getColumnName(type, value,date = null){
				// This is for column with multiple values 
				// For Account, Assignment, Employee Role, Schedules and Site 
				if(this.multi_attr[this.multi_attr.indexOf(type)]){ 
					let values = this.$store.getters[`${type}/getRecords`];
					if(values.length > 0 && this.multi_attr[this.multi_attr.indexOf(type)]){
						let filtered_val = values.filter(data=>data.id == value);
						if(filtered_val.length > 0){
							let { name } = filtered_val[0];
							return name
						}
						if(type == 'assignments'){
							let names = [];
							value.forEach((value1)=>{
								let filtered_val = values.filter(data=>data.id ==value1 );
								let { name } = filtered_val[0];
								names.push(name);
							});
							return names.toString();
						}
					}
				} else if(type === "day_type"){
					if(this.holidays[date]){
						return `${value}, Holiday`;
					} else {
						return value;
					}
				} else {
					return value;
				}
			},

			onDone(){
				this.setOpen();
				
				// fetch holiday with the date initialized from form
				this.getCalendarHolidays(_.get(this.form,"column_attributes.from"));
			},

			onEditTemplate(){
				this.editTemplate();
				this.isDone = false;
			}
		},

		computed:{
			...mapGetters("builder_modal",{ 
				"dialog":"getDialog" 
			}),

			...mapGetters("builder",{ 
				"form":"getForm", 
				"visible":"getVisibility",
				"getKeepTemplate":"getKeepTemplate",
				"values":"getValues",
				"headers":"getHeaders",
				"loadings":"getLoading",
				"holidays":"getHolidays"
			}),

			keepTemplate:{
				get(){ return this.getKeepTemplate; },
				set(event){ this.setColumnAttributes({ keepTemplate:event }); }
			},
			hastitle(){
				return this.form.column_attributes.output_name != null &&
				this.form.column_attributes.output_name != ""  ;
			},
			hasDateRange(){
				return this.form.column_attributes.from != null && this.form.column_attributes.to != null;
			},
		}
	}
</script>