<template>
    <div>
		<!-- -------------------- CARD HEADER ------------------- -->
		<template v-if="mode == 'quick_add'">

			<!-- -------------------- SCHEDULES LABEL ------------------- -->
			<v-row style="margin-top:50px; margin-bottom:40px;">
				<label class="ml-3">Schedule Types</label>
			</v-row>

			<!-- -------------------- SCHEDULES LABEL ------------------- -->		
			<v-row style="margin-top:-30px;">
				<v-col class="my-3" v-for="(label,keys) in schedule_label" :key="label">
					<label class="label-style">{{ label }}</label>
				</v-col>
			</v-row>

			<!-- -------------------- SCHEDULES FORM ------------------- -->
			<v-form v-model="valid" ref="form" @keyup.native.enter="submit(mode)">
				<v-row style="margin-top:-30px;">

					<!-- ------------ SHIFT NAME ------------ -->
					<v-col>
						<v-text-field :rules="[rules.shift_name.required]" v-model="quick_schedule_form.name" placeholder="Type name here" outlined dense :disabled="isSubmit||isDelete"></v-text-field>
					</v-col>

					<!-- -------------- FROM --------------- -->
					<v-col>
	              		<v-dialog ref="quick_schedule1" v-model="quick_schedule_form.from_timepicker.show" :return-value.sync="quick_schedule_form.from_timepicker.value" persistent width="290px">
					        <template v-slot:activator="{ on }">
								<v-text-field v-model="quick_schedule_form.from = quick_schedule_form.from_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on" :rules="[rules.time.required]" :disabled="isSubmit||isDelete">
								</v-text-field>
					        </template>
					        <v-time-picker v-if="quick_schedule_form.from_timepicker.show" v-model="quick_schedule_form.from_timepicker.value" full-width>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="quick_schedule_form.from_timepicker.show = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.quick_schedule1.save(quick_schedule_form.from_timepicker.value)">OK</v-btn>
					        </v-time-picker>
					    </v-dialog>
					</v-col>

          			<!-- -------------- TO --------------- -->
					<v-col>
	              		<v-dialog ref="quick_schedule2" v-model="quick_schedule_form.to_timepicker.show" :return-value.sync="quick_schedule_form.to_timepicker.value" persistent width="290px">
					        <template v-slot:activator="{ on }">
								<v-text-field v-model="quick_schedule_form.to = quick_schedule_form.to_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on" :rules="[rules.time.required]" :disabled="isSubmit||isDelete">
								</v-text-field>
					        </template>
					        <v-time-picker v-if="quick_schedule_form.to_timepicker.show" v-model="quick_schedule_form.to_timepicker.value" full-width>
								<v-spacer></v-spacer>
								<v-btn text color="primary" @click="quick_schedule_form.to_timepicker.show = false">Cancel</v-btn>
								<v-btn text color="primary" @click="$refs.quick_schedule2.save(quick_schedule_form.to_timepicker.value)">OK</v-btn>
					        </v-time-picker>
					    </v-dialog>
					</v-col>

					<!-- --------- BREAK TYPE -------- -->
				    <v-col>
				    	<v-select :items="break_type" class="elevation-0" placeholder="Select break type" dense outlined item-text="name" item-value="id" v-model="quick_schedule_form.break_type" :disabled="isSubmit||isDelete"></v-select>
				    </v-col>
          		</v-row>
	        </v-form>
    	</template>

        <template v-else>
        	<v-form v-model="valid" ref="form" @keyup.native.enter="submit(mode)">
				<v-skeleton-loader v-if="isLoading.schedule" height="100%" type="article,break"></v-skeleton-loader>
				<template v-else>
					<!-- -------------------- SCHEDULES FORM ------------------- -->
					<v-row style="margin-top:50px; margin-bottom:40px;">
						<label class="ml-3">Schedule Types</label>
						<a class="ml-3" :class="isLoading.schedule || isSubmit || isDelete ? 'disable' : ''"  @click="add_schedule()">
							<strong>+ Add</strong>
						</a>
					</v-row>

					<!-- -------------------- SCHEDULES LABEL ------------------- -->		
					<v-row style="margin-top:-30px;">
						<v-col class="my-3 " v-for="(label,keys) in schedule_label" :key="label">
							<span class="label-style">{{ label }}</span>
						</v-col>
					</v-row>
					
					<!-- -------------------- SCHEDULES FORM ------------------- -->
					<div v-for="(input,index) in schedule_form">
						<v-row style="margin-top:-30px;">

							<!-- ------------ SHIFT NAME ------------ -->
							<v-col>
								<v-text-field :rules="[rules.shift_name.required]" v-model="input.name" placeholder="Type name here" outlined dense :disabled="isSubmit||isDelete"></v-text-field>
							</v-col>

							<!-- -------------- FROM --------------- -->
							<v-col>
				          		<v-dialog ref="dialog_schedule1" v-model="input.from_timepicker.show" :return-value.sync="input.from_timepicker.value" persistent width="290px">
							        <template v-slot:activator="{ on }">
										<v-text-field v-model="input.from = input.from_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on"
										:rules="[rules.time.required]" :disabled="isSubmit||isDelete">
										</v-text-field>
							        </template>
							        <v-time-picker v-if="input.from_timepicker.show" v-model="input.from_timepicker.value" full-width>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="input.from_timepicker.show = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.dialog_schedule1[index].save(input.from_timepicker.value)">OK</v-btn>
							        </v-time-picker>
							    </v-dialog>
							</v-col>

				  			<!-- -------------- TO --------------- -->
							<v-col>
				          		<v-dialog ref="dialog_schedule2" v-model="input.to_timepicker.show" :return-value.sync="input.to_timepicker.value" persistent width="290px">
							        <template v-slot:activator="{ on }">
										<v-text-field v-model="input.to = input.to_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on"
										:rules="[rules.time.required]" :disabled="isSubmit||isDelete">
										</v-text-field>
							        </template>
							        <v-time-picker v-if="input.to_timepicker.show" v-model="input.to_timepicker.value" full-width>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="input.to_timepicker.show = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.dialog_schedule2[index].save(input.to_timepicker.value)">OK</v-btn>
							        </v-time-picker>
							    </v-dialog>
							</v-col>

							<!-- --------- BREAK TYPE -------- -->
						    <v-col>
						    	<v-select :items="break_type" class="elevation-0" placeholder="Select lunch type" dense outlined item-text="name" item-value="id" v-model="input.break_type" :disabled="isSubmit||isDelete">
						    	</v-select>
						    </v-col>
						    <v-col>
							    <v-checkbox v-model="input.night_shift" style="height: auto; margin-top: 0px; padding-left: 50px; position: static; white-space: normal;"></v-checkbox>
							</v-col>

						    <!-- --------- DELETE BUTTON -------- -->
						    <div class="my-5"> 
						    	<v-btn icon small @click="remove_schedule({data:input,index:index})">
						    		<v-icon color="red" small>mdi-close</v-icon>
						    	</v-btn>
						    </div>
				  		</v-row>
					</div>
				</template>

				<v-skeleton-loader v-if="isLoading.break" height="100%" type="article,break"></v-skeleton-loader>
				
				<template v-else>
		        	<!-- -------------------- BREAKS FROM ------------------- -->
					<v-row style="margin-bottom:40px;">
						<label class="ml-3">Break Types</label>
						<a class="ml-3" :class="isLoading.break || isSubmit || isDelete ? 'disable' : ''" @click="add_break()">
							<strong>+ Add</strong>
						</a>
					</v-row>

					<!-- -------------------- BREAKS LABEL ------------------- -->		
					<v-row style="margin-top:-30px;">
						<v-col class="my-3" v-for="(label,key) in breaks_label" :key="label">
							<label class="label-style">{{ label }}</label>
						</v-col>
					</v-row>

					<!-- -------------------- BREAKS FORM ------------------- -->
					<div v-for="(input,index) in break_form">
						<v-row style="margin-top:-30px;">
							
							<!-- ------------ SCHEDULE NAME ------------ -->
							<v-col>
								<v-text-field :rules="[rules.break_name.required]" v-model="input.name" placeholder="Type name here" outlined dense :disabled="isSubmit||isDelete"></v-text-field>
							</v-col>

							<!-- -------------- FROM --------------- -->
							<v-col>
			              		<v-dialog ref="dialog_break1" v-model="input.from_timepicker.show" :return-value.sync="input.from_timepicker.value" persistent width="290px">
							        <template v-slot:activator="{ on }">
										<v-text-field  v-model="input.from = input.from_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on"
										:rules="[rules.time.required]" :disabled="isSubmit||isDelete">
										</v-text-field>
							        </template>
							        <v-time-picker v-if="input.from_timepicker.show" v-model="input.from_timepicker.value" full-width>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="input.from_timepicker.show = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.dialog_break1[index].save(input.from_timepicker.value)">OK</v-btn>
							        </v-time-picker>
							    </v-dialog>
							</v-col>

	              			<!-- -------------- TO --------------- -->
							<v-col>
			              		<v-dialog ref="dialog_break2" v-model="input.to_timepicker.show" :return-value.sync="input.to_timepicker.value" persistent width="290px">
							        <template v-slot:activator="{ on }">
										<v-text-field v-model="input.to = input.to_timepicker.value" dense outlined readonly placeholder="HH:MM" v-on="on"
										:rules="[rules.time.required]" :disabled="isSubmit||isDelete">
										</v-text-field>
							        </template>
							        <v-time-picker v-if="input.to_timepicker.show" v-model="input.to_timepicker.value" full-width>
										<v-spacer></v-spacer>
										<v-btn text color="primary" @click="input.to_timepicker.show = false">Cancel</v-btn>
										<v-btn text color="primary" @click="$refs.dialog_break2[index].save(input.to_timepicker.value)">OK</v-btn>
							        </v-time-picker>
							    </v-dialog>
							</v-col>

							<!-- --------- BREAK TYPE -------- -->
						    <v-col>
						    	<v-select :items="break_fee" class="elevation-0" placeholder="Select break type" dense outlined item-text="text" item-value="value" :rules="[rules.fee.required]" v-model="input.fee" :disabled="isSubmit||isDelete">
						    	</v-select>
						    </v-col>

						    <!-- --------- DELETE BUTTON -------- -->
						    <div class="my-5"> 
						    	<v-btn icon small @click="remove_break({data:input,index:index})">
						    		<v-icon color="red" small>mdi-close</v-icon>
						    	</v-btn>
						    </div>
	              		</v-row>
		        	</div>
	        	</template>

	        	<v-skeleton-loader v-if="isLoading.attendance" height="100%" type="article,break"></v-skeleton-loader>
				
				<template v-else>

		        	<!-- ------------------ ATTENDANCE FROM ----------------- -->
					<v-row style="margin-bottom:40px;">
						<label class="ml-3">Attendance Types</label>
						<a class="ml-3" :class="isLoading.attendance || isSubmit || isDelete ? 'disable' : ''" @click="add_attendance()">
							<strong>+ Add</strong>
						</a>
					</v-row>

					<!-- -------------------- ATTENDANCE LABEL ------------------- -->		
					<v-row style="margin-top:-30px;">
						<v-col class="my-3" v-for="(index,key) in attendance_label" :key="index">
							<label class="label-style">{{ index }}</label>
						</v-col>
					</v-row>

					<!-- ------------------ ATTENDANCE FROM ----------------- -->
					<div v-for="(input,index) in attendance_form">
						<v-row style="margin-top:-30px;" >

							<!-- ------------ SHIFT NAME ------------ -->
							<v-col>
								<v-text-field :rules="[rules.attendance_name.required]" v-model="input.name" placeholder="Type name here" outlined dense :disabled="isSubmit||isDelete">
								</v-text-field> 
							</v-col>

							<!-- -------------- LOG TYPE --------------- -->
							<v-col>
			              		<v-select :items="logtype" class="elevation-0" placeholder="Select log type" dense outlined item-text="text" item-value="value"
	                     		:rules="[rules.log.required]" v-model="input.log_type" :disabled="isSubmit||isDelete"></v-select>
							</v-col>

	              			<!-- -------------- CONDITION --------------- -->
							<v-col>
			              		<v-select :items="condition" class="elevation-0" placeholder="Select condition type" dense outlined item-text="text" item-value="value"
	                     		:rules="[rules.condition.required]" v-model="input.condition" :disabled="isSubmit||isDelete"></v-select>
							</v-col>

							<!-- --------- TIMEFRAME -------- -->
						    <v-col>
						    	<v-select :items="timeframe" class="elevation-0" placeholder="Select timeframe" dense outlined item-text="text" item-value="value"
	                     		:rules="[rules.timeframe.required]" v-model="input.timeframe" :disabled="isSubmit||isDelete"></v-select>
						    </v-col>

							<!-- --------- DELETE BUTTON -------- -->
						    <div class="my-5"> 
						    	<v-btn icon small @click="remove_attendance({data:input,index:index})" >
						    		<v-icon color="red" small>mdi-close</v-icon>
						    	</v-btn>
						    </div>
	              		</v-row>
		        	</div>
		        </template>
	        </v-form>
        </template>
	</div>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import   Errors   from "@/validation/errors";
import { mapActions } from "vuex";
export default {
	mixins:[modal,states],
	data()
	{ 
		return {
			// to check if the field were all valid
			valid: false,

			// to validate the fields 
			rules:rules,

			// errors: new Errors()
			error:new Errors(),

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: { attendance:false, break:false, schedule:false },

			// to check if action is submitting
			isSubmit: false,

			isDelete:false,

			schedule_label:["Shift Name","From","To","Break Type","Night Shift"],

			breaks_label:["Break Name","From","To","Fee"],

			attendance_label:["Attendance Name","Log Type","Condition","Timeframe"],

			quick_schedule_form:{
				from_timepicker:{ show:false, text:"", value:"" },
                to_timepicker:{ show:false, text:"", value:"" }
            },

			schedule_form:[],

        	break_form:[],

        	attendance_form:[],

        	submitted:0,

        	timeframe:[
        		{text:"None",value:"none"},
	        	{text:"From",value:"from"},
	        	{text:"To",value:"to"},
	        ],

        	logtype:[
        		{text:"None",value:"none"},
	        	{text:"Time In",value:"timein"},
	        	{text:"Time Out",value:"timeout"},
        	],
        	
        	condition:[
        		{text:"None",value:"none"},
	        	{text:"On",value:"on"},
	        	{text:"Before",value:"before"},
	        	{text:"After",value:"after"}
        	],
        	
        	break_fee:[
        		{text:"None",value:"none"},
	        	{text:"Paid",value:"paid"},
	        	{text:"Unpaid",value:"unpaid"},
        	],

        	isActive:true,

        	break_type:[{ id:null, name: "No Break" }],

        	transition:"scale-transition"
		}	
	}, 

	mounted()
    {	
    	this.$bus.$on("submit-modal",()=>{
    		this.submit(this.mode);
    	});

		this.isLoading.schedule = true;
    	this.isLoading.break = true;
		this.isLoading.attendance = true;

    	this.initilize();
    },

    beforeDestroy()
    {
    	this.$bus.$off("submit-modal");
    },

    updated()
    {	
    	this.isDelete = this.mode == "delete" ? true : false;
    },

	methods:{

		...mapActions(["getScheduleList","getBreakList","getAttendanceList","setDeleteSchedule","setDeleteBreak","setDeleteAttendance"]),

		initilize()
		{	
			this.getScheduleList();
			this.getBreakList();
			this.getAttendanceList();
		},

		view_more(array, length)
		{	
			this.attendance_form = this.attendance_form.slice(0,length);
		},

		view_all(array)
		{	
			array = array.slice(0,this.attendance_form.length);
		},

		add_schedule() 
		{
            this.schedule_form.push({ 
        		from_timepicker:{ show:false, text:"", value:"" },
                to_timepicker:{ show:false, text:"", value:"" },
                night_shift:0,
	        });
        },

        add_break() 
		{
            this.break_form.push({ 
        		from_timepicker:{ show:false, text:"", value:"" },
                to_timepicker:{ show:false, text:"", value:"" }
	        });
        },

        add_attendance() 
		{
            this.attendance_form.push({ 
        		from_timepicker:{ show:false, text:"", value:"" },
                to_timepicker:{ show:false, text:"", value:"" }
	        });
        },

        remove_schedule(row)
        {	
        	if(!row.data.id && row.index >= 0)
        	{	
        		this.schedule_form.splice(row.index,1);
        	}

        	else
        	{
            	let index = this.schedule_form.indexOf(row.data);
        		this.setDeleteSchedule(row.data).then(()=>{ this.schedule_form.splice(index,1); });
        	}
        },

        remove_break(row)
        {	
        	if(!row.data.id && row.index >= 0)
        	{	
        		this.break_form.splice(row.index,1);
        	}

        	else
        	{
	        	let index = this.break_form.indexOf(row.data);
	        	this.setDeleteBreak(row.data).then(()=>{ this.break_form.splice(index,1); });
        	}
        },

        remove_attendance(row)
        {	
        	if(!row.data.id && row.index >= 0)
        	{	
        		this.attendance_form.splice(row.index,1);
        	}

        	else
        	{
        		let index = this.attendance_form.indexOf(row.data);
        		this.setDeleteAttendance(row.data).then(()=>{ this.attendance_form.splice(index,1); });
        	}
        },

        /*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		submitmode()
		{	
	    	this.isSubmit = true;

			this.$store.dispatch("setUpdateRegisterCalendar",this.data)
			.then((response) => { this.isSubmit = false; })
			.then((response) => { this.close() })
      	},

		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		submit(action = "add")
		{			
			switch(action)
			{	
				case "quick_add": 
					if(this.$refs.form.validate())
					{
						this.isSubmit = true;
						this.$bus.$emit("onSubmit-modal",true);
						this.quick_schedule_form = Object.assign(this.quick_schedule_form,this.data);
						this.$store.dispatch("setCreateAndAssignSchedule",this.quick_schedule_form)
			     		.then((response) => { this.isSubmit = false; this.close(); })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
					}
				break;

				case "add": 
					if(this.$refs.form.validate())
					{	
						this.isSubmit = true;
						this.$bus.$emit("onSubmit-modal",true);
			     		this.$store.dispatch("setUpdateOrRegisterSchedule",this.schedule_form)
			     		.then((response) => { this.submitted = 1; })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })	                  	

	                  	this.$store.dispatch("setUpdateOrRegisterBreak",this.break_form)
			     		.then((response) => { this.submitted = 2;  })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })

	                  	this.$store.dispatch("setUpdateOrRegisterAttendance",this.attendance_form)
			     		.then((response) => { this.submitted = 3;  })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; });
					}
				break;
			} 
      	},
	},

	computed:{

		schedules()
		{
			return this.$store.getters.getScheduleList;
		},

		breaks()
		{
			return this.$store.getters.getBreakList;
		},

		attendances()
		{
			return this.$store.getters.getAttendanceList;
		}
	},

	watch:{

		submitted(value)
		{
			if(value == 3 )
			{
				this.isSubmit = false;
				this.close();
			}
		},

		/*
 		* Resets the field form
 		*
 		* @return void
 		*/ 
 		dialog(value)
 		{
 			if(!value) { this.$refs.form.reset(); }
 		},

 		/*
 		* To set values into a field form when edit/viewing 
 		*
 		* @return void
 		*/ 
 		schedules(value)
 		{	
 			Object.values(value).forEach((value,key)=>{
 				this.schedule_form.push({ 
 					id:value.id,
 					name:value.name,
 					from:value.from,
 					to:value.to,
 					night_shift:value.night_shift,
	        		from_timepicker:{ show:false, text:"", value:value.from },
	                to_timepicker:{ show:false, text:"", value:value.to },
	                break_type:value.breaks.length ? value.breaks[0].id : null
		        });
 			});

 			if(value){ this.isLoading.schedule = false; }
 		},

 		/*
 		* To set values into a field form when edit/viewing 
 		*
 		* @return void
 		*/ 
 		breaks(value)
 		{	 			
 			Object.values(value).forEach((value,key)=>{
 				this.break_form.push({ 
 					id:value.id,
 					name:value.name,
 					from:value.from,
 					to:value.to,
 					fee:value.fee,
	        		from_timepicker:{ show:false, text:"", value:value.from },
	                to_timepicker:{ show:false, text:"", value:value.to }
		        });

		        this.break_type.push({ 
 					id:value.id,
 					name:value.name,
 					from:value.from,
 					to:value.to,
 					fee:value.fee
		        });
 			});

 			if(value){ this.isLoading.break = false; }
 		},

 		/*
 		* To set values into a field form when edit/viewing 
 		*
 		* @return void
 		*/ 
 		attendances(value)
 		{
 			Object.values(value).forEach((value,key)=>{
 				this.attendance_form.push({ 
 					id:value.id,
 					name:value.name,
 					log_type:value.log_type,
 					condition:value.condition,
 					timeframe:value.timeframe,
		        });
 			});

			if(value){ 
				this.isLoading.attendance = false; 
				this.$bus.$emit("onLoading-modal",false);
			}
			else{
				this.isLoading.attendance = false; 
			}
 		},

 		/*
 		* To set values into a true/ false when quick create schedule 
 		*
 		* @return void
 		*/ 
 		mode(value)
 		{
 			this.mode = value == "add" ? true : false;
 		}
	},
}

</script>

<style type="text/css" scoped>

	.label-style{
		font-weight:bold; 
		color:black;
	}
	
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}
	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}

  	.disable{
  		pointer-events:none;
  		cursor: default;
  		color: grey;
  	}

 

	.input-group.checkbox .input-group__input{
	  order: -1;
	}
 
</style>
<style type="text/css">
	 	.v-input .theme--light .v-input--selection-controls .v-input--checkbox{
	  
	}
</style>