export const states = {

	methods:{

		/*
 		* To redirect in original page 
 		* When creating record from other module side 
 		*
 		* @return void
 		*/ 
		redirect(path){
   			if (this.$route.path !== path){ this.$router.push(path) }
		}
	},
} 