
export const state = {
	loading:false,
	table_loading:false,
	column_items:[
		{ text:'Employee ID', type:"employee_id", value:'employee_id', group:1, disabled:false , visible:true },
		{ text:'First Name',  type:"first_name", value:'first_name' , group:1 , disabled:false , visible:true },
		{ text:'Last Name',   type:"last_name", value:'last_name'  , group:1 , disabled:false , visible:true },

		{ text:'Site Name', type:"sites", value:'sites', group:2 , disabled:false , visible:true },
		{ text:'Account Name', type:"accounts", value:'accounts', group:2 , disabled:false , visible:true },
		{ text:'Assignment Name', type:"assignments", value:'assignments', group:2 , disabled:false , visible:true },
		{ text:'Employee Role', type:"employee-roles", value:'employee-roles', group:3 , disabled:false , visible:true },
		{ text:'Schedule', type:"schedules", value:'schedules', group:2 , disabled:false , visible:true },
 
		{ text:'Time In', type:"timein", value:'timein', group:2 , disabled:false , visible:true },
		{ text:'Time Out', type:"timeout", value:'timeout', group:2 , disabled:false , visible:true },
		{ text:'Duration', type:"duration", value:'duration', group:2 , disabled:false , visible:true },

		{ text:'Overtime In (OT in)', type:"overtime_in", value:'overtime_in', group:2 , disabled:false , visible:true },
		{ text:'Overtime Out (OT out)', type:"overtime_out", value:'overtime_out', group:2 , disabled:false , visible:true },
		{ text:'Overtime Status (OT Status)', type:"overtime_status", value:'overtime_status', group:2 , disabled:false , visible:true },
		{ text:'Approved OT', type:"approved_ot", value:'approved_ot', group:2 , disabled:false , visible:true },
		{ text:'Total Overtime', type:"overtime_total", value:'overtime_total', group:2 , disabled:false , visible:true },
		
		{ text:'Leave Status', type:"leave_status", value:'leave_status', group:2 , disabled:false , visible:true },
		{ text:'Remarks', type:"remarks", value:'remarks', group:2 , disabled:false , visible:true },
		
		{ text:'Total Late', type:"total_late", value:'total_late', group:3 , disabled:false , visible:true },
		{ text:'Temperature', type:"temperature", value:'temperature', group:2 , disabled:false , visible:true },
		{ text:'Hours Rendered', type:"hours_rendered", value:'hours_rendered', group:3 , disabled:false , visible:true },

		{ text:'Day Type', type:"day_type", value:'day_type', group:2 , disabled:false , visible:true },
	],

	columns:[],
	headers:[],
	values:{
		list:[],
		pagination:{ page:0, per_page:0 },
		total:0,
	},
	form:{
		title:null,
		status:null,
		column_attributes:{
			keepTemplate:true, 
			thisDay:false,
			output_name:null,
			from:null,
			to:null,
			columns:[],
		},
	},
	editable:false,
	form_cache:{},
	visibility:{
		builder:true,
		preview:true,
		header:{
			type:"add",
		},
	},

	holidays:{}
}