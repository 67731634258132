<template>
	<v-autocomplete 
	v-model="assignments" 
	:readonly="readonly" 
	:disabled="disabled"
	@click="readonly ? onClick($event) : 'javascipt:void(0)'" 
	:append-icon="display_only ? 'mdi-open-in-new' : 'search'" 
	@change="onChangeValue($event)" 
	:placeholder="placeholder" 
	:multiple="multiple" 
	item-text="name" 
	item-value="id" 
	:items="values" 
	:clearable="clearable"
	outlined chips dense >
		<template v-if="display_only" v-slot:selection="{ item, index }">
	        <v-chip v-if="index <= 3">
	          <span>{{ item.name }}</span>
	        </v-chip>
	        <span  v-if="index >= 4"  class="grey--text caption">(+{{ assignment.length - index - 1 }} others)</span>
	    </template>

		<template v-else v-slot:selection="data">
			<v-chip v-bind="data.attrs" :input-value="data.selected" close @click:close="remove(data.item.id)">
				<v-avatar left>
					<v-img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo"></v-img>
				</v-avatar>
				<span>{{ data.item.name }}</span>
			</v-chip>
		</template>

		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
	props:["assignment","placeholder","readonly","multiple","disabled","clearable","display_only"],
	mixins:[modal,states,auth],
    data () {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			isEmpty: false,
			assignments:[],
		}
    },

	created()
	{	
		this.initilize();
	},

	methods:{
		...mapActions("assignment",["getDropdown"]),

		check(data){
		},

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/assignment/index 
 		*/ 
		initilize()
		{	
			if(this.assignment)
			{	
				// select organization with organization id 
				this.assignments = this.assignment;
			}
 		},

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item) 
		{
			if(!this.multiple && typeof this.assignments === "number")
			{
				this.assignments = null;
			}	

			else if(this.multiple && typeof this.assignments === "number")
			{
				this.assignments = null;
			}

			else if(this.multiple && typeof this.assignments === "object")
			{	
				var removeIndex = this.assignments.indexOf(item);
				if(removeIndex >= 0) this.assignments.splice(removeIndex, 1)
			}

			this.$emit("onChanged",this.assignments);
		},

		/*
 		* Change value selected
 		*
 		* @return void
 		*/ 
		onChangeValue(event) 
		{	
			this.$emit("onChanged",event);
		},

		/*
 		* This show the site table view from organization table 
 		* by name of organization 
 		*
 		* @return void 
 		*/  
		onClick(event)
		{	
			this.$emit("redirect",event);
		}
    },

	computed:{
		...mapGetters("assignment",{"values":"getDropdown"}),
	},

    watch:{
    	
    	/*
 		* Loading/Progress when update values 
 		*
 		* @return void
 		*/ 
		isUpdating(val)
		{
			if(val) 
			{
				setTimeout(() => (this.isUpdating = false), 3000)
			}
		},

        /*
 		* Look for value account id as sync
 		*
 		* @return array getsiteList
 		*/ 
        assignments(val)
        {
        	this.$emit('update:assignment', val)
        },

        /*
 		* Watch for value from 'account' props 
 		*
 		* @return void
 		*/ 
        assignment(val)
        {	
        	this.assignments = val;
        }, 
    },
}
</script>