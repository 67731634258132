<template>
	<v-row justify="center">
		<div v-if="dialog">
			<component :is="modal_view"></component>
		</div>
	</v-row>
</template>

<script>
	
import { modal } from "@/mixins/modal"; 
import modal_import from "@/components/Form/Modal/Import/Index.vue";
import modal_site from "@/components/Form/Modal/Site/Index.vue"
import modal_account from "@/components/Form/Modal/Account/Index.vue"
import modal_organization from "@/components/Form/Modal/Organization/Index.vue"
import modal_mobile_admin from "@/components/Form/Modal/MobileAdmin/Index.vue"
import modal_admin from "@/components/Form/Modal/Admin/Index.vue";
import modal_employee from "@/components/Form/Modal/Employee/Index.vue";
import modal_timelog_map from "@/components/Form/Modal/Timelog/Map/Index.vue";
import modal_timelog_logs from "@/components/Form/Modal/Timelog/Logs/Index.vue";
import modal_timelog_time_entry from "@/components/Form/Modal/Timelog/TimeEntry/Index.vue";
import modal_settings from "@/components/Form/Modal/Settings/Index.vue";
import modal_calendar_assignment from "@/components/Form/Modal/CalendarAssignment/Index.vue";
import modal_calendar_dialog from "@/components/Form/Modal/Calendar/Index.vue";
import modal_assignment from "@/components/Form/Modal/Assignment/Index.vue";
import modal_employee_role from "@/components/Form/Modal/EmployeeRole/Index.vue";
import modal_date_picker from "@/components/Fields/Compose/Modal/DatePicker/Index.vue";

export default
{
	mixins:[modal],
	components:{
		"modal-import":modal_import,
		"modal-site":modal_site,
		"modal-account":modal_account,
		"modal-organization":modal_organization,
		"modal-admin":modal_admin,
		"modal-mobile-admin":modal_mobile_admin,
		"modal-employee":modal_employee,
		"modal-timelog-map":modal_timelog_map,
		"modal-timelog-logs":modal_timelog_logs,
		"modal-timelog-time-entry":modal_timelog_time_entry,
		"modal-settings":modal_settings,
		"modal-calendar-dialog":modal_calendar_dialog,
		"modal-calendar-assignment":modal_calendar_assignment,
		"modal-assignment":modal_assignment,
		"modal-employee-role":modal_employee_role,
		"modal-date-picker":modal_date_picker,
	},
	computed: {
	    modal_view()
	    {
	        return this.$store.getters.view;
	    },
    }
}

</script>
