const state = { 
	
	timelogs:{
		list:[],
	},
	daily_timelogs:{
		list:[]
	},
	total_timelogs:{
		list:[],
		headers:[]
	},
	date:[],
	location:"",
}

const getters = {
	
	/*
	* This sets a value of state timelogs.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	getActivityList:state => {
		return state.timelogs.list;
	},
}

const mutations = {

	/*
	* This sets a value of state timelogs.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_ACTIVITY_LIST:(state,list) => {
		state.timelogs.list = list;
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getActivityLogs:(context) => {
		axios.get(`/activities`)
		.then((response) => { context.commit('SET_ACTIVITY_LIST',response.data); })
		.catch((errors) => console.log(errors) );
	},

	/*
	* Get/Fetch mobile admin list
	*
	* @return string
	*/	
	setSearchActivity(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/activities/search",{ params:{ keyword:keyword } })
			.then((response)=>{ context.commit('SET_ACTIVITY_LIST',response.data); resolve(response)})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
}

export const activity  = {
	state,
	getters,
	mutations,
	actions
}