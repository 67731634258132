<template>
	<v-data-table :headers="headers" :items="values" :footer-props="{ 'items-per-page-options': [10, 50, 100] }" :loading="page.loading" loading-text="Loading" :options.sync="paginations" :server-items-length="totalItems" class="elevation-1 mx-2 pa-4">

      <!-- ----------------- LOGTYPE ----------------- -->
      <template v-slot:item.attendance="{ item }">
         <v-chip label>
            <span style="text-align:center;"> {{ item.attendance }}</span>
         </v-chip>
      </template>

      <!-- --------------- DEDUCTION ---------------- -->
      <template v-slot:item.deduction="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.deduction }}
         </span>
      </template>

      <!-- ------------- EMPLOYEE ID  --------------- -->
      <template v-slot:item.employee_id="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.employee_id }}
         </span>
      </template>

      <!-- -------------- FIRST NAME ---------------- -->
      <template v-slot:item.firstname="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.firstname ? item.firstname : '-' }}
         </span>
      </template>
      
      <!-- -------------- LAST NAME ----------------- -->
      <template v-slot:item.lastname="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.lastname ? item.lastname : '-' }}
         </span>
      </template>
      
       <!-- ----------- FIRST TIME IN --------------- -->
      <template v-slot:item.timein="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.timelogs && item.timelogs.first_time_in != 0 ? format(item.timelogs.first_time_in,"time","h:mm a") : 0 }}
         </span>
      </template>

      <!-- -------------- LAST TIME OUT ------------- -->
      <template v-slot:item.timeout="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.timelogs && item.timelogs.last_time_out != 0 ? format(item.timelogs.last_time_out,"time","h:mm a") : 0 }}
         </span>
      </template>

      <!-- --------------- DURATION ----------------- -->
      <template v-slot:item.duration="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.duration ? item.duration : 0 }}
         </span>
      </template>

      <!-- ----------------- LATE ------------------ -->
      <template v-slot:item.late="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.late ? item.late : 0 }}
         </span>
      </template>

      <!-- --------------- UNDERTIME --------------- -->
      <template v-slot:item.undertime="{ item }">
         <span v-bind:style="item.manual_time_entry ? 'color:red' : '' ">
            {{ item.undertime ? item.undertime : 0 }}
         </span>
      </template>

      <!-- -------------- SCHEDULE ----------------- -->
      <template v-slot:item.schedule="{ item }">
         <v-select class="mt-5" outlined dense :value="item.schedule ? item.schedule.id : 0" :items="schedules" ref="schedule" @change="setScheduleForm(item,$event)" :disabled="current_date > date ? true :false">
         </v-select>
      </template>  

	</v-data-table>
</template>
<script>
	 
import { column_filter } from "@/mixins/filter/column";
import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { auth } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
   mixins:[column_filter,auth,modal,excel],
   data(){
      return {
         headers:[
            { text: "ATTENDANCE", value:"attendance", visible:true },
            { text: "DEDUCTION",  value:"deduction", visible:true },
            { text: "EMPLOYEE ID", value:"employee_id", visible:true }, 
            { text: "FIRST NAME", value:"firstname", visible:true },
            { text: "LAST NAME", value:"lastname", visible:true },
            { text: "FIRST TIME IN", value:"timein", visible:true },
            { text: "LAST TIME OUT", value:"timeout", visible:true },
            { text: "SCHEDULE", value:"schedule", visible:true },
            { text: "HOURS RENDERED", value:"duration", visible:true },
            { text: "LATE", value:"late", visible:true },
            { text: "UNDERTIME", value:"undertime", visible:true },
            { text: "OVERTIME", value:"overtime.total", visible:true },

         ],
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         date: moment(new Date()).format("YYYY-MM-DD"),
         current_date:moment().format('YYYY-MM-DD'),
         items:[
            { text:"No Schedule", value:0 }
         ],
         form:"",
         page:{
            page:1,
            itemsPerPage:10,
            loading:false
         },
         initializing:false
      } 
   },
   
   beforeMount()
   {
      this.AuthRoleAllowed("Admin");
      this.initialize();
   },
   
   beforeDestroy()
   {  
      this.$bus.$off("export-excel-daily-timelogs-report");
      this.$bus.$off("attendance-date-picker");
   },

   methods:{
      
      ...mapActions("attendance",["getAttendance"]),

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/Timelogs/Totals/index 
      */ 
      initialize()
      {     
         this.initializing = true;

         let filter = this.$store.getters.getFilter;

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            this.date = filter.date;
         }

         // set value null
         this.$bus.$emit("clear-value",null);

         // get the daily time report list by date 
         this.setAttendanceValues(this.page,{ date:this.date });

         // get the schedule list 
         this.$store.dispatch("getScheduleList");
        
         // set value for date picker component
         this.$bus.$emit("set-date-picker",this.date);

         // to export the records into an excel file
         this.$bus.$on("export-excel-daily-timelogs-report",()=> this.export_exel() );
         
         // to set the date using Vue Event Bus
         this.$bus.$on("attendance-date-picker",(value)=>{ 
            this.filterPayload(value); // to retain the date selected 
            this.setAttendanceValues(this.page,value);
         });
      },

      filterPayload(values)
      {  
         this.$store.dispatch("setFilter",values);
      },

      /*
      * This sets the values for attendance fetching 
      *
      * @return void 
      */
      setAttendanceValues(page, date)
      {  
         // set new date property 
         this.date = date.date;

         // loading for table 
         this.page.loading = true;

         Object.assign(this.page,page);

         // set date property to "page" value 
         let payload = Object.assign(page,date);

         payload = Object.assign(payload,this.getFiltersValues());

         // this set the header column by filtering 
         this.setTableFilter();
         
         // set fetch with dates 
         this.getAttendance(payload).then(response=>{ 
            this.initializing = false;
            this.page.loading = false;
         });
      },

      /*
      * This sets the values from filters site and account on header 
      *
      * @return void 
      */ 
      getFiltersValues()
      {
         let filter = this.$store.getters.getFilter;
         let value = {};

         if(Object.prototype.hasOwnProperty.call(filter,"site_id"))
         {
            Object.assign(value,{ site_id:filter.site_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"account_id"))
         {
            Object.assign(value,{ account_id:filter.account_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            Object.assign(value,{ date:filter.date });
         }

         return value;
      },

      /*
      * This set the form for changes of schedule
      *
      * @return void 
      */ 
      setScheduleForm(data,schedule)
      { 
         this.edit_item({ date:this.date, employee_id:data.id, schedule_id:schedule });
      },

      /*
      * This show the modal view for edit action
      *
      * @return void 
      */  
      edit_item(data)
      {     
         this.$store.dispatch("modal",{ title:"Edit Employee Schedule", text:this.message(data), data:data, mode:"edit", view:"modal-calendar-dialog" });
      },

      /*
      * This show the message for modal view for edit action of schedule
      *
      * @return string 
      */  
      message(value)
      {
         if(moment().add(-1,'d').format('YYYY-MM-DD') == value.date)
         {
            return "You are about to change previous schedule. Would you like to proceed?";
         }
         
         else if(moment().format('YYYY-MM-DD') == value.date)
         {
            return "You are about to change today's schedule. Would you like to proceed?";
         }

         else if(moment().add(+1,'d').format('YYYY-MM-DD') == value.date)
         {
            return "You are about to change tommorows schedule. Would you like to proceed?";
         }

         else 
         {
            return "You are about to change other schedule. Would you like to proceed?";
         }
      },

      /*
      * export date into an excel file 
      *
      * @return void
      */
      export_exel()
      {
         this.export_attendance_today_report(this.values)
      },

      /*
      * to set timestamp/date into format date with 
      *
      * @parameter value, the date
      * @parameter type, date only | time
      * @parameter format 
      *
      * @return string date
      */
      format(value,type,format)
      {
         switch(type)
         {
            case "time": return moment(value).format(format);  break;
            case "date": return moment(value).format(format); break;
         }
      },

      /*
      * to set log type with design 
      *
      * @parameter value, the date
      * @return string object
      */
      logtype(value)
      {
         switch(value)
         { 
            case "present": return { color:"#E8F5E9" ,  text_color:"#5EAF59", format:"Present"}; break;
            case "undertime": return { color:"#FFD4BB" , text_color:"#FF8101", format:"Undertime" }; break;
            case "late": return { color:"#F7CBCB" , text_color:"#CA0000", format:"Late"}; break;
            case "absent": return { color:"#E0E0E0" , text_color:"#757575", format:"Absent"}; break;
         }
      },

      value(data)
      {
         return data.schedule ? data.schedule.id : 0;
      }
   },

   computed:{ 

      ...mapGetters("attendance",{values:"getAttendanceList", totalItems:"totalItems", pagination:"pagination"}),

      /*
      * Array values for schedule select dropdown
      *
      * @return array getOrganizationList
      */
      schedules()
      {  
         let items = [
            { text:"No Schedule", value:0 }
         ];
         
         this.$store.getters.getScheduleList.map((data)=>{
            items.push({ text: `${data.name}, ${ moment(data.from,"hh:mm:ss").format("hh:mm A") } - ${moment(data.to,"hh:mm:ss").format("hh:mm A")}`, value:data.id});
         });

         return items;
      },

      paginations:{
         get()
         {
            return this.pagination;
         },
         set(value)
         {  
            if(!this.initializing)
            {  
               this.setAttendanceValues(value,{date:this.date});
            }
         }
      }
   },
}
</script> 

<style type="text/css">
   .v-chip-label{
      width:90px;
      display:block;
      text-align:center;
   }
</style>