<template>
	<main>
		<section class="mx-5 d-flex justify-space-between align-center">
			<h2>Made Recently</h2>
			<div style="position:relative; right:20px;">
				<FilterSort :disabled="loading"></FilterSort>
			</div>
		</section>
		<v-skeleton-loader v-if="loading" height="100%" type="table"/>
		<v-simple-table v-else class="mx-5 my-5" width="100%">
			<template v-slot:default>
				<thead class="ml-2 text-left">
					<th>Title</th>
					<th>Last Modified</th>
					<th>Edited By</th>
					<th></th>
				</thead>
				<tbody>
					<template v-for="(value,key ) in grouped">

						<template v-if="value.type == 'week'">
							<tr class="text-left">
								<h2>This Week</h2>
							</tr>	
						</template>

						<template v-if="value.type == 'month'">
							<tr class="text-left">
								<h2>This Month</h2>
							</tr>	
						</template>

						<template v-if="value.type == 'ealier'">
							<tr class="text-left">
								<h2>This Earlier</h2>
							</tr>	
						</template>

						<tr v-for="(value, key2) in value.value">
							<td class="text-left" >
								<span>{{ value.title }}</span>
								<template v-if="value.status == 2">
									<span>&nbsp; [DRAFT]</span>
								</template>
							</td>
							<td class="text-left">{{ format(value.updated_at,"date","MMM DD, YYYY") }}</td>
							<td class="text-left">{{ value.user && value.user.first_name ? value.user.first_name : null }}</td>
							<td>
								<v-btn @click="setModal({ type:'edit', values:value})" icon>
									<v-icon color="black">mdi-pencil</v-icon>
								</v-btn>
								
								<v-btn  @click="setModal({ type:'delete', values:value})" icon>
									<v-icon color="black">mdi-delete</v-icon>
								</v-btn>
							</td>
							<td class="text-left">
								<v-btn icon>
								</v-btn>
							</td>
						</tr>

					</template>
				</tbody>
			</template>
		</v-simple-table>
	</main>
</template>
<script>

	import { mapActions, mapGetters } from "vuex";
	import FilterSort from "@/modules/QueryBuilder/Template/components/TemplateListFilter";
	export default {

		components:{
			FilterSort
		},

		props:{
			items:{
				type:Object,
				required:true
			},
			loading:{
				type:Boolean
			}
		},

		data(){
			return {
				grouped:[],
			}
		},

		methods:{
			...mapActions('query_builder_modal', ["setModal","setOpen","setClose"]),

			/*
			* to set timestamp/date into format date with 
			*/
			format(value,type,format){
				switch(type){
					case "time": return moment(value).format(format);  break;
					case "date": return moment(value).format(format); break;
				}
			},

			/*
			* to set group the data by each created date 
			*/
			groupBy(date,range,type){
				switch(range)
				{
					case "within": return moment(date).isSame(new Date(),type); break;
					case "after": return moment(date).isAfter(new Date(),type); break;
				}
			},
		},
		watch:{
 			items(value){
				let week   = [];
				let month  = [];
				let ealier = [];

				value.forEach((value,key)=>{
					if(this.groupBy(moment(value.created_at).format("YYYY-MM-DD"),"within","week")){
						week.push(Object.assign(value,{ edit:0 }));
					}
					if(this.groupBy(moment(value.created_at).format("YYYY-MM-DD"),"within","month")){
						month.push(Object.assign(value,{ edit:0 }));
					}
					if(this.groupBy(value.created_at,"after","month")){
						ealier.push(Object.assign(value,{ edit:0 }));
					}
				});
				
				this.grouped = [{ type:"week","value":week },{ type:"month","value":month },{ type:"ealier","value":ealier }];
 			}
		},
	}
</script>