const state = { 
	list:[]
}

const getters = {

	/*
	* GET list
	* to get the list of account
	*/	
	getRecords:state => {
		return state.list;
	}
}

const mutations = {

	/*
	* GET list
	* to get the list after fetching
	*/	
	SET_LIST:(state,list)=>{

		let items = [{ name:"No Assignment", id:-1, disabled:false }];

		list.map((data)=>{
			let { id, name } = data;
			items.push({id, name, disabled:false });
		});

		state.list = items;
	},

	/*
	* set column attribute selected as disabled 
	* to prevent selection of already selected item
	*/
	SET_DISABLE_SELECTED_ITEM(state,value){
		let val = state.list.findIndex(data=>data.id == value.id);
		if(val > -1){
			state.list[val].disabled = value.boolean;
		}
	},
}

const actions = {
	
	/*
	* Get/Fetch accounts list
	*
	* @return string
	*/	
	fetchRecords(context){	
		return new Promise((resolve,reject)=>{ 
			axios.get("/assignments")
			.then((response)=>{ 
				context.commit('SET_LIST',response.data); 
				resolve(response); 
			})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	*  to set the column items availability
	*  to restrict the reselection of an item 
	*/
	setColumnAccessability(context,data={}){
		context.commit("SET_DISABLE_SELECTED_ITEM",data)
	},
}

export default  {
	namespaced:true,
	name:"assignments",
	state,
	getters,
	mutations,
	actions
}  