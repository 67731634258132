export const calendar_filter = {

	methods:{

		/*
		* This sets the values from filter
		*
		* @return void 
		*/ 
		setFilters(values){	
			return new Promise((resolve,reject)=>{
    			if(values){
    				this.$store.dispatch("setFilter",values);
	        		resolve(values);
    			}
    		});
      	},

      	/*
		* This clear the values from filter
		*
		* @return void 
		*/ 
      	clearFilters(){
      		this.$store.dispatch("clearFilter");
      	}
	},

	computed:{
		/*
		* This gets the values from filter
		*
		* @return void 
		*/ 
		getFilters()
		{
			return this.$store.getters.getFilter;
		},
	}
}