<template>
	
   <!-- -------------- MODAL: MAP ------------------- -->
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px" @keydown.esc="close()">
         <v-card :loading="isSubmit">

            <!-- -------------------- CARD HEADER ------------------- -->
            <v-card-title>
               <span class="font-weight-bold">{{ title }}</span>
               <v-spacer></v-spacer>
               <span  class="caption mr-3">Press</span>
               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
               <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <!-- -------------------- CARD CONTENTS ------------------- -->
            <v-card-text>
              	<v-form v-model="valid" ref="form" @keyup.native.enter="submit()">
	            	<v-row>
	            		<!-- -------------------- PERSON NAME -------------------- -->
	              		<v-col class="mr-4">
		              		<label class="d-block text-left font-weight-bold label my-1"> Person </label>

		              		<template v-if="mode == 'edit' || mode == 'delete'">
		              			<v-text-field v-model="fullName" :disabled="mode == 'delete' || isSubmit" readonly dense outlined/>
		              		</template>
		              		
		              		<template v-if="mode == 'add'">
		              			<FilterEmployees 
		              			:disabled="mode =='delete' || isSubmit" 
		              			:value.sync="employee_record" 
		              			:rules="[rules.person_name.required]"/>
							</template>

					    </v-col>

	            		<!-- -------------------- LOG TYPE -------------------- -->
	            		<v-col>
							<label class="d-block text-left font-weight-bold label my-1"> Log Type </label>
							<v-radio-group v-model="form.type" style="margin-top:-2px;"   
							:disabled="mode == 'delete' || isSubmit" :mandatory="true" row>
							<v-radio label="Time In" selected value="timein"  color="#0057AD"></v-radio>
							<v-radio label="Time Out" value="timeout" color="#0057AD"></v-radio>
							</v-radio-group>
	            		</v-col>
	            	</v-row>

	            	<v-row>

	            		<!-- -------------------- DATE PICKER -------------------- -->
	              		<v-col class="mr-3">
		              		<label class="d-block text-left font-weight-bold label my-1">Date</label>
							<v-menu ref="menu" v-model="datepicker.show" persistent transition="scale-transition" max-width="230px" min-width="290px">
								<template v-slot:activator="{ on }">
									<v-text-field v-model="datepicker.text" v-on="on"readonly dense outlined placeholder="Choose date"
									:rules="[rules.date.required]" :disabled="mode == 'delete' || isSubmit" ></v-text-field>
								</template>
								<v-date-picker v-model="datepicker.value" no-title scrollable ></v-date-picker>
							</v-menu>
	              		</v-col>

	            		<!-- -------------------- TIME PICKER -------------------- -->
	              		<v-col class="mr-3">
			              	<label class="d-block text-left font-weight-bold label my-1">Time</label>
		              		<v-dialog ref="dialog" v-model="timepicker.show" :return-value.sync="timepicker.value" persistent width="290px">
						        <template v-slot:activator="{ on }">
									<v-text-field v-model="timepicker.text = timepicker.value" dense outlined readonly placeholder="Choose time" v-on="on"
									:rules="[rules.time.required]" :disabled="mode == 'delete' || isSubmit">
									</v-text-field>
						        </template>
						        <v-time-picker v-if="timepicker.show" v-model="timepicker.value" full-width use-seconds>
									<v-spacer></v-spacer>
									<v-btn text color="primary" @click="timepicker.show = false">Cancel</v-btn>
									<v-btn text color="primary" @click="$refs.dialog.save(timepicker.value)">OK</v-btn>
						        </v-time-picker>
						    </v-dialog>
	              		</v-col>

	            	</v-row>	
            	</v-form>
            </v-card-text>

            <v-divider></v-divider>

	    	<!-- -------------------- CARD ACTIONS ------------------- -->
			<v-card-actions class="ma-4">
               <!-- ------------- DELETE OR DISCARD ------------- -->
               <v-btn dark class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
               @click="mode == 'delete' ? submit(mode) : close()"
               :color="mode == 'delete' ? 'red' : '#0057AD'"
               :loading="mode == 'delete' ? isSubmit : false">
               {{ mode == 'delete' ? 'Delete':'Discard' }} 
               </v-btn>

               <v-spacer></v-spacer>

               <!-- ------------- CANCEL OR SUBMIT ------------- -->
               <v-btn dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
               @click="mode == 'delete' ? close() : submit(mode)"
               :loading="mode == 'delete' ? false  : isSubmit">
               {{ mode == 'delete' ? 'Cancel':'Submit' }}
               </v-btn> 
            </v-card-actions>

        </v-card>
        <v-divider></v-divider>
      </v-dialog>
   </v-row>

</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";

import FilterEmployees from "@/components/Form/Modal/Timelog/TimeEntry/components/FilterEmployees.vue";

export default {
   mixins:[modal],
   components:{
   	FilterEmployees
   },
   data(){ 
      return {
			rules:rules,
			form:{ type:"timein", source_id:1, latitude:"0", longitude:"0", name: "Manual Time Entry" },
			datepicker:{ show:false, text:"", value:"" },
			timepicker:{ show:false, text:"", value:"" },
			employee_record:"",
			isSubmit:false,
	        valid:false,
        }
    }, 

	created(){
		this.initilize();
	},
	
    methods:{

    	...mapActions("recent",['getRecent','setCreateLog','setUpdateLog','setDeleteLog']),

		initilize(){
			if(this.data){
				if(Object.hasOwnProperty.call(this.data,"timestamp")){
					this.timepicker.value = moment(this.data.timestamp).format("hh:mm:ss");
					this.datepicker.value = moment(this.data.timestamp).format("YYYY-MM-DD")
				}

				if(Object.hasOwnProperty.call(this.data,"type")){
					this.form.type = this.data.type;
				}

				if(Object.hasOwnProperty.call(this.data,"employee_id")){
					this.form.employee_id = this.data.employee_id;
				}

				if(Object.hasOwnProperty.call(this.data,"id")){
					this.form.id = this.data.id;
				}
			}
		},

		format(value,type="",format){
			switch(type){
				case "time": return moment(value).format(format);  break;
				case "date": return moment(value).format(format); break;
			}
		},

		submit(){  	
			Object.assign(this.form,{ 
					 date:`${this.datepicker.value}`,
				timestamp:`${this.datepicker.value} ${this.timepicker.value}`,
			});

			if(this.$refs.form.validate()){	
				this.isSubmit = true;
				if(this.mode == "add") this.addDTRLog();
				else if(this.mode == "edit") this.updateDTRLog();
				else if(this.mode == "delete") this.deleteDTRLog();
			}
		},

		addDTRLog(){
			this.setCreateLog(this.form)
			.then((response)=>this.close())
			.then((response)=>this.redirect("/timelogs/recent"))
			.catch((errors) =>this.isSubmit = false)
            .catch((errors) =>this.error.record(errors.response.data))
		},

		updateDTRLog(){
			this.setUpdateLog(this.form)
			.then((response)=>this.close())
			.then((response)=>this.redirect("/timelogs/recent"))
			.catch((errors) =>this.isSubmit = false)
            .catch((errors) =>this.error.record(errors.response.data))
		},

		deleteDTRLog(){
			this.setDeleteLog(this.form)
			.then((response)=>this.close())
			.catch((errors) =>this.isSubmit = false)
            .catch((errors) =>this.error.record(errors.response.data))
		},

		setTime(value){
			this.timepicker.show  = false;
			this.timepicker.value = value
		},
    },

    computed:{
    	fullName(){
			if(this.data && this.data.employee) return `${this.data.employee.firstname} ${this.data.employee.lastname}`;
		}
    },

    watch:{

		"datepicker.value"(value){
			this.datepicker.text = this.format(value,'date','LL');
		},

		"timepicker.value"(value){
			this.timepicker.text = this.format(value,'time','hh:mm a');
		},

		dialog(value){
			if(value||!value){ this.$refs.form.reset(); this.isSubmit = false; }
		},

		employee_record(values){
			if(values){
				let { organization_id, employee_id, id } = values;
				Object.assign(this.form,{ organization_id, employee_id, employee_table_id:id });
			}
		}
    },
}

</script>

<style type="text/css" scoped>

   .custom-card-button{
      height:40px;
      width:150px;
   }

   .label {
      color:black;
      font-size: 16px;
   }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
   }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }
</style>

