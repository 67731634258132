<template>
	<v-container>
		<template >
			<v-row align="center" justify="center" no-gutters>
				<v-col :cols="4">
					<v-btn icon class="nav-icon" @click="onPrevious" :disabled="no_previous"> <v-icon>mdi-chevron-left</v-icon></v-btn>
				</v-col>
				<v-col :cols="2">
					<span>{{ employees_name }}</span> 
				</v-col>
				<v-col :cols="4">
					<v-btn icon class="nav-icon" @click="onNext" :disabled="limited_next"> <v-icon>mdi-chevron-right</v-icon> </v-btn>
				</v-col>
			</v-row>
			<v-row align="center" justify="center" no-gutters>
				<v-col class="col-auto">
					<span class="recipient-text"> Recipient </span>
				</v-col>
			</v-row>
		</template>
	</v-container>
</template>

<script>
	import { mapStates, mapGetters, mapActions } from "vuex";
	
	export default {

		data(){
			return {
				item_index:0,
				item_index_count:0,
				employees:[],
				cache_employees:[],

				assignments:[],
				timeout:null,
			}
		},

		async created(){		
 			this.getEmployees().then(response=>{
 				this.employees = response.data;
 				this.cache_employees = response.data;
 				if(this.form.employee_role_id){
 					this.onEmitValue();
 					this.getEmployeesByRoleId(this.form.employee_role_id);
 				}
 				this.onEmitValue();
 			});
		},

		methods:{  
 			...mapActions("message",["getAssignment","getEmployees"]),

			/*
			* set item index on previous data 
			*
			* @return void
			*/
			onPrevious()
			{	
				this.item_index--;
				if(this.item_index <= -1) this.item_index = 0;

				if(this.employees[this.item_index]){
					this.onEmitValue();
				}
			},

			/*
			* set item index on next data 
			*/
			onNext()
			{	
				this.item_index++;
				if(!this.item_index == this.item_index_count) this.item_index = this.item_index_count - 1;

				if(this.employees[this.item_index]){
					this.onEmitValue();
				}
			},

			onEmitValue()
			{
				if(this.employees[this.item_index]){
					this.fetchAssignments().then(response=>{
						let text  = "";
						if(response.length > 0){	
							response.forEach((value,index)=>text+=`${index+1}. ${value.name} \n`);
						}
						this.$emit("update:assignment",text);
					})
				}
			},

			fetchAssignments(){
				return new Promise((resolve,reject)=>{
					if(this.form.date){
						let _self = this;
						clearTimeout(this.timeout);
						this.timeout = setTimeout(function(){
							_self.getAssignment({ ..._self.employees[_self.item_index], date:_self.form.date })
							.then(response=>resolve(response.data))
						},500);
					}
				})
			},

			/*
			* To parse and get assignment values 
			*/
			getAssignmentsIntoAnOrderList()
			{
				let text  = "";
			
				if(this.assignments.length > 0)
				{	
					this.assignments.forEach((value,index)=>text+=`${index+1}. ${value} \n`);
				}

				return text;
			},
            
            /*
			* To parse the object into a text 
			*/
			parseArrayObject(array,property)
			{     
				return (array && array.length > 0) ? array.map(data=>data.name) : [];
			},

			/*
			* To get employees based on their assigned employee roles
			*/
			getEmployeesByRoleId(id = null)
			{
				const filtered = [];

				this.employees.map(data=>data.employee_role).forEach((value,key)=>{
					if(value.length > 0){ 
						if(value.map(data=>data.id).includes(id)){
							filtered.push(key); 
						} 
					}
				});	

				// set filter base on the array pushed included employee role id 
				this.employees = filtered.map(data=>this.employees[data]);

				// when no employee in filter found 
				// this makes employees leaved empty
				if(!id) {
					this.employees = this.cache_employees;
				}

				this.item_index = 0;        
			}
		},

		computed:{
			...mapGetters("message",{ "form":"getForm" }),

			employees_name(){	
				if(this.employees && this.employees.length > 0 ){
					return Object.hasOwnProperty.call(this.employees[this.item_index],"firstname") ? this.employees[this.item_index].firstname : null;
				}
			},

			limited_next(){
				return this.item_index == this.item_index_count - 1 || !this.employees.length;
			},

			no_previous(){
				return this.item_index == 0 || !this.employees.length;
			}
		},

		watch:{
			"form.employee_role_id"(value){
				this.getEmployeesByRoleId(value);
			},

			"form.date"(value){
				this.onEmitValue();
			},

			item_index_count(){
				return this.employees.length;
			},

			employees(value){
				this.item_index_count = this.employees.length;
			},
		}
	}
</script>

<style scoped>
	.nav-icon{
		color:#000!important;
	}
</style>