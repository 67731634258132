<template>
  
	<v-data-table :headers="headers" :items="values" :loading="page.loading" loading-text="Loading" :item-per-page="10" class="elevation-1 mx-2 pa-4">
	
		<!-- -------------- LOGO ----------------- -->
		<template v-slot:item.organization_logo="{ item }">
			<v-avatar size="35px">
				<img :src="item.organization == null || item.organization.logo == null ? default_logo : item.organization.logo"/>
			</v-avatar>
			{{ item.organization ? item.organization.short_name : '' }}
		</template>

		<!-- -------------- PHOTO ----------------- -->
		<template v-slot:item.avatar="{ item }">
			<v-avatar size="35px">
				<img alt="Photo" :src="item.avatar ? item.avatar : default_avatar ">
			</v-avatar>
		</template>

		<!-- -------------- FACE BIO ----------------- -->
		<template v-slot:item.facebio="{ item }">
			<v-avatar size="35px">
				<img alt="FaceBio" :src="item.facebio ? item.facebio : default_facebio ">
			</v-avatar>
		</template>

		<!-- -------------- ROLE --------------- -->
		<template v-slot:item.role="{ item }">
			<v-chip  label outlined>
				<span style="color: black;">{{ item.role }}</span>
			</v-chip>
		</template>

		<!-- -------------- EMPLOYEE --------------- -->
		<template v-slot:item.employee_type="{ item }">
			<v-chip style="width:100px; display:block; text-align: center;" label outlined>{{ item.employee_type }}</v-chip>
		</template>

		<!-- -------------- ACTIONS ----------------- -->
		<template v-slot:item.action="{ item }" >
	        <v-menu bottom left transition="scale-transition">
            	<template v-slot:activator="{ on }">
			        <v-btn slot="activator" v-on="on" icon>
			            <v-icon>mdi-dots-horizontal</v-icon>
			        </v-btn>
		    	</template>
	            <v-list>
	            	<v-list-item  @click="edit_item(item)" >
				        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
				        <v-list-item-title> Edit </v-list-item-title>
					</v-list-item>
					<v-list-item  @click="delete_item(item)" >
						<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
						<v-list-item-title> Delete </v-list-item-title>
					</v-list-item>
	            </v-list>
	        </v-menu>
        </template>

	</v-data-table>
</template>
<script>

import { rules } from "@/validation/rules/index";
import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { auth  } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
	name:"index",
	mixins:[modal,excel,auth],
	data(){
		return {
			headers:[
				{ text:"ORGANIZATION", value:"organization_logo", visible:true },
				{ text:"PHOTO", value:"avatar", visible:true },
				{ text:"USER ID", value:"id", visible:true },
				{ text:"EMPLOYEE ID", value:"employee_id", visible:true },
				{ text:"ROLE", value:"role", visible:true },
				{ text:"FIRST NAME", value:"firstname", visible:true },
				{ text:"MIDDLE NAME", value:"middlename", visible:true },
				{ text:"LAST NAME", value:"lastname", visible:true },
				{ text:"SUFFIX", value:"suffix", visible:true },
				{ text:"EMAIL ADDRESS", value:"email_address", visible:true },	 
				{ text:"ACTIONS", value:"action", visible:true },
			],
			default_facebio:require('@/assets/images/facebio/default-facebio.jpg'),
			default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
			default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			form:{}, 
			page:{
				page:1,
				itemsPerPage:10,
				loading:false
			},
			initializing:false
		}
	},


	mounted()
	{
		this.$bus.$emit("set-column-filter-values",this.headers)
	},

	async created()
	{		
		this.initialize();

		let array = this.headers;

		this.$bus.$on("column-filter",(value)=>{
			if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
			else { this.headers.push(array[value.index]); }
		});
	},
	
	beforeDestroy()
    {
    	this.$bus.$off("export-excel-mobile-admin");
    	this.$bus.$off(`mobile_admins-search`);
    },

	methods:{

		...mapActions(["getMobileAdminList","setSearchMobileAdmin"]),

		/*
 		* This initilize the value for table view
 		*
 		* @return array getEmployeeList
 		*/ 
		initialize()
		{	
			// get values with or without query parameter 
        	this.fetch();

			// to export the records into an excel file 
        	this.$bus.$on("export-excel-mobile-admin",()=> this.export_exel());

        	// search for values from API 
        	this.$bus.$on(`mobile_admins-search`,(value)=>this.search(value));
		},
		

		/*
 		* This fatch values with or without query parameter
 		*
 		* @return array /store/modules/organization/index 
 		*/	
		fetch()
		{	
			let query = this.$route.query; 
			this.getMobileAdminList(query);
		},

		/*
 		* This do search on API value for table view
 		*
 		* @return array /store/modules/account/index 
 		*/ 
		search(value)
		{	
			this.page.loading = true;

			this.setSearchMobileAdmin(value).then(response=>{ 
				this.page.loading = false;
			})	
		},

		/*
		* export date into an excel file 
		*
		* @return void
		*/
		export_exel()
		{
			this.export_mobile_admin(this.values)
		},

		/*
 		* This show the modal view for edit action
 		*
 		* @return void 
 		*/  
		edit_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Edit Mobile Admin", data:data, mode:"edit", view:"modal-mobile-admin" });
		},

		/*
 		* This show the modal view for delete action
 		*
 		* @return void 
 		*/  
		delete_item(data)
		{	
			this.$store.dispatch("modal",{ title:"Delete Mobile Admin", data:data, mode:"delete", view:"modal-mobile-admin" });
		},

		organization(item)
		{
			if(!item.organization && !item.organization.logo){
				return this.default_facebio;
			}
			
			else {
				return item.organization.logo;
			}
		},
	},
	
	computed:{
		
		/*
 		* Get Array values for table view
 		*
 		* @return array getEmployeeList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getMobileAdminList;
 		},
	}
}

</script>

