<template>
    <div class="text-center">
	    <v-menu top :close-on-content-click="closeOnContentClick">
	    	<template v-slot:activator="{ on, attrs }">
		        <v-text-field v-on="on" value="Columns" readonly dense outlined></v-text-field>
		    </template>
			<v-list class="overflow-y-auto" max-height="500" dense>
				<v-list-item v-for="(item, index) in items" :key="index" class="mt--3"> 
 
					<v-list-item-avatar>
						<v-switch v-model="item.visible" @change="filter({ index:index, visible:$event })"></v-switch>
			        </v-list-item-avatar>

			        <v-list-item-content>
			        	<v-list-item-title v-text="item.text"></v-list-item-title>
			        </v-list-item-content>

				</v-list-item>
			</v-list>
	    </v-menu>
    </div>
</template>

<script>

export default {

    data() 
    {
    	return {
		  	items:[{ title:'None', visible:false }],
      		closeOnContentClick: false,
		}
    },

	created()
	{	
		this.$bus.$on("set-column-filter-values",(value)=>{ this.items = value; })
	},

	methods:{

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/site/index 
 		*/ 
		filter(data)
		{	
			this.$bus.$emit("column-filter",data);
 		},
    },
}
</script>