const state = { 
	table:{
		list:[],
		pagination:{ page:0, per_page:0 },
		total:0,
	},

	dropdown:{ list:[] }
}

const getters = {
	
	/*
	* This sets a value of state table list
	*
	* @param state  
	*
	* @return void  
	*/
	getList:state => {
        return state.table.list;
	},

	/*
	* This sets a value of state dropdown list
	*
	* @param state  
	*
	* @return void  
	*/
	getDropdown:state => {
        return state.dropdown.list;
	},

	/*
	* This gets count per page item
	*
	* @param state  
	*
	* @return void  
	*/
	getPagePerItem:state=>{
		return state.table.pagination.per_page;
	},

	/*
	* This gets a value of state table.list
	*
	* @param state  
	*
	* @return void  
	*/
	totalItems:state=>{
		return state.table.total;
	},

	/*
	* This gets pagination page number
	*
	* @param state  
	*
	* @return void  
	*/
	pagination:state=>{
		return state.table.pagination.page;
	}
}

const mutations = {

	/*
	* This sets a value of state attendance.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	GET_LIST:(state,payload) => {
		state.table.list = payload.data;
	    state.table.pagination.current_page = payload.current_page;
	    state.table.pagination.per_page = payload.per_page;
	    state.table.total = payload.total;
	},

	/*
	* GET site list
	* to get the list after fetching
	*/	
	GET_DROPDOWN:(state,list)=>{
		state.dropdown.list = list;
	},

	/*
	* ADD record
	* to set the list additional record after add/registraion
	*/	
	ADD_RECORD:(state,payload)=>{
		state.table.list.unshift(payload.data);
		state.table.total += 1;  
	},

 	/*
	* UPDATE record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_RECORD:(state,payload)=>{
		let index = state.table.list.findIndex(list=> list.id == payload.data.id)
       	Object.assign(state.table.list[index],payload.data)
	},

	/*
	* DELETE record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_RECORD:(state,payload) => {
		let index = state.table.list.findIndex(list => list.id == payload.id)
		state.table.list.splice(index, 1)
		state.table.total -= 1;  
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getList:(context,data = {}) => {
		return new Promise((resolve,reject)=>{ 
			axios.get("/assignments",{
				params:{
					keyword:data.keyword,	
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : 10,
					orderBy:(data.sortBy && data.sortBy.length) ? data.sortBy.toString() : null,
					order:Object.hasOwnProperty.call(data,"sortDesc") && data.sortDesc[0] ? "desc" : "asc",
				} 
			})
			.then((response)=>{ context.commit('GET_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Get/Fetch list
	*
	* @return string
	*/	
	getDropdown(context,organization_id)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/assignments",{ params:{ organization_id:organization_id } })
			.then((response)=>{ context.commit('GET_DROPDOWN',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Add/Register record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegister(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.post("/assignments",data)
			.then((response)=>{ context.commit("ADD_RECORD",response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* Edit/Update record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdate(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/assignments/${data.id}`,data)
			.then((response)=>{ context.commit("UPDATE_RECORD",response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDelete(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/assignments/${data.id}`)
			.then((response)=>{ context.commit("DELETE_RECORD",data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const assignment = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}