
const state = { 
	account:{ list:[] },
	account_dropdown:{ list:[] }
}

const getters = {

	/*
	* GET account list
	* to get the list of account
	*/	
	getAccountList:state => {
		return state.account.list;
	},

	/*
	* GET account list
	* to get the list of account
	*/	
	getAccountDropDownList:state => {
		return state.account_dropdown.list;
	}
}

const mutations = {

	/*
	* GET account list
	* to get the list after fetching
	*/	
	GET_ACCOUNT_LIST:(state,list)=>{
		state.account.list = list;
	},

	/*
	* GET account list
	* to get the list after fetching
	*/	
	GET_ACCOUNT_LIST_DROPDOWN:(state,list)=>{
		state.account_dropdown.list = list;
	},

	/*
	* ADD account record
	* to set the list additional record after add/registraion
	*/	
	ADD_ACCOUNT_RECORD:(state,data)=>{
		Object.assign(state.account.list, data);
	},

 	/*
	* UPDATE account record   
	* to set the list additional record after add/registraion
	*/	
	UPDATE_ACCOUNT_RECORD:(state,data)=>{
		let index = state.account.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.account.list[index],data)
	},
 
	/*
	* DELETE account record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_ACCOUNT_RECORD:(state,id) => {
		let index = state.account.list.findIndex(list => list.id == id)
		state.account.list.splice(index, 1)
	},
}

const actions = {
	
	/*
	* Get/Fetch accounts list
	*
	* @return string
	*/	
	getAccounts(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/accounts")
			.then((response)=>{ context.commit('GET_ACCOUNT_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Search account 
	*
	* @return array
	*/	
	setSearchAccount(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get(`/accounts/search?keyword=${keyword}`)
			.then((response)=>{ context.commit('GET_ACCOUNT_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get/Fetch sites list
	*
	* @return string
	*/	
	getAccountsPerOrganization(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get(`/accounts?organization_id=${data.organization_id}`)
			.then((response)=>{ 
				context.commit('GET_ACCOUNT_LIST_DROPDOWN',response.data);
			})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Add/Register account record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterAccount(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.post("/accounts",data)
			.then((response)=>{ context.dispatch("getAccounts");})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* Edit/Update account record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateAccount(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/accounts/${data.id}`,data)
			.then((response)=>{ context.dispatch("getAccounts"); })
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove account record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteAccount(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/accounts/${data.id}`)
			.then((response)=>{ context.dispatch("getAccounts");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const account = {
	state,
	getters,
	mutations,
	actions
}  