<template>
   <div>
      <v-skeleton-loader v-if="isLoading" height="100%" type="table"/>
      <v-data-table v-else 
         :items="values" 
         :footer-props="{'items-per-page-options': [5, 10, 20]}" 
         :options.sync="pagination" 
         :server-items-length="totalItems" 
         class="elevation-1 mx-2 pa-4" 
         style="position:relative;"
      >
         <template slot="header" slot-scope="props">
            <thead>
               <template v-if="values.length == 0">
                  <th></th>
               </template>
               <template v-if="values.length != 0">
                  <th v-for="(item, index) in headers" :key="index" > 
                     <template v-if="item.text && item.text.date && item.text.day"> 
                        <p 
                           class="my-1" 
                           :style="[holidays[item.value]? {'color':'#0057AD'} :'']"
                        >
                           {{item.text.date}}
                        </p>

                        <!-- TOOL TIP-->
                        <!-- IF HAS HOLIDAY -->
                        <template v-if="holidays[item.value]">
                           <v-tooltip top class="elevation-5">
                              <template v-slot:activator="{ on, attrs }">
                                 <h1 
                                    v-on="on" 
                                    :style="[holidays[item.value]? {'color':'#0057AD'} :'']"
                                 >
                                    {{item.text.day}}
                                 </h1>
                              </template>
                              <span>{{ holidays[item.value] }}</span>
                           </v-tooltip>
                        </template>

                        <!-- IF NO HOLIDAY -->
                        <template v-else>
                           <h1 v-on="on">{{item.text.day}}</h1>
                        </template>

                     </template>
                     {{item.text && !item.text.date && !item.text.day ? item.text : null}}
                  </th>
               </template>
            </thead>
         </template>

         <template v-slot:body="{ items }">
            <tbody>
               <template v-if="items && items.length <= 0">
                  <tr>
                     <td :colspan="headers.length"> No Data Available</td>
                  </tr>
               </template>
               <tr v-for="item in items" :key="item.id" :colspan="items.length"> 
                  <td>
                     <v-layout> 
                        <v-avatar size="35px">
                           <img 
                              alt="avatar"
                              class="organization_logo" 
                              :src="item.image && item.image.avatar != null ? item.image.avatar : default_avatar"
                           />
                        </v-avatar>
                        <a @click="redirectToCalendar('calendar/monthly',{ 
                              organization_id:item.organization_id, 
                              employee_id:item.employee_id,
                        })">
                           <span class="mx-3">{{item.firstname}}</span>
                           <span class="mr-6">{{item.lastname}}</span>
                        </a>
                     </v-layout>
                  </td>
                  <template v-for="(date, index) in dates"> 
                     <td :key="index">
                        <p class="font-weight-bold " style="color:#0057AD;">
                           {{ getDayType(item,date) }}
                        </p>
                        <v-layout class="d-flex flex-center justify-center">
                           <v-chip class="mb-2" label :outlined="current_date > date ? false : true"
                              @click="current_date > date ? 
                              'javascript:void(0)' : setModalCalendarAssignment(item, date)">
                              {{ parseTextFromObject(item,date) }}
                           </v-chip>
                        </v-layout>
                     </td>       
                  </template>
               </tr>
            </tbody>
         </template>
      </v-data-table>
      <div class="table-footer-prepend d-flex pl-2 align-center ml-8 col d-flex justify-start ml-5 col-md-2 ">
         <v-view-by v-show="!isLoading" :type.sync="view_by"></v-view-by>
      </div>
   </div>
</template>

<script>

import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";
import { auth } from "@/mixins/auth";

import { mapActions, mapGetters }  from "vuex";
import { calendar_filter } from "@/mixins/filter/calendar/index";
import { ArrayDates } from "@/helper/dates";

import VSchedule from "@/components/Fields/Schedule/Index";
import ViewBy from "@/components/Calendar/components/ViewByFilter/Index";
import _ from "lodash";

export default {
   mixins:[auth,modal,excel,calendar_filter],
   name:"index",
   components:{
      "v-schedule":VSchedule,
      "v-view-by":ViewBy,
   },
   data(){ 
      return {
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'),
         date:{ from:moment().format("YYYY-MM-DD"), to:moment().add(5,"d").format("YYYY-MM-DD")},
         current_date:moment().format('YYYY-MM-DD'),
         arraydates: new ArrayDates(),
         dates:[],
         headers:[{ text:"EMPLOYEE", value:"employee", visible:true }],
         items:[{ text:"No Schedule", value:0 }],
         excel_header:[
            { "header":"Employee ID", "width":25 },
            { "header":"First Name", "width":25 },
            { "header":"Last Name", "width":25 },
         ],
         filter:[
            { name:"Shift", value:"SHIFT"},
            { name:"Account", value:"ACCOUNT"},
            { name:"Site", value:"SITE"},
            { name:"Assignment", value:"ASSIGNMENT"},
         ],

         view_by:"SHIFT",

         initializing:true,
         filtering:false,
         isLoading:true,

         pagination:{
            page:1,
            itemsPerPage:10,
            loading:true
         },
      }
   }, 
   
   mounted(){
      this.getCalendarHolidays(moment().format("YYYY-MM-DD"));
      this.$store.dispatch("clearFilter");
   },

   beforeDestroy()
   { 
      this.$bus.$off("export-excel-weekly-calendar");
      this.$bus.$off("monthly-calendar-submitted"); 
      this.$bus.$off("discard-calendar-changes");
      this.$bus.$off("filter-weekly-calendar");
      this.$bus.$off("get-date-range");
      this.$bus.$off("loading"); 
   },

   created()
   {  
      this.initialize(); 

      this.$bus.$on("filter-weekly-calendar",value=>{
         this.getFetchCalendar(this.pagination,this.date);
      })

      this.$bus.$on("load-calendar",()=>this.initialize())

      // from modal calendar assignment
      this.$bus.$on("weekly-calendar-submitted",()=>this.initialize());

      // to export the records into an excel file 
      this.$bus.$on("export-excel-weekly-calendar",()=>this.export_exel());

      // to set the date in range using Vue Event Bus
      this.$bus.$on("get-date-range", date=>{

         const { from } = date;

         this.getCalendarHolidays(from);
         
         this.arraydates.setDates(date).then(dates=>{

            // get calendar dates object 
            Object.assign(this.date,dates);

            // set headers for excel exports 
            this.setHeadersWithDates(dates);

            // get calendar values 
            this.getFetchCalendar(this.pagination, dates);
         }) 
      });
   },

   methods:{

      ...mapActions("calendar",[
         "getCalendar",
         "getCalendarForExcel",
         "getCalendarHolidays"
      ]),

      /*
      * This initialize value for table view
      */ 
      initialize()
      {  
         // set value for date picker component
         this.$bus.$emit("set-date-range",[this.date.from, this.date.to]);

         // get calendar
         this.getFetchCalendar(this.pagination,this.date);

         // set headers for excel exports 
         this.setHeadersWithDates(this.date);
      },

      setHeadersWithDates(dates){

         // reset the headers array for table and excel. To remove some dates 
         this.headers.length = 1; 
         
         // reset the excel header array for excel. To remove some values 
         this.excel_header.length = 3; 
         
         // date array for select field 
         this.dates.length = 0; 

         // date ranges values 
         let { from, to } = dates;

         // this set the header for header date " MM/DD "
         this.arraydates.getDateRangeInArray(from, to)
         .then(dates=>{
            dates.map(date=>{

               // header date for table
               this.headers.push({
                  "text":{ 
                     date: moment(date).format('ddd'),
                     day:  moment(date).format('DD')
                  },
                  value:moment(date).format('YYYY-MM-DD')
               });
               
               // formatted the date 
               const formattedDate = moment(date).format('YYYY-MM-DD');

               // if holiday is exist on the date
               const header = this.holidays[date] ? `${formattedDate}-Holiday` : formattedDate

               // excel header date for table
               this.excel_header.push({
                  "header":header, 
                  "width":25 
               })
               
               // to use as header/key on schedules
               this.dates.push(moment(date).format('YYYY-MM-DD'));
            })
         });
      },

      /*
      * This get the values by fetching 
      */ 
      getFetchCalendar(page,date={}){

         this.isLoading = true;
         this.initializing = true;

         this.setFilters(date).then(res=>{ 
            let payload = Object.assign(
               this.pagination, page, date, this.getFilters, { view_by: this.view_by }
            );

            this.getCalendar(payload).then(()=>{
               this.isLoading = false;
               this.initializing = false;
            });
         });
      },

      /*
      * This set the form for changes of schedule
      */ 
      setModalCalendarAssignment(data,date){
         this.$store.dispatch("modal",{ 
            title:`${data.firstname} ${data.lastname}`, 
            data:Object.assign({ date:date }, data), 
            view:"modal-calendar-assignment",
            mode:"edit", 
         });
      },

      /*
      * export data into an excel file 
      */
      export_exel(){ 
         this.getCalendarForExcel(this.getFilters).then(response=>{
            this.export_calendar_report(response,this.view_by); 
         });
      },

      /*
      * to parse object into a text value
      */
      parseTextFromObject(value,date){
         const index = 0;
         if(!value.length && value.calendar[date]){
            if(this.view_by == "ASSIGNMENT"){
               return this.parseAssignmentData(value.calendar[date],"No assignment");
            } else if(this.view_by == "SHIFT"){
               if(_.get(value.calendar[date],"schedule")){
                  return this.parseObjectToText(_.get(value.calendar[date],"schedule"));
               } 
               return "No Shift";
            } else {
               return this.parseObjectToText(value.calendar[date]);
            }
         }
         return `No ${this.view_by.toLowerCase()}`;
      },

      getDayType(item, date){
         const type = _.get(item.calendar[date],"day_type");
         if(type){
            if(type === 2){
               return "Rest Day";
            }
         } 
      },

      /*
      * To parse the object into a text 
      */
      parseAssignmentData(array,placeholder)
      {  
         if(array.filter(data=>data!=null).length > 0)
         {  
            let names = array
            .map(data=>data && data.name ? data.name : null )
            .filter(data=>data!=null);
            
            return (names.length > 1) ? `${ names[0] } + ${names.length - 1}` : names.toString();
         } 

         return placeholder;
      },

      /*
      * to parse object data into a text 
      */
      parseObjectToText(data){
         let { name, from, to } = data || {};
         const newFormat = (date) => moment(date,"h a").format("h a")
         return (from && to) ? 
         `${name}, ${newFormat(from)} - ${newFormat(to)}` : 
         name;
      },

      /*
      * This redirect to monthly calendar view
      */    
      redirectToCalendar(to,data){  
         this.$router.push({ path:`${to}`, query:data });
      },
   },

   computed: {
      ...mapGetters("calendar",{ 
         "values":"getCalendarList", 
         totalItems:"totalItems", 
         "holidays":"getHolidays"
      }),
   },

   watch:{
      view_by(value){  
         if(!this.initializing) this.getFetchCalendar(this.pagination,this.date);
      },

      "pagination.page"(oldV,oldN){
         this.getFetchCalendar(this.pagination,this.date)
      },

      "pagination.itemsPerPage"(oldV,oldN){
         this.getFetchCalendar(this.pagination,this.date)
      }
   },

   filters:{
      dayType:(type)=>{
         if(type === 2){
            return "Rest Day";
         }
      }
   }
}
</script>

<style type="text/css" scoped>
.table-footer-prepend {
  margin: -65px 0px 0px 50px;
  height: 58px;
  width: 150px;
  position: relative;
}
/* 
.v-tooltip__content {
  font-size: 15px !important;
  display: block !important;
  background-color: #FFF!important;
  color: #000!important;
  box-shadow: 4px 1px 30px 2px rgba(0,0,0,0.52);
  -webkit-box-shadow: 4px 1px 30px 2px rgba(0,0,0,0.52);
  -moz-box-shadow: 4px 1px 30px 2px rgba(0,0,0,0.52);
} */
</style>