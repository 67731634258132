
import BuilderForm from "@/modules/QueryBuilder/Builder/views/Index.vue";
import TableList from "@/modules/QueryBuilder/Template/views/Index.vue";

export const queryBuilderRoutes = [
	{ 
		path:"/query-builder",
		name:"query_builder",
	  	component:TableList,
	  	redirect:"",
	  	meta:{ requiredAuth:true, title:"Query Builder" },
	},
	
	{ 	
		path:"/query-builder/template", 
		name:"query_builder_templates", 
		component:BuilderForm, 
		meta:{ requiredAuth:true, title:"Query Builder" }
	}
];

