<template>
	<main>
		<v-card class="mx-2">
			<v-card-text>
				<section id="header" class="mx-5 d-flex justify-space-between align-center">
					<h2 id="headings">Quick Start</h2>
					<div id="search-bar" class="mt-6">
						<TemplateListSearchBar/>
					</div>
				</section>
				<section id="templates-list">
					<TemplateList :loading="loading" :items="values" @hide="onHide"/>
				</section>
				<TemplateRecentTable v-if="!hidden" :loading="loading" :items="values"/>
			</v-card-text>
		</v-card>
		<TemplateListModal/>
	</main>
	
</template>

<script>	
 	
 	import { mapActions, mapGetters } from "vuex";
	import TemplateList from "@/modules/QueryBuilder/Template/components/TemplateList";
	import TemplateListSearchBar from "@/modules/QueryBuilder/Template/components/TemplateListSearchBar";
	import TemplateRecentTable from "@/modules/QueryBuilder/Template/components/TemplateRecentTable";
	import TemplateListModal from "@/modules/QueryBuilder/Template/components/TemplateListModal.vue";

	export default {
		components:{
			 TemplateList, TemplateRecentTable, TemplateListSearchBar, TemplateListModal,
		},

		data(){
			return {
				hidden:false,
				type:null,
				isLoading:true,
			}   
		},

		created(){
			this.getTemplates();
		},

		methods:{
			...mapActions("template", ["getTemplates"]),

			onHide(boolean){
				this.hidden = boolean;
				this.getTemplates();
			}
		},

		computed:{
			...mapGetters('template', {'values':'getQueryBuilderList', 'loading':'getLoading'})
		}
	}
	
</script>

<style type="text/css">

	#headings {
		color: #000;
	}

	@media only screen and (max-width: 600px){
		#header { 
			flex-direction: column;
		}
		#search-bar {
			min-width:100%;
		}
		#templates-list{

		}
	}

</style>