
const state = { 
	site:{ list:[] },
	site_dropdown:{ list:[] }
}

const getters = {

	/*
	* GET site list
	* to get the list of site
	*/	
	getSiteList:state => {
		return state.site.list;
	},

	/*
	* GET site list
	* to get the list of site
	*/	
	getSiteDropDownList:state => {
		return state.site_dropdown.list;
	}
}

const mutations = {

	/*
	* GET site list
	* to get the list after fetching
	*/	
	GET_SITE_LIST:(state,list)=>{
		state.site.list = list;
	},

	/*
	* GET site list
	* to get the list after fetching
	*/	
	GET_SITE_LIST_DROPDOWN:(state,list)=>{
		state.site_dropdown.list = list;
	},

	/*
	* ADD site record
	* to set the list additional record after add/registraion
	*/	
	ADD_SITE_RECORD:(state,data)=>{
		Object.assign(state.site.list, data);
	},

 	/*
	* UPDATE site record   
	* to set the list additional record after add/registraion
	*/	
	UPDATE_SITE_RECORD:(state,data)=>{
		let index = state.site.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.site.list[index],data)
	},
 
	/*
	* DELETE site record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_SITE_RECORD:(state,id) => {
		let index = state.site.list.findIndex(list => list.id == id)
		state.site.list.splice(index, 1)
	},
}

const actions = {
	
	/*
	* Get/Fetch sites list
	*
	* @return string
	*/	
	getSites(context,data={})
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/sites",{
				params:{
					organization_id:data.organization_id
				}
			})
			.then((response)=>{ context.commit('GET_SITE_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Search site 
	*
	* @return array
	*/	
	setSearchSite(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/sites/search",{
				params:{
					keyword:keyword
				}
			})
			.then((response)=>{ context.commit('GET_SITE_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get/Fetch sites list
	*
	* @return string
	*/	
	getSitesByOrganization(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get(`/sites?organization_id=${data.organization_id}`)
			.then((response)=>{ 
				context.commit('GET_SITE_LIST_DROPDOWN',response.data);
			})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Add/Register site record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterSite(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.post("/sites",data)
			.then((response)=>{ context.dispatch("getSites");})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* Edit/Update site record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateSite(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/sites/${data.id}`,data)
			.then((response)=>{ context.dispatch("getSites"); })
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove site record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteSite(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/sites/${data.id}`)
			.then((response)=>{ context.dispatch("getSites");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const site  = {
	state,
	getters,
	mutations,
	actions
}  