<template>
	<v-select v-model="value" :items="items" @change="onChange" 
	placeholder="Attributes" item-text="name" item-value="id" 
	outlined dense/>
</template>

<script>
	
import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
	props:{
		namespace:{
			type:String,
			required:true,
		},

		value:{
			required:true,	
		}
	},
	
	data(){
		return { 
			cache_item:null,
			selected:null,
		}
	},

	created(){
		this.initialize();
	},

	beforeDestroy(){
		// No [type] get selected then once the assign attribute change then remove disabling 
		this.$store.dispatch(`${this.namespace}/setColumnAccessability`,{ id:this.value, boolean:false });
	},
	
	methods:{
		initialize(){
			// when is already selected 
			this.$store.dispatch(`${this.namespace}/setColumnAccessability`,{ id:this.value, boolean:true });
		},

		fetchRecords() {
			return this.$store.dispatch(`${this.namespace}/fetchRecords`);
		},

		onChange(value){
			this.$store.dispatch(`${this.namespace}/setColumnAccessability`,{ id:value.id, boolean:true });
			this.$emit("update:value",value);
			this.$emit("change",value);
		}
	},

	computed:{ 
		// when option have select then disable it for other column
		items(){
			return this.$store.getters[`${this.namespace}/getRecords`];
		},
	},

	watch:{
		value(newVal,oldVal){	
			// set the new selected option as disable true
			this.$store.dispatch(`${this.namespace}/setColumnAccessability`,{ id:newVal, boolean:true });
			// if new id selected isn't the same with old id selected 
			// make the option with old id option, disable
			if(newVal != oldVal){
				this.$store.dispatch(`${this.namespace}/setColumnAccessability`,{ id:oldVal, boolean:false });
			}
		},
	}
}
</script>