export const getters = {

	/*
	* to get the visibility property
	* this serves as restriction for visibility of a component 
	*/
	getVisibility(state){
		return state.visibility;
	},
	
	/*
	* to get the form properties
	*/
	getForm(state){
		return state.form; 
	},
	
	/*
	* to get the form cache properties
	*/
	getFormCache(state){
		return state.form_cache; 
	},

	/*
	* to get the output name from the form property 
	*/
	getOutputName(state){
		return state.form.column_attributes.output_name
	},
	
	/*
	* to get the title or template name from the form property
	*/
	getTitle(state){
		return state.form.title
	},

	/*
	* to get the from and to date range values in one
	* this serves as restriction for add columns 
	*/
	getDateRange(state){
		if(state.form.column_attributes.from && state.form.column_attributes.to){
			return { from:state.form.column_attributes.from, to: state.form.column_attributes.to }
		}
	},
	
	/*
	* to get the boolean value of keep template property
	*/
	getKeepTemplate(state){
		return state.form.column_attributes.keepTemplate
	},
	
	/*
	* to get the headers created for data table preview 
	*/
	getHeaders(state){
		return state.headers;
	},
	
	/*
	* to get the columns set for custom columns
	*/
	getColumns(state){
		return state.form.column_attributes ? state.form.column_attributes.columns : []; 
	},
	
	/*
	* to get the column items property for select dropdown
	*/
	getColumnItems(state){
		return state.column_items
	},

	/*
	* to get the boolean value for skeleton loaders
	*/
	getLoading(state){ 
		return state.loading;
	},

	/*
	* to get the boolean value for skeleton loaders
	*/
	getTableLoading(state){ 
		return state.table_loading;
	},

	/*
	* to get the boolean value for skeleton loaders
	*/
	getValues(state){ 
		return state.values.list;
	},

	totalItems:state=>{
		return state.values.total;
	},

	pagination:state=>{
		return state.values.pagination;
	},

	page:state=>{
		const { page } = state.values.pagination;
		return page;
	},

	/*
	* This gets holidays object values
	*
	* @param state  
	*
	* @return void  
	*/
	getHolidays:state=>{
		return state.holidays;
	}
}