const state = { 
	timeline:{
		list:[],
		pagination:{ page:0, per_page:0 },
		total:0,
	},
}

const getters = {

	/*
	* This sets a value of state timelogs.list
	*
	* @param state  
	*
	* @return void  
	*/
	getTimelineList:state=>{
		return state.timeline.list;
	},

	// pagination of table 
	PagePerItem:state=>{
		return state.timeline.pagination.per_page;
	},

	totalItems:state=>{
		return state.timeline.total;
	},

	pagination:state=>{
		return state.timeline.pagination.page;
	}
}

const mutations = {

	/*
	* This sets a value of state timeline.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_TIMELINE_LIST:(state,payload) => {
		state.timeline.list = payload.data;
	    state.timeline.pagination.current_page = payload.current_page;
	    state.timeline.pagination.per_page = payload.per_page;
	    state.timeline.total = payload.total;
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getTimeline:(context,data = {}) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/timeline-v2",{ 
				params:{ 
					from:data.from, 
					to:data.to,
					account:data.account_id,
					site:data.site_id,
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : context.state.timeline.total,
				} 
			})
			.then((response) => { context.commit('SET_TIMELINE_LIST',response.data); resolve(); })
			.catch((errors) => console.log(errors));
		})
	},
}

export const timelineV2  = {
	namespaced: true,
	state,
	getters,
	mutations,
	actions
}