<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="400">
      <template v-slot:activator="{ on }">
      <template v-on="isIdle"></template>
      </template>
      <v-card>
        <v-card-title class="headline">Browser Idle</v-card-title>
        <v-card-text>
        	<p>You have left this browser idle for 15 minutes.</p>
    		<p>{{ seconds }} second left</p>
    	</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
	props:["isIdle"],
    data () {
		return {
			dialog:true,
			time: 10000
		}
    },
    created() {

	    let timerId = setInterval(() => {

			this.time -= 1000;

			if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

			if (this.time < 1) 
			{
				clearInterval(timerId);
				
				// Your logout function should be over here
				this.$store.dispatch("logout");
			}

	    }, 1000);
    },

    computed:{
    	seconds(){
    		return this.time / 1000;
    	}
    },
    watch:{
    	isIdle(value){
    		this.dialog = value;
    	},
    }

}
</script>