<template>
	<!-- mobile view -->
	<v-container class="container">

		<!-- status bar -->
		<v-layout fluid>
			<v-row align="end" justify="end" no-gutters>
				<div>
					<v-icon class="icon">mdi-wifi-strength-4</v-icon>
				</div>
				<div>
					<v-icon class="icon">mdi-network-strength-4</v-icon>
				</div>
				<div>
					<v-icon class="icon">mdi-battery</v-icon>
				</div>
				<div>
					<span class="icon">11:00</span>
				</div>
			</v-row>
		</v-layout>
		<!-- ../status bar -->

		<!-- header -->
		<v-layout fluid class="my-2">
			<v-row no-gutters>
				<div>
					<v-icon class="single-icon">mdi-arrow-left</v-icon>
				</div>
				<v-col>
					<img class="logo" :src="logo"/>
				</v-col>
				<div>
					<v-icon class="single-icon">mdi-dots-horizontal</v-icon>
				</div>
			</v-row>
		</v-layout>
		<!-- ../header -->

		<!-- content -->
		<div class="my-5 content" >
			<div>
				<h2 class="title" style="">{{ form.title }}</h2>
			</div>

			<div class="my-8">
				<p>{{ dateTimeLable }}</p>
			</div>

			<div class="my-8"> 
				<pre class="paragraph-text">{{ paragraph }}</pre>
			</div>

			<div align="center" justify="center">
				<v-btn width="80%" color="#0057AD" class="elevation-0 white--text">{{ form.button_text }}</v-btn>
			</div>
		</div>
		<!-- ../content -->

		<!-- pagniation -->	
		<div>
			<pagniation :assignment.sync="assignments"></pagniation>
		</div>
		<!-- ../pagniation -->	

	</v-container>
	<!-- ../mobile view -->

</template>

<script>

	import Pagniation from "@/components/Fields/Compose/Pagination/Index.vue";

	import { mapStates, mapGetters, mapActions } from "vuex";

	export default {
		components:{
			"pagniation":Pagniation,
		},
		data(){
			return {
				logo:require('@/assets/images/brand/abizo_dtr_logo.png'),
				item_index:0,
				item_index_count:0,
				assignments:null,
			}
		},

		methods:{  
			/*
	 		* This show the modal view for edit action
	 		*
	 		* @return void 
	 		*/  
			openModalDatePicker(){ this.$store.dispatch("modal",{ view:"modal-date-picker" }) },

		    /*
			* To look for string  from the paragraph field 
			*
			* @return boolean
			*/
		    paragraphContains(text, value){ return text && text.indexOf(value) !== -1 ? true : false },

			/*
			* To check values from paragraph
			*
			* @return string
			*/
			onGetParagraph(value) 
			{
				let val = Object.hasOwnProperty.call(this.form,"paragraph") && this.form.paragraph ? this.form.paragraph : ""; 
				if(!this.paragraphContains(val,"{{date}}") && !this.form.date){ Object.assign(this.form,{ date:null, time:null }); }
				if(this.paragraphContains(val,"{{date}}") && this.form.date) { val = val.replace("{{date}}",moment(this.form.date).format("MMM DD")); }
				if(this.paragraphContains(val,"{{time}}") && this.form.time){ val = val.replace("{{time}}",this.form.time); }
				if(this.paragraphContains(val,"{{assignment}}")&& this.assignments){ val = val.replace("{{assignment}}",this.assignments); }
				return val;
			},
		},

		computed:{
			...mapGetters("message",{ "form":"getForm" }),

			dateTimeLable(){
				if(Object.hasOwnProperty.call(this.form,"status") && this.form.status == 2){
					return `Draft - ${moment(this.form.created_at).format("MMM DD, YYYY H:s:a")}`;
				}
				return null; 
			},

			paragraph(){
				return this.onGetParagraph();
			}
		},
	}
</script>

<style scoped>
	.container {
		margin-top: -10px;
	}

	.content{
		height:500px;
	}
		
	.paragraph-text{
		height: 300px;
    	overflow-y: auto;
    	font-family: "Roboto";
    	text-align:left;
	}

	.recipient-text
	{
		color:#000; 
		font-weight:bold;
	}

	.status-bar-style{
		font-size:14px; 
		color:#000;
	}

	.title{
		overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
    	text-align:left;
	    color:#000;
	}

	.logo{
		width:30px;
	}

	.icon {
		font-size:14px!important; 
		color:#000!important;
	}

	.single-icon{
		color:#000!important;
	}
</style>
