const state = { 
	attendance:{
		list:[],
		pagination:{ page:1, per_page:10 },
		total:0,
	},
}

const getters = {
	
	/*
	* This sets a value of state attendance.list
	*
	* @param state  
	*
	* @return void  
	*/
	getAttendanceList:state => {
        return state.attendance.list;
	},

	// pagination of table 
	getPagePerItem:state=>{
		return state.attendance.pagination.per_page;
	},

	totalItems:state=>{
		return state.attendance.total;
	},

	pagination:state=>{
		return state.attendance.pagination.page;
	}
}

const mutations = {

	/*
	* This sets a value of state attendance.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_ATTENDANCE_LIST:(state,payload) => {
		state.attendance.list = payload.data;
	    state.attendance.pagination.current_page = payload.current_page;
	    state.attendance.pagination.per_page = payload.per_page;
	    state.attendance.total = payload.total;
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getAttendance:(context,data = {}) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/attendances",{
				params:{
					date:data.date,
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : context.state.attendance.total,
					account:data.account_id,
					site:data.site_id
				}
			})
			.then((response)=>{ context.commit('SET_ATTENDANCE_LIST',response.data); resolve();})
			.catch((errors)=>console.log(errors));
		});
	},
}

export const attendance = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}