<template>
    <v-row justify="center">
		<v-dialog 
			persistent
			scrollable
			v-model="dialog"
			max-width="800px"
			style="max-height:3000px;"
			@keydown.esc="close()"
		> 
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <span class="font-weight-bold">{{ title }}</span>
		            <v-spacer/>
		            <span class="caption mr-3">Press</span>
		            <v-chip
						label
						small
						outlined
						color="grey"
						class="mr-5"
					>
						Esc
					</v-chip>
		            <v-btn icon @click="close()">
						<v-icon>close</v-icon>
					</v-btn>
		        </v-card-title>
				<v-divider/>

				<!-- -------------------- CARD CONTENT ------------------- -->
				<v-form
					ref="form"
					v-model="valid"
					@keyup.native.enter="submit(mode)"
				>
					<v-container class="fill-height">
						<v-col>
							<v-row no-gutters class="ma-1">
								<label class="d-block text-left font-weight-bold label my-1">
									Short Name 
								</label>
							</v-row>
							<v-row no-gutters class="ma-1">
								<v-text-field  
									dense
									outlined
									clearable
									v-model="form.short_name"
									placeholder="Type the name of your organization"
									@input="error.clear('short_name')"
									@keydown="rules.alphaNumericOnly"
									:disabled="isSubmit||isDelete"
									:error-messages="error.get('short_name')"
									:error.sync="error.has('short_name')"
									:rules="[
										rules.organization_short_name.required,
										rules.organization_short_name.format
									]"
								/>
							</v-row>
						</v-col>
						<v-col>
							<v-row class="ma-1">
								<label class="d-block text-left font-weight-bold label my-1">
									Logo
								</label>
							</v-row>
							<v-row class="ma-1">
								<v-file-input
									dense
									outlined
									prepend-icon=""
									placeholder="Upload a logo"
									accept="image/jpeg,image/png"
									@input="error.clear('logo')"
									@change="image_upload($event,'logo')"
									:rules="[rules.logo.type, rules.logo.size]"
									:disabled="isSubmit||isDelete" v-model="logo"
									:error-messages="error.get('logo')"
									:error="error.has('logo')"
								/>
							</v-row>
						</v-col>
						<v-row class="ma-1">
							<span class="bottom-note ml-3">
								Short Name and Logo will be used to personalize your Abizo web account.
							</span>
						</v-row>

						<!-- -------------------- LABEL NOTE -------------------- -->
						<v-divider class="mx-3 my-2"/>

						<!-- -------------------- ORGANIZATION NAME -------------------- -->
						<v-row no-gutters class="mx-3">
							<v-col>
								<label class="d-block text-left font-weight-bold label my-1">
									Organization Name
								</label>
								<v-text-field
									dense
									outlined
									clearable
									name="name"
									v-model="form.name"
									placeholder="Type the name of your organization" 
									@input="error.clear('name')"
									@keydown="rules.alphaNumericOnly"
									:disabled="isSubmit||isDelete"
									:rules="[rules.organization_name.required,rules.organization_name.format]"
									:error-messages="error.get('name')"
									:error.sync="error.has('name')"
								/>
							</v-col>
						</v-row>

						<!-- -------------------- ORGANIZATION CODE -------------------- -->
						<v-row no-gutters class="mx-3">
							<v-col dense>
								<label class="d-block text-left font-weight-bold label">
									Organization Code
								</label>
								<v-text-field
									dense
									outlined
									clearable
									maxLength="6"
									v-model="form.code"
									placeholder="Type a 6-digit code"
									@input="error.clear('code')"
									@keydown="rules.integerOnly"
									:error-messages="error.get('code')"
									:error="error.has('code')"
									:disabled="isSubmit||isDelete"
									:rules="[
										rules.organization_code.required,
										rules.organization_code.format,
										rules.organization_code.length
									]"
								/>
							</v-col>
						</v-row>

						<!-- -------------------- ORGANIZATION URL --------------------- -->
						<v-row no-gutters class="mx-3">
							<v-col dense>
								<label class="d-block text-left font-weight-bold label ">
									Organization URL
								</label>
								<v-text-field
									dense
									outlined
									clearable
									placeholder="Type Here"
									v-model="form.url":disabled="isSubmit||isDelete"
									@input="error.clear('url')"
									:rules="[rules.url.format]"
									:error-messages="error.get('url')" 
									:error.sync="error.has('url')"
								/>
							</v-col>
						</v-row>
					</v-container>
				</v-form>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">

	               <!-- ------------- DELETE OR DISCARD ------------- -->
	               <v-btn dark  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
	               @click="mode == 'delete' ? submit(mode) : close()"
	               :color="mode == 'delete'?'red':'#0057AD'"
	               :loading="mode == 'delete'? isSubmit : false">
	               {{ mode == 'delete' ? 'Delete':'Discard'}} 
	               </v-btn>

	               <v-spacer></v-spacer>

	               <!-- ------------- CANCEL OR SUBMIT ------------- -->
	               <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
	               @click="mode == 'delete' ? close() : submit(mode)"
	               :loading="mode == 'delete'? false  : isSubmit">
	               {{ mode == 'delete' ? 'Cancel':'Submit' }}
	               </v-btn> 
	               
	            </v-card-actions>
	        </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import   Errors   from "@/validation/errors";

export default {
	mixins:[modal,states],
	data(){
		return {
			// to check if the field were all valid
			valid: false,

			// to validate the fields 
			rules:rules,

			// to set the data event Add or Edit
			form:{},

			// errors: new Errors()
			error:new Errors(),

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: false,

			// to check if action is submitting
			isSubmit: false,

			// to check if mode is delete
			isDelete:false,

			// to reset the value from form.logo
			logo:null
		}
	}, 

	mounted(){
       this.initilize();
    },

    updated(){
    	this.setActions();
    },

	methods:{

		/*
		* This initilize value values needed
		*
		* @return void
		*/ 
		initilize(){
			this.setValues();
		},

      	/*
 		* To get image into base64 string 
 		*
 		* @return void
 		*/ 
		image_upload(event,type){
			try {
				if(typeof null != event){
					// new instance of FileReader class 
					const reader = new FileReader();
					
				 	if(type == "logo"){
                  		// get the base64 from result from File Reader
                  		reader.onloadend = (() => { 
							this.form.logo  =  reader.result;
						});
               		}

					// read the data as url from upload payload file
					reader.readAsDataURL(event);
				}
			}

			catch(e){
				if (!(e instanceof TypeError)){
			      // The exception is not a TypeError, so throw it again.
			      throw e;
			    }
			}
		},

		/*
		* This assign value values to the form object
		*
		* @return void
		*/
        setValues(){
        	Object.assign(this.form,this.data);
        },

		/*
		* This set actions based on modal action mode
		*
		* @return void
		*/
		setActions(){
			this.isDelete = this.mode == "delete" ? true : false;
			this.isEdit   = this.mode == "edit" ? true : false;
		},

		/*
 		* This initilize value for table view
 		*
 		* @return array /store/modules/organization/index 
 		*/ 
		submit(action = "add") {
			switch(action){
				case "add": 
					if(this.$refs.form.validate()){	
						this.isSubmit = true;

			     		this.$store.dispatch("setRegisterOrganization",this.form)
			     		.then((response) => { this.isSubmit = false; this.redirect("/organizations"); })
						.then((response) => { this.close() })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
	                  	.finally(() => {});
			        }
				break;

				case "edit":
					if(this.$refs.form.validate()){
						this.isSubmit = true;
						this.$store.dispatch("setUpdateOrganization",this.form)
						.then((response) => { this.isSubmit = false })
						.then((response) => { this.close() })
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
	                  	.finally(() => {});
					}
				break;

				case "delete": 
						this.isSubmit = true;
						this.$store.dispatch("setDeleteOrganization",this.form)
						.then((response) => {this.isSubmit = false})
						.then((response) => {this.close()})
						.catch((errors)  =>{})
	                  	.finally(() => {});
				break;
			} 
      	},
	},

	watch:{
		
		/*
		* Resets the form.logo field form
		*
		* @return void
		*/
		logo(value){
			this.form.logo = value;
		},

		/*
 		* Resets the field form
 		*
 		* @return void
 		*/ 
 		dialog(value){
 			if(!value) { this.$refs.form.reset(); }
 		},
	},
}

</script>

<style type="text/css" scoped>
	.custom-card-button{
	      height:40px;
	      width:150px;
	}

	.label {
		color:black;
		font-size: 16px;
	}

	.img-format-label {
		color:#BDBDBD; 
		font-size: 13px;
	}

	.bottom-note{
		color:#BDBDBD; 
		font-size: 13px; 
		margin-top: -20px;
  	}

</style>