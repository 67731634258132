export const actions = {

	/*
	* to set the data table properties needed 
	* this executes by actions and alse by component too
	*/
	setUpdateTable(context){
		context.commit("SET_TABLE_DATE_HEADER");
		context.commit("SET_ADD_PARENT_HEADER_TABLE_BY_GROUP",1);
		context.commit("SET_ADD_PARENT_HEADER_TABLE_BY_GROUP",3);
		context.commit("SET_SORT_HEADER_BY_GROUP_TABLE");
	},

	/*
	* to clear the form properties as new 
	*/
	clearForm(context){
		context.commit("CLEAR_FORM");
	},
	
	setDeleteAssignAttribute(context,index){
		context.commit("DELETE_ASSIGN_ATTRIBUTE",index);
	},

	/*
	* to set the boolean value 
	* this set by beforeDestroy() life cycle on index component as for builder use only 
	*/
	setFormEditable(context, boolean){
		context.commit("SET_FORM_EDITABLE",boolean);
	},
	
	/*
	* to set the visibility property 
	* to set the visibility of a component which only needed to show 
	* to set visibility property from the other components individually
	*/
	setVisibility(context, property){
		context.commit("SET_VISIBILITY",property);
	},

	/*
	* to set the form by single or multiple property
	*/
	setForm(context,data={}){
		context.commit("SET_FORM_VALUES",data);
	},

	/*
	* to set the column attibutes 
	*/
	setColumnAttributes(context,data){
		context.commit("SET_COLUMN_ATTRIBUTES",data);
	},

	/*
	* to add custom column with initial value of null 
	*/
	addColumn(context, data={}){
		context.commit("SET_ADD_COLUMN",data);
	},

	/*
	*  to update the columns in a header array 
	*  to set the parent and sub header 
	*/
	updateColumn(context, data={}){
		let column = context.state.column_items.filter(col=>col.type == data.type);
		if(column.length){
			context.commit("SET_ADD_PARENT_HEADER_TABLE",column);
			context.commit("SET_ADD_PARENT_SUBHEADER_TABLE",column);
		} 
		context.commit("SET_UPDATE_COLUMNS",data);
		context.dispatch("setUpdateTable");
	},

	/*
	*  this fetch a single template record by id
	*/
	setColumnVisibility(context,data={}){
		context.commit("SET_COLUMN_VISIBILITY",data)
	},

	/*
	*  to set the column items availability
	*  to restrict the reselection of an item 
	*/
	setColumnAccessability(context,data={}){
		context.commit("SET_COLUMN_ACCESSABILITY",data)
	},

	/*
	*  to set the column items availability
	*  to restrict the reselection of an item 
	*/
	setColumnAttrAccessability(context,data={}){
		context.commit("SET_COLUMN_ACCESSABILITY2",data)
	},


	
	/*
	*  to set the default column values
	*/
	setDefaultColumn(context, data){
		context.commit("SET_DEFAULT_COLUMN_VALUES",data);
		context.dispatch("setUpdateTable");
	},

	/*
	* to set visibility of component into report view 
	* this executes in confirm modal 
	*/
	doneGenerateReport(context){
		context.commit("SET_VISIBILITY",{ builder:false, preview:true, header:{ type:"edit", } });
	},

	/*
	*  this fetch a single template record by id
	*/
	getTemplate(context,id){
		return new Promise((resolve,reject)=>{
			context.commit("SET_LOADING",true); 
			axios.get(`/query-builder/${id}`)
			.then(async(response)=>{  
				await context.commit("SET_FORM_VALUES",response.data); // set form values 
				await context.commit("SET_FORM_CACHE_VALUES",response.data);
				await context.commit("SET_PARSE_CUSTOM_ATTRIBUTES",response.data); // parse data
 
				// Update table 
				await context.commit("SET_TABLE_DATE_HEADER");
				await context.commit("SET_ADD_PARENT_HEADER_TABLE_BY_GROUP",1);
				await context.commit("SET_ADD_PARENT_HEADER_TABLE_BY_GROUP",3);
				await context.commit("SET_SORT_HEADER_BY_GROUP_TABLE");
 				
 				// clear the form when keep template is false
				await context.commit("CLEAR_FORM_WHEN_UNKEEP_TEMPLATE"); 
				await context.commit("SET_LOADING",false);
				await resolve(response.data);
			 })
			.catch((errors)=>{ reject(errors); context.commit("SET_LOADING",false); })
		});
	},

	/*
	* to set all properties as EDIT mode 
	* this executes by edit button from the header
	* after generating of the report done
	*/
	editTemplate(context){
		context.commit("SET_PARSE_FORM_CACHE_VALUES");
		context.commit("SET_FORM_WITH_FORM_CACHE_VALUES");
		context.dispatch("setUpdateTable");
		context.commit("SET_VISIBILITY",{ builder:true, preview:true, header:{ type:"add" } });
	},

	/*
	* to submit the form as create into an API
	*/
	setCreateTemplate(context,data){
		return new Promise((resolve,reject)=>{
			axios.post(`/query-builder`,data)
			.then((response)=>{ 
				context.commit("SET_FORM_CACHE_VALUES",response.data);
				context.commit("SET_FORM_EDITABLE", true);
				resolve(response.data); 
			})
			.catch((errors)=>reject(errors))
		})
	},

	/*
	* to submit the form as update into an API
	*/
	setUpdateTemplate(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.put(`/query-builder/${data.id}`,data)
			.then((response)=>{ 
				context.commit("SET_FORM_CACHE_VALUES",response.data);
				context.commit("SET_FORM_EDITABLE", true);
				resolve(response.data); 
			})
			.catch((errors)=>reject(errors))
		})
	},

	/*
	* to submit the form as update into an API
	*/
	getGenerateReport(context, data={}){
		context.commit("SET_TABLE_LOADING",true);
		return new Promise((resolve,reject)=>{
			axios.post(`/query-builder/fetch`, data, { 
				params:{
					page:data.page,
					per_page:data.itemsPerPage
				}
			})
			.then(async(response)=>{ 
				await context.commit("SET_VALUES",response.data);
				await context.commit("SET_TABLE_LOADING",false);
				await resolve(response.data); 
			})
			.catch((errors)=>reject(errors))
		})
	},

	/*
	* to submit the form as update into an API
	*/
	getGenerateReportPaginate(context, data={}){
		return new Promise((resolve,reject)=>{
			axios.post(`/query-builder/fetch`, data, { 
				params:{
					page:data.page,
					per_page:data.itemsPerPage
				}
			})
			.then(async(response)=>{ 
				await context.commit("SET_VALUES",response.data);
				await context.commit("SET_TABLE_LOADING",false);
				await resolve(response.data); 
			})
			.catch((errors)=>reject(errors))
		})
	},

	/*
	* to get Holidays from calendar
	*/
	getCalendarHolidays : (context, date) => {
		
		const baseURL = process.env.VUE_APP_GOOGLE_CALENDAR_API;
		const calendarId = process.env.VUE_APP_GOOGLE_CALENDAR_ID;
		const myKey = process.env.VUE_APP_GOOGLE_CALENDAR_API_KEY;

		const start = `${moment(date).startOf("year").format("YYYY-MM-DDTHH:mm:ss")}Z`;
		const end = `${moment(date).endOf("year").format("YYYY-MM-DDTHH:mm:ss")}Z`;

		return new Promise((resolve,reject)=>{
			fetch(`${baseURL}/${calendarId}/events?key=${myKey}&timeMin=${start}&timeMax=${end}`)
			.then((res) => res.json()) // Transform the data into json
			.then((res)=>{
			   let holidays = {};
   
			   res.items.map(item=>{
				  holidays = { ...holidays, [item.start.date]:item.summary }
			   });
   
			   context.commit("SET_HOLIDAYS_OBJECT", holidays);
			});
		});
	}
}