<template>

   <v-data-table :headers="headers" :items="values" :loading="pagination.loading"  
      loading-text="Loading" :options.sync="pagination" :server-items-length="totalItems" 
      :footer-props="{ 'items-per-page-options': [10, 50, 100] }" class="elevation-2 mx-2 pa-3">
      <template>{{ header }}</template>
      
      <template v-slot:item.provider="{ item }">
         {{ item.organization && item.organization.face_recognition ? item.organization.face_recognition[0].name : "None" }}
      </template>

      <!-- -------------- LOGTYPE --------------- -->
      <template v-slot:item.type="{ item }">
         <v-chip label class="v-chip-label" :color="logtype(item.type).color" :text-color="logtype(item.type).text_color"> 
            {{ logtype(item.type).format }}
         </v-chip>
      </template>

      <!-- -------------- DATE ----------------- -->
      <template v-slot:item.date="{ item }">
         {{ format(item.timestamp,"date","DD MMM YYYY") }}
      </template>

      <!-- -------------- FIRSTNAME ----------------- -->
      <template v-slot:item.firstname="{ item }">
         {{ item.employee ? item.employee.firstname : "-" }}

      </template>
      <!-- -------------- LASTNAME ----------------- -->
      <template v-slot:item.lastname="{ item }">
         {{ item.employee ? item.employee.lastname : "-" }}
      </template>

      <!-- -------------- TIME ----------------- -->
      <template v-slot:item.time="{ item }">
         {{ format(item.timestamp,"time","h:mm a") }}
      </template>

      <!-- -------------- TEMPERATURE ----------------- -->
      <template v-slot:item.temp="{ item }">
         {{ item.temp ? item.temp+`°C` : "N/A" }}
      </template>

      <!-- -------------- SOURCE ----------------- -->
      <template v-slot:item.source="{ item }">
         {{ item.source ? item.source.source_name : "-" }}
      </template>

      <!-- -------------- ACTIONS ---------------- -->
      <template v-slot:item.action="{ item }" >
         <v-menu bottom left transition="scale-transition">
            <template v-slot:activator="{ on }">
              <v-btn slot="activator" v-on="on" icon>
                  <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>

               <v-list-item  @click="map(item)">
                    <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-eye</v-icon>
                    <v-list-item-title> View </v-list-item-title>
               </v-list-item>

                  <template v-if="item.source && item.source.source_id == 1">
                     <v-list-item @click="edit_item(item)">
                        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
                        <v-list-item-title> Edit </v-list-item-title>
                     </v-list-item>
                  </template>

               <v-list-item  v-if="item.source && item.source.source_id == 1" @click="delete_item(item)">
                  <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
                  <v-list-item-title> Delete </v-list-item-title>
               </v-list-item>

            </v-list>
         </v-menu>
      </template>
   </v-data-table>
</template>

<script>

import { column_filter } from "@/mixins/filter/column";
import { excel } from "@/mixins/docs/excel";
import { auth } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
   mixins:[column_filter,excel,auth],
   data()
   {
      return {
         headers: [
            { text: "#",align: "left",sortable: false, value: "id", visible:true },
            { text: "LOG TYPE", value: "type", visible:true },
            { text: "LOG DATE", value: "date", visible:true },
            { text: "LOG TIME", value: "time", visible:true },
            { text: "EMPLOYEE ID", value: "employee_id", visible:true },
            { text: "FIRST NAME", value: "firstname", visible:true },
            { text: "LAST NAME", value: "lastname", visible:true },
            { text: "TEMPERATURE", value: "temp", visible:true },
            { text: "SOURCE", value: "source", visible:true },
            { text: "LOCATION", value: "location", visible:true },
            { text: "ACTIONS", value: "action", visible:true },
         ],  
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         date: moment(new Date()).format("YYYY-MM-DD"),
         location_loading:false,
         form:{},
         header:"",
         pagination:{
            page:1,
            itemsPerPage:10,
            loading:false,
         },
         initializing:false,
      }
   },

   beforeMount()
   {  
      this.initialize();
      this.$bus.$on("filter_recent",()=>this.initialize());
   },
   
   beforeDestroy()
   {
      this.$bus.$off("export-excel-timelogs");
      this.$bus.$off("recent-date-picker");
      this.$bus.$off("recent-search");
      this.$bus.$off("filter_recent");
   },

   methods:{

      ...mapActions("recent",['getRecent']),

      /*
      * This initilize value for table view
      *
      * @return array /store/modules/Timelogs/Totals/index 
      */ 
      initialize()
      {  
         this.initializing = true;

         this.filteredDate();

         // set filters 
         this.setFilterValues({ date:this.date, ...this.pagination });

         // get values while passing filter values
         this.getRecentValues(this.getFilterValues());

         // fiter events 
         this.filterEvents();
      },

      filteredDate(){
         let filter = this.$store.getters.getFilter;

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            this.date = filter.date;
         }
      },

      filterEvents(){

         // set date picker date
         this.$bus.$emit("set-date-picker",this.date);

         // set value null
         this.$bus.$emit("clear-value",null);

         // to export the records into an excel file 
         this.$bus.$on("export-excel-timelogs",()=>this.export_exel());

         // to set the date using Vue Event Bus
         this.$bus.$on("recent-date-picker",(value)=>{ 
            this.setFilterValues({ ...value, ...this.pagination });
            this.getRecentValues(this.getFilterValues());
         });
   
         // search for values from API 
         this.$bus.$on(`recent-search`,(value)=>{
            this.setFilterValues({ keyword: value });
            this.getRecentValues(this.getFilterValues());
         });
      },

      setFilterValues(values){  
         this.$store.dispatch("setFilter",values);
      },

      /*
      * This gets the values from filters
      */ 
      getFilterValues(){

         let filter = this.$store.getters.getFilter;
         let value  = {};         

         if(Object.prototype.hasOwnProperty.call(filter,"site_id"))
         {
            Object.assign(value,{ site_id:filter.site_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"account_id"))
         {
            Object.assign(value,{ account_id:filter.account_id });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"date"))
         {  
            Object.assign(value,{ date:filter.date });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"page"))
         {
            Object.assign(value,{ page:filter.page });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"itemsPerPage"))
         {
            Object.assign(value,{ itemsPerPage:filter.itemsPerPage });
         }

         if(Object.prototype.hasOwnProperty.call(filter,"keyword"))
         {
            Object.assign(value,{ keyword:filter.keyword });
         }

         return value;
      },

      /*
      * get values from API
      */
      getRecentValues(payload){
         // loading for table 
         this.pagination.loading = true;

         // get timelogs records
         this.getRecent(payload).then(response=>{ 
            this.initializing = false;
            this.pagination.loading = false 
         });
      },

      /*
      * export data into an excel file 
      */
      export_exel()
      {
         this.export_timelogs(this.values)
      },

      /*
      * to view modal with map and timelog details
      */
      map(data)
      {  
         this.$store.dispatch("modal",{ title:"Logs", data:data, mode:"view", view:"modal-timelog-map" });
      },
      
      /*
      * This show the modal view for edit action
      */ 
      edit_item(data)
      {  
         this.$store.dispatch("modal",{ title:"Edit Manual Time Entry", data:data, mode:"edit", view:"modal-timelog-time-entry" });
      },

      /*
      * This show the modal view for delete action
      */ 
      delete_item(data)
      {  
         this.$store.dispatch("modal",{ title:"Delete Manual Time Entry", data:data, mode:"delete", view:"modal-timelog-time-entry" });
      },

      /*
      * to set timestamp/date into format date with 
      */
      format(value,type,format)
      {
         switch(type)
         {
            case "time": return moment(value).format(format);  break;
            case "date": return moment(value).format(format); break;
         }
      },

      /*
      * to set log type with design 
      */
      logtype(value)
      {
         switch(value)
         { 
            case "timein": return { color:"#E8F5E9" ,  text_color:"#5EAF59", format:"Time In"}; break;
            case "timeout": return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
            case "log": return { color:"#ffefc3" , text_color:"#ffbf4b", format:"Log"}; break;
            default: return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
         }
      },
   },

   computed:{
      ...mapGetters("recent",{ values:"getRecentList", totalItems:"totalItems", pagination:"paginations", per_page:"getPagePerItem" }),
   },

   watch:{
      "pagination.page"(oldV,oldN){
         this.getRecentValues(Object.assign(this.getFilterValues(), Object.assign(this.pagination,{ page:oldV })));
      },

      "pagination.itemsPerPage"(oldV,oldN){
         this.getRecentValues(Object.assign(this.getFilterValues(), Object.assign(this.pagination,{ itemsPerPage:oldV })));
      },
   }
}

</script>

<style type="text/css">

   .v-chip-label{
      width:90px;
      display:block;
      text-align:center;
   }

</style>