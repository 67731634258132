const state = {
	table:{ 
		list:[],
		pagination:{ page:0, per_page:0 },
		total:0,
	}, 
	assignment:[],
	employees:[],
	form:{
		title:null,
		paragraph:null,
		button_text:null,
		employee_role_id:null,
		broadcast_schedule:null,
		date:null,
		time:null,
		status:0,
	},
}

const getters = {

	/*
	* This sets a value of state table list
	*
	* @param state  
	*
	* @return void  
	*/
	getRecent:state=>state.table.list,

	getForm:state=>state.form,

	getAssignment:state=>state.assignment,

	getEmployees:state=>state.employees,


	/*
	* This gets count per page item
	*
	* @param state  
	*
	* @return void  
	*/
	getPagePerItem:state=>{
		return state.table.pagination.per_page;
	},

	/*
	* This gets a value of state table.list
	*
	* @param state  
	*
	* @return void  
	*/
	totalItems:state=>{
		return state.table.total;
	},
}

const mutations = {

	SET_FORM:(state,data)=>{

		Object.assign(state.form,data);

		if(Object.hasOwnProperty.call(state.form,"employee_role"))
		{	
			if(state.form.employee_role)
			{
				Object.assign(state.form,{ employee_role_id: Object.assign({},...state.form.employee_role).id });
			}
		}
	},

	SET_CLEAR_FORM:(state,data)=>{
		state.form = {
			title:null,
			paragraph:null,
			button_text:null,
			employee_role_id:null,
			broadcast_schedule:null,
			date:null,
			time:null,
			status:0,
		}
	},

	GET_ASSIGNMENT:(state,data)=>{
		state.assignment = data
	},

	GET_EMPLOYEES:(state,data)=>{
		state.employees = data
	},

	
	/*
	* This sets a value of state attendance.list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	GET_LIST:(state,payload) => {
		state.table.list = payload.data;
	    state.table.pagination.current_page = payload.current_page;
	    state.table.pagination.per_page = payload.per_page;
	    state.table.total = payload.total;
	},


	/*
	* UPDATE record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_RECORD:(state,payload)=>{
		let index = state.table.list.findIndex(list=> list.id == payload.data.id)
		
		if(index > -1){
			Object.assign(state.table.list[index],payload.data); 
		}
		else{
			state.table.list.unshift(payload.data);
			state.table.total += 1;  
		}
	},

	/*
	* DELETE record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_RECORD:(state,payload) => {
		let index = state.table.list.findIndex(list => list.id == payload.id)
		state.table.list.splice(index, 1)
		state.table.total -= 1;  
	},


} 

const actions = {

	setForm(context,data={}){
		context.commit("SET_FORM",data);
	},

	setClearForm(context){
		context.commit("SET_CLEAR_FORM");
	},
	
	send(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.post("/assignment-messages",data)
			.then((response)=>{ resolve(); context.commit("UPDATE_RECORD",response.data) })
			.catch((errors)=>reject(errors))
		});
	},

	getList(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.get("/assignment-messages",{
				params:{
					keyword:data.keyword,	
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : 10,
					orderBy:(data.sortBy && data.sortBy.length) ? data.sortBy.toString() : null,
					order:Object.hasOwnProperty.call(data,"sortDesc") && data.sortDesc[0] ? "desc" : "asc",
				} 

			})
			.then((response)=>{resolve(); context.commit("GET_LIST",response.data); })
			.catch((errors)=>reject(errors))
		});
	},

	delete(context,data){
		return new Promise((resolve,reject)=>{
			axios.delete(`/assignment-messages/${data.id}`)
			.then((response)=>{ resolve(data); context.commit("DELETE_RECORD",data); })
			.catch((errors)=>reject(errors))
		});
	},

	getAssignment(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.get(`/employee/assignments/${data.id}/${data.date}`)
			.then((response)=>resolve(response))
			.catch((errors)=>reject(errors))
		});
	},

	getEmployees(context,data={}){
		return new Promise((resolve,reject)=>{
			axios.get("/assignments/messages/employees")
			.then((response)=>{ resolve(response); context.commit("GET_EMPLOYEES",response); })
			.catch((errors)=>reject(errors))
		});
	},
}

export const message = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}