import store from "@/store"

const state = { 
	schedule:{ list:[] }
}

const getters = {

	/*
	* GET Schedule list
	* to set the list after fetching
	*/	
	getScheduleList:state=>{
		return state.schedule.list;
	}
}

const mutations = {

	/*
	* GET Schedule list
	* to get the list after fetching
	*/	
	GET_SCHEDULE_LIST:(state,list)=>{
		state.schedule.list = list;
	},

	/*
	* ADD Schedule record
	* to set the list additional record after add/registraion
	*/	
	ADD_SCHEDULE_RECORD:(state,data)=>{
		Object.assign(state.schedule.list, data);
	},

 	/*
	* UPDATE Schedule record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_SCHEDULE_RECORD:(state,data)=>{
		let index = state.schedule.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.schedule.list[index],data)
	},

	/*
	* DELETE Schedule record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_SCHEDULE_RECORD:(state,id) => {
		let index = state.schedule.list.findIndex(list => list.id == id)
		state.schedule.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch Schedule list
	*
	* @return string
	*/	
	getScheduleList:(context,auth = {})=>{
		axios.get(`/schedules`)
		.then((response) => { context.commit('GET_SCHEDULE_LIST',response.data); })
		.catch((errors)  => console.log(errors));
	},

	/*
	* Add/Register Schedule record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateOrRegisterSchedule:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/schedules`,data)
			.then((response)=>{})
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Add/Register Schedule record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterSchedule:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/schedules`,data)
			.then((response)=>{})
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Edit/Update Schedule record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateSchedule:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.put(`/schedules/${data.id}`,data)
			.then((response)=>{ context.dispatch("getScheduleList"); })
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>{ resolve(response) });
		});
	},
	
	/*
	* Delete/Remove Schedule record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteSchedule:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.delete(`/schedules/${data.id}`)
			.then((response)=>{})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const schedule = {
	state,
	getters,
	mutations,
	actions
}