

import { status_text, status_color, chips_color } from "@/helper/status";
import _ from "lodash";

export const excel = {

   methods:{
      
      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      excel_template(headers,title)
      {
         const workbook  = new Excel.Workbook();
         const worksheet = workbook.addWorksheet(`${title} Sheet`);
         const worksheet_title = `${title} template`;
         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_timelogs(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet = workbook.addWorksheet("Time Logs Sheet");
         const worksheet_title = "Time Logs";
         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         // 1st row as Headers 
         let headers = [

            { "header":"Log Type", "width":25 },
            { "header":"Log Date", "width":25 },
            { "header":"Log Time", "width":25 },
            { "header":"Employee Id", "width":25 },

            { "header":"First Name", "width":25 },
            { "header":"Last Name", "width":25 },
            { "header":"Location", "width":25 },
            { "header":"Temperature", "width":25 },
            { "header":"Source", "width":25 },
            { "header":"Server Timestamp", "width":25 },
            { "header":"Latitude", "width":25 },
            { "header":"Longitude", "width":25 },
         ];

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++ ){
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key)=>{

            // this set all values while iterating
            let values = [

               // log type
               data[key].type

               // Provider
               // ,data[key].organization && data[key].organization.face_recognition ? data[key].organization.face_recognition[0].name : "None"

               // log date
               ,moment(data[key].timestamp).format('DD MMM YYYY') 

               // log timestamp
               ,moment(data[key].timestamp).format('h:mm a')

               // employee id
               ,data[key].employee_id
               
               // firstname
               ,data[key].employee ? data[key].employee.firstname : null

               // lastname
               ,data[key].employee ? data[key].employee.lastname : null

               // Location
               ,data[key].location

               // Temperature
               ,data[key].temp ? data[key].temp+`°C` : "N/A" 

               // Source
               ,data[key].source ? data[key].source.source_name : null

               // Server Timestamp
               ,data[key].server_timestamp ? data[key].server_timestamp : null

               // Latitude
               ,data[key].latitude

               // Longitude
               ,data[key].longitude
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);
         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_timeline_report(data = [],title = "Timeline")
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = title;
         let   worksheet = workbook.addWorksheet(`${title} Record Sheet`);
         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = this.header; 

         // this iterate the column per header 
         for( let c = 0; c <= this.header.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [
               // firstname
               data[key].firstname,

               // lastname
               data[key].lastname,

               // days
               data[key].number_of_days,

               // durations
               data[key].hours_rendered,
            ];

            /*
            * this iterate the number of days base on month selected 
            * then add it all to an array for each row
            */
            for(let i = 0; i < this.dates.length; i++)
            {        
               values.push(data[key].timeline[this.dates[i]]);
            }

            // this add rows for worksheet 
            worksheet.addRow(values);
         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },
      
      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_attendance_today_report(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Attendance Today";
         let worksheet = workbook.addWorksheet("Attendance Today");
         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Attendance","width":25},
            { "header":"Deduction","width":25},
            { "header":"Employee Id","width":25},
            { "header":"First Name","width":25},
            { "header":"Last Name","width":25},
            { "header":"First Time In","width":25},
            { "header":"Last Time Out","width":25},
            { "header":"Schedule","width":25},
            { "header":"Hours Rendered","width":25},
            { "header":"Late","width":25},
            { "header":"Undertime","width":25},
            { "header":"Overtime","width":25},
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [
               data[key].attendance,
               data[key].deduction,
               data[key].employee_id,
               data[key].firstname,
               data[key].lastname,
               data[key].timelogs.first_time_in,
               data[key].timelogs.last_time_out,
               data[key].schedule ? data[key].schedule.name : "No Schedule",
               data[key].duration,
               data[key].late,
               data[key].undertime,
               data[key].overtime ? data[key].overtime.total : 0,
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

          /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_daily_activity_report(data = [], name = "Daily Activity Report")
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = name;
         let worksheet = workbook.addWorksheet("Attendance Today");
         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Employee Id","width":25},
            { "header":"First Name","width":25},
            { "header":"Last Name","width":25},
            { "header":"Hours Rendered","width":25},
            { "header":"Total Overtime","width":25},
            { "header":"Time In","width":25},
            { "header":"Time Out","width":25},

            { "header":"OT In","width":25},
            { "header":"OT Out","width":25},
            { "header":"OT Status","width":25},
            { "header":"Leave Status","width":25},
            { "header":"Employee Type","width":25},

         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [
               data[key].employee_id,
               data[key].firstname,
               data[key].lastname,
               data[key].hours_rendered,
               data[key].overtime ? data[key].overtime.total : 0,
               data[key].timein,
               data[key].timeout,

               data[key].overtime ? data[key].overtime.start_time : 0,
               data[key].overtime ? data[key].overtime.end_time : 0,
               data[key].overtime ? status_text(data[key].overtime.status) : "",
               data[key].leave ? status_text(data[key].leave.status) : "",
               data[key].employee_type,
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },


      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_calendar_report(data = [],category)
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = `Calendar Record (${category.toLowerCase()}s)`;
         let   worksheet = workbook.addWorksheet(`Calendar Record Sheet (${category.toLowerCase()})s`);
        
         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = this.excel_header; 

      worksheet.getRow(1).font = {
            bold:true
         }

         // this iterate the column per header 
         for( let c = 0; c <= this.headers.length; c++)
         {
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [
               // employee id
               data[key].employee_id,

               // firstname
               data[key].firstname,

               // lastname
               data[key].lastname,
            ];

            this.dates.forEach((keys,value)=>{
               
               let _data = { from:null, to:null, name:"No Schedule" };

               if(data[key].calendar[keys]) Object.assign(_data, data[key].calendar[keys]);

               if(category == "SHIFT"){
                  
                  let dayType = "Regular Day";

                  if(!_.isEmpty(data[key].calendar[keys])){
                     if(data[key].calendar[keys].day_type == 2) dayType = "Rest Day";
                  }

                  // values.push(
                  //    `${ _data.name }`
                  //    +", "+
                  //    `${moment(_data.from,"hh:mm A").format("hh:mm A")}`
                  //    +" - "+
                  //    `${moment(_data.to,"hh:mm A").format("hh:mm A")}`
                  // );

                  values.push(`${ _data.name },${dayType}`);
               }

               else if(category == "ACCOUNT")
               {  
                  values.push(`${data[key].calendar[keys] ? data[key].calendar[keys].name : "No Account" }`);
               }

               else if(category == "SITE")
               {
                  values.push(`${data[key].calendar[keys] ? data[key].calendar[keys].name : "No Site" }`);
               }

               else if(category == "ASSIGNMENT")
               {  
                  values.push(
                  `${data[key].calendar[keys] && data[key].calendar[keys][0] ? data[key].calendar[keys][0].name + (`${ data[key].calendar[keys] && data[key].calendar[keys].length > 1 ? ` +${data[key].calendar[keys].length }`:''}`)
                   : "No Assignment" }`);
               }
            });

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_organization(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Organizations";
         let   worksheet = workbook.addWorksheet("Organizations List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Id","width":25},

            { "header":"Name","width":25},

            { "header":"Code","width":25},

            { "header":"Short Name","width":25},   

            { "header":"Sites","width":25},

            { "header":"Mobile Admins","width":25},

            { "header":"Employees","width":25}
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // firstname
               data[key].id,

               // lastname
               data[key].name,

               // days
               data[key].code,

               // short_name
               data[key].short_name,

               // sites
               data[key].sites,

               // mobile_admins
               data[key].mobile_admins,

               // employees
               data[key].employees
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_site(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Sites";
         let   worksheet = workbook.addWorksheet("Site List");

         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [

            { "header":"Id","width":25},

            { "header":"Organizations","width":25},

            { "header":"Name","width":25},

            { "header":"Code","width":25},

            { "header":"Short Name","width":25},

            { "header":"Mobile Admins","width":25},

            { "header":"Employees","width":25}
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // firstname
               data[key].id,

                 // durations
               data[key].organization.name,

               // lastname
               data[key].name,

               // days
               data[key].code,

               // shortname
               data[key].short_name,

               // mobile admin
               data[key].mobile_admins,

               // employees
               data[key].employees

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },
      

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_assignments(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Assignments";
         let   worksheet = workbook.addWorksheet("Assignments List");

         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [

            { "header":"Id","width":25},

            { "header":"Organizations","width":25},

            { "header":"Assignment Name","width":25},

            { "header":"Assignment Code","width":25},

            { "header":"Assignment Short Name","width":25},

            { "header":"Employees","width":25}
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // id
               data[key].id,

               // organization name
               data[key].organization.name,

               // name
               data[key].name,

               // code
               data[key].code,

               // short name
               data[key].short_name,

               // employee count
               data[key].employee_count

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_employee_roles(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Employee Roles";
         let   worksheet = workbook.addWorksheet("Employee Roles List");

         
         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [

            { "header":"Id","width":25},

            { "header":"Organizations","width":25},

            { "header":"Employee Role","width":25},

            { "header":"Employee Role Code","width":25},

            { "header":"Business Rules","width":25},

            { "header":"Employees","width":25}
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // id
               data[key].id,

               // organization name
               data[key].organization.name,

               // name
               data[key].name,

               // days
               data[key].code,

               // type
               data[key].type,

               // employee count
               data[key].employee_count

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },
      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_accounts(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Accounts";
         let   worksheet = workbook.addWorksheet("Account List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [

            { "header":"Id","width":25},

            { "header":"Organizations","width":25},

            { "header":"Name","width":25},

            { "header":"Code","width":25},

            { "header":"Short Name","width":25},
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // firstname
               data[key].id,

               // organization
               data[key].organization.name,

               // lastname
               data[key].name,

               // days
               data[key].code,

               // shortname
               data[key].short_name,

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

     /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_mobile_admin(data = [])
      {  
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Mobile Admin";
         let   worksheet = workbook.addWorksheet("Mobile Admin List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Id","width":25},
            { "header":"First Name","width":25},
            { "header":"Middle Name","width":25},
            { "header":"Last Name","width":25},
            { "header":"Suffix","width":25},
            { "header":"Email","width":25},
            { "header":"Role","width":25},
            { "header":"Employee Id","width":25},
            { "header":"Organization Id","width":25},

         ];

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // Id
               data[key].id,

               // Firstname
               data[key].firstname,

               // Middlename
               data[key].middlename,

               // Lastname
               data[key].lastname,

               // Suffix
               data[key].suffix,

               // Email
               data[key].email_address,
              
               // Role
               data[key].role,
               
               // Employee Id
               data[key].employee_id,

               // Organization Id
               data[key].organization_id
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

       /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_admin(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Admin";
         let   worksheet = workbook.addWorksheet("Admins List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Id","width":25},
            { "header":"First Name","width":25},
            { "header":"Middle Name","width":25},
            { "header":"Last Name","width":25},
            { "header":"Suffix","width":25},
            { "header":"Role","width":25},
            { "header":"Email","width":25},
         ];

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // Id
               data[key].id,

               // First_name
               data[key].first_name,

               // Middle_name
               data[key].middle_name,

               // Last_name
               data[key].last_name,

               // Role
               data[key].suffix,

               // Role
               data[key].roles[0].name,

               // Email
               data[key].email,

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },


      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_employee(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Employees";
         let   worksheet = workbook.addWorksheet("Employee List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Id","width":15},
            { "header":"Employee Id","width":15},

            { "header":"First Name","width":15},
            { "header":"Middle Name","width":15},
            { "header":"Last Name","width":15},
            { "header":"Suffix","width":15},

            { "header":"Role","width":15},
            { "header":"Position","width":15},
            { "header":"Region","width":15},
            { "header":"Assigned Position","width":15},
            { "header":"Outlet","width":15},

            { "header":"Email Address","width":15},
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [
               data[key].id,
               data[key].employee_id,
               data[key].firstname,
               data[key].middlename,
               data[key].lastname,
               data[key].suffix,
               data[key].work_info ? data[key].work_info.role : null,
               data[key].work_info ? data[key].work_info.position : null,
               data[key].work_info ? data[key].work_info.region : null,
               data[key].work_info ? data[key].work_info.assigned_position : null,
               data[key].work_info ? data[key].work_info.outlet : null,
               data[key].contact ? data[key].contact.email : null,
            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_activities(data = [])
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = "Activity";
         let   worksheet = workbook.addWorksheet("Activity List");

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }


         const headers = [
            { "header":"Id","width":15},
            { "header":"Log Type","width":15},
            { "header":"Timestamp","width":15},
            { "header":"Role","width":15},
            { "header":"First Name","width":15},
            { "header":"Last Name","width":15},
            { "header":"Login IP","width":15},
         ]

         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            let values = [

               // Id
               data[key].id,

               // log_type
               data[key].log_type,

               // timestamp
               data[key].timestamp,

                // role
               data[key].role,

               // first_name
               data[key].first_name,

               // Last name
               data[key].last_name,

               // login_ip
               data[key].login_ip,

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });

         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },

      /*
      * this method calls by event v-on:click button excel,
      * this exports excel 
      */
      export_viewlogs(data = [],name)
      {
         const workbook  = new Excel.Workbook();
         const worksheet_title = name;
         let   worksheet = workbook.addWorksheet(name);

         // set bold first rows 
         worksheet.getRow(1).font = {
            bold:true
         }

         const headers = [
            { "header":"Dates","width":15},
            { "header":"Time In","width":15},
            { "header":"Time Out","width":15},
            { "header":"Duration (Hours)","width":15},
         ]
         // this set the columns for worksheet in excel that will be export 
         worksheet.columns = headers; 

         // this iterate the column per header 
         for( let c = 0; c <= headers.length; c++)
         {
            // let column = worksheet.getColumn(c);
            worksheet.getColumn(c);
         }
        
         // this iterate the value and set into an array 
         Object.keys(data).forEach((key) => {

            // this set all values while iterating
            
            let values = [

               // Id
               data[key].date,

               // first_name
               data[key].timein,

               // First name
               data[key].timeout,

               // Last name
               data[key].durations,

            ];

            // this add rows for worksheet 
            worksheet.addRow(values);

         });
  
         // save the file base on format by extension
         workbook.xlsx.writeBuffer().then(data => {
            const blob = new Blob([data], { type: this.blobType }); 
            FileSaver.saveAs(blob, `${worksheet_title}.xlsx`);
         });
      },
   }
}

     
