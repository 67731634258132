
const state = { 
	organization:{ list:[], record:"" }
}

const getters = {

	/*
	* GET organization list
	* to get the list of organization
	*/	
	getOrganizationList:state => {
		return state.organization.list;
	},

	/*
	* GET organization record
	* to get the organization record
	*/	
	getOrganizationRecord:state => {
		return state.organization.record;
	}
}

const mutations = {

	/*
	* GET organization list
	* to get the list after fetching
	*/	
	GET_ORGANIZATION_LIST:(state,list)=>{
		state.organization.list = list;
	},

	/*
	* GET organization record
	* to get the record after fetching
	*/	
	GET_ORGANIZATION_RECORD:(state,record)=>{
		state.organization.record = record;
	},

	/*
	* ADD organization record
	* to set the list additional record after add/registraion
	*/	
	ADD_ORGANIZATION_RECORD:(state,data)=>{
		Object.assign(state.organization.list, data);
	},

 	/*
	* UPDATE organization record   
	* to set the list additional record after add/registraion
	*/	
	UPDATE_ORGANIZATION_RECORD:(state,data)=>{
		let index = state.organization.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.organization.list[index],data)
	},
 
	/*
	* DELETE organization record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_ORGANIZATION_RECORD:(state,id) => {
		let index = state.organization.list.findIndex(list => list.id == id)
		state.organization.list.splice(index, 1)
	},
}

const actions = {
	
	/*
	* Search organization 
	*
	* @return array
	*/	
	setSearchOrganization(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/organizations/search",{ params:{ keyword:keyword} })
			.then((response)=>{ context.commit('GET_ORGANIZATION_LIST',response.data); resolve(response); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get/Fetch organization list
	*
	* @return string
	*/	
	getOrganizations(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/organizations")
			.then((response)=>{ context.commit('GET_ORGANIZATION_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Get/Fetch organization list
	*
	* @return string
	*/	
	getOrganizationsById(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get(`/organizations/${data.id}`)
			.then((response)=>{ context.commit('GET_ORGANIZATION_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Add/Register organization record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterOrganization(context,data)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.post("/organizations",data)
			.then((response)=>{ context.dispatch("getOrganizations");})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* Edit/Update organization record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateOrganization(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/organizations/${data.id}`,data)
			.then((response)=>{ context.dispatch("getOrganizations"); })
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove organization record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteOrganization(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/organizations/${data.id}`)
			.then((response)=>{ context.dispatch("getOrganizations");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const organization  = {
	state,
	getters,
	mutations,
	actions
}  