<template>
	<v-dialog v-model="dialog"  persistent max-width="500">
		<v-stepper v-model="step">
		    <v-stepper-items>
				<v-stepper-content step="1">
					<v-form v-model="form1" ref="form1" v-on:prevent.submit>
						<h2 class="text-left mb-5">Are you sure do you want to save this file as</h2>
						<v-text-field v-model="output_name" placeholder="Type here..." 
						:rules="[rules.output_name.required,rules.output_name.format]" 
						:error-messages="error.get('output_name')"
						:error.sync="error.has('output_name')"
						@input="error.clear('output_name')"
						clearable outlined dense/>
						<template style="display: flex;">
							<v-btn text @click="step == 1 ? setClose() : step = 1">
								{{ step == 1 ? 'Cancel' : 'Back' }}
							</v-btn>
							<v-btn color="primary" :disabled="!form1" @click="stepOne()">
								Next
							</v-btn>
						</template>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="2">
					<v-form v-model="form2" ref="form2" v-on:prevent.submit>
						<h2 class="text-left mb-5">What will you name this template ?</h2>
						<v-text-field v-model="title" placeholder="Type here..." 
						:rules="[rules.template_name.required,rules.template_name.format]" 
						:error-messages="error.get('template_name')"
						:error.sync="error.has('template_name')"
						@input="error.clear('template_name')"
						clearable outlined dense/>
						<template class="mr-auto">
							<v-btn @click="step = 1" text>Back</v-btn>
							<v-btn color="primary" :disabled="!form2" @click="stepTwo()">Next</v-btn>
						</template>
					</v-form>
				</v-stepper-content>
				<v-stepper-content step="3">
					<template class="my-12">
						<h2 class="my-5"> Generating Report </h2>
						<v-progress-circular class="my-5" indeterminate color="primary"/>
					</template>
				</v-stepper-content>
		    </v-stepper-items>
  		</v-stepper>
	</v-dialog>
</template>
                           
<script>
	import { mapActions, mapMutations, mapGetters } from "vuex";
	import Errors from "@/validation/errors";
	export default {
		data(){
			return {
		        error:new Errors(),
		        rules:{
		        	output_name:{
						required:value => !!value ||"Output Name is required !",
						format:value => /^[a-zA-Z0-9\s]*$/.test(value) || "Output Name must be valid !"
					},

					template_name:{
						required:value => !!value ||"Template Name is required !",
						format:value => /^[a-zA-Z0-9()\s]*$/.test(value) || "Template Name must be valid !"
					},
		        },
				form1:false,
				form2:false,
				step:1,
				
				pagination:{
		            page:1,
		            itemsPerPage:10,
		            loading:false,
		        },
			}
		},
		
		created(){
			this.initialize();
		},

		methods:{
			...mapActions("query_builder_modal",["setClose"]),
			...mapActions("builder",["getGenerateReport","setForm","doneGenerateReport","getTemplate","setCreateTemplate","setUpdateTemplate"]),

			initialize(){
				if(this.getOutputName != null){ 
					if(this.getOutputName != ""){
						this.step = 2; 
					}
				}

				 if(this.getTitle != null){ 
					if(this.getTitle != ""){
						this.handleSubmit(); 
						this.step = 3; 
					}
				}  
			},

			stepOne(){
				if(this.$refs.form1.validate()) this.step = 2; 
			},

			stepTwo(){
				if(this.$refs.form2.validate()) this.stepThree();
			},

			stepThree(){
				this.step = 3; 
				this.handleSubmit(); 
			},

			async handleSubmit(){
				event.preventDefault();
				if(!this.getForm.hasOwnProperty("id")){
					this.setCreateTemplate(this.form).then((response)=>{
						if(response) this.getTemplateById(response.data.id);
					});
				} else {
					this.setUpdateTemplate(this.form).then((response)=>{
						if(response) this.getTemplateById(response.data.id);
					});
				}
			},

			async getTemplateById(template_id){
				await this.getTemplate(template_id).then((response)=>{ 
					if(!this.$route.query.hasOwnProperty("id")){
						this.$router.push({ path:'/query-builder/template', query:{ id:template_id } });
					}
				})
				await this.setClose();
				await this.doneGenerateReport();
				await this.onGeneratePreview();
			},

			async onGeneratePreview(){
				let form = await this.getForm;
				if(form.hasOwnProperty("column_attributes")){
					if(form.column_attributes.hasOwnProperty("columns")){
						let columns = await form.column_attributes.columns.filter(data=>data.type !=null);
						await this.getGenerateReport(Object.assign(form.column_attributes,this.pagination));
					}
				}
			},
		},

		computed:{
			...mapGetters("query_builder_modal",{ "dialog":"getDialog"}),
			...mapGetters("builder",["getForm","getOutputName","getTitle"]),

			output_name:{
				get(){ return this.getOutputName; },
				set(value){ this.setForm({ column_attributes:{ output_name:value } }); }
			},

			title:{
				get(){ return this.getTitle; },
				set(value){ this.setForm({ title:value }); }
			},

			form(){
				return Object.assign(this.getForm,{ 
					column_attributes:JSON.stringify({
						...this.getForm.column_attributes, 
						editable:false 
					}),
					status:1,
				});
			}
		},
	}

</script>