<template>
   <v-card class="mx-3 elevation-2">
      <template v-if="AuthUserInfo && AuthUserInfo.url" >
         <iframe width="100%" style="height:100vh" :src="AuthUserInfo.url"></iframe>
      </template>
      <template v-else-if="AuthUserInfo && AuthUserInfo.organization && AuthUserInfo.organization.url" >
         <iframe width="100%" style="height:100vh" :src="AuthUserInfo.organization.url"></iframe>
      </template>
      <template v-else>
         <v-row>
            <template v-for="(item,key) in header">
               <v-col>
                  <v-chip class="font-weight-bold" outlined label dense x-large style="width:300px; height:50px;">
                     <v-row>
                        <v-col>
                           <label style="">{{item.name}}</label>
                        </v-col>
                        <v-col>
                           <label style="font-size: 18px;" >{{item.total}}</label>
                        </v-col>
                     </v-row>
                  </v-chip>
               </v-col>
            </template>
         </v-row>
         <v-row style="margin-top: -10px;">
            <v-col>
               <v-card>
                  <v-card-title>
                     <span style="font-size:18px;" class="font-weight-bold mr-4">Map</span>
                     <img style="width:16px;" :src="timein_mark" class="mr-2" /><span style="font-size:15px;" class="mr-2">Time In</span>
                     <img style="width:16px;" :src="timeout_mark" class="mr-2" /><span style="font-size:15px;" class="mr-2">Time Out</span>
                  </v-card-title>
                     <gmap-map ref="gmap" :center="center" :zoom="15" style="width:100%;  height: 100vh; ">
                        <gmap-marker :key="index" v-for="(value, index) in markers" :position="value.position":icon="value.icon"
                         @click="toggleInfoWindow(value,index)">
                        </gmap-marker>
                        <gmap-info-window :options="infoOptions" :position="infoWindowPos" :opened="isInfoWindowOpen" :clickable="true" :draggable="true" @closeclick="isInfoWindowOpen=false">
                           <div v-html="infoContent"></div>
                        </gmap-info-window>
                     </gmap-map>
               </v-card>
            </v-col>
         </v-row>
      </template>
   </v-card>
</template>

<script>

import { auth } from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

export default {
   name: "GoogleMap",
   mixins:[auth],
    data() {
      return {
         center: {lat:0, lng:0},
         map: null,
         infoContent:'',
         infoWindowPos: {
            lat: 0,
            lng: 0
         },
         isInfoWindowOpen: false,
         currentMidx: null,
         infoOptions: {
            pixelOffset: {
               width: 0,
               height: -35
            }
         },
         coordinates:{},
         fetched_coordinates:{},
         markers:[], 
         header:[
            { name:"Total Logs", total:0},
            { name:"Time Ins", total:0},
            { name:"Time Outs", total:0},
         ],

         timein_mark:require('@/assets/images/mark/time_in_mark.png'),
         timeout_mark:require('@/assets/images/mark/time_out_mark.png'),
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         authorized_user:"Super Admin"
      }
   },

   created()
   {
      this.getRecentMap();
   },

   methods: {
      ...mapActions("recent",["getRecentMap"]),

      /*
      * to set timestamp/date into format date with 
      *
      * @parameter value, the date
      * @parameter type, date only | time
      * @parameter format 
      *
      * @return string date
      */
      format(value,type,format)  
      {
         switch(type)
         {
            case "time": return moment(value).format(format);  break;
            case "date": return moment(value).format(format); break;
         }
      },

      toggleInfoWindow(marker, idx)
      {  
         this.infoWindowPos = marker.position;
         this.infoContent = this.getInfoWindowContent(marker);
        
         //check if its the same marker that was selected if yes toggle
         if (this.currentMidx == idx)
         {
            this.isInfoWindowOpen = !this.isInfoWindowOpen;
         }

         //if different marker set infowindow to open and reset current marker index
         else 
         {
            this.isInfoWindowOpen = true;
            this.currentMidx = idx;
         }
      },

      getInfoWindowContent(marker)
      {  
         return (`
         <div class="card" style="height:75px; width:250px; overflow-y:hidden; horizontal; overflow-x:hidden;">
            <div class="card-body row">
               <div class="col-md-4">
                  <img src="${marker.avatar}" style="width:50px; height:50px; border-radius:30px"  alt="Placeholder image">
               </div>
               <div class="col-md-6">
                  <div class="row" style="font-size:16px; font-weight:bold;">
                  ${marker.name}
                  </div>
                    <div class="row">
                  ${marker.description}
                  </div>
               </div>
            </div>
         </div>`);
      },
   },

   computed:{
      ...mapGetters("recent",{"values":"getRecentMap"})
   },

   watch:{

      /*
      * This set the values for map view
      *
      * @return void
      */ 
      values(data)
      {  
         let vm = this;

         // this iterate the value from data 
         Object.values(data).forEach(function(val,key){

            // get the timelog type for info window display
            // if the timelog is either timein or timeout
            let type = val.type == "timein"? "In at":"Out at";

            // if the timelog is admin , the circle marker color is green 
            // else the color of circle is grey 
            let icon = val.type == "timein"? vm.timein_mark : vm.timeout_mark;
             // if the latitude and longitude both not equal to zero
            if(parseFloat(val.latitude) != 0  && parseFloat(val.longitude)  != 0 )
            {  
                // set the array of markers for info window ballon 
               vm.markers.push({
                  "avatar":val && val.image && val.image.avatar ? val.image.avatar : vm.default_avatar ,
                  "name": val.employee ? `${val.employee.firstname} ${val.employee.lastname}`:'',
                  "description":`${type} ${vm.format(val.timestamp,'time','hh:mm a')} `,
                  "position": { lat:parseFloat(val.latitude), lng:parseFloat(val.longitude) },
                  "icon":{ "url":icon},
               }) 
            }
         }); 

         // total logs 
         this.header[0].total = data.length

         // time in total
         this.header[1].total = data.filter(value => value.type == "timein").length

         // time out total
         this.header[2].total = data.filter(value => value.type == "timeout").length 
      }, 

      /*
      * This watch the values from makers array
      * This set / plot the marker in the map
      * 
      * @return void
      */ 
      markers(value)
      {  
         // set bounds of the map
         // this display all markers in the map using values from array maker

         if(this.$refs.gmap)
         {
            this.$refs.gmap.$mapPromise.then((map) => {

               // create instance google map latlong bounds
               const bounds = new google.maps.LatLngBounds()

               // iterating the values with details 
               // for info window
               for (let m of value) 
               {
                  // if the latitude and longitude both not equal to zero
                  if(m.position.lat != 0  && m.position.lng != 0 )
                  {  
                     // set bound marker in position using both lat and lng
                     bounds.extend(m.position)
                  }
               }
               map.fitBounds(bounds);
            });
         } 
      }
   }
}
</script>

<style type="text/css">
   .label 
   {
      color:black;
      font-size: 16px;
   }
   .label-value {
      color:black;
      font-size: 30px; 
   }

</style>