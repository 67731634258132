<template>
	<v-card class="my-3">
		<v-card-text>
			<!-- Title -->
			<div class="my-3 d-flex justify-start">
				<h3>Column</h3>
			</div>
			<!--../Title -->

			<!-- Title -->
			<div class="my-1 d-flex justify-start">
				<h4>Assign Attribute</h4>
			</div>
			<!-- ../Title -->
			
			<!-- Show/Hide -->
			<div class="d-flex justify-space-around">
				<v-select 
					placeholder="Attributes" 
					style="height:45px;"
					item-text="text"
					item-value="type"
					@change="options"
					v-model="option"
					:items="items"
					return-object
					outlined
					dense
				/>
				<v-btn @click="showOrHideColumn(data.visible =!data.visible)" icon>
					<v-icon>{{ data.visible ?'mdi-eye':'mdi-eye-off' }}</v-icon>
				</v-btn>
			</div>
			<!-- ../Show/Hide -->

			<!-- ---- MULTIPLE ATTRIBUTE ---- -->
			<div 
				class="d-flex justify-space-around mx-auto" 
				style="height:45px;"
				v-for="(attribute, index) in attributes"
				:key="index"
			 >
				<template class="d-flex flex-row" v-if="multiColumnAttr">

					<BuilderAttributes 
						v-if="multiColumnAttr" 
						style="width:45px;"
						:namespace="option" 
						:value.sync="attribute.value" 
						@change="updateAttribute()"
					/>

					<v-btn 
						v-if="index < attributes.length  - 1" 
						@click="removeItem(attribute)" 
						icon
					>
						<v-icon>mdi-minus</v-icon>
					</v-btn>
					
					<v-btn 
						v-if="index >= attributes.length - 1" 
						:disabled="disabledSelectedItems < 1" 
						@click="addItem()" 
						icon
					>
						<v-icon>mdi-plus</v-icon>
					</v-btn>	
				</template>
			</div>
			<!-- ---- MULTIPLE ATTRIBUTE ---- -->

		</v-card-text>
	</v-card>
</template> 
<script>

	import { mapActions, mapGetters } from "vuex";

	import BuilderAttributes from "@/modules/QueryBuilder/Builder/components/BuilderAttributes";

	export default {
		components:{
			BuilderAttributes
		},

		props:{
			index:{  // key 
				type:Number,
				required:true,
			}
		},

		data(){
			return {
				multi_attr:['sites','accounts','employee-roles','assignments','schedules'],
				attributes:[],
				radio_val:null,
				data:{
					group:null,
					text:null,
					type:null,
					value:null,
					visible:true
				},
				multi:null
			}
		},

		created(){
			this.setInitializeAttributes();
			this.setInitialOptionAttributes();
		},

		methods:{

			...mapActions("builder", [
				"addColumn",
				"updateColumn",
				"setDeleteAssignAttribute",
				"setColumnVisibility",
				"setColumnAccessability"
			]),

			/*
			* to add another column attribute from multi column attribute
			* to initialize a values of null
			*/
			addItem(){
				this.attributes.push({ value:null });
			},

			/*
			* to remove column attributes added from multi column attribute
			*/
			removeItem(value){

				this.attributes.splice(this.attributes.indexOf(value),1);

				let index = this.multi_attr.indexOf(this.option);

				if(this.multi_attr[index])
				{
					this.$store.dispatch(`${this.multi_attr[index]}/setColumnAccessability`,{ 
						...value,
						boolean:false 
					});
				}

				this.updateColumn({
					...this.data, 
					index:this.index, 
					value:this.attributes.map(data=>data.value)
				});
			},

			/*
			* to get the values on radio button when it changes or not
			*/
			updateColumnUsingRadio(event){
				this.updateColumn({
					...this.data, 
					index:this.index, 
					value:event 
				});
			},

			/*
			* to update by event "on changes" 
			* when there is a value selected from it
			*/
			updateAttribute(){
				this.updateColumn({
					...this.data, 
					index:this.index, 
					value:this.attributes.map(data=>data.value)
				});
			},

			showOrHideColumn(boolean){
				this.setColumnVisibility({ 
					...this.data, 
					boolean:boolean
				});
			},

			/*
			* to set the attributes when created as initialization
			*/
			setInitializeAttributes(){
				let index = this.multi_attr.indexOf(this.option);
				if(this.multi_attr[index]){
					this.attributes = this.columns[this.index].value.map(data=>{ 
						return { value:data } 
					});
				}
			},

			/*
			* to set the attribute from computed "option"
			*/
			setOptionAttributes(attribute){

				// reset the array attributes 
				this.attributes.length = 0; 

				// set a new option attribute values
				Object.assign(this.data, attribute);

				// This is for column with multiple values 
				// For Account, Assignment, Employee Role, Schedules and Site 
				if(this.multi_attr[this.multi_attr.indexOf(attribute.type)])
				{ 
					// initialize
					this.attributes.push({ value:null }); 
					
					this.updateColumn(Object.assign(attribute,{ 
						index:this.index, 
						value:this.attributes.map(data=>data.value) 
					}));

					this.multi = this.$store.getters[`${attribute}/getRecords`]
				}

				else 
				{
					this.updateColumn(Object.assign(attribute,{ index:this.index }));
				}
			},

			/*
			* to set the option with initial value
			*/
			setInitialOptionAttributes(){
				// set a data object as cache to use the initialized data
				Object.assign(this.data,this.columns[this.index]);

				// when the record initialized then set the attribute as selected from dropdown
				// to disable reselection of an item which selected already
				this.setColumnAccessability({ type:this.columns[this.index].type, boolean:true });
			},
		},

		computed:{
			...mapGetters("builder",{ 
				"columns":"getColumns", 
				"items":"getColumnItems"
			}),

			disabledSelectedItems() {
				let type = this.multi_attr[this.multi_attr.indexOf(this.option)];
				if(type){
					return this.$store.getters[`${type}/getRecords`].filter(data=>data.disabled == false);
				}
			},

			option:{
				get(){ return this.columns[this.index].type; },
				set(attribute){ this.setOptionAttributes(attribute); }
			},

			// to check if the log is time in or out
			logTypeMatched(){
				return this.option == 'timein' || this.option == 'timeout';
			},

			multiColumnAttr(){
				return this.multi_attr[this.multi_attr.indexOf(this.option)];
			}
		},

		watch:{
			// when option have select then disable it for other column
			option(newVal,oldVal){	
				this.$nextTick(()=>{
					this.setColumnAccessability({ type:newVal, boolean:true });
					if(this.data.type != oldVal){
						this.setColumnAccessability({ type:oldVal, boolean:false });
					}
				})
			},

			// for a column with multiple attributes
			attributes(values){
				this.updateAttribute();
			}
		}
	}
</script>

<style>
	.theme--light.v-select .v-select__selection--disabled {
    	color: rgba(0, 0, 0, 1)!important;
	}
</style>