export default class Errors {

	constructor(){
		this.errors = {}
	}

	// this use to get blur the field when get error from backend
	get(field){
		if(this.errors[field]){
			return this.errors[field] ?  this.errors[field][0] : ''

		}
	}

	// this use to get blur the field when get error from backend
	has(field){
		return this.errors.hasOwnProperty(field);
	}

	// this to check if theres an error encountered 
	any(){
		return Object.keys(this.errors).length > 0; 
	}

	record(errors){
		this.errors = errors;
	}

	clear(field){
		delete this.errors[field];
	}
}