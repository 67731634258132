export const modal  = {

	methods:{
		
 		/*
 		* To redirect on url/page
 		* When creating record from other module side 
 		*
 		* @return void
 		*/ 
		redirect(path){
   			if (this.$route.path !== path){ this.$router.push(path) }
		},

 		/*
 		* To clear fields/reset
 		*
 		* @return void
 		*/ 
 		open(){
 			this.$store.dispatch("open");
 		},

 		/*
 		* To clear fields/reset
 		*
 		* @return void
 		*/ 
 		close(){
 			this.$store.dispatch("close");
 		},
	},

	computed:{

		/*
 		* modal name from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		name(){
			return this.$store.getters.name;
		},
		
		/*
 		* modal title from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		title(){
			return this.$store.getters.title;
		},

		/*
 		* modal text from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		text(){
			return this.$store.getters.text;
		},

		/*
 		* modal title from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		data(){
			return this.$store.getters.data;
		},
		
		/*
 		* modal mode "Add", "Edit" from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		mode(){
			return this.$store.getters.mode;
		},

		/*
 		* modal mode "Add", "Edit" from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		view(){
			return this.$store.getters.view;
		},

		/*
 		* modal title from /store/modules/dialog/index
 		*
 		* @return string
 		*/
		dialog(){
			return this.$store.getters.dialog;
		},	
	}
}