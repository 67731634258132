<template>
	<v-row justify="center">
		<v-dialog v-model="dialog" persistent max-width="290">
			<v-card>
				<v-card-title class="headline justify-center">{{ title }}</v-card-title>
				<v-card-text>{{ message }}</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn text @click="yes">Yes</v-btn>
					<v-spacer></v-spacer>
					<v-btn text @click="no">No</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
	export default {
		props:{
			title:{
				type:String,
				required:true,
			},
			message:{
				type:String,
				required:true,
			},   
			dialog:Boolean,
			response:Boolean,
		},
		methods:{
			yes(){
				this.$emit("update:response",true);
				this.$emit("update:dialog",false);
			},
			no(){
				this.$emit("update:dialog",false);
			}
		}
	}
</script>