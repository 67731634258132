export const mutations = {

	/*
	* to set the form, form cache and header as null 
	*/
	CLEAR_FORM(state,payload){
		state.headers.length = 0; // headers for the data table used 
		state.values.list.length = 0;  // for generate preview and report
		state.column_items.map(data=>data.disabled = false);
		state.form_cache = {}; // use in create and update only 
		state.form = { 
			title:null,
			status:null,		
			column_attributes:{
				keepTemplate:true,
				thisDay:false,
				editable:true,
				output_name:null,
				from:null,
				to:null,
				columns:[],
			},
		};
	},

	/*
	* to remove some values on custom columns
	* when the keep template is false 
	*/
	CLEAR_FORM_WHEN_UNKEEP_TEMPLATE(state){
		if(!state.form.column_attributes.keepTemplate){
			if(!state.editable && state.form.status != 2){
				state.headers.length = 0;
				state.form.column_attributes.columns.length = 0;
				state.column_items.map(data=>data.disabled = false);
			}
		} 
	},
 
	DELETE_ASSIGN_ATTRIBUTE(state, index){
		state.form.column_attributes.columns.findIndex(data=>data.index === index)
	},

	/*
	* when the page is starting to see template
	*/
	SET_FORM_VALUES(state,payload){
		Object.assign(state.form,Object.assign({}, payload));
	},
	
	/*
	* to set the available visibility of each sections on builder
	*/
	SET_VISIBILITY(state,payload){
		state.visibility = payload;
	},

	/*
	* to set the loading state of the whole query builder
	*/
	SET_LOADING(state,boolean){
		state.loading = boolean;
	},

	SET_TABLE_LOADING(state,boolean){
		state.table_loading = boolean;
	},

	/*
	* to add date parent header as a group of subheader
	*/
	SET_TABLE_DATE_HEADER(state){

		let from = moment(state.form.column_attributes.from);
		let to = moment(state.form.column_attributes.to);
		let values = [];

		// iterate, to get the dates in range
		while (from <= to) {
			values.push(moment(from).format("YYYY-MM-DD")); // push the dates in to an array
			from = moment(from).add(1, 'days'); // addition 1 day
		}

		// reset the header with group of 2 
		// for header with date value		
		state.headers = state.headers.filter(data=>data.group != 2)

		// push the object as new 
		values.map(date=>{
			state.headers.push({ 
				text:moment(date).format("MMM DD"), 
				value:date, 
				group:2, 
				subheader:state.form.column_attributes.columns.filter(data=>data.group == 2) ||[]
			});
		});
	},

	/*
	* to add parent header as a group of subheader
	*/
	SET_ADD_PARENT_HEADER_TABLE_BY_GROUP(state,group){
		let _group = state.form.column_attributes.columns.filter(data=>data.group == group);
		if(_group.length){
			state.headers = state.headers.filter(data=>data.group != group)
			state.headers.push({ 
				text:"",
				value:`column-${group}` ,
				group:group,  
				subheader:_group
			});
		}
	},

	/*
	* to add parent header as a group of subheader 
	* if one of the custom column selected and theres no group belongs to 
	* create a parent header 
	*/
	SET_ADD_PARENT_HEADER_TABLE(state,column){
		if(column.length){
			let { group } = column[0];
			let headerHasGroup = state.headers.findIndex(data=>data.group == group);
			if(headerHasGroup < 0) { 
				state.headers.push({ 
					text:null, value:`column-${group}`, group:group, subheader:column  
				});
			}
		}
	},

	/*
	* to add subheader on the table from a selected column  
	*/
	SET_ADD_PARENT_SUBHEADER_TABLE(state,column){
		if(column.length){
			let { group } = column[0];
			let headers = state.headers.filter(data=>data.group == group);
			let subheader = state.form.column_attributes.columns.filter(data=>data.group == group);
			headers.map(data=>{ data.subheader = subheader; });
		}
	},

	/*
	* to sort the data table header by group 
	*/
	SET_SORT_HEADER_BY_GROUP_TABLE(state){
		state.headers.sort((a,b)=>a.group - b.group);
	},

	/*
	* to add column that triggers by + add button
	*/
	SET_ADD_COLUMN(state,value){	
		state.form.column_attributes.columns.push(value);
	},

	/*
	* to update the column_attributes with a new created column 
	* to update when the column selected has changes 
	*/
	SET_UPDATE_COLUMNS(state,value){	
		state.form.column_attributes.columns.splice(value.index,1,value);
	},

	/*
	* set column attribute selected as disabled 
	* to prevent selection of already selected item
	*/
	SET_COLUMN_ACCESSABILITY(state,value){
		let val = state.column_items.findIndex(data=>data.type == value.type);
		if(val > -1) state.column_items[val].disabled = value.boolean;
	},

	/*
	* set column attribute selected as disabled 
	* to prevent selection of already selected item
	*/
	SET_COLUMN_ACCESSABILITY2(state,value){
		let val = state.form.column_attributes.columns.findIndex(data=>data.type == value.type);
		if(val > -1) state.form.column_attributes.columns[val].disabled = value.boolean;
	},

	/*
	* to show or hide some custom columns
	*/
	SET_COLUMN_VISIBILITY(state,value){
		let headers = state.headers.findIndex(data=>data.group == value.group);
		if(headers > -1){
			let subheader = state.headers[headers].subheader.findIndex(data=>data.type == value.type);
			if(subheader > -1){
				state.headers[headers].subheader[subheader].visible = value.boolean;
			}
		}
	},

	/*
	* to set the column attibutes 
	*/
	SET_COLUMN_ATTRIBUTES(state,value){
		Object.assign(state.form.column_attributes,value);
	},

	/*
	* to set values in default columns 
	*/
	SET_DEFAULT_COLUMN_VALUES(state, value){	
		Object.assign(state.form.column_attributes, value);
	},

	/*
	* to make a form cache after the create and update done
	*/
	SET_FORM_CACHE_VALUES(state,payload){
		state.form_cache = payload;
	},

	/*
	* to use the form cache into form
	* to edit the values supplied 
	*/
	SET_FORM_WITH_FORM_CACHE_VALUES(state){
		state.form = state.form_cache;
	},
	
	/*
	* To ensure the template with non keep template can be editable 
	* after creation and update then trying to edit again for the second time.
	*/
	SET_FORM_EDITABLE(state,boolean){
		state.editable = boolean;
	},

	/*
	* to parse the column attributes from string into an object 
	*/
	SET_PARSE_FORM_CACHE_VALUES(state){
		if(state.form_cache.column_attributes){
			state.form_cache.column_attributes = JSON.parse(state.form_cache.column_attributes);
		}
	},

	/*
	* when the page is starting to see template
	*/
	SET_PARSE_CUSTOM_ATTRIBUTES(state,payload){
		state.form = Object.assign(state.form, payload);
		if(state.form.column_attributes){
			state.form.column_attributes = JSON.parse(state.form.column_attributes);
		}
	},

	SET_VALUES(state,payload){
		state.values.list = payload.data;
		state.values.pagination.page = payload.current_page;
	    state.values.pagination.per_page = payload.per_page;
	    state.values.total = payload.total;
	},

	/*
	* SET holidays details  
	* to set the holiday objects after fetching
	*/	
	SET_HOLIDAYS_OBJECT:(state,payload) => {
		state.holidays = payload;
	}
}