const state = { 
	recent:{
		list:[],
		map:[],
		pagination:{ page:0, per_page:0 },
		total:0,
		filter:{ site:0, account:0 }
	},

	individualEmployeeLog:{
		list:[]
	}
}

const getters = {
	
	/*
	* This sets a value of state recent list
	*
	* @param state  
	* @param list
	*
	* @return array  
	*/
	getRecentList:state => {
		return state.recent.list;
	},
	
	/*
	* This sets a value of state individual employee log list
	*
	* @param state  
	* @param list
	*
	* @return array  
	*/
	getIndividualEmployeeLogList:state => {
		return state.individualEmployeeLog.list;
	}, 

	/*
	* This sets a value of state recent map
	*
	* @param state  
	* @param list
	*
	* @return array  
	*/
	getRecentMap:state => {
		return state.recent.map;
	},

	// pagination of table 
	getPagePerItem:state=>{
		return state.recent.pagination.per_page;
	},

	totalItems:state=>{
		return state.recent.total;
	},

	pagination:state=>{
		return state.recent.pagination.page;
	}
}

const mutations = {

	/*
	* This sets a value of state recent list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_RECENT_LIST:(state,payload) => {
		state.recent.list = payload.data;
	    state.recent.pagination.current_page = payload.current_page;
	    state.recent.pagination.per_page = payload.per_page;
	    state.recent.total = payload.total;
	},


	/*
	* This sets a value of state recent list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_INDIVIDUAL_EMPLOYEE_LOGS_LIST:(state,payload) => {
		state.individualEmployeeLog.list = payload.data;
	},

	/*
	* This sets a value of state recent list
	*
	* @param state  
	* @param list
	*
	* @return void  
	*/
	SET_RECENT_MAP:(state,payload) => {
		state.recent.map = payload;
	},
}

const actions = {

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getRecent:(context,data={}) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/recent",{
				params:{
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : context.state.recent.total,
					account:data.account_id,
					site:data.site_id,
					date:data.date,
					keyword:data.keyword,
				}
			})
			.then((response)=>context.commit('SET_RECENT_LIST',response.data))
			.then((response)=>resolve(response))
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getIndividualEmployeeLogs:(context,data) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/employee/logs",{ 
				params:{
					employee_id:data.employee_id,
					organization_id:data.organization_id,
					from:data.from,
					to:data.to
				}
			})
			.then((response)=>{context.commit("SET_INDIVIDUAL_EMPLOYEE_LOGS_LIST",data); resolve(response); })
			.then((response)=>resolve(response))
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},

	/*
	* This do an actions, to set the data into state through mutations
	*
	* @param context  
	*
	* @return void  
	*/
	getRecentMap:(context,data={}) => {
		return new Promise((resolve,reject)=>{
			axios.get("/dtr/timelogs/recent")
			.then((response)=>context.commit('SET_RECENT_MAP',response.data))
			.then((response)=>resolve(response))
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},

	/*
	* To get the reverse geolocation for timelogs
	*
	* @param context  
	*
	* @return void  
	*/
	setCreateLog:(context,data) => {
		return new Promise((resolve,reject)=>{
		 	axios.post("/dtr",data)
			.then((response)=>resolve(response))
		 	.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},

	/*
	* To get the reverse geolocation for timelogs
	*
	* @param context  
	*
	* @return void  
	*/
	setDeleteLog:(context, data)=>{
		return new Promise((resolve, reject)=>{
			axios.delete(`/dtr/${data.id}`)
			.then((response)=>{
				resolve(response);
			})
			.catch((errors)=>{
				reject(errors);
			})
		});
	},

	setUpdateLog:(context, data)=>{
		return new Promise((resolve,reject)=>{
			axios.put(`/dtr/${data.id}`,data)
			.then((response)=>{
				resolve(response);
			})
			.catch((errors)=>{
				reject(errors);
			});
		});
	}
}

export const recent = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}