import store from "@/store"

const state = {
	filter:{}
}

const getters = {
	getFilter:state=>{
		return state.filter;
	}
}

const mutations = {
	SET_FILTER:(state,value)=>{
		Object.assign(state.filter,value);
	},

	SET_CLEAR_FILTER:(state,value)=>{
		state.filter = {};
	}
}

const actions = {
	setFilter(context,data){
		context.commit("SET_FILTER",data);
	},
	clearFilter(context){
		context.commit("SET_CLEAR_FILTER");
	}
}

export const filter = {
	state,
	getters,
	mutations,
	actions
}