import store from "@/store"

const state = { 
	face_recognition:{ list:[], record:[] }
}

const getters = {

	/*
	* GET Face Recognition list
	* to set the list after fetching
	*/	
	getFaceRecognitionList:state=>{
		return state.face_recognition.list;
	},

	/*
	* GET Face Recognition record
	* to set the record after fetching
	*/	
	getFaceRecognitionRecord:state=>{
		return state.face_recognition.record;
	}
}

const mutations = {

	/*
	* GET Face Recognition record
	* to get the record after fetching
	*/	
	GET_FACE_RECOGNITION_RECORD:(state,record)=>{
		state.face_recognition.record = record;
	},

	/*
	* GET Face Recognition list
	* to get the list after fetching
	*/	
	GET_FACE_RECOGNITION_LIST:(state,list)=>{
		state.face_recognition.list = list;
	},

	/*
	* ADD Face Recognition record
	* to set the list additional record after add/registraion
	*/	
	ADD_FACE_RECOGNITION_RECORD:(state,data)=>{
		Object.assign(state.face_recognition.list, data);
	},

 	/*
	* UPDATE Face Recognition record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_FACE_RECOGNITION_RECORD:(state,data)=>{
		let index = state.face_recognition.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.face_recognition.list[index],data)
	},

	/*
	* DELETE Face Recognition record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_FACE_RECOGNITION_RECORD:(state,id) => {
		let index = state.face_recognition.list.findIndex(list => list.id == id)
		state.face_recognition.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch Face Recognition list
	*
	* @return string
	*/	
	getFaceRecognitions(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/face-recognitions")
			.then((response)=>{ context.commit('GET_FACE_RECOGNITION_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get/Fetch Face Recognition list
	*
	* @return string
	*/	
	getFaceRecognition(context)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/face-recognitions/record")
			.then((response)=>{ context.commit('GET_FACE_RECOGNITION_RECORD',response.data);})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*	
	* Add/Register Face Recognition record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterFaceRecognition(context,data)
	{
		return new Promise((resolve,reject)=>{ 
			axios.post("/face-recognitions",data)
			.then((response)=>{ resolve(response);})
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>context.dispatch("getFaceRecognitionList"));
		})
	},
	
	/*
	* Edit/Update Face Recognition record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateFaceRecognition(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/face-recognitions/${data.id}`,data)
			.then((response)=>{ context.dispatch("getFaceRecognitionList"); resolve(response);})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Edit/Update Face Recognition record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setFRProviderByOrganization(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.post("/face-recognitions/settings/assign/provider",data)
			.then((response)=>{ context.dispatch("getFaceRecognitionList"); resolve(response);})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Delete/Remove Face Recognition record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteFaceRecognition(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/face-recognition/${data.id}`)
			.then((response)=>{ context.dispatch("getFaceRecognitionList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const face_recognition = {
	state,
	getters,
	mutations,
	actions
}