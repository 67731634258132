const state = {
	dialog:true,
	modal:{		

		// NAME OF ACCESS FOR MODAL EX. ORGZNIATION
		name:"",

		// MODAL TITLE EX. REGISTRATION
		title:"",

		// MODAL TEXT EX. DO YOU WANT TO DELETE
		text:"",

		// DATA WILL SET FOR MODAL FIELDS
		data:"",

		// EITHER ADD OR DELETE
		mode:"",

		// VIEW COMPONENT
		view:"",
	},
}

let newState = {};

const getters = {

	dialog:state=>{
		return state.dialog;
	},

	name:state=>{
		return state.modal.name;
	},
	
	title:state=>{
		return state.modal.title;
	},
	
	text:state=>{
		return state.modal.text;
	},

	data:state=>{
		return state.modal.data;
	},

	mode:state=>{
		return state.modal.mode;
	},

	view:state=>{
		return state.modal.view;
	},
}

const mutations = {

	SET_DIALOG:(state,data)=>{
		state.dialog = data;
		state.modal.data = ""
	},

	SET_NAME:(state,data)=>{
		state.modal.name = data
	},

	SET_TITLE:(state,data)=>{
		state.modal.title = data
	},

	SET_TEXT:(state,data)=>{
		state.modal.text = data
	},

	SET_DATA:(state,data)=>{
		state.modal.data = data
	},

	SET_MODE:(state,data)=>{
		state.modal.mode = data
	},

	SET_VIEW:(state,data)=>{
		state.modal.view = data
	},
}

const actions = {
    
	modal(context,modal)
	{  
		context.dispatch("open");

		context.commit("SET_NAME",modal.name);

		context.commit("SET_TITLE",modal.title);

		context.commit("SET_TEXT",modal.text);

		context.commit("SET_DATA",modal.data);

		context.commit("SET_MODE",modal.mode);

		context.commit("SET_VIEW",modal.view);
	},

	close(context){ context.commit("SET_DIALOG",false); },

	open(context){  context.commit("SET_DIALOG",true) },
}

export const dialogs = {
	state,
	getters,
	mutations,
	actions
}