
	export function status_text(value){
        let text = "Pending"
        switch(value){
            case 1:
               text = "Approved";
            break;
            case 2:
               text = "Disapproved";
            break;
        }
        return text;
    }

    export function status_color(value){
        let color = "grey"
        switch(value){
            case 1:
               color = "green";
            break;
            case 2:
               color = "red";
            break;
        }
        return color;
    }

    export function chips_color(value){
        let color = "#efefef"
        switch(value){
            case 1:
               color = "#cfffcc";
            break;
            
            case 2:
               color = "#fecbcb";
            break;
        }
        return color;
    }

	/*
	* to set log type with design 
	*
	* @parameter value, the date
	*
	* @return string object
	*/
	export function logtype(value)
	{
		switch(value)
		{ 
			case "timein": return { color:"#E8F5E9" ,  text_color:"#5EAF59", format:"Time In"}; break;
			case "timeout": return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
			case "log": return { color:"#ffefc3" , text_color:"#ffbf4b", format:"Log"}; break;
			default: return { color:"#E0E0E0" , text_color:"#757575", format:"Time Out" }; break;
		}
	}