<template>
   <v-card class="mx-3 elevation-2">
      <iframe width="100%" style="height:100vh" :src="`${url}/${AuthOrganization.id}`"></iframe>
   </v-card>
</template>

<script>

import { auth } from "@/mixins/auth";

export default {
   mixins:[auth],
    data() {
      return {
         url:process.env.VUE_APP_TIMESHEET_URL
      }
   },
}
</script>

<style type="text/css">
   .label 
   {
      color:black;
      font-size: 16px;
   }
   .label-value {
      color:black;
      font-size: 30px; 
   }

</style>