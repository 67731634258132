// VUE ROUTER
import vue from "vue"
import router from "@/router";
import store from "@/store"

const state = { 
    token: localStorage.getItem("abizo_user_token") || "",
    status: "",
    user:{
        info:"",
        role:""
    }
}
    
const getters = {

    authloggedIn(state) 
    {
        return state.token !== null;
    },

    authUserInfo(state)
    {
        return state.user.info;
    },

    authUserRole(state) 
    {
        return state.user.info.roles ? state.user.info.roles.map((value)=>{ return value.name }) : null;
    },

    authUserOrganization(state) 
    {
        return state.user.info.organization;
    },

    isAuthenticated({state, context})
    {
        return localStorage.getItem("abizo_user_token") ? true : false;
    }
}

const mutations = {

    SET_TOKEN(state,value)
    {
        // just assigning to an state
        state.token = value;
        
        // set the token get after authentication done 
        localStorage.setItem("abizo_user_token",value);
    },
    
    SET_USER_INFO(state,value)
    {
        // just assigning to an state
        state.user.info = value;
    },
   
    SET_USER_ROLE(state,value)
    {
        // just assigning to an state
        state.user.info = value;
    },
    
    AUTH_LOGOUT({commit, dispatch})
    {
        // remove the axios default header
        delete axios.defaults.headers.common["Authorization"];

        // clear your user"s token from localstorage
        localStorage.removeItem("abizo_user_token");

        // get to blank url 
        router.go('/');
    },
}

const actions = {

    /*
    * Get user profile if the user authenticated
    *
    * @return void
    */  
    profile({commit,dispatch}){
        axios.get("/users/profile")
        .then((response)=>{  
            // set user record profile 
            commit("SET_USER_INFO",response.data);
            commit("SET_USER_ROLE",response.data);

         })
        .catch((errors)=>{})
    },

    /*
    * Login for user credential 
    *
    * @return string
    */  
    login({commit, dispatch}, credentials){
        return new Promise((resolve,reject)=>{
            axios.post("/login",credentials)
            .then(response=>{ 
                if(response.status === 200){

                    // set access token 
                    commit("SET_TOKEN",response.data.token);

                    // get the authenticated profile
                    dispatch('profile');

                    // this set the aut
                    axios.defaults.headers.common["Authorization"] =`Bearer ${response.data.token}`;
                    
                    // response when resolve
                    resolve(response);
                }
            })
            .catch(errors=>{ reject(errors); })
        })
    },

    /*
    * To logout from API the token and clear the token from local storage
    * Revoke token issued to user 
    *
    * @return string
    */   
    logout({commit, dispatch}){
        return new Promise((resolve,reject)=>{

            axios.post("/logout").then(response=>{ 
                // set logout / clear the token
                commit("AUTH_LOGOUT");
                resolve(response);
            })
            .catch(errors=>{ reject(errors); 
                if(error.status == 401){
                    store.dispatch("clear");
                }
            })
        })

        // remove the axios default header
        delete axios.defaults.headers.common["Authorization"];
    },


    /*
    * To clear the token from local storage
    * To able to clear if not able to do logout due to 401 
    *
    * @return void
    */  
    clear({commit, dispatch}){
        // set logout / clear the token
        commit("AUTH_LOGOUT");
    },
}

export const auth  = {
    state,
    getters,
    mutations,
    actions
}


