<template>
	<v-text-field v-model="query" @keyup="onSearch" outlined dense :placeholder="placeholder" prepend-inner-icon="search"> </v-text-field>
</template>

<script>
	
	import { mapActions } from 'vuex';

	export default {
		props:["value","placeholder","disabled"],
		data(){
			return {
				query:"",
				outputValue: '',
          		timeout: null
			}
		},

		updated()
		{
			this.route = this.$route.name;
		},

		methods:{

			...mapActions(["setSearchOrganization","setSearchSite","setSearchUser","setSearchMobileAdmin", "setSearchEmployee", "setSearchActivity", "setCalendarSearch", "setSearchAccount"]),

			// after entering some text in the input field
			// the text will be shown after 1 second
			onSearch(){
				
				 // clear timeout variable
				clearTimeout(this.timeout);

				var self = this;
				this.timeout = setTimeout(function () {
					// enter this block of code after 1 second
					// handle stuff, call search API etc.
					self.$bus.$emit("keyword",self.query);
					self.$bus.$emit(`${self.$route.name}-search`,self.query);
					self.outputValue = self.query;
				}, 1000);
			},
		},

		watch:{

			"$route"(to,from)
			{	
				to.name == "calendar" || to.name == "calendar_monthly" ? true : this.query = null; 
			},
		}
	}
</script>