<template>
	<main>
		<v-data-table 
		:items="values" 
		:headers="headers" 
		loading-text="Loading" 
		:options.sync="pagination"
		:loading="pagination.loading" 
		:server-items-length="totalItems" 
		:item-per-page="10">
			
			<template v-slot:item.title="{ item }">
				<p>{{ titleLable(item) }}</p>
			</template>

			<template v-slot:item.recipient="{ item }">
				<v-chip label class="v-chip-label">{{ parseArrayObjectToText(item.employee_role,"None") }}</v-chip>
			</template>

			<template v-slot:item.updated_at="{ item }">
				<span>{{ dateFormat(item.updated_at) }}</span>
			</template>

			<!-- -------------- ACTIONS ---------------- -->
			<template v-slot:item.action="{ item }" >
		        <v-menu bottom left transition="scale-transition">
	            	<template v-slot:activator="{ on }">
				        <v-btn slot="activator" v-on="on" icon>
				            <v-icon>mdi-dots-horizontal</v-icon>
				        </v-btn>
			    	</template>
		            <v-list>
		            	<v-list-item  @click="editMessage(item)" v-if="item.status == 0 || item.status == 2">
					        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-pencil</v-icon>
					        <v-list-item-title> Edit </v-list-item-title>
						</v-list-item>
						<v-list-item  @click="resendMessage(item)" v-else-if="item.status == 1">
					        <v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-telegram</v-icon>
					        <v-list-item-title> Resend </v-list-item-title>
						</v-list-item>
						<v-list-item  @click="deleteMessage(item)">
							<v-icon style="font-size: 20px; color:#212529;" class="mx-2">mdi-delete</v-icon>
							<v-list-item-title> Delete </v-list-item-title>
						</v-list-item>
		            </v-list>
		        </v-menu>
	        </template>
		</v-data-table>
 
		<Dialog v-if="dialog" :title="title" :message="message" :dialog.sync="dialog" :response.sync="response"/>
	</main>
</template>

<script>

	import { mapGetters, mapActions } from "vuex";
	import Dialog from "@/components/Fields/Compose/Dialog/Index.vue";

	export default {
		components:{
			"Dialog":Dialog,
		},
		data(){
			return {
				headers:[ 
					{ "text":"Title", "value":"title"},
					{ "text":"Recipient", "value":"recipient", "sortable":false },
					{ "text":"Last edited date", "value":"updated_at"},
					{ "text":"", "value":"action", "sortable":false },
				],
				title:"",
				message:"",
				form:{},
				type:null,
				dialog:false,
				response:false,
				pagination:{
					page:1,
					itemsPerPage:10,
					loading:true
				},
				initializing:false,
			}
		},
		async mounted(){
			this.fetch();
		},
		methods:{
			...mapActions("message",["getList","send","delete","setForm"]), 

			/*
			* To parse the object into a text 
			*/
			dateFormat:(date)=>moment(date).format("MMM DD, YYYY"),

			/*
	 		* To set the message form and get edit
	 		*/ 
			editMessage(item){ this.setForm(item); this.$bus.$emit("edit",true); },
			
			/*
	 		* To set the message form status as new  
	 		*/ 
			resendMessage(item){ 
				this.setDialog(item,"Resend Message","Do you want to resend message","resend");
			},

			/*
	 		* To delete the message
	 		*/ 
			deleteMessage(item){
				this.setDialog(item,"Delete Message","Do you want to delete message","delete");
			},
			
			/*
	 		* This fatch values with or without query parameter
	 		*/ 
			fetch(page){	
				this.getList(Object.assign(this.pagination,page)).then(response=>{ 
					this.sorting = true;
					this.initializing = false;
					this.pagination.loading = false;
				});
			},

			/*
			* To parse the object into a text 
			*/
			parseArrayObjectToText(array,placeholder){     
				if(array && array.length > 0){  
					return (array.length > 1) ? `${array.map(data=>data.name)[0].toString()} + ${array.length - 1}` : array.map(data=>data.name).toString();
				} 
				return placeholder;
			},

			/*
			* To parse the object into a text 
			*/
			titleLable(values){	
				let title =  values.title;
				if(Object.hasOwnProperty.call(values,"status") && values.status == 2){
					title += ` (Draft)`;
				}
				if(Object.hasOwnProperty.call(values,"broadcast_schedule") && values.broadcast_schedule){
					title += ` (Scheduled for broadcast: ${moment(values.broadcast_schedule).format("MMMM DD, YYYY hh:MM a")})`;
				}
				return title;
			},

			/*
	 		* To set dialog values 
	 		*/
			setDialog(item,title,message,type){
				this.form = Object.assign({},item);
				this.message = message;
				this.title = title; 
				this.type = type;
				this.dialog = true; 
			}
		},

		computed:{ 
			...mapGetters("message",{ "values":"getRecent", totalItems:"totalItems" }), 
		},

		watch:{
			response(value){
				if(value && this.type == "delete") {
					this.delete(this.form).then(response=>{ this.response = false; })
				} 
				else if(value && this.type == "resend") {
					this.send(Object.assign(this.form,{ status:0 })).then(response=>{ this.response = false; })
				}
				this.dialog = false;
			},

			"pagination.page"(oldV,oldN){
				this.fetch(this.pagination);
			},

			"pagination.itemsPerPage"(oldV,oldN){
				this.fetch(this.pagination);
			},

			"pagination.sortDesc"(oldV,oldN){
				if(oldV.length > 0){
					this.fetch(this.pagination);
				}	
			},
		}
	}
</script>

<style type="text/css">
   .v-chip-label{
      width:90px;
      display:block;
      text-align:center;
      width:auto;
   }
</style>