<template>
    <v-row justify="center">
		<v-dialog v-model="dialogs" persistent scrollable max-width="800px" @keydown.esc="close()">
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text class="card-text">
					<div class="modal-text">{{ text }}</div>
				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">
	            	
	               <!-- ------------- DELETE OR DISCARD ------------- -->
	               <v-btn dark  class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
	               @click="mode == 'delete' ? submit(mode) : cancel()"
	               :color="mode == 'delete'?'red':'#0057AD'"
	               :loading="mode == 'delete'? isSubmit : false ">
	               {{ mode == 'delete' ? 'Delete':'Discard'}} 
	               </v-btn>

	               <v-spacer></v-spacer>

	               <!-- ------------- CANCEL OR SUBMIT ------------- -->
	               <v-btn  dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
	               @click="mode == 'delete' ? close() : submit(mode)"
	               :loading="mode == 'delete'? false  : isSubmit">
	               {{ mode == 'delete' ? 'Cancel':'Submit' }}
	               </v-btn> 

	            </v-card-actions>

	        </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { index } from "@/mixins/modal";
import { auth }  from "@/mixins/auth";
import { modal } from "@/mixins/modal";
import { mapActions, mapGetters } from "vuex";
export default {
	mixins:[modal,auth],
	data(){ 
		return {
			
			// to check if the field were all valid
			valid: false,

			// to set the data event Add or Edit
			form:{},

			dialogs:true,

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: false,

			// to check if action is submitting
			isSubmit: false,

			// to check if mode is delete
			isDelete:false,

			// to check if mode is delete
			isEdit:false,
		}
	}, 

	updated()
	{
    	this.isDelete = this.mode == "delete" ? true : false;
		this.isEdit   = this.mode == "edit" ? true : false;
    },

	methods:{
		...mapActions("calendar",["setUpdateRegisterCalendar"]),

 		/*
 		* This submit value for update or register on calendar 
 		*
 		* @return void
 		*/ 
		submit()
		{	
	    	this.isSubmit = true;
			this.setUpdateRegisterCalendar(Object.assign(this.data,{ schedule_status:1 }))
			.then((response) => { this.isSubmit = false; })
			.then((response) => { this.close() })
      	},

      	/*
 		* This set event actions for cancel action from modal
 		*
 		* @return void
 		*/ 
		cancel()
		{	
			// to close modal action 
			this.close();

			// this emit event for cancel 
			this.$bus.$emit("discard-changes");	
      	},
	},

	watch:{
		data(values)
		{
			this.form = values;
		}
	}
}

</script>

<style type="text/css" scoped>
	.card-text {
		height: 150px;
	}
	.modal-text {
		font-size:25px;  
		margin:40px; 
	}
    .custom-card-button{
      height:40px;
      width:150px;
    }

    .label {
      color:black;
      font-size: 16px;
    }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
    }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }

</style>