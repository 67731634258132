<template>
   <!-- -------------- MODAL: LOG ------------------- -->
   <v-row justify="center">
      <v-dialog v-model="dialog" persistent scrollable max-width="800px"@keydown.esc="close()">
         <v-card>

            <!-- -------------------- CARD HEADER ------------------- -->
            <v-card-title>
               <span class="font-weight-bold">{{ title }}</span>
               <v-spacer></v-spacer>
               <span  class="caption mr-3">Press</span>
               <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
               <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
            </v-card-title>

            <v-divider></v-divider>

            <!-- -------------------- CARD CONTENTS ------------------- -->
            <v-card-text style="height:700px;">
               <div class=" ma-5 row">

                  <!-- ------------------ AVATAR ------------------ -->
                  <div class="col-xs-1">
                     <v-avatar class="mr-6">
                        <img :src="data.image && data.image.avatar ? data.image.avatar : default_avatar" alt="avatar">
                     </v-avatar>
                  </div>

                  <!-- ------------------ FULLNAME ------------------ -->
                  <div class="col-xs-1">
                     <span class="font-weight-bold title">{{ fullname }}</span><br>
                  </div>

                  <v-spacer></v-spacer>

                  <!-- ------------------ DATE RANGE PICKER ------------------ -->
                  <div class="col-xs" style="margin-bottom:-50px;">
                     <date-range :value.sync="date"></date-range>
                  </div>

               </div>
               
               <!-- ------------------ DATA TABLE ------------------ -->
               <v-data-table :headers="header" :items="log_record.logs" :items-per-page="10" class="mx-2 pa-3">
                  <!-- ------------------ DATE ----------------- -->
                  <template v-slot:item.date="{ item }">
                     {{ format(item.date,"date","DD MMM YYYY") }}
                  </template>

                  <!-- ---------------- TIME IN ---------------- -->
                  <template v-slot:item.timein="{ item }">
                     {{ item.timein == 0 ? 0 : format(item.timein,"time","h:mm a") }}
                  </template>
                  
                  <!-- ---------------- TIME OUT ---------------- -->
                  <template v-slot:item.timeout="{ item }">
                     {{ item.timeout == 0 ? 0 : format(item.timeout,"time","h:mm a") }}
                  </template>
               </v-data-table>
            </v-card-text>
            <v-divider></v-divider>

            <!-- -------------------- CARD ACTIONS ------------------- -->
            <v-card-actions class="ma-4">
               <span class="subtitle-1 mr-2"><span style="color:grey">Days </span><span class="title font-weight-bold">{{log_record.days}}</span></span>
               <span class="subtitle-1 mr-2"><span style="color:grey">Hours</span> <span class="title font-weight-bold">{{log_record.hours}}</span></span>
               <v-spacer></v-spacer>
               <v-btn class="mx-2 elevation-0 font-weight-bold btn-download-excel"dark color="#0057AD" @click="export_exel()">
                  <span style="font-size: 12px;">Download Excel</span>
               </v-btn>  
            </v-card-actions>

         </v-card>
         <v-divider></v-divider>
      </v-dialog>
   </v-row>
</template>

<script>

import TimeLogsMap from "@/components/Form/Modal/Timelog/Map/Index";
import DateRange from "@/components/Fields/DateRange/Index";

import { rules } from "@/validation/rules/index";
import { excel } from "@/mixins/docs/excel";
import { modal } from "@/mixins/modal";

import { mapGetters, mapActions } from "vuex";

export default {
   mixins:[modal,excel],
   components:{
      "v-timelog-map":TimeLogsMap,
      "date-range":DateRange
   },
   data(){ 
      return {
         header:[
            { text: "Date",     value:"date" },
            { text: "Time In",  value:"timein" },
            { text: "Time Out", value:"timeout" },
            { text: "Duration (HOURS)", value: "durations" },
         ], 
         default_avatar:require('@/assets/images/avatar/default-avatar.jpg'), 
         log_record:[],
         form:{},
         date:
         { 
            from:moment().startOf('month').format('YYYY-MM-DD'), 
            to:moment().endOf('month').format('YYYY-MM-DD') 
         },
         excel_data:{}
      }
   }, 

   async created()
   {  
      this.initilize(this.data);
   },

   beforeDestroy()
   {
      this.$bus.$off("date-range");
      this.$bus.$off("recent-date-range");
   },
 
   methods:{   
      ...mapActions("recent",["getIndividualEmployeeLogs"]),
     
      /*
      * This initilize value for table view
      *
      * @return array /store/modules/organization/index getOrganizations
      */ 
      initilize(data)
      {  
         // set value for date picker component
         this.$bus.$emit('set-date-range',[this.date.from, this.date.to]);

         // this get the daily timelogs as initialization
         this.getDailyLogs(data.employee_id, data.organization_id, this.date.from, this.date.to);
         
         // this get the daily timelogs when date range theres an action
         // to set the date in range using Vue Event Bus
         this.$bus.$on(`${this.$route.name}-date-range`,(date) => { 

            // if the date was revesely inputed 
            // should be accepted then fix the position
            if(date.from > date.to)
            {
               this.getDailyLogs(data.employee_id, data.organization_id, date.to,date.from);
            }

            // if ever the date range only one get selected date
            // this accept atleast one date only
            if (date.from && !date.to)
            {
               this.getDailyLogs(data.employee_id, data.organization_id, date.from,date.from);
            }

            else
            {
               // this set the header for header date " MM/DD "
               this.getDailyLogs(data.employee_id, data.organization_id, date.from,date.to);
            }
         });
      },
    
      /*
      * export date into an excel file 
      *
      * @return void
      */
      export_exel()
      {
         this.export_viewlogs(this.excel_data.logs,`${this.fullname} ${this.title}`)
      },

      /*
      * get employee daily logs  
      *
      * @return void
      */
      getDailyLogs(employee_id,organization_id,from,to)
      {
         // set the header dates using current start and end date
         this.getIndividualEmployeeLogs({ employee_id:employee_id, organization_id:organization_id, from:from, to:to })
         .then((response)=>{ 
            this.log_record = response.data;  
            this.excel_data = response.data
         })
      },

      /*
      * to set timestamp/date into format date with 
      *
      * @parameter value, the date
      * @parameter type, date only | time
      * @parameter format 
      *
      * @return string date
      */
      format(value,type,format)
      {
         switch(type)
         {
            case "time": return moment(value).format(format);  break;
            case "date": return moment(value).format(format); break;
         }
      },
   },

   computed:{
      ...mapGetters("recent",{ "values":"getIndividualEmployeeLogList" }),

      fullname()
      {  
         if(this.data.employee)
         {
            return this.data ? `${this.data.employee.firstname} ${this.data.employee.lastname}`:"";
         }
      }
   },
}

</script>

<style type="text/css" scoped>
    .btn-download-excel{
       height: 40px; 
       width: 150px;
       font-size: 10px;
    }
</style>