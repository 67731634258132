import store from "@/store"

const state = { 
	mobile_admin:{ list:[], record:[] }
}

const getters = {

	/*
	* GET Mobile Admin list
	* to set the list after fetching
	*/	
	getMobileAdminList:state=>{
		return state.mobile_admin.list;
	}, 

	/*
	* GET employee record
	* to set the record after fetching
	*/	
	getMobileAdminRecord:state=>{
		return state.mobile_admin.record;
	}
}

const mutations = {
	
	/*
	* GET employee record
	* to get the record after fetching
	*/	
	GET_MOBILE_ADMIN_RECORD:(state,record)=>{
		state.mobile_admin.record = record;
	},

	/*
	* GET Mobile Admin list
	* to get the list after fetching
	*/	
	GET_MOBILE_ADMIN_LIST:(state,list)=>{
		state.mobile_admin.list = list;
	},

	/*
	* ADD Mobile Admin record
	* to set the list additional record after add/registraion
	*/	
	ADD_MOBILE_ADMIN_RECORD:(state,data)=>{
		Object.assign(state.mobile_admin.list, data);
	},

 	/*
	* UPDATE Mobile Admin record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_MOBILE_ADMIN_RECORD:(state,data)=>{
		let index = state.mobile_admin.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.mobile_admin.list[index],data)
	},

	/*
	* DELETE Mobile Admin record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_MOBILE_ADMIN_RECORD:(state,id) => {
		let index = state.mobile_admin.list.findIndex(list => list.id == id)
		state.mobile_admin.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch Mobile Admin list
	*
	* @return string
	*/	
	getMobileAdminList(context,data = {})
	{
		return new Promise((resolve,reject)=>{ 
			axios.get("/mobileadmin",{
				params:{
					organization_id:data.organization_id,
					site_id:data.site_id
				}
			})
			.then((response)=>{ context.commit('GET_MOBILE_ADMIN_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Get/Fetch mobile admin list
	*
	* @return string
	*/	
	setSearchMobileAdmin(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/mobileadmin/search",{ params:{ keyword:keyword } })
			.then((response)=>{ context.commit('GET_MOBILE_ADMIN_LIST',response.data); resolve(response); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get/Fetch employee list
	*
	* @return string
	*/	
	getMobileAdminRecord(context,data = {})
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get(`/mobileadmin/${data.id}`)
			.then((response)=>{ context.commit('GET_MOBILE_ADMIN_RECORD',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Add/Register Mobile Admin record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterMobileAdmin(context,data)
	{
		return new Promise((resolve,reject)=>{ 
			axios.post("/mobileadmin",data)
			.then((response)=>{ context.dispatch("getMobileAdminList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		})
	},
	
	/*
	* Edit/Update Mobile Admin record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateMobileAdmin(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/mobileadmin/${data.id}`,data)
			.then((response)=>{ context.dispatch("getMobileAdminList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove Mobile Admin record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteMobileAdmin(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/mobileadmin/${data.id}`)
			.then((response)=>{ context.dispatch("getMobileAdminList");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const mobile_admin = {
	state,
	getters,
	mutations,
	actions
}