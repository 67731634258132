const state = {
	value:[],
	selectedHeaders:[]
}

const getters = {
	getHeaders(state){
		return state.value;
	},
	getSelectedHeaders(state){
		return state.selectedHeaders;
	},
}

const mutations = {
	SET_HEADERS(state,value){
		state.value = value;
	},
	SET_SELECTED_HEADERS(state,value){
		state.selectedHeaders = value;
	},
}

const actions = {
	setHeaders:(context,value)=>{
		context.commit("SET_HEADERS",value);
	},
	setSelectedHeader:(context,value)=>{
		context.commit("SET_SELECTED_HEADERS",value);
	}
}

export const column = {
	state,
	getters,
	mutations,
	actions
}