import Vue from "vue"

import store from "@/store"

import VueRouter from "vue-router"

import Login from "@/views/Login/Login"

import Index from "@/views/Layout/Index";

import Dashboard from "@/views/Dashboard/Index";

import Timelogs from "@/views/Timelogs/Index";

import Recent from "@/views/Timelogs/Recent/Index";

import Timeline from "@/views/Timelogs/Timeline/Index";

import TimelineV2 from "@/views/Timelogs/TimelineV2/Index";

import DailyActivityReport from "@/views/Timelogs/DailyActivityReport/Index";
import DailyActivityReport2 from "@/views/Timelogs/DailyActivityReport2/Index";


import Timesheet from "@/views/Timesheet/Index";

import Attendance from "@/views/Timelogs/Attendance/Index";

import Activities from "@/views/Activities/Index";

import Organizations from "@/views/Organizations/Index";

import Site from "@/views/Site/Index";

import Assignment from "@/views/Assignment/Index";

import Account from "@/views/Account/Index";

import Admins from "@/views/Admins/Index";

import MobileAdmin from "@/views/MobileAdmin/Index";

import Employees from "@/views/Employees/Index";

import EmployeeRoles from "@/views/EmployeeRoles/Index";

import Weekly from "@/views/Calendar/Weekly/Index";

import Compose from "@/views/Compose/Index";

import Monthly from "@/views/Calendar/Monthly/Index"

import { queryBuilderRoutes } from "@/modules/QueryBuilder/routes.js";


Vue.use(VueRouter);
   
const ifNotAuthenticated = (to, from, next) => {
   if(localStorage.getItem("abizo_user_token") && to.path === "/login")
   {
      next({ path: '/dashboard' });
   } 
   next();
}

const isSuperAdmin = (to, from, next) => {
   if (store.getters.authUserRole == "Admin")
   {
      next({ path:'/dashboard', query:{ redirect:to.fullPath } });
   } 
   next();
}

const isAdmin = (to, from, next) => {
   if (store.getters.authUserRole == "Super Admin")
   {
      next({ path:'/dashboard', query:{ redirect:to.fullPath } });
   } 
   next();
}

const routes = [

   {
      path:"/login",
      name:"Login",
      component:Login,
      meta:{ requiresAuth:false, title:"Login"},
      beforeEnter:ifNotAuthenticated
   }, 
   
   {
      path:"/",
      component:Index,
      meta:{ requiresAuth:true },
      redirect:"/dashboard",
      children:[
         
         {
            path:"/dashboard",
            name:"dashboard",
            component:Dashboard,
            meta:{ requiresAuth:true, title:"Dashboard"}, 
         },

         {
            path:"/timelogs",
            component:Timelogs,
            meta:{ requiresAuth:true, title:"Timelog"}, 
            children:[
               { path:"/", name:"recent", component:Recent, meta:{ requiresAuth:true, title:"Time Logs", module:"timelogs"}},
               { path:"recent", name:"recent", component:Recent, meta:{ requiresAuth:true, title:"Time Logs", module:"timelogs"}},
               { path:"timeline", name:"timeline",  component:Timeline, meta:{ requiresAuth:true, title:"Timeline", module:"timelogs"}},
               { path:"attendance",name:"attendance", component:Attendance, meta:{ requiresAuth:true, title:"Attendance Today", module:"timelogs"}, beforeEnter:isAdmin },
               { path:"daily/activity/report", name:"daily_activity_report",  component:DailyActivityReport, meta:{ requiresAuth:true, title:"Daily Activity Report 1", module:"timelogs"},beforeEnter:isAdmin},
               { path:"daily/activity/report2", name:"daily_activity_report2",  component:DailyActivityReport2, meta:{ requiresAuth:true, title:"Daily Activity Report 2", module:"timelogs"},beforeEnter:isAdmin},
            ],
         },
        
         {
            path:"/activities",
            name:"activities",
            component:Activities,
            meta:{ requiresAuth:true, title:"Activities"},
            beforeEnter:isSuperAdmin
         }, 

         {
            path:"/calendar/monthly",
            name:"calendar_monthly",
            component:Monthly,
            meta:{ requiresAuth:true, title:"Calendar", text:"Calendar" },
            beforeEnter:isAdmin
         },  

         {
            path:"/calendar",
            name:"calendar",
            component:Weekly,
            meta:{ requiresAuth:true, title:"Calendar", text:"Calendar" },
            beforeEnter:isAdmin,
         }, 

         {
            path:"/timesheet",
            name:"timesheet",
            component:Timesheet,
            meta:{ requiresAuth:true, title:"Time Sheet"},
            beforeEnter:isAdmin,
         }, 

         {
            path:"/organizations",
            name:"organizations",
            component:Organizations,
            meta:{ requiresAuth:true, title:"Organizations"},
            beforeEnter:isSuperAdmin
         },

         {
            path:"/sites",
            name:"sites",
            component:Site,
            meta:{ requiresAuth:true, title:"Sites"},
         },

         {
            path:"/accounts",
            name:"accounts",
            component:Account,
            meta:{ requiresAuth:true, title:"Accounts"},
         },
         
         {
            path:"/assignments",
            name:"assignments",
            component:Assignment,
            meta:{ requiresAuth:true, title:"Assignments"},
         },

         {
            path:"/admins",
            name:"admins",
            component:Admins,
            meta:{ requiresAuth:true, title:"Admins"},
         },

         {
            path:"/mobileadmins",
            name:"mobile_admins",
            component:MobileAdmin,
            meta:{ requiresAuth:true, title:"Mobile Admin"},
            
         },

         {
            path:"/employees",
            name:"employees",
            component:Employees,
            meta:{ requiresAuth:true, title:"Employees"},
         },

         {
            path:"/employee/roles",
            name:"employee_roles",
            component:EmployeeRoles,
            meta:{ requiresAuth:true, title:"Employee Roles"},
         },

         {
            path:"/assignments/compose",
            name:"compose",
            component:Compose,
            meta:{ requiresAuth:true, title:"Compose" }
         },

         ...queryBuilderRoutes
      ],
   },
]

const router = new VueRouter({
   mode:"hash",
   routes
});

router.beforeEach((to, from, next) => {

   if (to.matched.some(record => record.meta.requiresAuth))
   {
      if (!localStorage.getItem("abizo_user_token"))
      {
         next({ path:'/login', query:{ redirect:to.fullPath } });
      } 
     
      else 
      {
         next();
      }
   } 
  
   else 
   {  
      if (localStorage.getItem("abizo_user_token") && to.path === "/login")
      {
         next({ path:'/dashboard' });
      } 

      if (localStorage.getItem("abizo_user_token") && to.path === "/")
      {
         next({ path:'/dashboard' });
      }

      if(!localStorage.getItem("abizo_user_token"))
      {
         next();
      }
   }
})


export default router;
