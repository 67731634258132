<template>
	<section class="d-flex justify-center flex-column">
		<section class="d-flex justify-end" style="position:relative; right:50px;">
			<a v-if="!hidden && limit < items.length" @click="seeAllTemplates">See all templates >></a>
		</section>
		<v-layout id="layout" class="d-flex justify-start flex-row" style=" flex-wrap:wrap">
			<v-skeleton-loader v-if="loading" class="mt-5" height="150" width="250" type="article, actions"/>
			<template v-else>
				<v-card width="250" height="150" @click="newTemplateForm" class="mx-5 my-5">
					<v-card-text class="d-flex justify-center align-center" style="background:blue; height:inherit;">
						<h3 style="color:#FFF;">+ Create from Blank document</h3>
					</v-card-text>
				</v-card>
				<div v-for="(item,key) in items" v-if="key < limit">
					<v-card width="250" height="150" @click="editTemplateForm(item.id)" class="mx-5 my-5">
						<v-card-text class="d-flex flex-column justify-end">
						  <h3 class="d-flex align-end" style="color:#000;">{{item.title}}</h3>
						  <div class="d-flex" style="color:#000;">Last edited on {{ format(item.updated_at,"date","MMM DD, YYYY") }}</div>
						</v-card-text>
					</v-card>
				</div>
			</template>
		</v-layout>
		<section class="d-flex justify-end" style="position:relative; right:50px;">
			<a v-if="hidden" @click="seeLessTemplates"><< Back </a>
		</section>	
	</section>
</template>

<script>
	import { mapActions, mapGetters } from "vuex";

	export default {
		props:{
			items:{
				type:Object,
				required:true
			},
			loading:{
				type:Boolean
			}
		},
		
		data(){
			return {
				limit:4,
				hidden:false,
			}
		},

		methods:{

			...mapActions('template', ["setNewTemplate","seeSavedTemplate"]),

			/*
			* to set timestamp/date into format date with 
			*/
			format(value,type,format){
				switch(type){
					case "time": return moment(value).format(format);  break;
					case "date": return moment(value).format(format); break;
				}
			},

			seeAllTemplates(){
				this.limit  = this.items.length;
				this.hidden = true;
				this.$emit("hide",true);
			},

			seeLessTemplates(){
				this.limit  = 4;
				this.hidden = false;
				this.$emit("hide",false);
			},

			async newTemplateForm(){
				await this.$router.push("/query-builder/template")
			},

			// going to template builder page 
			editTemplateForm(id){
				this.$router.push({ path:"/query-builder/template", query:{ id:id } });
			}
		},

		computed:{
			isNotEmpty(){ return this.items.length == 0 }
		}
	}
</script> 	

<style type="text/css">
	@media only screen and (max-width: 600px){
		#layout  { 
			justify-content:center;
		}
		#search-bar {
			min-width:100%;
		}
	}
</style>