<template>
	<v-autocomplete v-model="accounts" :items="values" @change="onChangeValue($event)" @input="onInputValue($event)" outlined dense item-text="name" item-value="id" placeholder="Account" clearable>
		<template v-slot:selection="data">
			<v-chip v-bind="data.attrs" :input-value="data.selected" small class="ma-0"@click="data.select" @click:close="remove(data.item.id)" >
				{{ data.item.name }}
			</v-chip>
		</template>
		<template v-slot:item="data">
			<template v-if="typeof data.item !== 'object'">
				<v-list-item-content v-text="data.item"></v-list-item-content>
			</template>
			<template v-else>
				<v-list-item-avatar>
					<img :src="data.item && data.item.organization ? data.item.organization.logo : default_logo">
				</v-list-item-avatar>
				<v-list-item-content>
					<v-list-item-title v-html="data.item.name"></v-list-item-title>
				</v-list-item-content>
			</template>
		</template>
	</v-autocomplete>
</template>

<script>

import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";

export default {
	props:["account","disabled"],
	mixins:[modal,states,auth],
    data () {
    	return {
	    	default_logo:require('@/assets/images/logo/logo-placeholder.png'),
			valid: false,
			autoUpdate: true,
			isUpdating: false,
			isEmpty: false,
			accounts:"",
		}
    },

	mounted()
	{	
		this.$nextTick(()=>this.initilize());
	},

	methods:{

    	/*
 		* This initilize value 
 		*
 		* @return array /store/modules/site/index 
 		*/ 
		initilize()
		{	
			if(this.account)
			{	
				// set the value initializing
				this.accounts = this.account;
			}

			// get sites per organization
			this.$store.dispatch("getAccountsPerOrganization",{ organization_id:this.AuthOrganization.id });
 		},

        /*
 		* Look for value account id as sync
 		*
 		* @return array getsiteList
 		*/ 
        accounts(val)
        {
        	this.$emit('update:account', val)
        },

		/*
 		* Remove value selected
 		*
 		* @return void
 		*/ 
		remove(item) 
		{	
			var removeIndex = this.account.indexOf(item);

			if(removeIndex >= 0) this.account.splice(removeIndex, 1)
		},

		/*
 		* Change value selected
 		*
 		* @return void
 		*/ 
		onChangeValue(event) 
		{	
			this.$emit("onChanged",event);
		},

		/*
 		* Change value selected
 		*
 		* @return void
 		*/ 
		onInputValue(event) 
		{	
			this.$emit("onInput",event);
		},
    },

	computed:{

 		/*
 		* Array values 
 		*
 		* @return array getsiteList
 		*/ 
 		values()
 		{
 			return this.$store.getters.getAccountDropDownList;
 		},
	},

    watch:{
    	
    	/*
 		* Loading/Progress when update values 
 		*
 		* @return void
 		*/ 
		isUpdating(val)
		{
			if (val) 
			{
				setTimeout(() => (this.isUpdating = false), 3000)
			}
		},

        /*
 		* Look for value account id as sync
 		*
 		* @return array getsiteList
 		*/ 
        accounts(val)
        {
        	this.$emit('update:account', val)
        },

        /*
 		* Watch for value from 'account' props 
 		*
 		* @return void
 		*/ 
        account(val)
        {	
        	this.accounts = val;
        }, 
    },
}

</script>