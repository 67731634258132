export const column_filter = {

	beforeMount()
	{	
    	// get table filter value
        this.getTableFilter();

        // set table filter value
		this.setTableFilter();
	},
	
	methods:{
		
		/*
		* To set the table column for filter dropdown
		*
		* @return void
		*/ 
		setTableFilter()
		{	
			this.$bus.$emit("set-column-filter-values",this.headers);
		},

		/*
		* To set the table column by toggling using filter dropdown
		*
		* @return void
		*/ 
		getTableFilter()
		{
			const array = this.headers;

			this.$bus.$on("column-filter",(value)=>{
				if(!value.visible) { this.headers = this.headers.filter(data => data.visible != value.visible); }
				else { this.headers.splice(value.index,0, array[value.index]); }
			});
		}
	}
}