import store from "@/store"
import axios from "axios";

const state = { 
	calendar:{
		weekly:{
			list:[],
			pagination:{ page:0, per_page:0 },
			total:0,
		},

		monthly:{ 
			list:[] 
		}
	},
	employees:{
		list:[],
	},
	holidays:{}
}

const getters = {

	/*
	* GET calendar list
	* to set the list after fetching
	*/	
	getCalendarList:state=>{
		return state.calendar.weekly.list;
	},

	/*
	* GET monthly calendar 
	* to set the list after fetching
	*/	
	getCalendarMonthly:state=>{
		return state.calendar.monthly.list;
	},

	/*
	* GET calendar list
	* to set the list after fetching
	*/	
	getEmployees:state=>{
		return state.employees.list;
	},

	/*
	* This gets count per page item
	*
	* @param state  
	*
	* @return void  
	*/
	getPagePerItem:state=>{
		return state.calendar.weekly.pagination.per_page;
	},

	/*
	* This gets a value of state table.list
	*
	* @param state  
	*
	* @return void  
	*/
	totalItems:state=>{
		return state.calendar.weekly.total;
	},

	/*
	* This gets pagination page number
	*
	* @param state  
	*
	* @return void  
	*/
	pagination:state=>{
		return state.calendar.weekly.pagination.page;
	},

	/*
	* This gets holidays object values
	*
	* @param state  
	*
	* @return void  
	*/
	getHolidays:state=>{
		return state.holidays;
	}
}

const mutations = {

	/*
	* GET calendar list
	* to get the list after fetching
	*/	
	GET_CALENDAR_WEEKLY:(state,payload)=>{
		state.calendar.weekly.list = payload.data;
	    state.calendar.weekly.pagination.current_page = payload.current_page;
	    state.calendar.weekly.pagination.per_page = payload.per_page;
	    state.calendar.weekly.total = payload.total;
	},

	/*
	* GET employee calendar 
	* to get the list after fetching
	*/	
	GET_CALENDAR_MONTH:(state,payload)=>{
		state.calendar.monthly.list = payload;
	},

	/*
	* GET employees details  
	* to get the list after fetching
	*/	
	GET_EMPLOYEES:(state,payload)=>{
		state.employees.list = payload;
	},

	/*
	* GET employees details  
	* to get the list after fetching
	*/	
	GET_INDIVIDUAL_RECORD:(state,payload)=>{
		state.employees.list = payload;
	},

	/*
	* SET holidays details  
	* to set the holiday objects after fetching
	*/	
	SET_HOLIDAYS_OBJECT:(state,payload) => {
		state.holidays = payload;
	}
}

const actions = {

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getCalendar:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get("/calendars",{
				params:{
					view_by: (data.view_by == "SHIFT" ? "schedule" : (data.view_by ? data.view_by.toLowerCase() : data.view_by)),
					keyword:data.keyword,
				
					site:data.site_id,
					account:data.account_id,
					assignment:data.assignment_id,
					
					from:data.from,
					to:data.to,
				
					page:data.page,
					per_page:data.itemsPerPage != -1 ? data.itemsPerPage : 10,
					orderBy:(data.sortBy && data.sortBy.length) ? data.sortBy.toString() : null,
					order:Object.hasOwnProperty.call(data,"sortDesc") && data.sortDesc[0] ? "desc" : "asc",
				}
			})
			.then(response=>{ resolve(response.data); context.commit('GET_CALENDAR_WEEKLY',response.data); })
			.catch((errors)=>console.log(errors));
		})
	},

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getCalendarForExcel:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get("/calendars",{
				params:{
					view_by: (data.view_by == "SHIFT" ? "schedule" : (data.view_by ? data.view_by.toLowerCase() : data.view_by)),
					keyword:data.keyword,
				
					site:data.site_id,
					account:data.account_id,
					assignment:data.assignment_id,
					
					from:data.from,
					to:data.to,
				}
			})
			.then(response=>resolve(response.data))
			.catch((errors)=>console.log(errors));
		})
	},

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getMonthlyCalendar:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get("/calendars",{
				params:{
					view_by: (data.view_by == "SHIFT" ? "schedule" : (data.view_by ? data.view_by.toLowerCase() : data.view_by)),
					organization_id:data.organization_id,
					employee_id:data.employee_id,
					site:data.site_id,
					account:data.account_id,
					assignment:data.assignment_id,

					from:data.from,
					to:data.to,
				}
			})
			.then(response=>{ 
				resolve(response.data); 
				context.commit('GET_CALENDAR_MONTH',response.data);
			})
			.catch((errors)=>console.log(errors));
		})
	},

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getIndividualRecord:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get(`/calendars/record/${data.id}`,{
				params:{
					organization_id:data.organization_id,
					employee_id:data.employee_id,
					
					from:data.from,
					to:data.to,
					view_by: (data.view_by == "SHIFT" ? "schedule" : (data.view_by ? data.view_by.toLowerCase() : data.view_by)),
				}
			})
			.then((response)=>resolve(response.data))
		})
	},


	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getOneEmployeeRecord:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get(`/calendars/employees`,{
				params:{
					organization_id:data.organization_id,
					employee_id:data.employee_id,
				}
			})
			.then(response=>{ resolve(response.data); context.commit("GET_EMPLOYEES",response.data); })
			.catch(errors=>console.log(errors));
		})
	},


	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getEmployeeRecord:(context,data={})=>{
		return new Promise((resolve,reject)=>{ 
			axios.get(`/calendars/employees`,{
				params:{
					site:data.site_id,
					account:data.account_id,
					assignment:data.assignment_id,
					keyword:data.keyword,

					from:data.from,
					to:data.to,
				}
			})
			.then(response=>{ resolve(response.data); context.commit("GET_EMPLOYEES",response.data); })
			.catch(errors=>console.log(errors));
		})
	},

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	setCalendarSearch:(context,data)=>{
		return new Promise((resolve,reject)=>{ 
			axios.get("/calendars",{
				params:{
					keyword:data.keyword,
					site:data.site_id,
					account:data.account_id,
					from:data.from,
					to:data.to,
				}
			})
			.then((response)=>{ context.commit('GET_CALENDAR_WEEKLY',response.data); })
			.catch((errors)=>console.log(errors));
		})
	},

	/*
	* Get/Fetch calendar list
	*
	* @return string
	*/	
	getCalendarMonthly:(context,data)=>{
		axios.get(`/calendars/monthly/${data.date}/${data.id}`)
		.then((response) => { context.commit('GET_CALENDAR_MONTH',response.data); })
		.catch((errors)  => console.log(errors));
	},

	/*
	* Add/Register calendar record 
	*
	* @parameter object payload data 
	* @return promise 
	*/	
	setUpdateRegisterCalendar:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/calendars`,data)
			.then((response)=>resolve())
			.catch((errors)=>reject(errors))
		});
	},

	/*
	* Add/Register calendar record 
	*
	* @parameter object payload data 
	* @return promise 
	*/	
	setCreateAndAssignSchedule:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/schedules/create/assign`,data)
			.then((response)=>{ })
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Add/Register calendar record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterCalendar:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.post(`/attendances`,data)
			.then((response)=>{ context.dispatch("getCalendar");})
			.catch((errors) =>{ reject(errors); })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Edit/Update calendar record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateCalendar:(context,data)=>{
		return new Promise((resolve,reject) => { 
			axios.put(`/attendances/${data.id}`,data)
			.then((response)=>{ context.dispatch("getCalendar");})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>{ resolve(response) });
		});
	},

	setDayType:(context,payload) => {
		return new Promise((resolve,reject)=>{
			axios.post("/calendars/day-type",payload)
			.then((response)=>{
				resolve(true);
			})
			.catch((error)=>{
				reject(error)
				console.log(error);
			})
		});
	},

	getCalendarHolidays : (context, date) => {
		
		const baseURL = process.env.VUE_APP_GOOGLE_CALENDAR_API;
		const calendarId = process.env.VUE_APP_GOOGLE_CALENDAR_ID;
		const myKey = process.env.VUE_APP_GOOGLE_CALENDAR_API_KEY;

		const start = `${moment(date).startOf("year").format("YYYY-MM-DDTHH:mm:ss")}Z`;
		const end = `${moment(date).endOf("year").format("YYYY-MM-DDTHH:mm:ss")}Z`;

		return new Promise((resolve,reject)=>{
			fetch(`${baseURL}/${calendarId}/events?key=${myKey}&timeMin=${start}&timeMax=${end}`)
			.then((res) => res.json()) // Transform the data into json
			.then((res)=>{
			   let holidays = {};
   
			   res.items.map(item=>{
				  holidays = { ...holidays, [item.start.date]:item.summary }
			   });
   
			   context.commit("SET_HOLIDAYS_OBJECT", holidays);
			});
		});
	}
}

export const calendar = {
	namespaced:true,
	state,
	getters,
	mutations,
	actions
}