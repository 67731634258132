import store from "@/store"

const state = { 
	employee:{ list:[], record:[], pagination:{ page:0, rowsPerPage:0 } }
}

const getters = {

	/*
	* GET employee list
	* to set the list after fetching
	*/	
	getEmployeeList:state=>{
		return state.employee.list;
	},

	/*
	* GET employee record
	* to set the record after fetching
	*/	
	getEmployeeRecord:state=>{
		return state.employee.record;
	}
}

const mutations = {

	/*
	* GET employee record
	* to get the record after fetching
	*/	
	GET_EMPLOYEE_RECORD:(state,record)=>{
		state.employee.record = record;
	},

	/*
	* GET employee list
	* to get the list after fetching
	*/	
	GET_EMPLOYEE_LIST:(state,list)=>{
		state.employee.list = list;
	},

	/*
	* ADD employee record
	* to set the list additional record after add/registraion
	*/	
	ADD_EMPLOYEE_RECORD:(state,data)=>{
		Object.assign(state.employee.list, data);
	},

 	/*
	* UPDATE employee record 
	* to set the list additional record after add/registraion
	*/	
	UPDATE_EMPLOYEE_RECORD:(state,data)=>{
		let index = state.employee.list.findIndex(list=> list.id == data.id)
       	Object.assign(state.employee.list[index],data)
	},

	/*
	* DELETE employee record
	* to remove the index from list after delete successful
	*
	*/	
	DELETE_EMPLOYEE_RECORD:(state,id) => {
		let index = state.employee.list.findIndex(list => list.id == id)
		state.employee.list.splice(index, 1)
	},
}

const actions = {

	/*
	* Get/Fetch employee list
	*
	* @return string
	*/	
	getEmployees(context,data = {})
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/employees",{
				params:{
					organization_id:data.organization_id,
					employee_role_id:data.employee_role_id,
					assignment_id:data.assignment_id,
					site_id:data.site_id,
				}
			})
			.then((response)=>{ context.commit('GET_EMPLOYEE_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*
	* Get search employee 
	*
	* @return string
	*/	
	setSearchEmployee(context,keyword)
	{	
		return new Promise((resolve,reject)=>{ 
			axios.get("/employees/search",{
				params:{
					keyword:keyword
				}
			})
			.then((response)=>{ context.commit('GET_EMPLOYEE_LIST',response.data); })
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	},

	/*	
	* Add/Register employee record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setRegisterEmployee(context,data)
	{
		return new Promise((resolve,reject)=>{ 
			axios.post("/employees",data)
			.then((response)=>{ resolve(response);})
			.catch((errors)=>{ reject(errors);})
			.finally((response)=>context.dispatch("getEmployees"));
		})
	},
	
	/*
	* Edit/Update employee record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setUpdateEmployee(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.put(`/employees/${data.ref_employee_id}`,data)
			.then((response)=>{ context.dispatch("getEmployees"); resolve(response);})
			.catch((errors)=>{ reject(errors) })
			.finally((response)=>resolve(response));
		});
	},
	
	/*
	* Delete/Remove employee record 
	*
	* @parameter object payload data 
	* @return promise
	*/	
	setDeleteEmployee(context,data)
	{
		return new Promise((resolve,reject) => { 
			axios.delete(`/employees/${data.id}`)
			.then((response)=>{ context.dispatch("getEmployees");})
			.catch((errors)=>reject(errors))
			.finally((response)=>resolve(response));
		});
	}
}

export const employee = {
	state,
	getters,
	mutations,
	actions
}