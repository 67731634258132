<template>
    <v-row justify="center">
		<v-dialog v-model="dialog" persistent scrollable max-width="800px" @keydown.esc="close()">
			<v-card :loading="isSubmit">
				
				<!-- -------------------- CARD HEADER ------------------- -->
		        <v-card-title>
		            <span class="font-weight-bold">{{ title }}</span>
		            <v-spacer></v-spacer>
		            <span  class="caption mr-3">Press</span>
		            <v-chip outlined label small color="grey" class="mr-5">Esc</v-chip>
		            <v-btn icon @click="close()"> <v-icon>close</v-icon> </v-btn>
		        </v-card-title>

				<v-divider></v-divider>

				<!-- -------------------- CARD HEADER ------------------- -->
				<v-card-text style="height: 800px;">

					<v-form v-model="valid" ref="form" @keyup.native.enter="submit(mode)">
			         	
			           	<!-- -------------------- ORGANIZATION -------------------- -->
		              	<v-row no-gutters fluid class="mt-2">
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Organization </label>
		              			<v-organization :organization.sync="form.organization_id" :disabled="isEdit||AuthRole == 'Admin'||isEdit||isDelete||isSubmit">
                     			</v-organization>
		              		</v-col>
			            </v-row>

		                <!-- -------------------- ACCOUNT -------------------- -->
		                <!-- <v-row no-gutters fluid>
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Account </label>
		              			<v-account :placeholder="'Assign this account to an employee'" :account.sync="form.account" :disabled="isEdit||isDelete||isSubmit">
		              			</v-account>
		              		</v-col>
		              	</v-row> -->

		              	<!-- -------------------- SITE -------------------- -->
		              <!--   <v-row no-gutters fluid>
		                   <v-col md class="mx-2">
		                       <label class="d-block text-left font-weight-bold label my-1"> Site </label>
		                       <v-site :placeholder="'Assign this site to an employee'" :site.sync="form.site" :multiple="true" :disabled="isEdit||isDelete||isSubmit">
		                       </v-site>
		                   </v-col>
		                </v-row> -->

		                <!-- -------------------- EMPLOYEE ROLE -------------------- -->
		              	<v-row no-gutters fluid>
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Employee Role </label>
		              			<v-employee-role :placeholder="'Assign this employee role to an employee'" :employee_role.sync="form.employee_role" :disabled="isDelete||isSubmit">
		              			</v-employee-role>
		              		</v-col>
		              	</v-row>

		              	<v-row no-gutters fluid class="mb-3"  v-if="AuthRole == 'Admin' && isEdit">
		              		<span class="col d-flex justify-start justify-center"> 
								Accounts, sites, assignments and employee schedule can only be assigned through the employee calendar.
							</span> 
							<div class="col d-flex justify-end">
								<v-btn dark class="mx-2 elevation-0 font-weight-bold calendar-card-button" outlined 
								@click="redirectToCalendar('calendar/monthly',{ 
		              				organization_id:form.organization_id, 
		              				employee_id:form.employee_id,
		              			})" 
		              			:color="'#0057AD'">
									Go to Calendar 
								</v-btn>
							</div>
		              	</v-row>

		              	<!-- -------------------- ASSIGNMENT -------------------- -->
		                <!-- <v-row no-gutters fluid v-show="isEdit" v-if="AuthRole == 'Admin'">
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Assignment </label>
		              			<v-assignment :placeholder="'Assign this assignment to an employee'" 
		              			:readonly="true" :assignment.sync="form.assignment_id" :disabled="isEdit||isDelete||isSubmit" 
		              			:multiple="true" :display_only="true" :clearable="false"
		              			@redirect="redirectToCalendar('calendar/monthly',{ 
		              				organization_id:form.organization_id, 
		              				employee_id:form.employee_id,
		              				view_by:'ASSIGNMENT',
		              			})" >
		              			</v-assignment>
		              		</v-col>
		              	</v-row> -->

						<!-- -------------------- LABEL NOTE ------------------ -->
						<!-- <v-row class="mx-2 my-2" v-show="isEdit" v-if="AuthRole == 'Admin'">
							<span class="d-block text-left bottom-note"> 
								Assignments can only be assigned through the employee calendar 
							</span> 
						</v-row> -->

		              	<!-- -------------------- SCHEDULE ID -------------------- -->
		             <!--  	<v-row v-if="AuthRole == 'Admin'">
		              		<v-col md class="mx-2 py-0">
		              			<label class="d-block text-left font-weight-bold label my-1"> Default Schedule </label>
		              			<v-schedule :value.sync="form.schedule" :disabled="isEdit||isDelete||isSubmit"></v-schedule>
		              		</v-col>
		              	</v-row> -->

		              	<v-divider class="mx-2 my-2"></v-divider>

		              	<!-- -------------------- EMPLOYEE ID -------------------- -->
		              	<v-row no-gutters fluid>
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Employee ID </label>
			             		<v-text-field outlined placeholder="Type Here" dense clearable 
			             		:error-messages="error.get('employee_id')"
			             		:error.sync="error.has('employee_id')"
			             		@input="error.clear('employee_id')"
			             		:rules="[rules.employee_id.required]" 
			             		@keydown="rules.alphaNumericWithDashOnly"
			             		v-model="form.employee_id"
			             		:disabled="isEdit||isSubmit||isDelete">
			             		</v-text-field>
			             	</v-col>
		               
							<!-- <v-col md class="mx-2">
		              				<label class="d-block text-left font-weight-bold label my-1"> Photo </label>
		              				<v-spacer></v-spacer>
		              				<span class="d-block text-right img-format-label my-1">JPG / JPEG</span>
			             		<v-file-input accept="image/jpeg" placeholder="Upload a photo" prepend-icon="" dense outlined @change="image_upload($event,'avatar')" :rules="[rules.avatar.type]":disabled="isEdit||isSubmit||isDelete" v-model="avatar"
				             		:error-messages="error.get('avatar')"
	                        		:error="error.has('avatar')"
	                        		@input="error.clear('avatar')">
                        		</v-file-input>
		              		</v-col> -->

		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Photo </label>
			             		<v-file-input accept="image/jpeg" placeholder="Upload a photo" prepend-icon="" dense outlined @change="image_upload($event,'avatar')" :rules="[rules.avatar.type]":disabled="isEdit||isSubmit||isDelete" v-model="avatar"
				             		:error-messages="error.get('avatar')"
	                        		:error="error.has('avatar')"
	                        		@input="error.clear('avatar')">
                        		</v-file-input>
			             		</v-text-field>
			             	</v-col>
		              	</v-row>

		              	<v-row no-gutters fluid >
		              		<!-- ------------------- EMAIL ADDRESS ------------------- -->
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Email Address </label>
		              			<v-text-field outlined placeholder="Type Here"  dense clearable
		              			:rules="[rules.email.format]"
		              			v-model="form.email"
		              			:disabled="isEdit||isSubmit||isDelete"></v-text-field>
		              		</v-col>

		              		<!-- -------------------- FACE BIO ------------------- -->
		              		<!-- <v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Face Bio </label>
			             		<v-file-input accept="image/jpeg,image/jpg" placeholder="Upload a face bio" prepend-icon="" dense outlined v-model="facebio" @change="image_upload($event,'face_biometrics')" :rules="[rules.facebio.type]" :disabled="isEdit||isSubmit||isDelete"
			             		:error-messages="error.get('facebio')"
                        		:error="error.has('facebio')"
                        		@input="error.clear('facebio')">
                        		</v-file-input>
		              		</v-col> -->
		              	</v-row>

		              	<!-- -------------------- User ID -------------------- -->
		              	<v-row no-gutters fluid v-if="mode == 'edit' || mode == 'delete'">
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> User ID </label>
		              			<v-text-field outlined placeholder="User ID"  dense clearable disabled v-model="form.id" disabled>
		              			</v-text-field>
		              		</v-col>
		              	</v-row>

		              	<v-divider class="mx-2 my-2"></v-divider>

		              	<v-row no-gutters fluid>
		              		
		              		<!-- -------------------- FIRST NAME -------------------- -->
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> First Name </label>
		              			<v-text-field outlined placeholder="Type Here" dense clearable 
		              			:error-messages="error.get('firstname')"
			             		:error.sync="error.has('firstname')"
			             		@input="error.clear('firstname')"
								:rules="[rules.first_name.required,rules.first_name.format]"
								@keydown="rules.stringOnly"
								v-model="form.firstname"
								:disabled="isEdit||isSubmit||isDelete">
								</v-text-field>
		              		</v-col>

		              		<!-- -------------------- LAST NAME -------------------- -->
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Last Name </label>
			             		<v-text-field outlined placeholder="Type Here" dense clearable 
		              			:error-messages="error.get('lastname')"
			             		:error.sync="error.has('lastname')"
			             		@input="error.clear('lastname')"
		              			:rules="[rules.last_name.required,rules.last_name.format]" 
		              			@keydown="rules.stringOnly"
		              			v-model="form.lastname"
		              			:disabled="isEdit||isSubmit||isDelete">
		              			</v-text-field>
		              		</v-col>
		              	</v-row>

		              	<v-row no-gutters fluid>
		              		
		              		<!-- ------------------ MIDDLE NAME ----------------- -->
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Middle Name (optional) </label>
		              			<v-text-field outlined placeholder="Type Here" dense clearable 
		              			:error-messages="error.get('middlename')"
			             		:error.sync="error.has('middlename')"
			             		@input="error.clear('middlename')"
		              			:rules="[rules.middle_name.format]" v-model="form.middlename"
		              			@keydown="rules.stringOnly"
		              			:disabled="isEdit||isSubmit||isDelete">
		              			</v-text-field>
		              		</v-col>

		              		<!-- -------------------- SUFFIX -------------------- -->
		              		<v-col md class="mx-2">
		              			<label class="d-block text-left font-weight-bold label my-1"> Suffix (optional) </label>
			             		<v-text-field outlined placeholder="Type Here" dense clearable
			             		:error-messages="error.get('suffix')"
			             		:error.sync="error.has('suffix')"
			             		@input="error.clear('suffix')"
		              			:rules="[rules.suffix.format]" v-model="form.suffix"
		              			@keydown="rules.stringOnly"
			             		:disabled="isEdit||isSubmit||isDelete">
			             		</v-text-field>
		              		</v-col>
		              	</v-row> 
		        	</v-form>  
				</v-card-text>

				<v-divider></v-divider>

        	    <!-- -------------------- CARD ACTIONS ------------------- -->
	            <v-card-actions class="ma-4">
	            	
	               <!-- ------------- DELETE OR DISCARD ------------- -->
	             <!--   <v-btn dark class="mx-2 elevation-0 font-weight-bold custom-card-button" outlined 
	               @click="mode == 'delete' ? submit(mode) : close()"
	               :color="mode == 'delete'?'red':'#0057AD'"
	               :loading="mode == 'delete'? isSubmit : false ">
	               {{ mode == 'delete' ? 'Delete':'Discard'}} 
	               </v-btn> -->
 				   <v-btn dark class="mx-2 elevation-0 font-weight-bold custom-card-button"
 				    outlined @click="close()" color="#0057AD" :loading="isSubmit">{{'Discard'}}</v-btn>

	               <v-spacer></v-spacer>

	               <!-- ------------- CANCEL OR SUBMIT ------------- -->
	               <v-btn dark color="#0057AD" class="mx-2 elevation-0 font-weight-bold custom-card-button" 
	               @click="mode == 'delete' ? close() : submit(mode)"
	               :loading="mode == 'delete'? false  : isSubmit">
	               {{ mode == 'delete' ? 'Cancel':'Submit' }}
	               </v-btn> 

	            </v-card-actions>

	        </v-card>
	    </v-dialog>
	</v-row>
</template>
<script>

import { rules }  from "@/validation/rules/index";
import { modal }  from "@/mixins/modal";
import { states } from "@/mixins/states";
import { auth }   from "@/mixins/auth";
import { mapGetters, mapActions } from "vuex";

import  Errors  from "@/validation/errors";
import VOrganizaion from "@/components/Fields/Autocomplete/Organization/Index";
import VAccount from "@/components/Fields/Autocomplete/Accounts/Index";
import VSite from "@/components/Fields/Autocomplete/Site/Index";
import VSchedule from "@/components/Fields/Schedule/Index";
import VAssignment from "@/components/Fields/Autocomplete/Assignment/Index";
import VEmployeeRole from "@/components/Fields/Autocomplete/EmployeeRole/Index";

export default {
	mixins:[modal,states,auth],
	components:{
		"v-site":VSite,
		"v-schedule":VSchedule,
		"v-organization":VOrganizaion,
		"v-account":VAccount,
		"v-assignment":VAssignment,
		"v-employee-role":VEmployeeRole
	},
	data(){ 
		return {
			// to check if the field were all valid
			valid: false,

			// to validate the fields 
			rules:rules,

			// to set the data event Add or Edit
			form:{},

			// errors: new Errors()
			error:new Errors(),

			// ------------- STATES ------------------ //

			// to check if loading or not 
			isLoading: false,

			// to check if action is submitting
			isSubmit: false,

			// to check if mode is delete
			isDelete:false,

			// to check if mode is delete
			isEdit:false,

			// to reset the value from form.avatar
			avatar:null,

			// to reset the value from form.facebio
			facebio:null,
		}
	}, 

	beforeMount()
	{   
		this.initialize();
    },

	updated()
	{
		this.setActions();
		this.setAuthOrganizationId();
    },

	methods:{	
		...mapActions(["getCalendar","getScheduleList"]),

		/*
 		* This initialize value values needed
 		*
 		* @return void
 		*/ 
		initialize()
		{
			this.setValues();
			this.getScheduleList();
			this.setAuthOrganizationId();
		},

		/*
 		* To get image into base64 string 
 		*
 		* @return void
 		*/ 
		image_upload(event,type)
		{	
			try {
				if(typeof null != event)
				{
					// new instance of FileReader class 
					const reader = new FileReader();
					
					if(type == "avatar")
					{
						// get the base64 from result from File Reader
						reader.onloadend = ((image) =>{ this.form.avatar =  reader.result; });
					}

				 	else if(type == "face_biometrics")
				 	{
				 		// get the base64 from result from File Reader
						reader.onloadend = ((image) =>{ this.form.facebio =  reader.result; });
				 	}

					// read the data as url from upload payload file
					reader.readAsDataURL(event);
				}
			}

			catch(e)
			{
				if (!(e instanceof TypeError)) 
				{
			      // The exception is not a TypeError, so throw it again.
			      throw e;
			    }
			}
		},

 		/*
 		* To submit values for Add,Edit,Delte
 		*
 		* @return void
 		*/ 
		submit(action = "add")
		{
			switch(action)
			{
				case "add": 
					if(this.$refs.form.validate())
			        {	
			        	this.isSubmit = true;
						this.$store.dispatch("setRegisterEmployee",this.form)
						.then((response) => { this.isSubmit = false; })
						.then((response) => { this.close() })
						.then((response) => { this.redirect("/employees"); })
						.catch((errors)  => { this.error.record(errors.response.data);this.isSubmit = false; })
			        }
				break;

				case "edit": 
					if(this.$refs.form.validate())
					{	
						this.isSubmit = true;
						this.$store.dispatch("setUpdateEmployee",this.form)
						.then((response) => {this.isSubmit = false})
						.then((response) => {this.close()})
						.catch((errors)  => { this.error.record(errors.response.data); this.isSubmit = false; })
					}
				break;

				case "delete": 
				 	this.isSubmit = true;
					this.$store.dispatch("setDeleteEmployee",this.form)
					.then((response) => {this.isSubmit = false})
					.then((response) => {this.close()})
					.catch((errors)  => {})
				break;
			} 
      	},

      	/*
 		* This assign value values to the form object
 		*
 		* @return void
 		*/
      	setValues()
      	{	
  			Object.assign(this.form,this.data);
			Object.assign(this.form,this.getValuesFromObject(this.data));
      	},
 
		/*
		* This sets the values from filters site and account on header 
		*
		* @return void 
		*/ 
		getValuesFromObject(data){
			let values = {};
			
			if(data)
			{
				if(Object.hasOwnProperty.call(data,"employee_id"))
				{
	  				this.form.ref_employee_id = this.data.employee_id;
				}

				if(Object.hasOwnProperty.call(data,"account"))
				{	
					Object.assign(values,{ account: Object.assign({},...data.account).id });
				}

				if(Object.hasOwnProperty.call(data,"site"))
				{	
					Object.assign(values,{ site:data.site.map((data)=>{ return data.id }) });
				}

				if(Object.hasOwnProperty.call(data,"schedule"))
				{	
					Object.assign(values,{ schedule: Object.assign({},...data.schedule).id });
				}

				if(Object.hasOwnProperty.call(data,"assignment"))
				{	
					Object.assign(values,{ assignment_id:data.assignment.map((data)=>{ return data.id }) });
				}

				if(Object.hasOwnProperty.call(data,"contact"))
				{	
					const { email = null } = data.contact || {};
					Object.assign(values,{ email });
				} 
	 
				if(Object.hasOwnProperty.call(data,"work_info"))
				{
					const { assigned_position  = null, outlet = null, position = null, region = null} = data.work_info || {};
					Object.assign(values,{ assigned_position, outlet, position, region });
				}

				if(Object.hasOwnProperty.call(data,"employee_role"))
				{
					Object.assign(values,{ employee_role:Object.assign({},...data.employee_role).id });
				}
				return values;
			}
		},

      	/*
 		* This assign organization id coming from organization admin
 		*
 		* @return void
 		*/
      	setAuthOrganizationId(){
	    	if(this.AuthRole == "Admin")
	    	{
	        	this.form.organization_id = this.AuthOrganization.id;
	        }
      	},

      	/*
 		* This redirect to monthly calendar view
 		*
 		* @return void
 		*/ 	
		redirectToCalendar(to,data){	
			// redirect to monthly calendar table 
			this.$router.push({ path:`${to}`, query:data });

			// close the modal after redirected 
			this.close();
		},

      	/*
 		* This set actions based on modal action mode
 		*
 		* @return void
 		*/
      	setActions(){
      		this.isDelete = this.mode == "delete" ? true : false;
			this.isEdit   = this.mode == "edit" ? true : false;
      	},
	},

	computed:{
		...mapGetters("assignment",{"assignments":"getDropdown"}),
		...mapGetters({"calendars":"getCalendar"}),

		employee(){
			return this.$store.getters.getEmployeeRecord;
		},
	},

	watch:{
		/*
 		* Resets the form.avatar field form
 		*
 		* @return void
 		*/
		avatar(value)
		{
			this.form.avatar = value;
		},

		/*
 		* Resets the form.facebio field form
 		*
 		* @return void
 		*/
		facebio(value)
		{
			this.form.facebio = value;
		},

		/*
 		* Resets the field form
 		*
 		* @return void
 		*/ 
 		dialog(value)
 		{
 			if(!value) { this.$refs.form.reset(); }
 		},
	},
}

</script>

<style type="text/css" scoped>

    .custom-card-button{
		height:40px;
		width:150px;
    }

    .calendar-card-button{
      height:40px;
      width:180px;
    }

   .label {
      color:black;
      font-size: 16px;
   }

   .img-format-label {
      color:#BDBDBD; 
      font-size: 13px;
   }

   .bottom-note{
      color:#BDBDBD; 
      font-size: 13px; 
      margin-top: -20px;
   }

</style> 