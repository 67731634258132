export const getters = {

	/*
	* to get the list of query builder 
	*/
	getQueryBuilderList(state){	
		return state.table.list;
	},
	
	/*
	* to get the boolean value for skeleton loaders
	*/
	getLoading(state){ 
		return state.loading;
	},
}