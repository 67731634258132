// VUE
import Vue from "vue"

// FONT AWESOME 
import "@fortawesome/fontawesome-free/css/all.css";

// MATERIAL
import "@mdi/font/css/materialdesignicons.css"; 

import "material-design-icons-iconfont/dist/material-design-icons.css" 

// VUETIFY 
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.css";

// VUE COLOR THEME
import colors from "vuetify/lib/util/colors"

Vue.use(Vuetify);

export default new Vuetify({
	icons: { iconfont:"fa", iconfont:"mdi",iconfont:"md" },
	dark: true
});

